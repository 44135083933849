import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { filesActions } from 'bus/files/actions';
import { deleteFile } from 'api/methods/files/uploads';

export function* deleteUploadsFileWorker({ payload }) {
  const { filename, options, type } = payload;
  const UI = get(options, 'UI', false);

  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['editor', 'deleteFileBrowser', filename],
  })));

  try {
    yield call(deleteFile, token, { pathParams: { tf }, bodyParams: { filename, type } });
    yield put(filesActions.deleteUploadsFileSuccess(filename));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = 'Невалидный запрос';

        break;
      }
      case 403: {
        message = 'Отказано в доступе. Отсутствует доступ к загрузкам данной турфирмы';

        break;
      }
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['editor', 'deleteFileBrowser', filename, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['editor', 'deleteFileBrowser', filename, 'message'] })));
    yield put(filesActions.deleteUploadsFileFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['editor', 'deleteFileBrowser', filename, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['editor', 'deleteFileBrowser', filename, 'completed'] })));
  }
}
