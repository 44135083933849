import { put, takeEvery, call, race, take, select } from 'redux-saga/effects';

import { bookingActions } from 'bus/booking/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { createBookingMessageWorker } from 'bus/booking/saga/workers/bookingMessages';
import { getToken } from 'bus/auth/selectors';

import { createAccessCookie } from 'api/methods/auth/createAccessCookie';

import { showConfirmModal } from 'components/ConfirmModal';

import { newInstance } from 'localization';

import { autoInvoiceGenerate, invoiceRequest } from './actions';

import { INVOICE_REQUEST_UI } from './constants';

function* autoInvoiceGenerateSaga() {
  yield put(registerUI(createUi({ loading: true }), INVOICE_REQUEST_UI));

  yield put(bookingActions.autoGenerateInvoice());

  const [success] = yield race([
    take(bookingActions.autoGenerateInvoiceSuccess),
    take(bookingActions.autoGenerateInvoiceFail),
  ]);
  if (success) {
    const token = yield select(getToken);
    yield call(createAccessCookie, token);

    const { id } = success.payload;

    window.open(`/api/accounting/invoices/${id}.pdf`, '_self');
  } else {
    yield call(createBookingMessageWorker, bookingActions.createBookingMessage(
      { text: newInstance.t('INVOICES:INVOICE_REQUEST_MESSAGE') }
    ));

    yield put(showConfirmModal({
      props: {
        title: newInstance.t('INVOICES:INVOICE_REQUEST_MODAL_HEADER'),
        content: newInstance.t('INVOICES:INVOICE_REQUEST_MODAL_CONTENT'),
        confirmButtonText: 'Ok',
        cancelButton: false,
        isCentered: true
      },
    }));
  }

  yield put(updateUI({ completed: true, loading: false }, INVOICE_REQUEST_UI));
}

function* invoiceRequestSaga() {
  yield put(registerUI(createUi({ loading: true }), INVOICE_REQUEST_UI));

  yield call(createBookingMessageWorker, bookingActions.createBookingMessage(
    { text: newInstance.t('INVOICES:INVOICE_REQUEST') }
  ));

  yield put(showConfirmModal({
    props: {
      title: newInstance.t('INVOICES:INVOICE_REQUEST_SENT'),
      content: newInstance.t('INVOICES:INVOICE_REQUEST_SENT'),
      confirmButtonText: 'Ok',
      cancelButton: false,
      isCentered: true
    },
  }));

  yield put(updateUI({ completed: true, loading: false }, INVOICE_REQUEST_UI));
}

export default function* () {
  yield takeEvery(autoInvoiceGenerate, autoInvoiceGenerateSaga);
  yield takeEvery(invoiceRequest, invoiceRequestSaga);
}
