import { takeEvery } from 'redux-saga/effects';

// instruments
import { updateTfProfileWorkerMediator } from './watchers/updateTfProfileWorkerMediator';
import { updateTfImage, updateTFProfile, updateTFProfileInfo } from '../actions';
import { updateTFProfileInfoWorker } from './watchers/updateTFProfileInfo';
import { updateTfImageWorker } from './watchers/updateTfImage';

export const updateTfProfileMediatorWatcher = {
  * updateTfProfileWorkerMediator() {
    yield takeEvery(updateTFProfile, updateTfProfileWorkerMediator);
  },
  * updateTfProfileInfoMediator() {
    yield takeEvery(updateTFProfileInfo, updateTFProfileInfoWorker);
  },
  * updateTfImageMediator() {
    yield takeEvery(updateTfImage, updateTfImageWorker);
  },
};
