import { put, takeEvery, race, take } from 'redux-saga/effects';

import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import {
  accountingActions
} from 'bus/accounting/actions';

import loader from 'containers/modalsDispatcher/loader';

import { BOOTSTRAP_UI } from './constants';
import { showPaymentModal } from './actions';

const PAYMENT_MODAL = 'PAYMENT_MODAL ';

const showModal = loader({
  key: PAYMENT_MODAL,
  getModal: () => import(/* payment-modal */'../PaymentModal'),
});

function* showPaymentModalSaga({ payload: { values, callbacks } }) {
  try {
    const { onSuccess, onPaymentTypeChange } = callbacks;

    yield put(registerUI(createUi({ loading: true }), BOOTSTRAP_UI));

    const isEditMode = Boolean(values.id);

    yield put(showModal({
      props: { isEditMode, onSuccess, onPaymentTypeChange },
    }));

    if (isEditMode) {
      yield put(accountingActions.getPayment(values.id));

      yield race([
        take(accountingActions.getPaymentSuccess),
        take(accountingActions.getPaymentFail),
      ]);
    } else {
      yield put(accountingActions.getDefaultPayment(values));

      yield race([
        take(accountingActions.getDefaultPaymentSuccess),
        take(accountingActions.getPaymentFail),
      ]);
    }
  } catch (e) {
    yield put(updateUI({ error: true }, BOOTSTRAP_UI));
  } finally {
    yield put(updateUI({ loading: false, completed: true }, BOOTSTRAP_UI));
  }
}
export function* paymentModalSaga() {
  yield takeEvery(showPaymentModal, showPaymentModalSaga);
}
