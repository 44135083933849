import { select, put, call } from 'redux-saga/effects';

import { unsubscription } from 'api/methods/subscription';
import { getErrorMessage } from 'api/fn/parseErrors';

import { authActions } from 'bus/auth/actions';
import { getToken, getUserTfId } from 'bus/auth/selectors';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { SUBSCRIPTION_UI } from 'containers/subscription/NotifyBalanceInfo/store/saga';

// TODO_ERROR
export function* unsubscriptionWorker() {
  const token = yield select(getToken);
  const tf = yield select(getUserTfId);

  yield put(registerUI(createUi({ loading: true }), SUBSCRIPTION_UI));

  try {
    yield call(unsubscription, token, { pathParams: { tf } });
  } catch (error) {
    const { status } = error.msg || {};

    const isShowError = status !== 406;

    const message = getErrorMessage(
      error,
      {
        400: 'Уже отписаны',
        403: 'Отказано в доступе',
        409: 'Не удалось отписаться, обратитесь в техподдержку',
      }
    );

    isShowError && (yield put(updateUI({ error, message: message ? message : 'Ошибка сервера' }, SUBSCRIPTION_UI)));
  } finally {
    yield put(updateUI({ loading: false, completed: true }, SUBSCRIPTION_UI));
    yield put(authActions.fetchMyself());
  }
}
