import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

export const getOperatorCredentials = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/operator-credentials',
  method: 'GET',
  ...params,
})
  .setParseBodyToClientSchema(
    ({ operator_credentials: operatorCredentials, ...rest }) => ({
      ...rest,
      operatorCredentials: R.map(
        ({ is_deleted: isDeleted, ...credential }) => ({ ...credential, isDeleted }),
        operatorCredentials
      )
    })
  )
  ._load({ token });

export const createOperatorCredentials = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/operator-credentials',
  method: 'POST',
  ...params,
})._load({ token });

export const updateOperatorCredential = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/operator-credentials/{:credentialId}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteOperatorCredential = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/operator-credentials/{:credentialId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
