import { call, put, take } from 'redux-saga/effects';

import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { isDownloadedComponent, saveComponent } from '../componentsCache';

import { load, open, setup } from './actions';

function* loadModalWorker() {
  while (true) {
    const { payload: { getModal, key, onClose, props, mapActions2Store } } = yield take(load);

    yield put(registerUI(createUi(), key));
    yield put(setup({ key, onClose, props, mapActions2Store }));

    if (!isDownloadedComponent(key)) {
      yield put(updateUI({ loading: true }, key));

      const { default: Component } = yield call(getModal);

      saveComponent(key, Component);
    }
    yield put(updateUI({ loading: false, completed: true }, key));
    yield put(open(key));
  }
}

export default loadModalWorker;
