import { call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';
import * as R from 'ramda';

import { updateAccountingPayment } from 'api/methods/accounting';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { isSeverancePayment, PAYMENT_STATUSES, SEVERANCE_PAYMENT_STATUSES } from 'dictionary/payments';

export const mapPayment2Apy = R.when(
  ({ type, status }) => isSeverancePayment(type) && status === PAYMENT_STATUSES.SENDING,
  payment => R.mergeAll([
    payment,
    {
      status: PAYMENT_STATUSES.PROCESSED,
      outgoing_status: SEVERANCE_PAYMENT_STATUSES.SENDING,
    }
  ])
);

export function* updatePaymentWorker({ payload }) {
  const { values, id, formik, onSuccess } = payload;

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  try {
    const payment = yield call(
      updateAccountingPayment,
      token,
      { pathParams: { id }, bodyParams: mapPayment2Apy(values) }
    );

    if (onSuccess) onSuccess(payment);

    yield call(formik.setStatus, { success: true, message: 'Платеж успешно обновлен' });
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Невозможно создать платёж на/от удалённого контрагента');
        } else {
          message = 'Невозможно создать платёж на/от удалённого контрагента';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
