import { put, select, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

// instruments
import { financeActions } from 'bus/finance/actions';
import { getPayments } from 'api/methods/finance/getPayments';
import { compile } from 'helpers/hash';

export function* getPaymentsWorker({ payload: formikAPI }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { query, page, searchString } = yield select(({ finance, router }) => ({
    query: finance.getIn(['paymentList', 'query']),
    page: finance.getIn(['paymentList', 'page']),
    searchString: router.location.search,
  }));
  const search = new URLSearchParams(searchString);

  search.set('form', compile(query));
  search.set('page', page);

  yield put(replace(`?${search.toString()}`));

  try {
    const {
      data,
      total,
      countOnPage: count
    } = yield call(getPayments, token, { bodyParams: { ...query, page } });

    yield put(financeActions.getPaymentsSuccess(data, total, count));
  } catch (error) {
    const { status } = error;

    let message = null;

    switch (status) {
      case 403: {
        message = 'Пользователь не имеет прав на просмотр платежей';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formikAPI.setStatus, { error: message });

    yield put(financeActions.getPaymentsFail(error));
  } finally {
    yield call(formikAPI.setSubmitting, false);
  }
}
