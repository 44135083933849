import { put, call, select } from 'redux-saga/effects';

import { bookingActions as actions } from 'bus/booking/actions';
import { withUIWorker } from 'bus/ui/helpers';

import { getBookingCash } from 'api/methods/booking';

import { getToken } from 'bus/auth/selectors';

export const BASE_UI_PATH = 'BOOKING_CASH';

function* getBookingCashSaga({ payload: bookingId }) {
  const token = yield select(getToken);

  try {
    const cash = yield call(getBookingCash, token, { pathParams: { bookingId } });

    yield put(actions.getBookingCashSuccess(cash));
  } catch (error) {
    yield put(actions.getBookingCashFail(error));
  }
}

export const getBookingCashWorkerWithUI = withUIWorker(
  [actions.getBookingCashFail, actions.getBookingCashFail],
  BASE_UI_PATH,
)(getBookingCashSaga);
