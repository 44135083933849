import * as R from 'ramda';
import { parsePath } from 'history';
import { matchPath } from 'react-router-dom';

import book from 'routes/book';

import { DEFAULT_REDIRECT_PATH, DOMAINS_WHITE_LIST } from './constants';

const transformRoutesToList = routes => R.call(
  R.pipe(
    R.values,
    R.reduce(
      (acc, route) => acc.concat(typeof route === 'object'
        ? transformRoutesToList(route)
        : route),
      []
    )
  ),
  routes
);

const isValidPath = pathname => R.any(
  path => matchPath(pathname, { path }),
  transformRoutesToList(book)
);

export const getIsValidOuterURL = refParam => {
  try {
    const { origin } = new URL(refParam);

    return DOMAINS_WHITE_LIST.includes(origin);
  } catch (_) {
    return false;
  }
};
export const getLinkFromRef = refParam => {
  const { pathname } = parsePath(refParam);

  return isValidPath(pathname)
    ? refParam
    : DEFAULT_REDIRECT_PATH;
};
