import { handleActions, combineActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { commonActions as actions } from './actions';

const inititalState = Map({
  operators: [],
  operatorsPagination: {
    total: 0,
    limit: 0,
  },
  docs: [],
  managers: [],
  departments: [],
  tags: [],
  offices: [],
  regions: [],
  details: [],
  fieldset: [],
  docsByOperators: [],
  ipassportFieldSet: [],
  patterns: [],
  roles: [],
  notificationTypes: [],
  countries: [],
  uniqueActiveEpassports: {},
  smsPrices: null,
  creditCoeffs: [],
  claimSource: [],
  closingReasons: [],
  documentTypes: []
});

export default handleActions({
  [combineActions(actions.getOperatorsMtSuccess, actions.clearOperators)]: (state, { payload: operators }) => state.set('operators', operators),
  [actions.getOperatorsMtDocsSuccess]: (state, { payload: docs }) => state.set('docs', docs),
  [actions.setOperatorsPagination]: (state, { payload: pagination }) => state.set('operatorsPagination', pagination),
  [actions.updateOperatorSuccess]: (state, { payload }) => {
    const updateTagIndex = state.get('operators').findIndex(operator => operator.id === payload.id);

    return state.setIn(['operators', updateTagIndex], payload);
  },
  [actions.createOperatorSuccess]: (state, { payload: operator }) => {
    return state.update('operators', list => [operator, ...list]);
  },
  [actions.getManagersSuccess]: (state, { payload: managers }) => state.set('managers', managers),
  [actions.closingReasonsSuccess]: (state, { payload: reasons }) => state.set('closingReasons', reasons),
  [actions.getDepartmentsSuccess]: (state, { payload: departments }) => state.set('departments', departments),
  [actions.getTagsSuccess]: (state, { payload: tags }) => state.set('tags', tags),
  [actions.deleteTagSuccess]: (state, { payload: id }) => {
    return state.updateIn(['tags'], tags => tags.filter(tag => tag.id !== id));
  },
  [actions.deleteOperatorSuccess]: (state, { payload: id }) => {
    return state.updateIn(['operators'], operators => operators.filter(operator => operator.id !== id));
  },
  [actions.updateTagSuccess]: (state, { payload }) => {
    const updateTagIndex = state.get('tags').findIndex(tag => tag.id === payload.id);

    return state.setIn(['tags', updateTagIndex], payload);
  },
  [actions.createTagSuccess]: (state, { payload: tag }) => state.updateIn(['tags'], tags => [...tags, tag]),
  [actions.getOfficesSuccess]: (state, { payload: offices }) => state.set('offices', offices),
  [actions.createOfficeSuccess]: (state, { payload: office }) => state.updateIn(['offices'], offices => [...offices, office]),
  [actions.deleteOfficeSuccess]: (state, { payload: id }) => {
    return state.updateIn(['offices'], offices => offices.filter(office => office.id !== id));
  },
  [actions.updateOfficeSuccess]: (state, { payload }) => {
    const updateOfficeIndex = state.get('offices').findIndex(office => office.id === payload.id);

    return state.setIn(['offices', updateOfficeIndex], payload);
  },
  [actions.getRegionsSuccess]: (state, { payload: regions }) => state.set('regions', regions),
  [actions.getDocumentsTypesSuccess]: (state, { payload: types }) => state.set('documentTypes', types),
  [actions.createRegionSuccess]: (state, { payload: region }) => state.updateIn(['regions'], regions => [...regions, region]),
  [actions.deleteRegionSuccess]: (state, { payload: id }) => {
    return state.updateIn(['regions'], regions => regions.filter(region => region.id !== id));
  },
  [actions.updateRegionSuccess]: (state, { payload }) => {
    const updateRegionIndex = state.get('regions').findIndex(region => region.id === payload.id);

    return state.setIn(['regions', updateRegionIndex], payload);
  },
  [actions.updateDetailSuccess]: (state, { payload: { id, detail } }) => {
    const updateDetailIndex = state.get('details').findIndex(item => item.id === id);

    return state
      .update('details', detailList => detailList.map(item => (detail.use_online ? { ...item, use_online: false } : item)))
      .mergeIn(['details', updateDetailIndex], detail);
  },
  [actions.createDetailSuccess]: (state, { payload: { tempID, detail } }) => {
    const updateDetailIndex = state.get('details').findIndex(({ id }) => id === tempID);

    return state
      .update('details', detailList => detailList.map(item => (detail.use_online ? { ...item, use_online: false } : item)))
      .setIn(['details', updateDetailIndex], detail);
  },
  [combineActions(
    actions.uploadDetailStampSuccess,
    actions.deleteDetailStampSuccess
  )]: (state, { payload: { id, url } }) => {
    const index = state.get('details').findIndex(item => item.id === id);

    return state.setIn(['details', index, 'stamp_url'], url);
  },
  [actions.addDetail]: (state, { payload: detail }) => state.setIn(['details', state.getIn(['details', 'length'], 0)], detail),
  [actions.getDetailsSuccess]: (state, { payload }) => state.set('details', payload.details).set('fieldset', payload.fieldset),
  [actions.deleteDetailSuccess]: (state, { payload: id }) => state.updateIn(['details'], details => details.filter(detail => detail.id !== id)),
  [actions.getDocsByOperatorsSuccess]: (state, { payload: list }) => state.set('docsByOperators', list),
  [actions.fetchIpassportFieldSetSuccess]: (state, { payload: fieldSet }) => state.set('ipassportFieldSet', fieldSet),
  [actions.getPatternsSuccess]: (state, { payload: patterns }) => state.set('patterns', patterns),
  [actions.getRolesSuccess]: (state, { payload: roles }) => state.set('roles', roles),
  [actions.getNotificationTypesSuccess]: (state, { payload: types }) => state.set('notificationTypes', types),
  [actions.getCountriesSuccess]: (state, { payload: countries }) => state.set('countries', countries),
  [actions.getUniqueActiveEpassportsSuccess]: (state, { payload }) => {
    const { clientID, epassports } = payload;

    return state.setIn(['uniqueActiveEpassports', String(clientID)], epassports);
  },
  [actions.getSmsPricesSuccess]: (state, { payload: prices }) => state.set('smsPrices', prices),
  [actions.getCreditCoeffsSuccess]: (state, { payload: data }) => state.set('creditCoeffs', data),

  [actions.getClaimSourceSuccess]: (state, { payload: source }) => state.set('claimSource', source),
  [actions.deleteClaimSourceSuccess]: (state, { payload: sourceId }) => {
    return state.updateIn(['claimSource'], sources => sources.filter(({ id }) => id !== sourceId));
  },
  [actions.updateClaimSourceSuccess]: (state, { payload: source }) => {
    const idx = state.get('claimSource').findIndex(item => item.id === source.id);

    return state.setIn(['claimSource', idx], source);
  },
  [actions.createClaimSourceSuccess]: (state, { payload: source }) => {
    return state.updateIn(['claimSource'], sources => [...sources, source]);
  },
}, inititalState);
