import * as R from 'ramda';
import { map } from 'ramda';
import { call, select } from 'redux-saga/effects';

import { getOtpuskToken, getUserOrTfLocale } from 'bus/auth/selectors';
import { getCountries } from 'bus/common/selectors';

import { LOCALES } from 'localization/constants';

import { recalculateBookingServices } from 'api/methods/tourscaner';

import { getLangForOtpuskApi } from './heplers';

const getCoutryName = (countries, id) => R.pipe(
  R.path([id, 'translations', LOCALES.EN]),
  R.toUpper
)(countries);

export function* recalculateBookingServicesSaga({
  payload: {
    callbacks,
    currency,
    offer,
    services,
    bookingEntity
  }
}) {
  try {
    const lang = yield select(getUserOrTfLocale);
    const otpuskToken = yield select(getOtpuskToken);
    const countries = yield select(state => {
      return R.indexBy(
        R.prop('id'),
        getCountries(state)
      );
    });

    const response = yield call(recalculateBookingServices, {
      queryParams: {
        access_token: otpuskToken,
        currencyLocal: currency,
        lang: getLangForOtpuskApi(lang)
      },
      pathParams: { offerID: offer.id },
      bodyParams: {
        services: R.map(
          R.applySpec({
            id: R.prop('id'),
            name: R.prop('name'),
            number: R.prop('selectedTourists'),
            type: R.prop('external_type'),
          }),
          services
        ),
        total_sum: offer.price[currency],
        currency,
        ...bookingEntity?.passports
          ? {
            tourists: map(
              ({
                citizenship,
                name,
                birthday,
                passportExpireDate,
                passport,
                sex,
                surname
              }) => ({
                category: sex,
                first_name: name,
                last_name: surname,
                birth_date: birthday,
                passport_expiry_date: passportExpireDate,
                passport_number: passport,
                citizenship: getCoutryName(countries, citizenship),
                fiscal_code: '',
                phone: bookingEntity.phone,
              }),
              bookingEntity.passports
            )
          }
          : {},
      }
    });

    if (response.status === 'error') throw new Error(response);

    const {
      currency_original: currencyOriginal,
      currency: responseCurrency,
      price,
      price_original: priceOriginal,
      rate
    } = response;

    callbacks?.onSuccess({
      price: {
        [responseCurrency]: price,
        [currencyOriginal]: priceOriginal,
      },
      rate,
    });
  } catch (error) {
    callbacks?.onFail(error);
  }
}
