import React, { memo } from 'react';

// styles
import cx from 'classnames';
import Styles from './styles.scss';

const W = ({ className, children }) => {
  return (
    <div className={cx(className)} id={Styles.root}>
      { children }
    </div>
  );
};

W.displayName = 'ContextMenuWrapper';
export const ContextMenuWrapper = memo(W);
