import { takeEvery } from 'redux-saga/effects';

// actions
import { commentsActions as actions } from 'bus/clientClaim/comments';

// workers
import { createCommentBaseWorker } from './workers/create/base';
import { updateCommentBaseWorker } from './workers/update/base';
import { dismissCommentBaseWorker } from './workers/dismiss/base';
import { deleteCommentBaseWorker } from './workers/delete/base';
import { submitCommentFormWorker } from './workers/forms/submitCommentForm';

export const commentsWatchers = Object.freeze({
  * createCommentWatcher() {
    yield takeEvery(actions.createComment, createCommentBaseWorker);
  },
  * updateCommentWatcher() {
    yield takeEvery(actions.updateComment, updateCommentBaseWorker);
  },
  * dismissCommentWatcher() {
    yield takeEvery(actions.dismissComment, dismissCommentBaseWorker);
  },
  * deleteCommentWatcher() {
    yield takeEvery(actions.deleteComment, deleteCommentBaseWorker);
  },
  * submitCommentFormWatcher() {
    yield takeEvery(actions.submitCommentForm, submitCommentFormWorker);
  },
});
