import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { MyError } from 'api/fn/Error';
import { createSource } from 'api/methods/common/createSource';
import { commonActions as actions } from 'bus/common/actions';

export function* createClaimSourceWorker({ payload }) {
  const { description, formik } = payload;
  const { token, sources } = yield select(({ auth, common }) => ({
    token: auth.get('token'),
    sources: common.get('claimSource'),
  }));

  try {
    const isDuplicate = sources.some(item => item.description.trim() === description.trim());

    if (isDuplicate) {
      throw new MyError({ status: 400, body: { path: 'description', message: 'Название не уникальное' } });
    }

    const response = yield call(createSource, token, { bodyParams: { description } });

    yield put(actions.createClaimSourceSuccess(response));
    yield call(formik.setStatus, { success: true });
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        if (body.path) {
          yield call(formik.setFieldError, body.path, body.message);
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
