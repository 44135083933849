import { call, select, put } from 'redux-saga/effects';

// instruments
import { getValueNotifyByType } from 'api/methods/tf/notify';
import { uiActions } from 'bus/ui/actions';
import { notifyActions } from 'bus/notify';

export function* getNotifyValueWorker({ payload }) {
  const { type } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const tfID = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['notify', 'valueNotify'],
  }));

  try {
    const notify = yield call(getValueNotifyByType, token, { pathParams: { tfID, type } });

    yield put(notifyActions.getNotifyValueSuccess(notify));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['notify', 'valueNotify', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['notify', 'valueNotify', 'message'],
    }));
    yield put(notifyActions.getNotifyValueFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['notify', 'valueNotify', 'loading'],
    }));
  }
}
