import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { history } from '../history';

// Instruments
import errorMiddleware from './errorMiddleware';
import createSentryMiddleware from './sentry';

const routerMiddleware = createRouterMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const sentryMiddleware = __PROD__ && createSentryMiddleware();

const middleware = [
  routerMiddleware,
  sagaMiddleware,
  errorMiddleware,
  sentryMiddleware
].filter(o => o);

export { middleware, sagaMiddleware };
