export const SEARCH_FLEXIBLE_REPORT_COOKIE_KEY = 'searchFlexibleReports';
export const SEARCH_FLEXIBLE_BONUSES_COOKIE_KEY = 'searchFlexibleBonusesReports';
export const SEARCH_FLEXIBLE_REPORT_UI = 'SEARCH_FLEXIBLE_REPORT_UI';
export const GET_BONUSES_STAT_UI = 'GET_BONUSES_STAT_UI';
export const GET_BONUSES_STAT_COOKIE_KEY = 'GET_BONUSES_STAT_COOKIE_KEY';
export const SALES_FUNNEL_COOKIE_KEY = 'SalesFunnelStatistic';

export const EMAIL_VALUES = {
  1: { emailEmpty: true },
  2: { emailEmpty: false },
  3: { notSendMarketing: false },
  4: { notSendMarketing: true },
};
