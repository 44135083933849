import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { detailsFieldsActions } from 'bus/detailsFields/actions';
import { deleteDetailFieldTf } from 'api/methods/tf/fields';

const BASE_UI_PATH = ['detailFields', 'fields', 'delete'];

export function* deleteFieldWorker({ payload }) {
  const { id, object } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: [...BASE_UI_PATH, id],
  }));

  try {
    yield call(deleteDetailFieldTf, token, { pathParams: { id } });

    yield put(detailsFieldsActions.removeField(object, id));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, id, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, id, 'message'] }));
    yield put(detailsFieldsActions.deleteFieldFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, id, 'loading'],
    }));
  }
}
