import React from 'react';

import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';
import { generatePath, Link } from 'react-router-dom';

import { createClaimBooking, updateClaimBooking } from 'api/methods/claim/booking';
import { deleteBooking } from 'api/methods/booking';

import { clientClaimActions } from 'bus/clientClaim/actions';
import { bookingActions as actions } from 'bus/booking/actions';
import { uiActions } from 'bus/ui/actions';

import { newInstance } from 'localization';

import { DURATIONS, showToastSuccess } from 'services/toaster';

import book from 'routes/book';

const BASE_UI_PATH = ['booking', 'managingBooking'];

const getErrors = (error, action) => {
  const { status, body } = error.msg || {};

  switch (status) {
    case 400: return body.violations.reduce((res, item) => {
      res[item.property_path] = item.message;

      return res;
    }, {});
    case 403: return get(body, 'message', `Отсутствует доступ на ${action} бронирования`);
    case 404: return 'Заказ не найден';
    case 409: return get(body, 'message', 'Данная заявка привязана к другому заказу');
    default: return 'Ошибка сервера';
  }
};

export function* createClaimBookingWorker({ payload }) {
  const { claimID, booking, formik, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const onSuccess = get(workerOptions, 'onSuccess', null);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null, isEnableRetie: false },
    path: BASE_UI_PATH,
  })));

  try {
    const response = yield call(
      createClaimBooking,
      token,
      { pathParams: { id: claimID }, bodyParams: booking }
    );

    UI && (yield put(actions.managingBookingSuccess(response.claim)));
    formik && (yield put(clientClaimActions.createClaimBookingSuccess(response)));

    yield put(actions.updateBookingSuccess(response));

    const { claim } = response;

    if (onSuccess) {
      onSuccess(claim);
    }

    // TODO NT
    showToastSuccess(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <b>{newInstance.t('BOOKING_CONNECT_MODAL:CONNECT_WITH_CLAIM')}</b>
        <div>
          <Link
            to={`${generatePath(book.clientClaim.children.claim, {
              clientId: claim.client.id,
              claimId: claim.id
            })}?active_step=bron`}
          >
            {newInstance.t('BOOKING_CONNECT_MODAL:TO_CLAIM')}
          </Link>
        </div>
      </div>,
      { duration: DURATIONS.LONG }
    );
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = 'Ошибка сервера';
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ на создание бронирования');
        break;
      }
      case 404: {
        message = 'Заказ не найден';
        break;
      }
      case 409: {
        const opClaimId = booking.op_claim_id;
        const resOpClaimId = get(body, 'op_claim_id', null);

        message = opClaimId === resOpClaimId
          ? newInstance.t('ERRORS:CONNECTING_BOOKING_THIS_BOOKING_CONNECTED')
          : newInstance.t('ERRORS:CONNECTING_BOOKING_OTHER_BOOKING_CONNECTED');

        resOpClaimId && (yield put(actions.managingBookingSuccess(body.claim)));
        UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'isEnableRetie'] })));
        break;
      }
      default: return 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: claimID, path: [...BASE_UI_PATH, 'claimID'] })));
    yield put(clientClaimActions.createClaimBookingFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
    formik && (yield call(formik.setStatus, { success: true, message: newInstance.t('TOUR:BOOKED') }));
    formik && (yield call(formik.setSubmitting, false));
  }
}

export function* deleteClaimBookingWorker({ payload }) {
  const { bookingId, formik, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['clientClaim', 'deleteClaimBooking'],
  })));

  try {
    yield call(deleteBooking, token, { pathParams: { id: bookingId } });
    yield put(clientClaimActions.deleteClaimBookingSuccess());
    formik && (yield call(formik.setStatus, { success: true, message: 'Бронь удалена' }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'deleteClaimBooking', 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['clientClaim', 'deleteClaimBooking', 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['clientClaim', 'deleteClaimBooking', 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'deleteClaimBooking', 'completed'] })));
  }
}

export function* updateClaimBookingWorker({ payload }) {
  const { booking: { id, ...booking }, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const response = yield call(
      updateClaimBooking,
      token,
      { pathParams: { id }, bodyParams: booking }
    );

    yield put(clientClaimActions.updateClaimBookingSuccess(response));
    formik && (yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') }));
  } catch (error) {
    const computedError = getErrors(error, 'изменение');

    if (computedError instanceof Object) {
      formik && (yield call(formik.setErrors, computedError));
    } else if (typeof computedError === 'string') {
      formik && (yield call(formik.setStatus, { error: true, message: computedError }));
    }

    yield put(clientClaimActions.updateClaimBookingFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
