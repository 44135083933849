import * as R from 'ramda';

import { createUiSelector } from 'bus/ui/helpers';

import { BUS_TICKETS_BOOTSTRAP_UI } from './constants';

const domain = ({ busTickets }) => busTickets;

export const getBookingIdentifier = R.pipe(
  domain,
  R.prop('identifier'),
);

export const getBusBookingUI = createUiSelector(BUS_TICKETS_BOOTSTRAP_UI);
