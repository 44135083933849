import { all, put, race, select, take } from 'redux-saga/effects';
import * as R from 'ramda';

import { ACTIVE_STEPS } from 'helpers/dictionary';

import { commonActions } from 'bus/common/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { getOperatorByOtpuskId, getOperators } from 'bus/common/selectors';
import { getRoomByName, getStaticLists } from 'bus/clientClaim/selectors.js';
import { stepersActions } from 'bus/clientClaim/stepers';

import {
  convertBasketOfferToClaimOffer,
  convertOfferToClaimOffer,
  createTouchedClaim
} from './helpers';

export function* setTouchedClaimWorker({ payload, type }) {
  const { callbacks, offer, hotel } = payload;
  const isSetOperators = yield select(state => !R.isEmpty(getOperators(state)));
  const isSetStaticLists = yield select(state => !getStaticLists(state).isEmpty());

  !isSetOperators && (yield put(commonActions.getOperatorsMt()));
  !isSetStaticLists && (yield put(clientClaimActions.getStaticLists()));

  const resultsFetching = yield all([
    !isSetOperators && race([
      take(commonActions.getOperatorsMtSuccess),
      take(commonActions.getOperatorsMtFail)
    ]),
    !isSetStaticLists && race([
      take(clientClaimActions.getStaticListsSuccess),
      take(clientClaimActions.getStaticListsFail)
    ])
  ]);

  const [operatorsResults, staticListsResults] = resultsFetching;

  try {
    if (operatorsResults) {
      const [, fail] = operatorsResults;

      if (fail) {
        throw new Error('Ошибка при загрузке операторов');
      }
    }

    if (staticListsResults) {
      const [, fail] = staticListsResults;

      if (fail) {
        throw new Error('Ошибка при загрузке статического списка');
      }
    }

    const isOfferFromBasket = type === clientClaimActions.setTouchedClaimFromBasket.toString();

    const claimOffer = isOfferFromBasket
      ? convertBasketOfferToClaimOffer(offer)
      : convertOfferToClaimOffer(offer);

    const operator = yield select(state => getOperatorByOtpuskId(state, { id: claimOffer.operator }));
    const room = yield select(state => getRoomByName(state, { name: claimOffer.room }));

    const touchedClaim = createTouchedClaim(
      claimOffer,
      hotel,
      operator,
      room
    );

    yield put(clientClaimActions.setTouchedClaimSuccess(touchedClaim));
    yield put(stepersActions.setActiveStep(ACTIVE_STEPS.getIn(['CONTRACT', 'marker'])));

    callbacks?.onSuccess();
  } catch (error) {
    yield put(clientClaimActions.setTouchedClaimFail(error.message));

    callbacks?.onFail(error);
  }
}
