import { takeEvery, put, select, call } from 'redux-saga/effects';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getBookingAdminSearchOrder, getQuery } from 'bus/booking/selectors';
import { prepareAdminBookingValues } from 'bus/booking/saga/workers/searchBooking/helpers';

import { removeSearch, saveSearch, startRemoveSearch, startSaveSearch } from './actions';
import { getSavedSearches } from './selectors';
import { saveSearchesToStorage } from './helpers';

const UI_KEY = 'SAVE_SEARCH';
function* startSaveSearchSaga({ payload: { title, onSuccess } }) {
  yield put(registerUI(createUi({ loading: true }), UI_KEY));

  try {
    const formValues = yield select(getQuery);
    const order = yield select(getBookingAdminSearchOrder);
    const currentSearches = yield select(getSavedSearches);

    const searchEntity = {
      id: uuid(),
      title,
      search: prepareAdminBookingValues({ ...formValues, order }),
    };

    yield call(saveSearchesToStorage, R.append(searchEntity, currentSearches));

    yield put(saveSearch(searchEntity));
  } catch (error) {
  //   todo
  }

  onSuccess && onSuccess();
  yield put(updateUI({ completed: true, loading: false }, UI_KEY));
}
function* startRemoveSearchSaga({ payload: searchId }) {
  try {
    const currentSearches = yield select(getSavedSearches);

    yield call(saveSearchesToStorage, R.filter(({ id }) => id !== searchId, currentSearches));

    yield put(removeSearch(searchId));
  } catch (error) {
  //   todo
  }
}

export default function* watcher() {
  yield takeEvery(startSaveSearch, startSaveSearchSaga);
  yield takeEvery(startRemoveSearch, startRemoveSearchSaga);
}
