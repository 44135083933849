import { Map } from 'immutable';
import moment from 'moment';

const DATE_FORMAT = 'DD.MM.YYYY';

export const createQueryFromApi = query => {
  const dateCreator = value => (moment(value, DATE_FORMAT, true).isValid() ? moment(value, DATE_FORMAT).format('YYYY-MM-DD') : null);

  const QUERY_CREATORS = {
    paid_from: (key, value) => [key, dateCreator(value)],
    paid_to: (key, value) => [key, dateCreator(value)],
    date_from: (key, value) => [key, dateCreator(value)],
    date_to: (key, value) => [key, dateCreator(value)],
    check_in_from: (key, value) => [key, dateCreator(value)],
    check_in_to: (key, value) => [key, dateCreator(value)],
    check_out_to: (key, value) => [key, dateCreator(value)],
    check_out_from: (key, value) => [key, dateCreator(value)],
  };

  return Map(query)
    .mapEntries(([key, value]) => {
      return QUERY_CREATORS[key] ? QUERY_CREATORS[key](key, value) : [key, value];
    })
    .toJS();
};
