import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { getBalances } from 'api/methods/subscription';
import { uiActions } from 'bus/ui/actions';
import { subscriptionActions } from 'bus/subscription';

const BASE_UI_PATH = ['subscription', 'balances', 'fetch'];

export function* getBalancesWorker({ payload }) {
  const { workerOptions } = payload;

  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    path: BASE_UI_PATH,
    status: { loading: true, error: false, message: null, completed: false },
  })));

  try {
    const balances = yield call(getBalances, token, { pathParams: { tf } });

    yield put(subscriptionActions.getBalancesSuccess(balances));
  } catch (error) {
    const { status, body } = error.message;

    let message = null;

    switch (status) {
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отказано в доступе');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(subscriptionActions.getBalancesFail(error, { message }));

    UI && (yield put(uiActions.changeUiLoaderFlag({
      path: [...BASE_UI_PATH, 'error'],
      status: true,
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      path: [...BASE_UI_PATH, 'message'],
      status: message,
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      path: [...BASE_UI_PATH, 'loading'],
      status: false,
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      path: [...BASE_UI_PATH, 'completed'],
      status: true,
    })));
  }
}
