// instruments
import BaseApiClass from 'api/BaseApiClass';
import { convertToClient } from 'api/methods/finance/convert/payment';
import { removeNull } from 'helpers';

export const processParams = params => {
  const {
    dateFrom,
    dateTo,
    dateSelectType,
    income_wait_for_pay: incomeWaitForPay,
    income_wait_for_clerk: incomeWaitForClerk,
    income_paid: incomePaid,
    expense_not_paid: expenseNotPaid,
    expense_paid: expensePaid,
    page,
    ...rest
  } = params;

  const parseDate = ([from, to], type) => {
    if (type === 0) {
      return {};
    }

    return {
      [`${type}_from`]: from ? from.clone().format('YYYY-MM-DD') : null,
      [`${type}_to`]: to ? to.clone().format('YYYY-MM-DD') : null,
    };
  };

  return {
    page: Number(page),
    statuses: [
      incomeWaitForPay && 'income_wait_for_pay',
      incomeWaitForClerk && 'income_wait_for_clerk',
      incomePaid && 'income_paid',
      expenseNotPaid && 'expense_not_paid',
      expensePaid && 'expense_paid'
    ].filter(o => o),
    ...removeNull(parseDate([dateFrom, dateTo], dateSelectType)),
    ...removeNull(rest),
  };
};

export const getPayments = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/payments.json',
    method: 'POST',
    ...params,
  });

  instance.parseBodyToClientSchema = ({ data, ...rest }) => {
    return {
      data: data.map(convertToClient),
      ...rest,
    };
  };

  instance.convertBodyToServerSchema = processParams;

  return instance._load({ token });
};
