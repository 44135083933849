import { newInstance } from 'localization';

export const ALLOWED_TYPES_FOR_OUTER_BOOKING = [1, 2];

export const EXTERNAL_FIELDS_PROPS = {
  id_number: {
    get label() {
      return newInstance.t('BOOKING_FORM:TIN_FIELD_LABEL');
    },
    validations: ['invalid_characters', 'required'],
    initialValue: '',
  },
  name_local: {
    get label() {
      return newInstance.t('BOOKING_FORM:NAME_FIELD_LABEL');
    },
    validations: ['required', 'cyrillicAndStandardChars'],
    initialValue: '',
    valueTransformer: value => value.toUpperCase()
  },
  surname_local: {
    get label() {
      return newInstance.t('BOOKING_FORM:SURNAME_FIELD_LABEL');
    },
    validations: ['required', 'cyrillicAndStandardChars'],
    initialValue: '',
    valueTransformer: value => value.toUpperCase()
  },
  patronymic_local: {
    get label() {
      return newInstance.t('BOOKING_FORM:PATRONYMIC_FIELD_LABEL');
    },
    validations: ['required', 'cyrillicAndStandardChars'],
    initialValue: '',
    get tooltip() {
      return newInstance.t('BOOKING_FORM:PATRONYMIC_FIELD_LABEL');
    },
    valueTransformer: value => value.toUpperCase()
  },
};

export const STANDARD_WITH_TIN_PASSPORT_FORMAT = 2;
export const STANDARD_WITH_TIN_AND_CYRILLIC_PASSPORT_FORMAT = 3;
export const REDUCED_PASSPORT_FORMAT = 4;

export const RATES_OF_CONFIRMATION = {
  LOW: 'low',
  HIGH: 'high',
};
