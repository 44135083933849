import { createActions } from 'redux-actions';

export const statisticActions = createActions({
  SEARCH_FLEXIBLE_REPORT: query => query,
  searchBonusesReports: query => query,
  SEARCH_FLEXIBLE_REPORT_SUCCESS: data => data,
  SEARCH_FLEXIBLE_REPORT_FAIL: error => error,

  CLEAR_FLEXIBLE_REPORT: () => ({ results: null, rows: [], columns: [] }),

  SET_FLEXIBLE_QUERY: query => query,

  GET_BONUSES: () => undefined,
  GET_BONUSES_SUCCESS: (results, count, limit) => ({ results, count, limit }),
  GET_BONUSES_FAil: error => error,

  SET_BONUSES_QUERY: query => query,
  SET_BONUSES_PAGE: page => page,

  GET_CALLS: formik => ({ formik }),
  GET_CALLS_SUCCESS: (results, count, limit, statistics) => ({ results, count, limit, statistics }),
  GET_CALLS_FAIL: error => error,

  SET_CALLS_QUERY: query => query,
  SET_CALLS_PAGE: page => page,

  getSalesFunnel: onSuccess => onSuccess,
  getSalesFunnelSuccess: (results, total, totalWithOpened) => ({ results, total, totalWithOpened }),
  getSalesFunnelFail: error => error,

  setSalesFunnelQuery: query => query,
  setSalesFunnelPage: page => page,

  GET_LOGS: formik => ({ formik }),
  GET_LOGS_SUCCESS: (results, count, limit) => ({ results, count, limit }),
  GET_LOGS_FAIL: error => error,

  SET_LOGS_QUERY: query => query,
  SET_LOGS_PAGE: page => page,
}, { prefix: 'MT_STATISTIC' });
