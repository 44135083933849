import { put, race, select, all, take, call } from 'redux-saga/effects';
import { offersActions } from '@otpusk/apisearch-toolbox/dist/offers';
import { hotelsActions } from '@otpusk/apisearch-toolbox/dist/hotels';
import { operatorsActions } from '@otpusk/apisearch-toolbox/dist/operators';
import { getOffer } from '@otpusk/apisearch-toolbox/dist/offers/selectors';
import { getHotel } from '@otpusk/apisearch-toolbox/dist/hotels/selectors';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { getSearchLocation } from 'api/methods/common/getSearchLocation';

export function* combineClaimWithOfferWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { currency, hotelId, offerId } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, meta: {} },
    path: ['claims', 'combineClaimWithOffer'],
  }));

  const isSetOffer = yield select(state => getOffer()(state, { offerID: offerId }));
  const isSetHotel = yield select(state => getHotel()(state, hotelId));

  if (!isSetHotel) {
    yield put(hotelsActions.getHotel(hotelId));
  }

  if (!isSetOffer) {
    yield put(offersActions.getOffer(offerId, true, currency));
  }

  const resultsFetching = yield all([
    !isSetHotel && race([
      take(hotelsActions.getHotelSuccess),
      take(hotelsActions.getHotelFail)
    ]),
    !isSetOffer && race([
      take(offersActions.getOfferSuccess),
      take(offersActions.getOfferFail)
    ])
  ]);

  try {
    const [resultHotelDownload, resultOfferDownload] = resultsFetching;

    if (resultHotelDownload) {
      const [, fail] = resultHotelDownload;

      if (fail) {
        throw new Error('Ошибка при загрузке отеля');
      }
    }

    if (resultOfferDownload) {
      const [, fail] = resultOfferDownload;

      if (fail) {
        throw new Error('Ошибка при загрузке офера');
      }
    }

    const countryIdByHotel = yield select(state => {
      return getHotel()(state, hotelId)?.country?.id;
    });
    const isSetOperatorsByConuntry = yield select(
      ({ operators }) => operators.hasIn(['store', countryIdByHotel])
    );
    const isSetStaticLists = yield select(
      ({ clientClaim }) => clientClaim.getIn(['staticLists', 'size'], null)
    );

    !isSetStaticLists && (yield put(clientClaimActions.getStaticLists()));
    !isSetOperatorsByConuntry && (yield put(operatorsActions.getOperators(countryIdByHotel)));

    const combineFetchingData = yield all([
      !isSetOperatorsByConuntry && race([
        take(operatorsActions.getOperatorsSuccess),
        take(operatorsActions.getOperatorsFail)
      ]),
      !isSetStaticLists && race([
        take(clientClaimActions.getStaticListsSuccess),
        take(clientClaimActions.getStaticListsFail)
      ]),
      call(function* saga() {
        try {
          const hotelName = yield select(
            ({ hotels }) => hotels.getIn(['store', String(hotelId), 'name'])
          );
          const response = yield call(
            getSearchLocation,
            token,
            { queryParams: { term: hotelName } }
          );
          const isSetOtpuskHotelInMt = get(response, 'hotels', [])
            .find(hotel => Number(hotel.id) === Number(hotelId));

          yield put(uiActions.changeUiLoaderFlag({
            status: Boolean(isSetOtpuskHotelInMt),
            path: ['claims', 'combineClaimWithOffer', 'meta', 'isSetOtpuskHotelInMt'],
          }));
        } catch (error) {
          if (error) {
            throw new Error('Ошибка при загрузке отеля');
          }
        }
      })
    ]);

    const [resultsOperatorsDownload, resultsStaticListDownload] = combineFetchingData;

    if (resultsOperatorsDownload) {
      const [, fail] = resultsOperatorsDownload;

      if (fail) {
        throw new Error('Ошибка при загрузке операторов');
      }
    }

    if (resultsStaticListDownload) {
      const [, fail] = resultsStaticListDownload;

      if (fail) {
        throw new Error('Ошибка при загрузке списка валют');
      }
    }

    const offerCurrency = yield select(state => {
      return getOffer()(state, { offerID: offerId }).currency;
    });
    const currencyList = yield select(
      ({ clientClaim }) => clientClaim.getIn(['staticLists', 'currencies'], [])
    );

    yield put(uiActions.changeUiLoaderFlag({
      status: Boolean(currencyList.find(cur => cur.value === offerCurrency.replace('uah', 'hrn'))),
      path: ['claims', 'combineClaimWithOffer', 'meta', 'isSetCurrencyOtpuskInMt'],
    }));
  } catch (error) {
    const { message } = error;

    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['claims', 'combineClaimWithOffer', 'error']
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['claims', 'combineClaimWithOffer', 'message']
    }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['claims', 'combineClaimWithOffer', 'loading']
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['claims', 'combineClaimWithOffer', 'completed']
    }));
  }
}
