import { combineActions } from 'redux-actions';
import { getIn } from 'immutable';
import * as R from 'ramda';

import { commentsActions as actions } from 'bus/clientClaim/comments';

import { DEFAULT_PERFORMER } from './constans';

export const commentsReducer = Object.freeze({
  [actions.createCommentSuccess]: (state, { payload }) => {
    const { comment } = payload;

    return state.updateIn(['claim', 'events'], events => [comment, ...events]);
  },
  [combineActions(actions.updateCommentSuccess, actions.dismissCommentSuccess)]:
    (state, { payload }) => {
      const { comment } = payload;
      const comments = state.getIn(['claim', 'events'], []);
      const updateIndexComment = comments.findIndex(event => event.id === comment.id);

      return state.setIn(['claim', 'events', comments.length ? updateIndexComment : 0], comment);
    },
  [combineActions(
    actions.createCommentSuccess,
    actions.updateCommentSuccess,
    actions.dismissCommentSuccess
  )]:
    (state, { payload }) => {
      const { comment, claimID } = payload;

      if (comment.claim) {
        const indexUpdatedClaim = state.getIn(['client', 'claims']).findIndex(claim => claim.id === claimID);
        const performer = R.propOr(DEFAULT_PERFORMER, 'performer', comment.claim);

        const step = getIn(comment, ['claim', 'step']);

        return state
          .setIn(['client', 'claims', indexUpdatedClaim, 'performer'], performer)
          .setIn(['client', 'performer'], performer)
          .setIn(['claim', 'performer'], performer)
          .mergeIn(['claim', 'step'], step);
      }

      return state;
    },
  [actions.deleteCommentSuccess]: (state, { payload }) => {
    const { eventID } = payload;
    const events = state.getIn(['claim', 'events'], []);
    const deleteIndexEvent = events.find(event => event.id === eventID);

    return state.setIn(['claim', 'events', events.length ? deleteIndexEvent : 0, 'removed'], true);
  },
});
