import { bookingActions as actions } from 'bus/booking/actions';

export const bookingTouristsReducer = {
  [actions.createBookingTouristSuccess]: (state, { payload: { tourist, id } }) => {
    if (!id) {
      return state.updateIn(['booking', 'data', 'tourists'], tourists => [...tourists, tourist]);
    }

    const touristIdx = state
      .getIn(['booking', 'data', 'tourists'])
      .findIndex(item => id === item.id);

    return state.setIn(['booking', 'data', 'tourists', touristIdx], tourist);
  },
  [actions.updateBookingTouristSuccess]: (state, { payload: tourist }) => {
    const touristIdx = state
      .getIn(['booking', 'data', 'tourists'])
      .findIndex(({ id }) => id === tourist.id);

    return state.setIn(['booking', 'data', 'tourists', touristIdx], tourist);
  },
  [actions.deleteBookingTouristSuccess]: (state, { payload: touristId }) => state
    .updateIn(['booking', 'data', 'tourists'], tourists => tourists.filter(({ id }) => id !== touristId)),
};
