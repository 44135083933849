import * as R from 'ramda';
import { call, select } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { updateUser } from 'api/methods/tf/user';

const commissionsMapper = R.applySpec({
  ticket_percent: R.pipe(
    R.prop('commissionByTicketPrice'),
    Number
  )
});

export function* updateCommissionsSaga({ payload: { callbacks, commissions } }) {
  try {
    const token = yield select(getToken);
    const userId = yield select(getUserId);

    yield call(
      updateUser,
      token,
      {
        pathParams: { id: userId },
        bodyParams: { commissions: commissionsMapper(commissions) }
      }
    );

    callbacks?.onSuccess(commissions);
  } catch (e) {
    callbacks?.onFail(e);
  }
}
