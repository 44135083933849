import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { epassportsActions } from 'bus/clientClaim/epassports';
import { createPassport } from 'api/methods/claim/passports';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['clientClaim', 'epassports', 'create'];

export function* createEpassportBaseWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { claimId, tempId, passport, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, tempId],
  })));

  try {
    const response = yield call(
      createPassport,
      token,
      { bodyParams: passport, pathParams: { id: claimId } }
    );

    yield put(epassportsActions.createEpassportSuccess(response, tempId));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    const processedErrors = {};

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);
        const { errorsByValues, otherErrors } = parseErrorsByFormik(passport, violations);

        processedErrors.errorsByValues = errorsByValues;
        processedErrors.otherErrors = otherErrors;
        message = 'Ошибка сервера';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление. Запрещено если активна подписка только на Турсканер');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    processedErrors.message = message;

    yield put(epassportsActions.createEpassportFail(error, processedErrors));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
