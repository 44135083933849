import { call, put, select } from 'redux-saga/effects';
import { Map } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { tfActions } from 'bus/tf/actions';
import { getFields } from 'api/methods/tf/fields';

export function* getTfFieldsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));
  const scenarios = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'scenarios']));

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['tf', 'fetchFields'] }));

  try {
    const response = yield call(getFields, token);
    const fields = scenarios.map(({ id, config_id: scenarioConfigId }) => {
      const fieldsByScenarioId = response.filter(field => {
        const { object } = field;
        const [configId = null] = object.match(/\d+/) || [];

        return Number(configId) === Number(scenarioConfigId);
      });

      return { scenarioId: id, fields: fieldsByScenarioId.map(item => ({ ...item, configId: scenarioConfigId })) };
    }).filter(item => Boolean(item.fields.length));

    yield put(tfActions.getTfFieldsSuccess(
      Map({ ...fields }).mapEntries(([, value]) => [value.scenarioId, value.fields])
    ));
  } catch (error) {
    tfActions.getTfFieldsFail(error);
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['tf', 'fetchFields'] }));
  }
}
