import React, { memo } from 'react';

import { getFavIcon } from 'crms/helpers';

const faviconName = getFavIcon();

export const FaviconComponent = memo(() => {
  return (
    // eslint-disable-next-line no-undef,camelcase
    <link href={`${__webpack_public_path__}static/favicon/${faviconName}`} rel="shortcut icon" type="image/png" />
  );
});
