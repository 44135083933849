import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insturments
import { getCalendarEvent } from 'api/methods/calendar';
import { calendarActions } from 'bus/calendar/actions';
import { uiActions } from 'bus/ui/actions';

const BASE_UI_PATH = ['calendar', 'event'];

export function* getCalendarEventWorker({ payload: id }) {
  const { token, user } = yield select(({ auth, calendar }) => ({
    token: auth.get('token'),
    user: calendar.getIn(['values', 'user']) || auth.getIn(['profile', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const event = yield call(getCalendarEvent, token, { pathParams: { user, id } });

    yield put(calendarActions.getCalendarEventSuccess(event));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Пользователя не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
