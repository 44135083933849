import { combineActions } from 'redux-actions';
import uuid from 'uuid/v4';

// instruments
import { epassportsActions as actions } from './actions';

const createPassport = external => ({
  country: { id: '' },
  birthday: '',
  isNew: true,
  issued: '',
  id: uuid(),
  last_date: '',
  name: '',
  passport: '',
  sex: 'm',
  start_date: '',
  surname: '',
  type: 'international',
  key: uuid(),
  ...external,
});

export const epassportsReducer = Object.freeze({
  [actions.createEmptyEpassport]: (state, { payload: external = {} }) => {
    const passport = createPassport(external);

    return state.updateIn(['claim', 'epassports'], passportsList => [...passportsList, passport]);
  },
  [actions.createEpassportSuccess]: (state, { payload: { passport, tempId } }) => {
    const updatePassportIndex = state.getIn(['claim', 'epassports']).findIndex(item => item.id === tempId);

    return state.updateIn(['claim', 'epassports', updatePassportIndex], ({ key }) => ({ key, ...passport }));
  },
  [actions.updateEpassportSuccess]: (state, { payload }) => {
    const { passport, id } = payload;
    const updatePassportIndex = state
      .getIn(['claim', 'epassports'])
      .findIndex(item => item.id === id);

    return state.updateIn(['claim', 'epassports', updatePassportIndex], ({ key }) => ({ key, ...passport }));
  },
  [combineActions(
    actions.deleteEpassportSuccess, actions.deleteFreshEpassport
  )]: (state, { payload: id }) => {
    return state.updateIn(
      ['claim', 'epassports'],
      epassports => epassports.filter(epassport => epassport.id !== id)
    );
  },
});
