import { takeEvery, put, select, call } from 'redux-saga/effects';
import * as R from 'ramda';
import { replace } from 'connected-react-router';

import { getStorage, GROUPS, resetStorage } from 'helpers/storage';

import { getIsAuth } from 'bus/auth/selectors';

import { getInfo } from 'api/methods/auth/user';

import { redirectAfterAuth } from './actions';
import { getAfterRegister, getRef } from './selectors';
import { DEFAULT_REDIRECT_PATH } from './constants';
import { getIsValidOuterURL, getLinkFromRef } from './helpers';

function* redirectToOuterUrl(refParam) {
  try {
    const isInitialized = yield select(getIsAuth);

    // does not need to check after authActions.authenticate coz token 100% valid
    if (!isInitialized) {
      const storage = yield call(getStorage);
      const token = storage.getIn([GROUPS.auth, 'token']);

      // need to check if token is valid before redirect
      yield call(getInfo, token);
    }

    global.location.replace(refParam);
  } catch (e) {
    yield call(resetStorage);

    global.location.replace(global.location.href);
  }
}

function* afterAuthRedirectSaga() {
  const refParam = yield select(getRef);

  if (refParam) {
    const isValidOuterUrl = getIsValidOuterURL(refParam);

    if (isValidOuterUrl) {
      yield call(redirectToOuterUrl, refParam);

      return;
    }

    yield put(replace(
      getLinkFromRef(refParam)
    ));
  } else {
    const afterRegister = yield select(getAfterRegister);

    yield put(replace(
      R.concat(
        DEFAULT_REDIRECT_PATH,
        afterRegister ? '?after_register=1' : ''
      )
    ));
  }
}

export default function* watcher() {
  yield takeEvery(redirectAfterAuth, afterAuthRedirectSaga);
}
