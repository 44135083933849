import BaseApiClass from 'api/BaseApiClass';

export const getPaymentTemplate = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/payments/{:id}.html',
    method: 'GET',
    ...params,
  });

  return instance._load({ token });
};
