import { select, put, call } from 'redux-saga/effects';

import { getCountries } from 'api/methods/common/getCountries';

import { commonActions } from 'bus/common/actions';
import { getToken } from 'bus/auth/selectors';
import { createUiSelector, withUIWorker } from 'bus/ui/helpers';

function* getCountriesWorker({ payload: { queryParams } }) {
  const token = yield select(getToken);
  try {
    const countries = yield call(getCountries, token, { queryParams });

    yield put(commonActions.getCountriesSuccess(countries));
  } catch (error) {
    yield put(commonActions.getCountriesFail(error));
  }
}

const UI_KEY = 'GET_COUNTRIES_UI';
export const getCountriesWithUI = withUIWorker(
  [
    commonActions.getCountriesSuccess,
    commonActions.getCountriesFail
  ],
  UI_KEY
)(getCountriesWorker);

export const getCountriesUI = createUiSelector(UI_KEY);
