import { select, call } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { updateBasket } from 'api/methods/tourscaner/baskets';

import { prepareBasketForUpdate } from './helpers';

export function* updateBasketWorker({ payload }) {
  const { callbacks, basketId, basket } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userId = yield select(getUserId);

  const bodyParams = prepareBasketForUpdate(basket);

  try {
    const updatedBasket = yield call(
      updateBasket,
      token,
      { pathParams: { userId, basketId }, bodyParams }
    );

    onSuccess && onSuccess(updatedBasket);
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
