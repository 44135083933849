import { select, call } from 'redux-saga/effects';

import { getUserOrTfLocale, getToken, getUserId } from 'bus/auth/selectors';

import { getBasketTemplate } from 'api/methods/tourscaner/baskets';

import { tourscanerActions } from '../../../actions';

import { prepareStringToCopy } from '../../../helpers';

const PREVIEW_TEMPLATE = 'tours_new';
const TEMPLATE_FOR_COPY = 'tours_just_text';

export function* getBasketTemplateWorker({ payload, type }) {
  const { callbacks, basket } = payload;
  const { onSuccess, onFail } = callbacks;

  const isPreviewTemplate = type === tourscanerActions.generateBasketPreview.toString();

  const token = yield select(getToken);
  const userId = yield select(getUserId);
  const defaultLocale = yield select(getUserOrTfLocale);

  try {
    const basketTemplate = yield call(
      getBasketTemplate,
      token,
      {
        pathParams: {
          userId,
          basketId: basket.id,
          template: isPreviewTemplate ? PREVIEW_TEMPLATE : TEMPLATE_FOR_COPY
        },
        queryParams: { locale: basket.locale || defaultLocale }
      }
    );

    onSuccess && onSuccess(
      isPreviewTemplate ? basketTemplate : prepareStringToCopy(basketTemplate)
    );
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
