import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import {
  mergeConnectedBookings,
  setConnectedBookings,
  setCOCommission,
  setTaCommission
} from './actions';

import { mergeConnectedBooking } from './helpers';

const initialState = {
  connectedBookings: [],
  TACommissions: [],
  COCommissions: [],
};

export default handleActions({
  [setConnectedBookings]: (state, { payload: bookings }) => R.assoc(
    'connectedBookings',
    bookings,
    state
  ),
  [setTaCommission]: (state, { payload: commissions }) => R.assoc(
    'TACommissions',
    commissions,
    state
  ),
  [setCOCommission]: (state, { payload: commissions }) => R.assoc(
    'COCommissions',
    commissions,
    state
  ),
  [mergeConnectedBookings]: (state, { payload: bookings }) => R.over(
    R.lensProp('connectedBookings'),
    prevBookings => mergeConnectedBooking(prevBookings, bookings),
    state
  ),
}, initialState);
