import { takeEvery } from 'redux-saga/effects';

import { searchClientAndClaimActions as actions } from '../actions';

import searchSummaryWorker from './workers/searchSummary';
import { searchDirectorStat, searchClients } from './workers/searchStart';

export const searchClientAndClaimWatcher = {
  * startSearchWatcher() {
    yield takeEvery(actions.search, searchClients);
  },
  * startSearchDirectorStat() {
    yield takeEvery(actions.searchDirectorStat, searchDirectorStat);
  },
  * searchSummaryWatcher() {
    yield takeEvery(actions.searchSummary, searchSummaryWorker);
  },
};
