import { load } from './store/actions';

const modalLoader = ({ getModal, key }) => ({ props, onClose, mapActions2Store } = {}) => load({
  getModal,
  key,
  mapActions2Store,
  props,
  onClose,
});

export default modalLoader;
