import { createActions } from 'redux-actions';

export const marketingActions = createActions({
  GET_MAILINGS: (params, workerOptions) => ({ params, workerOptions }),
  GET_MAILINGS_SUCCESS: result => result,
  GET_MAILINGS_FAIL: error => error,

  CLEAR_MAILINGS: () => undefined,

  DELETE_MAILING: (id, workerOptions) => ({ id, workerOptions }),
  DELETE_MAILING_SUCCESS: id => id,
  DELETE_MAILING_FAIL: error => error,

  REFRESH_MAILING: (id, workerOptions) => ({ id, workerOptions }),
  REFRESH_MAILING_SUCCESS: (id, mailing) => ({ id, mailing }),
  REFRESH_MAILING_FAIL: error => error,

  GET_MAILING_SUBSCRIBERS: (query, id, workerOptions) => ({ query, id, workerOptions }),
  GET_MAILING_SUBSCRIBERS_SUCCESS: subscribers => subscribers,
  GET_MAILING_SUBSCRIBERS_FAIL: error => error,

  GET_NEW_MAILING_SUBSCRIBERS: (id, workerOptions) => ({ id, workerOptions }),
  GET_NEW_MAILING_SUBSCRIBERS_SUCCESS: (newSubscribers, id) => ({ newSubscribers, id }),
  GET_NEW_MAILING_SUBSCRIBERS_FAIL: error => error,

  CLEAR_MAILING_SUBSCRIBERS: () => [],

  SEARCH_MAILING_CLIENTS: (values, formik, isSearchClientAndClaim) => (
    { values, formik, isSearchClientAndClaim }
  ),
  SEARCH_MAILING_CLIENTS_SUCCESS: (values, total) => ({ values, total }),
  SEARCH_MAILING_CLIENTS_FAIL: error => error,

  CREATE_MAILING: (values, formik) => ({ values, formik }),
  CREATE_MAILING_SUCCESS: mailing => mailing,
  CREATE_MAILING_FAIL: error => error,

  GET_MAILING: (id, workerOptions) => ({ id, workerOptions }),
  GET_MAILING_SUCCESS: mailing => mailing,
  GET_MAILING_FAIL: error => error,

  UPDATE_MAILING: (id, values, formik) => ({ id, values, formik }),
  UPDATE_MAILING_SUCCESS: mailing => mailing,
  UPDATE_MAILING_FAIL: error => error,

  CLEAR_MAILING: () => undefined,

  SEND_TEST_MAILING: (id, to, workerOptions) => ({ id, to, workerOptions }),
  SEND_TEST_MAILING_SUCCESS: success => success,
  SEND_TEST_MAILING_FAIL: error => error,

  SEND_MAILING: (id, workerOptions) => ({ id, workerOptions }),
  SEND_MAILING_SUCCESS: success => success,
  SEND_MAILING_FAIL: error => error,

  SEND_MAILING_UPDATE: (id, workerOptions) => ({ id, workerOptions }),
  SEND_MAILING_UPDATE_SUCCESS: id => id,
  SEND_MAILING_UPDATE_FAIL: error => error,

  GET_MAILS_OFFERS: (hash, workerOptions) => ({ hash, workerOptions }),
  GET_MAILS_OFFERS_SUCCESS: result => result,
  GET_MAILS_OFFERS_FAIL: error => error,

  CLEAR_MAILS_OFFERS: () => '',

}, { prefix: 'MT_MARKETING' });
