import { takeEvery } from 'redux-saga/effects';

// actions
import { documentFieldsActions as actions } from 'bus/documentFields';
import { fetchFieldsWorker } from './workers/fetchFields';

export const documentFieldsWatchers = Object.freeze({
  * fetchFieldsWatcher() {
    yield takeEvery(actions.fetchFields, fetchFieldsWorker);
  },
});
