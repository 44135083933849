import { Map } from 'immutable';
import * as R from 'ramda';

// instruments
import { parseErrorPath } from 'helpers';
import { MyError as APIError } from './Error';

export const parseErrorsByFormik = (values, errors) => {
  const valuesMap = Map(values);

  return Map({ ...errors }).reduce((results, error) => {
    const { property_path: path, message } = error;
    const transformPathToArray = parseErrorPath(path).split('.');
    const isErrorInValues = valuesMap.hasIn(transformPathToArray, null);

    if (isErrorInValues) {
      return results.setIn(['errorsByValues', ...transformPathToArray], message);
    }

    return results.update('otherErrors', errorsList => [...errorsList, { path: transformPathToArray, message: message.replace('.', '') }]);
  }, Map({ errorsByValues: {}, otherErrors: [] }))
    .update('errorsByValues', obj => (Map(obj).isEmpty() ? null : obj))
    .toObject();
};

export const isAPIError = error => error instanceof APIError;

export const getErrorMessage = (error, codesMap) => {
  const { status } = error.msg;

  if (codesMap[status]) {
    return R.call(
      R.pipe(
        R.prop(status),
        R.ifElse(
          mapValue => typeof mapValue === 'function',
          messageCreator => messageCreator(error.msg),
          mapValue => mapValue
        )
      ),
      codesMap
    );
  }

  __DEV__ && (
    console.warn(`Handle this [${status}] error code`),
    console.trace()
  );

  return null;
};
