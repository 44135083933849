import * as R from 'ramda';

import { authActions } from 'bus/auth/actions';

const MAX_LIMIT_FOR_ACCOUNT = 'No token could be found.';

export default store => next => action => {
  const { error, payload } = action;

  if (error) {
    const { status: statusCode, body } = payload.msg || {};

    if (statusCode) {
      switch (statusCode) {
        case 401: {
          return store.dispatch(authActions.logOut());
        }
        case 403: {
          return R.equals(R.prop('message', body), MAX_LIMIT_FOR_ACCOUNT) && store.dispatch(authActions.showLimitUserModal());
        }
        default: return next(action);
      }
    }
  }

  next(action);
};
