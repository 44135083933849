import { put, call, select } from 'redux-saga/effects';
import { get, Map } from 'immutable';
import moment from 'moment';

// instruments
import { marketingActions } from 'bus/marketing/actions';
import { getClientAndClaim } from 'api/methods/search/getClientAndClaim';
import { getClients } from 'api/methods/search/getClients';
import { toCamelCase } from 'helpers';

const EXCLUDE_VALUE = 'order';

export function* searchMailingClientsWorker({ payload }) {
  const { values, formik, isSearchClientAndClaim } = payload;

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  const searchMethod = isSearchClientAndClaim ? getClientAndClaim : getClients;

  try {
    const {
      total_clients: totalClients,
      total
    } = yield call(searchMethod, token, { bodyParams: { ...toCamelCase(values), page: 1 } });

    const preparedValues = Map(values)
      .map(item => (moment.isMoment(item) ? item.format() : item))
      .remove(EXCLUDE_VALUE)
      .toObject();

    yield put(marketingActions.searchMailingClientsSuccess(preparedValues, totalClients || total));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Ошибка валидации');
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
