import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { getCalendarEvents } from 'api/methods/calendar';
import { calendarActions } from 'bus/calendar/actions';
import { uiActions } from 'bus/ui/actions';
import { removeNull } from 'helpers';

const BASE_UI_PATH = ['calendar', 'events'];

export function* getCalendarEventsWorker() {
  const { token, user, values } = yield select(({ auth, calendar }) => ({
    token: auth.get('token'),
    user: auth.getIn(['profile', 'id']),
    values: calendar.get('values'),
  }));
  const { user: calendarUser, ...payload } = values;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const events = yield call(
      getCalendarEvents,
      token, {
        pathParams: { user: calendarUser || user }, queryParams: removeNull(payload)
      }
    );

    yield put(calendarActions.getCalendarEventsSuccess(events));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Ошибка валидации');
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Пользователя не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
