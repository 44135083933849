import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { destroy, globalReset, setup, updateProps, open, close } from './actions';

const initialState = {};

export default handleActions({
  [open]: (state, { payload: key }) => R.set(
    R.lensPath([key, 'open']),
    true,
    state
  ),
  [close]: (state, { payload: key }) => R.set(
    R.lensPath([key, 'open']),
    false,
    state
  ),
  [destroy]: (state, { payload: key }) => R.omit([key], state),
  [setup]: (state, { payload: modal }) => R.mergeAll([state, modal]),
  [updateProps]: (state, { payload: { key, props } }) => R.over(
    R.lensPath([key, 'props']),
    prevProps => R.mergeAll([prevProps, props]),
    state
  ),
  [globalReset]: () => R.clone(initialState),
}, initialState);
