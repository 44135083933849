import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';

// instruments
import { detailsFieldsActions as actions } from './actions';

const initialState = Map({
  fields: Map(),
  fieldsTypes: List(),
});

export default handleActions({
  [actions.fetchFieldsSuccess]: (state, { payload }) => state.set('fields', payload.fields),
  [actions.addEmptyField]: (state, { payload }) => {
    const { configId, fieldId } = payload;
    const field = {
      id: fieldId,
      type: 'text',
      object: configId,
      required: false,
      label: '',
      var: '',
      isNewField: true,
    };

    return state.updateIn(['fields', configId], (fields = []) => [...fields, field]);
  },
  [actions.removeField]: (state, { payload }) => {
    const { configId, fieldId } = payload;

    return state.updateIn(['fields', configId], fields => fields.filter(field => field.id !== fieldId));
  },
  [actions.createFieldSuccess]: (state, { payload }) => {
    const { oldId, field } = payload;

    const idx = state.getIn(['fields', field.object], []).findIndex(item => item.id === oldId);

    return state.setIn(['fields', field.object, idx], field);
  },
  [actions.updateFieldSuccess]: (state, { payload }) => {
    const { field } = payload;

    const idx = state.getIn(['fields', field.object], []).findIndex(item => item.id === field.id);

    return state.setIn(['fields', field.object, idx], field);
  },
  [actions.moveField]: (state, { payload: { objectId, from, to } }) => {
    return state.updateIn(['fields', objectId], fields => {
      const [offer] = fields.splice(from, 1);

      fields.splice(to, 0, offer);

      return fields;
    });
  },
}, initialState);
