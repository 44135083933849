import { handleActions } from 'redux-actions';
import { Map, OrderedMap } from 'immutable';

import { getStorage, GROUPS } from '../../helpers/storage';

import { searchClientAndClaimActions as actions } from './actions';

import { CLIENTS_SORT_TYPE } from './constants';

const initialState = Map({
  results: {
    store: null,
    total: 0,
  },
  page: 1,
  order: getStorage().getIn([GROUPS.clientClaim, CLIENTS_SORT_TYPE], 'claim_last_change_desc'),
  template: 'default',
  query: null,
  freshQuery: null,
  searchSummary: null,
});

export default handleActions({
  [actions.searchSuccess]: (state, { payload }) => {
    const { result, total } = payload;
    const { rows, clients } = result;

    return state.setIn(['results', 'store'], { rows: OrderedMap(rows), clients }).setIn(['results', 'total'], total);
  },
  [actions.searchFail]: state => state.setIn(['results', 'store'], null).setIn(['results', 'total'], 0),
  [actions.setPage]: (state, { payload: page }) => state.set('page', page),
  [actions.setOrder]: (state, { payload: order }) => state.set('order', order),
  [actions.setQuery]: (state, { payload: query }) => state.set('query', query),
  [actions.setTemplate]: (state, { payload: template }) => state.set('template', template),
  [actions.setFreshQuery]: (state, { payload: query }) => state.updateIn(['freshQuery'], freshQuery => ({ ...freshQuery, ...query })),
  [actions.resetFreshQuery]: (state, { payload: query }) => state.set('freshQuery', query),
  [actions.searchSummarySuccess]: (state, { payload: searchSummary }) => state.set('searchSummary', searchSummary),
}, initialState);
