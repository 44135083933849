import BaseApiClass from 'api/BaseApiClass';

export const getBirthdaysByProfile = (token, params = {}) => new BaseApiClass({
  url: 'clients/birthdaysByProfiles/{:date}',
  method: 'GET',
  ...params,
})._load({ token });

export const getBirthdaysByPassports = (token, params = {}) => new BaseApiClass({
  url: 'clients/birthdaysByPassports/{:date}',
  method: 'GET',
  ...params,
})._load({ token });
