import { call, select, put } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { dismissComment } from 'api/methods/claim/comment';
import { commentsActions as actions } from 'bus/clientClaim/comments';
import { uiActions } from 'bus/ui/actions';

const BASE_UI_PATH = ['clientClaim', 'comments', 'dismiss'];

export function* dismissCommentBaseWorker({ payload }) {
  const { claimID, commentID, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, commentID],
  })));

  try {
    const response = yield call(
      dismissComment,
      token,
      { pathParams: { claimID, eventID: commentID } }
    );

    yield put(actions.dismissCommentSuccess(claimID, response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права просматривать эту заявку или доступа к разделу ‘Клиенты’');
        break;
      }
      case 404: {
        message = 'Заказ не найден';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, commentID, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, commentID, 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, commentID, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, commentID, 'completed'],
    })));
  }
}
