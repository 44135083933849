import { select, put, call } from 'redux-saga/effects';
import { get, Map } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { detailsFieldsActions } from 'bus/detailsFields/actions';
import { createDetailFieldTf } from 'api/methods/tf/fields';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['detailFields', 'fields', 'create'];

export function* createFieldWorker({ payload }) {
  const { oldId, field, workerOptions } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: [...BASE_UI_PATH, oldId],
  })));

  const bodyParams = Map(field).deleteAll(['id']);

  try {
    const response = yield call(createDetailFieldTf, token, { bodyParams });

    yield put(detailsFieldsActions.createFieldSuccess(oldId, response));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    const processedErrors = {};

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);
        const { errorsByValues, otherErrors } = parseErrorsByFormik(field, violations);

        processedErrors.errorsByValues = errorsByValues;
        processedErrors.otherErrors = otherErrors;
        processedErrors.message = 'Ошибка сервера';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    processedErrors.message = message;

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, field.id, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, field.id, 'message'] })));
    yield put(detailsFieldsActions.createFieldFail(error, processedErrors));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, oldId, 'loading'],
    })));
  }
}
