import BaseApiClass from 'api/BaseApiClass';
import { convertToClient } from '../convert/payment';

export const getPayment = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/payments/{:id}.json',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = convertToClient;

  return instance._load({ token });
};
