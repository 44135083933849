import { select, call, put, race, take, delay, fork } from 'redux-saga/effects';
import { getIn, get } from 'immutable';
import * as R from 'ramda';

import { tfUsersActions } from 'bus/tfUsers/actions';
import { authActions } from 'bus/auth/actions';
import { getToken } from 'bus/auth/selectors';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { updateUser } from 'api/methods/tf/user';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { replaceValuesForPasswordError } from './helpers';

export const UPDATE_USER_UI_KEY = 'UPDATE_USER';

export const createUpdateUserUIKey = ui => `${UPDATE_USER_UI_KEY}_${ui}`;

function* disableSuccessStatus(uiKey) {
  const [isHideSuccessMsg] = yield race([
    delay(3000),
    take(tfUsersActions.updateUserInfo),
  ]);

  if (isHideSuccessMsg) {
    yield put(updateUI({ success: false }, uiKey));
  }
}
export function* updateTfUserWorker({ payload }) {
  const { userId, values, formik, uiKey: uniqUIKey } = payload;

  const token = yield select(getToken);

  const uiKey = uniqUIKey ? createUpdateUserUIKey(uniqUIKey) : UPDATE_USER_UI_KEY;

  try {
    yield put(registerUI(createUi({ loading: true }), uiKey));

    const user = yield call(updateUser, token, { pathParams: { id: userId }, bodyParams: values });

    if (R.prop('locale', values)) {
      yield put(authActions.fetchMyself());

      yield race([
        take(authActions.fetchMyselfSuccess),
        take(authActions.logOut)
      ]);
    }

    yield put(tfUsersActions.updateUserInfoSuccess(user));

    yield put(updateUI({ success: true }, uiKey));

    yield fork(disableSuccessStatus, uiKey);
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = '';

    switch (status) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { password, ...prepareValues } = values;
          const { errorsByValues, otherErrors } = parseErrorsByFormik({ ...prepareValues, plain_password: password }, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, replaceValuesForPasswordError(errorsByValues)));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к пользователю');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(tfUsersActions.updateUserInfoFail(error));
    yield put(updateUI({ message, error: true }, uiKey));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}
