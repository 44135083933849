import BaseApiClass from 'api/BaseApiClass';

export const getCalls = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'client/calls',
    method: 'GET',
    ...params,
  });

  return instance._load({ token });
};
