import { takeEvery } from 'redux-saga/effects';

// instruments
import { filesActions } from 'bus/files/actions';
import {
  getFilesWorker,
  updateFileBaseWorker,
  updateFileFormikWorker,
  deleteFileBaseWorker,
  createFilesBaseWorker,
  createFilesFormikWorker,
  getUploadsFilesWorker,
  deleteUploadsFileWorker,
  createDownloadFileIdWorker,
  createFinanceJobWorker,
  createBookingJobWorker,
  getStatusJobsWorker
} from './workers';

export const filesWatchers = Object.freeze({
  * getFiles() {
    yield takeEvery(filesActions.fetchFiles, getFilesWorker);
  },
  * createFilesBaseWatcher() {
    yield takeEvery(filesActions.createFiles, createFilesBaseWorker);
  },
  * createFilesFormikWatcher() {
    yield takeEvery(filesActions.createFilesByFormik, createFilesFormikWorker);
  },
  * updateFileBaseWatcher() {
    yield takeEvery(filesActions.updateFile, updateFileBaseWorker);
  },
  * updateFileFormikWatcher() {
    yield takeEvery(filesActions.updateFileByFormik, updateFileFormikWorker);
  },
  * deleteFileBaseWatcher() {
    yield takeEvery(filesActions.deleteFile, deleteFileBaseWorker);
  },
  * getUploadsFilesWatcher() {
    yield takeEvery(filesActions.getUploadsFiles, getUploadsFilesWorker);
  },
  * deleteUploadsFileWatcher() {
    yield takeEvery(filesActions.deleteUploadsFile, deleteUploadsFileWorker);
  },
  * createDownloadFileIdWatcher() {
    yield takeEvery(filesActions.createDownloadFileId, createDownloadFileIdWorker);
  },
  * createFinanceJobWatcher() {
    yield takeEvery(filesActions.createFinanceJob, createFinanceJobWorker);
  },
  * createBookingJobWatcher() {
    yield takeEvery(filesActions.createBookingJob, createBookingJobWorker);
  },
  * getStatusJobsWatcher() {
    yield takeEvery(filesActions.getStatusJobs, getStatusJobsWorker);
  },
});
