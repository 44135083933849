import { handleActions, combineActions } from 'redux-actions';
import { Map, List } from 'immutable';

import { tfActions as actions } from './actions';

import { apiKeysReducer, apiKeysState } from './apiKeys/reducer';

const initialState = Map({
  profile: null,
  scenariosList: List(),
  rolesList: List(),
  tfFields: Map(),
  operatorCredentials: {
    credentials: {
      total: null,
      limit: null,
      results: [],
      page: null,
    },
  },
  distribution: null,
  commission: {
    id: null,
    name: null,
    description: '',
    showContacts: false,
    results: []
  },
  commissionCounterparties: null,
  merchants: null,
  groups: null,
  commissionsGroups: null,

  ...apiKeysState,
});

export default handleActions({
  [combineActions(actions.fetchTfProfileSuccess, actions.updateTfProfileSuccess)]: (state, { payload: profile }) => state.set('profile', profile),
  [actions.clearTfProfile]: state => state.set('profile', null),
  [actions.fetchScenariosListSuccess]: (state, { payload }) => {
    const { scenarios } = payload;

    return state.set('scenariosList', List(scenarios));
  },
  [actions.fetchRolesListSuccess]: (state, { payload }) => state.set('rolesList', List(payload.roles)),
  [actions.getTfFieldsSuccess]: (state, { payload: fields }) => state.set('tfFields', fields),
  [combineActions(actions.uploadTfImageSuccess, actions.deleteTfImageSuccess)]: (state, { payload: { url, path } }) => state.setIn(['profile', path], url),

  [actions.getOperatorCredentialsSuccess]: (state, { payload: credentials }) => state.setIn(['operatorCredentials', 'credentials'], credentials),
  [actions.resetOperatorCredentials]: state => state.setIn(['operatorCredentials', 'credentials'], {
    total: null,
    limit: null,
    results: [],
    page: null,
  }),
  [actions.createOperatorCredentialsSuccess]: (state, { payload: credential }) => {
    return state.updateIn(['operatorCredentials', 'credentials', 'results'], results => [...results, credential]);
  },
  [actions.updateOperatorCredentialSuccess]: (state, { payload: credential }) => {
    const idx = state.getIn(['operatorCredentials', 'credentials', 'results']).findIndex(({ id }) => id === credential.id);

    return state.setIn(['operatorCredentials', 'credentials', 'results', idx], credential);
  },
  [actions.deleteOperatorCredentialSuccess]: (state, { payload: credentialId }) => {
    return state.updateIn(['operatorCredentials', 'credentials', 'results'], results => results.filter(({ id }) => id !== credentialId));
  },
  [combineActions(actions.getDistributionSuccess, actions.clearDistribution)]: (state, { payload: distribution }) => state.set('distribution', distribution),

  [actions.getCommissionSuccess]: (state, { payload: { id, name, results, description, showContacts } }) => state.set('commission', { id, name, results, description, showContacts }),
  [actions.getCommissionCounterpartiesSuccess]: (state, { payload: commission }) => state.set('commissionCounterparties', commission),
  [actions.getGroupsSuccess]: (state, { payload: commission }) => state.set('groups', commission),
  [actions.getCommissionGroupsSuccess]: (state, { payload: commission }) => state.set('commissionsGroups', commission),
  [actions.getMerchantsSuccess]: (state, { payload: merchants }) => state.set('merchants', merchants),
  [actions.createMerchantSuccess]: (state, { payload: { type, merchant } }) => {
    const idx = state.get('merchants').findIndex(item => item.type === type);

    return state.setIn(['merchants', idx], merchant);
  },
  [actions.updateMerchantSuccess]: (state, { payload: merchant }) => {
    const idx = state.get('merchants').findIndex(item => item.id === merchant.id);

    return state.setIn(['merchants', idx], merchant);
  },

  ...apiKeysReducer,
}, initialState);
