import * as R from 'ramda';

import { removeEmptyArray, removeEmptyString, removeNull, replaceMoment2String } from 'helpers';
import { ALLOW_CLIENTS_CLAIMS_FIELDS, EMAIL_VALUES } from './constants';

const filterQuery = R.pipe(
  removeEmptyArray,
  removeNull,
  removeEmptyString,
  replaceMoment2String,
  R.pick([...ALLOW_CLIENTS_CLAIMS_FIELDS, 'page'])
);

const isDisabledShowOtherManagers = limit => limit === 0;

export const prepareClientsSearchString = (query, page, limitClientView, userId) => R.pipe(
  filterQuery,
  ({ location, ...rest }) => R.mergeAll([
    rest,
    page === 1 ? {} : { page },
    location ? { location: [location.value, location.type, location.title] } : {},
    isDisabledShowOtherManagers(limitClientView) && { claimManager: userId }
  ]),
)(query);

export const prepareDirectorSearchString = (query, page, limitClientView, userId) => R.pipe(
  filterQuery,
  R.omit(['bonus_date_empty']),
  result => R.mergeAll([
    result,
    page === 1 ? {} : { page },
    isDisabledShowOtherManagers(limitClientView) && { claimManager: userId }
  ])
)(query);

export const prepareBodyParams = (query, page, order, template, limitClientView, userId) => R.pipe(
  filterQuery,
  ({ email, location, claimActive, claimManager, ...rest }) => R.mergeAll([
    {
      filters: {
        ...rest,
        ...EMAIL_VALUES[email],
        ...claimActive === 'all' ? {} : { claimActive },
        ...claimManager
          ? claimManager === 'none'
            ? { claimManagerEmpty: true } : { claimManager } : {},
        ...location ? { [location.type]: location.value } : {},
        ...isDisabledShowOtherManagers(limitClientView) ? { claimManager: userId } : {},
        order,
      },
    },
    { page, template }
  ]),
)(query);

export const prepareDirectorParams = (query, page, limitClientView, userId) => R.pipe(
  filterQuery,
  result => R.mergeAll([
    {
      filters: {
        ...result,
        ...isDisabledShowOtherManagers(limitClientView) ? { claimManager: userId } : {},
      },
    },
    { page, template: 'for_director' }
  ])
)(query);

export const prepareSearchSummary = (query, page) => R.pipe(
  filterQuery,
  result => R.mergeAll([
    {
      filters: {
        ...result,
        order: 'bonus_date_desc',
      },
    },
    { page, template: 'for_director' }
  ])
)(query);
