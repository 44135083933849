import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserOrTfLocale } from 'bus/auth/selectors';

import { useScript } from 'services/helmet/tags/Script';

import { getShow } from './store/selectors';

import { generateBinotelScriptPath } from './helpers';

import { setInitialized } from './store/actions';

const withShow = Component => () => {
  const isShow = useSelector(getShow);

  return isShow && <Component />;
};

const Binotel = () => {
  const dispatch = useDispatch();

  const locale = useSelector(getUserOrTfLocale);
  const scriptPath = generateBinotelScriptPath(locale);

  useScript(scriptPath, true, () => {
    dispatch(setInitialized(true));

    setTimeout(() => {
      const element = document.querySelector('.bwc-chat');

      element && element.click();
    }, 1000);
  });

  return null;
};

export default withShow(Binotel);
