import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';

import { searchAccountingInvoices } from 'api/methods/accounting';

const BASE_UI_PATH = ['booking', 'invoices'];

export function* getBookingInvoicesWorker({ payload: id }) {
  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const { results } = yield call(
      searchAccountingInvoices,
      token,
      { queryParams: { booking: id } }
    );

    yield put(actions.getBookingInvoicesSuccess(results));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));

    yield put(actions.getBookingInvoicesFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
