import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getTfSubscription } from 'bus/auth/selectors';

import { getCrmTypes } from 'crms/utils';

import book from 'routes/book';

import styles from './styles.scss';

const NotifyEndingSubscriptionHeader = () => {
  const iHaveSubscription = useSelector(getTfSubscription);

  const { is5Stars, isMt, isNakanikuly } = getCrmTypes();

  const { t } = useTranslation('SUBSCRIPTION');

  return !iHaveSubscription
        && (
          <div className={styles.root}>
            {isMt && t('SUN_IS_END_MT')}
            {is5Stars && t('SUN_IS_END_5STARS')}
            {isNakanikuly && t('CONTINUE_SUB_NK')}
            {' '}
            {!isNakanikuly && (
              <Link to={book.details.children.subscription}>
                {t('CONTINUE_SUB')}
                {' '}
                &rarr;
              </Link>
            )}
          </div>
        );
};

export default memo(NotifyEndingSubscriptionHeader);
