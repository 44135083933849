import { bookingActions as actions } from 'bus/booking/actions';
import { combineActions } from 'redux-actions';

export const bookingSchedulesReducer = {
  [actions.getBookingSchedulesSuccess]: (state, { payload: schedules }) => {
    return state.setIn(['booking', 'schedules'], schedules);
  },
  [combineActions(
    actions.createSchedulesFromTemplateSuccess,
    actions.createBookingSchedulesSuccess,
    actions.updateBookingSchedulesSuccess,
  )]: (state, { payload: schedules }) => {
    return state.setIn(['booking', 'schedules'], schedules);
  },
  [actions.updateBookingScheduleSuccess]: (state, { payload: schedule }) => {
    const scheduleIdx = state
      .getIn(['booking', 'schedules'])
      .findIndex(({ id }) => id === schedule.id);

    return state.setIn(['booking', 'schedules', scheduleIdx], schedule);
  },
};
