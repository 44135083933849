import { select, call, put } from 'redux-saga/effects';

import { uploadProfileImage } from 'api/methods/tf/profile';
import { tfActions } from 'bus/tf/actions';
import { getToken, getUserTf } from 'bus/auth/selectors';

export function* updateTfImageWorker({ payload }) {
  const { file, type, imagePath } = payload;
  const token = yield select(getToken);
  const { id: tf } = yield select(getUserTf);

  const bodyParams = new FormData();

  bodyParams.append('file', file);
  bodyParams.append('type', type);

  try {
    const { image_patch: url } = yield call(uploadProfileImage, token, { pathParams: { tf }, bodyParams });

    yield put(tfActions.uploadTfImageSuccess(url, imagePath));
  } catch (error) {
    yield put(tfActions.uploadTfImageFail(error));
  }
}
