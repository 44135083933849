import React, { memo, useMemo } from 'react';
import moment from 'moment';

// assets
import Santa from 'assets/santa_mt.svg';

// styles
import Styles from '../styles.scss';

const NEW_YEAR_START_DATE = '17.12';
const NEW_YEAR_LAST_DATE = '11.01';

const DATE_FORMAT = 'DD.MM.YYYY';

export const NewYearControl = memo(() => {
  const isInRange = useMemo(() => [
    moment().isSameOrAfter(moment(NEW_YEAR_START_DATE, DATE_FORMAT), 'day'),
    moment().isSameOrBefore(moment(NEW_YEAR_LAST_DATE, DATE_FORMAT), 'day')
  ].some(bool => bool), []);

  if (!isInRange) {
    return null;
  }

  return (
    <div className={Styles.newYear} dangerouslySetInnerHTML={{ __html: Santa }} />
  );
});
