import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

// insturments
import { getDocs } from 'api/methods/tf/docs';
import { detailDocsActions } from 'bus/detailDocs/actions';
import { uiActions } from 'bus/ui/actions';

export function* fetchDocsWorker({ payload: options }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(options, 'UI', false);

  UI && (
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, completed: false, message: null },
      path: ['detailDocs', 'fetchDocs'],
    }))
  );

  try {
    const response = yield call(getDocs, token);

    yield put(detailDocsActions.fetchDocsSuccess(response));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Отсутствует разрешение доступа к шаблонам договоров или активна подписка только на Турсканер';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(detailDocsActions.fetchDocsFail(error));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['detailDocs', 'fetchDocs', 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['detailDocs', 'fetchDocs', 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['detailDocs', 'fetchDocs', 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['detailDocs', 'fetchDocs', 'completed'] })));
  }
}
