import { call, select, put } from 'redux-saga/effects';

import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { getDocumentsTypes } from 'api/methods/booking';

import { commonActions } from '../../actions';

const GET_BOOKING_DOCUMENTS_TYPES_UI = 'GET_BOOKING_DOCUMENTS_TYPES_UI';

function* getDocumentsTypesSaga({ payload: bookingId }) {
  const token = yield select(getToken);

  try {
    const documents = yield call(getDocumentsTypes, token, { pathParams: { bookingId } });

    yield put(commonActions.getDocumentsTypesSuccess(documents));
  } catch (error) {
    const { message } = error.msg.body;

    error.message = message ?? 'Ошибка сервера';

    yield put(commonActions.getDocumentsTypesFail(error));
  }
}

export const getDocumentsTypesWithUI = withUIWorker(
  [
    commonActions.getDocumentsTypesSuccess,
    commonActions.getDocumentsTypesFail
  ],
  GET_BOOKING_DOCUMENTS_TYPES_UI
)(getDocumentsTypesSaga);
