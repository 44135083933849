import { call, put, select } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getManagers } from 'api/methods/common/getManagers';

export function* getManagersWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'fetchManagers'] }));

  try {
    const managers = yield call(getManagers, token);

    yield put(commonActions.getManagersSuccess(managers));
  } catch (error) {
    yield put(commonActions.getManagersFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'fetchManagers'] }));
  }
}
