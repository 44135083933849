import { select, put, call } from 'redux-saga/effects';

// instruments
import { updateDetailFieldTf } from 'api/methods/tf/fields';
import { detailsFieldsActions as actions } from 'bus/detailsFields/actions';

export function* sortFieldWorker({ payload }) {
  const { objectId, fieldId, from, to } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const iteratedTo = to + 1;

  yield put(actions.moveField(objectId, from, to));

  try {
    yield call(
      updateDetailFieldTf,
      token,
      { pathParams: { id: fieldId }, bodyParams: { order: iteratedTo }
      });
  } catch (error) {
    yield put(actions.moveField(objectId, to, from));
  }
}
