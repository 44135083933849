import { Map } from 'immutable';
import { handleActions, combineActions } from 'redux-actions';

// instruments
import { calendarActions as actions } from './actions';

const initialState = Map({
  values: {
    types: '',
    user: null,
    date_start: null,
    date_end: null,
  },
  events: [],
  event: {},
  editEvent: {
    open: false,
    id: null,
  },
});

export default handleActions({
  [actions.getCalendarEventsSuccess]: (state, { payload: events }) => state.setIn(['events'], events),
  [actions.setCalendarValues]: (state, { payload: data }) => state.mergeIn(['values'], data),
  [actions.createCalendarEventSuccess]: (state, { payload: event }) => state.updateIn(['events'], events => [...events, event]),
  [actions.setOpenEditEvent]: (state, { payload: bool }) => state.setIn(['editEvent', 'open'], bool),
  [actions.deleteCalendarEventSuccess]: (state, { payload: id }) => state.updateIn(['events'], events => {
    return events.filter(event => event.id !== id);
  }),
  [actions.updateCalendarEventSuccess]: (state, { payload: event }) => {
    const index = state.getIn(['events']).findIndex(({ id }) => event.id === id);

    return state.setIn(['events', index], event);
  },

  [combineActions(actions.setIdEditEvent, actions.clearIdEditEvent)]: (state, { payload: id }) => state.setIn(['editEvent', 'id'], id),
  [combineActions(actions.getCalendarEventSuccess, actions.clearCalendarEvent)]: (state, { payload: event }) => state.setIn(['event'], event),

}, initialState);
