import { takeEvery } from 'redux-saga/effects';

import { uiActions } from 'bus/ui/actions';

import { getOneTimeAccessWorker } from './workers/getOneTimeAccess';

export const uiWatchers = Object.freeze({
  *  getOneTimeAccessWatcher() {
    yield takeEvery(uiActions.getOneTimeAccess, getOneTimeAccessWorker);
  },
});
