import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

import { newInstance } from 'localization';

import { uiActions } from 'bus/ui/actions';
import { newsActions } from 'bus/news';
import { getSingleNews } from 'api/methods/news';

export function* getSingleNewsWorker({ payload }) {
  const { id, options } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(options, 'UI', false);

  try {
    const response = yield call(getSingleNews, token, { pathParams: { id } });

    yield put(newsActions.getSingleNewsSuccess(response));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = newInstance.t('ERRORS:NEWS_UNAVAILABLE');
        break;
      }
      case 404: {
        message = 'Новость не найден';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(newsActions.getSingleNewsFail(error));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['news', 'single', 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['news', 'single', 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['news', 'single', 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['news', 'single', 'completed'] })));
  }
}
