import { put, call, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { statisticActions } from 'bus/statistic/actions';
import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';

import { groupTypesMap } from 'containers/form/StatisticReport/constants';

import { getFlexibleReport } from 'api/methods/search/getFlexibleReport';
import { getErrorMessage } from 'api/fn/parseErrors';

import { getDataFromSelectors, onSaveSearchToCache, withSetQuery2Url } from 'services/formCreator/saga';

import { getFlexibleReportQuery } from '../../selectors';

import {
  SEARCH_FLEXIBLE_REPORT_COOKIE_KEY,
  EMAIL_VALUES,
  SEARCH_FLEXIBLE_REPORT_UI,
  SEARCH_FLEXIBLE_BONUSES_COOKIE_KEY
} from './constants';

import { filterQuery } from './helpers';

const convertGroupsToArray = groups => Object.keys(groups).reduce((res, groupKey) => {
  const groupData = groups[groupKey];

  res.push({ ...groupData, GROUP_KEY: groupKey });

  return res;
}, []);

// eslint-disable-next-line max-params
const readStatistic = (obj, groupColumns, columns, rows, resetColumns) => {
  const { groupings, results } = obj;

  if (groupings.length) {
    groupings.forEach(({ groups, type }) => {
      groupColumns.add(type);

      convertGroupsToArray(groups)
        .forEach(({ statistics, model_grouped_by: modelValue, ...rest }) => {
          columns[type] = { ...modelValue, ...rest };

          readStatistic(statistics, groupColumns, columns, rows, resetColumns);
        });
    });
  } else {
    rows.unshift({
      ...columns,
      ...results.reduce((res, item) => {
        delete item.type;

        const val = { ...res, ...item };

        return val;
      }, {}),
    });

    resetColumns();
  }
};

const prepareReadStatistic = root => {
  const groupColumns = new Set([]);
  const rows = [];

  let columns = {};

  const resetColumns = () => columns = {};

  readStatistic(root, groupColumns, columns, rows, resetColumns);

  return {
    columns: [...groupColumns].map(type => groupTypesMap.find(({ value }) => value === type)),
    rows,
  };
};

export const prepareQuerySearchString = query => R.pipe(
  filterQuery,
  ({ location, ...rest }) => R.mergeAll([
    rest,
    location ? { location: [location.value, location.type, location.title] } : {}
  ])
)(query);

export const prepareBodyParams = R.pipe(
  filterQuery,
  ({ email, location, claimActive, groupTypes, ...rest }) => R.mergeAll([
    {
      filters: {
        ...rest,
        ...EMAIL_VALUES[email],
        ...claimActive === 'all' ? {} : { claimActive },
        ...location ? { [location.type]: location.value } : {},
      },
    },
    { resultTypes: ['sales', 'tourists'] },
    { groupTypes }
  ])
);

function* searchFlexibleReport({ payload: query }) {
  const token = yield select(getToken);

  yield put(statisticActions.setFlexibleQuery(query));

  try {
    const response = yield call(getFlexibleReport, token, { bodyParams: prepareBodyParams(query) });

    const completeStatistic = prepareReadStatistic(response);
    const results = response.results.reduce((res, item) => ({ ...res, ...item }), {});

    yield put(statisticActions.searchFlexibleReportSuccess({ ...completeStatistic, results }));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        400: 'Ошибка валидации',
        403: 'Необходимо иметь доступ к разделу "Клиенты". Запрещен или активна подписка только на Турсканер',
        404: 'Ошибка. Страницы не найдено',
        500: 'Ошибка сервера 500',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(statisticActions.searchFlexibleReportFail(error));
  }
}

const actions = [statisticActions.searchFlexibleReportSuccess, statisticActions.searchFlexibleReportFail];
export const uiSaga = withUIWorker(actions, SEARCH_FLEXIBLE_REPORT_UI,)(searchFlexibleReport);

export const searchReports = withSetQuery2Url(
  actions,
  {
    onGetData: getDataFromSelectors([getFlexibleReportQuery], prepareQuerySearchString),
    onSuccess: onSaveSearchToCache(SEARCH_FLEXIBLE_REPORT_COOKIE_KEY)
  }
)(uiSaga);
export const searchBonusesReports = withSetQuery2Url(
  actions,
  {
    onGetData: getDataFromSelectors([getFlexibleReportQuery], R.pipe(
      filterQuery,
      R.omit(['resultTypes', 'groupTypes'])
    )),
    onSuccess: onSaveSearchToCache(SEARCH_FLEXIBLE_BONUSES_COOKIE_KEY)
  }
)(uiSaga);
