import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insturments
import { getDefaultTemplate } from 'api/methods/templates/getDefaultTemplate';
import { templatesActions } from 'bus/templates/actions';
import { uiActions } from 'bus/ui/actions';

export function* getDefaultTemplateWorker({ payload }) {
  const { type } = payload;

  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['templates', 'getDefaultTemplate'],
  }));

  try {
    const { text } = yield call(getDefaultTemplate, token, { pathParams: { tf, type } });

    yield put(templatesActions.getDefaultTemplateSuccess(text));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['templates', 'getDefaultTemplate', 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['templates', 'getDefaultTemplate', 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['templates', 'getDefaultTemplate', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['templates', 'getDefaultTemplate', 'completed'] }));
  }
}
