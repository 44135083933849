import moment from 'moment/moment';
import * as R from 'ramda';

import { createArrayValue, createMomentValue } from 'services/formCreator/helpers';

export const TYPES_MAP = Object.freeze({
  SCENARIO: 'Scenario',
  SOURCE: 'Source',
  COUNTRY: 'Country',
  HOTEL: 'Hotel',
  OPERATOR: 'Operator',
  PERFORMERREGION: 'PerformerRegion',
  PERFORMEROFFICE: 'PerformerOffice',
  PERFORMER: 'Performer',
  CREATEDDAY: 'CreatedDay',
  CREATEDWEEK: 'CreatedWeek',
  CREATEDMONTH: 'CreatedMonth',
  CONTRACTDATEDAY: 'ContractDateDay',
  CONTRACTDATEWEEK: 'ContractDateWeek',
  CONTRACTDATEMONTH: 'ContractDateMonth',
  DATEBONUSDAY: 'DateBonusDay',
  DATEBONUSWEEK: 'DateBonusWeek',
  DATEBONUSMONTH: 'DateBonusMonth',
  DATESTARTDAY: 'DateStartDay',
  DATESTARTWEEK: 'DateStartWeek',
  DATESTARTMONTH: 'DateStartMonth',
});
export const groupTypesMap = [
  { text: 'TOUR_MANAGER', value: TYPES_MAP.PERFORMER },
  { text: 'SELLING_TYPE', value: TYPES_MAP.SCENARIO },
  { text: 'CLAIM_SOURCE', value: TYPES_MAP.SOURCE },
  { text: 'COUNTRY', value: TYPES_MAP.COUNTRY },
  { text: 'HOTEL', value: TYPES_MAP.HOTEL },
  { text: 'TOUR_OPERATOR', value: TYPES_MAP.OPERATOR },
  { text: 'REGION', value: TYPES_MAP.PERFORMERREGION },
  { text: 'OFFICE', value: TYPES_MAP.PERFORMEROFFICE },
  { text: 'ORDER_DATE_BY_DATE', value: TYPES_MAP.CREATEDDAY },
  { text: 'ORDER_DATE_BY_WEEKS', value: TYPES_MAP.CREATEDWEEK },
  { text: 'ORDER_DATE_BY_MONTHS', value: TYPES_MAP.CREATEDMONTH },
  { text: 'CONTRACT_DATE_BY_DATE', value: TYPES_MAP.CONTRACTDATEDAY },
  { text: 'CONTRACT_DATE_BY_WEEKS', value: TYPES_MAP.CONTRACTDATEWEEK },
  { text: 'CONTRACT_DATE_BY_MONTHS', value: TYPES_MAP.CONTRACTDATEMONTH },
  { text: 'SALE_DATE_BY_DATE', value: TYPES_MAP.DATEBONUSDAY },
  { text: 'SALE_DATE_BY_WEEKS', value: TYPES_MAP.DATEBONUSWEEK },
  { text: 'SALE_DATE_BY_MONTHS', value: TYPES_MAP.DATEBONUSMONTH },
  { text: 'DEPARTURE_DATE_BY_DATE', value: TYPES_MAP.DATESTARTDAY },
  { text: 'DEPARTURE_DATE_BY_WEEKS', value: TYPES_MAP.DATESTARTWEEK },
  { text: 'DEPARTURE_DATE_BY_MONTHS', value: TYPES_MAP.DATESTARTMONTH }
];

const dataFields = [
  'birthdayFrom', 'birthdayTo', 'claimCreatedFrom', 'claimCreatedTo', 'flightDateFrom', 'flightDateTo',
  'returnDateFrom', 'returnDateTo', 'bonusDateFrom', 'bonusDateTo', 'contractDateFrom', 'contractDateTo'
];
const numberFields = ['email', 'clientCityId', 'operatorId', 'claimStatus', 'countryId', 'claimManager', 'claimSourceId'];
const arrayFields = ['tags', 'claimScenarios', 'claimSteps', 'offices', 'regions', 'closing_reasons'];

export const MAPPED_OPTIONS = [
  [(_, key) => R.includes(key, numberFields), Number],
  [(_, key) => R.includes(key, dataFields), createMomentValue],
  [(_, key) => R.includes(key, arrayFields), createArrayValue],
  [(_, key) => R.equals('claimActive', key), value => (value === 'all' ? 'all' : value === 'true')],
  [(_, key) => R.equals('location', key), value => {
    const [fieldValue, type, title] = R.split(',', value);

    return {
      value: Number(fieldValue),
      type,
      title,
    };
  }],
  [(_, key) => R.equals('groupTypes', key), R.split(',')],
];

export const DEFAULT_FORM_VALUES = {
  s: '',
  tags: [],
  clientCityId: '',
  birthdayFrom: null,
  birthdayTo: null,
  claimPassport: '',
  email: null,

  claimManager: null,
  claimActive: true,
  claimCreatedFrom: null,
  claimCreatedTo: null,
  claimSourceId: '',
  claimScenarios: [],
  claimSteps: [],
  location: null,
  offices: [],
  regions: [],
  closing_reasons: [],

  countryId: null,
  hotel: '',
  flightDateFrom: null,
  flightDateTo: null,
  returnDateFrom: null,
  returnDateTo: null,

  operatorId: null,
  bookingClaimId: '',
  bonusDateFrom: null,
  bonusDateTo: null,
  claimStatus: 1,
  contractDateFrom: null,
  contractDateTo: null,
  contract_number: '',

  groupTypes: [groupTypesMap[0].value],
};

const parseDiapasonDate = str => str.split('..');

export const parsers = Object.freeze({
  [TYPES_MAP.SCENARIO]: obj => (obj ? obj.name : '-'),
  [TYPES_MAP.SOURCE]: obj => (obj ? obj.description : '-'),
  [TYPES_MAP.COUNTRY]: obj => (obj ? `${obj.name || '-'}` : '-'),
  [TYPES_MAP.HOTEL]: obj => (obj ? `${obj.name || '-'} ${obj.stars ? `${obj.stars}*` : ''}` : '-'),
  [TYPES_MAP.OPERATOR]: obj => (obj ? obj.title : '-'),
  [TYPES_MAP.PERFORMERREGION]: obj => (obj ? obj.name : '-'),
  [TYPES_MAP.PERFORMEROFFICE]: obj => (obj ? obj.name : '-'),
  [TYPES_MAP.PERFORMER]: obj => (obj ? obj.username : '-'),
  [TYPES_MAP.CREATEDDAY]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''),
  [TYPES_MAP.CREATEDWEEK]: ({ GROUP_KEY }) => (GROUP_KEY ? parseDiapasonDate(GROUP_KEY).map(date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')).join(' - ') : ''),
  [TYPES_MAP.CREATEDMONTH]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM').format('MM.YYYY') : ''),
  [TYPES_MAP.CONTRACTDATEDAY]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''),
  [TYPES_MAP.CONTRACTDATEWEEK]: ({ GROUP_KEY }) => (GROUP_KEY ? parseDiapasonDate(GROUP_KEY).map(date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')).join(' - ') : ''),
  [TYPES_MAP.CONTRACTDATEMONTH]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM').format('MM.YYYY') : ''),
  [TYPES_MAP.DATEBONUSDAY]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''),
  [TYPES_MAP.DATEBONUSWEEK]: ({ GROUP_KEY }) => (GROUP_KEY ? parseDiapasonDate(GROUP_KEY).map(date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')).join(' - ') : ''),
  [TYPES_MAP.DATEBONUSMONTH]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM').format('MM.YYYY') : ''),
  [TYPES_MAP.DATESTARTDAY]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''),
  [TYPES_MAP.DATESTARTWEEK]: ({ GROUP_KEY }) => (GROUP_KEY ? parseDiapasonDate(GROUP_KEY).map(date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')).join(' - ') : ''),
  [TYPES_MAP.DATESTARTMONTH]: ({ GROUP_KEY }) => (GROUP_KEY ? moment(GROUP_KEY, 'YYYY-MM').format('MM.YYYY') : ''),
});
