import { call, takeEvery, put, select } from 'redux-saga/effects';
import moment from 'moment';
import * as R from 'ramda';

import { localeBootstrap } from 'localization';

import { CRM_TYPES } from 'crms/constants';

import { authActions } from 'bus/auth/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { initialize } from './actions';
import { UI_KEY } from './constants';
import { getLocale } from './selectors';

const getOtpuskToken = () => R.call(
  R.cond([
    [mt => mt, R.always('2a43e-e34bf-f77dd-e02a3-10fd4')],
    [(_, tat) => tat, R.always('29ae6-32ef8-d106b-7ea88-5f40d')],
    [(_, __, is5stars) => is5stars, R.always('1b20d-591d5-75518-560fd-8815a')],
    [(_, __, ___, isNakanikuly) => isNakanikuly, R.always('272a6-013e4-b3307-4f63c-b3c8c')],
    [R.T, R.always(null)]
  ]),
  CRM_TYPES.mt === __CRM__,
  CRM_TYPES.tat === __CRM__,
  CRM_TYPES['5stars'] === __CRM__,
  CRM_TYPES.nakanikuly === __CRM__,
);

function* initializePageSaga() {
  yield put(registerUI(createUi({ completed: false, loading: true }), UI_KEY));

  const locale = yield select(getLocale);
  const token = getOtpuskToken();

  yield put(authActions.setOtpuskToken(token));
  yield put(authActions.setLocale(locale));

  moment.locale(locale);

  yield call(localeBootstrap, locale);

  yield put(updateUI(createUi({ completed: true, loading: false }), UI_KEY));
}

export default function* watcher() {
  yield takeEvery(initialize, initializePageSaga);
}
