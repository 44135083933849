import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { deleteSearch } from 'api/methods/claim/searches';
import { clientClaimActions } from 'bus/clientClaim/actions';

const BASE_UI_PATH = ['clientClaim', 'searchList', 'delete'];

export function* deleteSearchWorker({ payload: searchId }) {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, searchId],
  }));

  try {
    yield call(deleteSearch, token, { pathParams: { id: searchId } });
    yield put(clientClaimActions.deleteSearchSuccess(searchId));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу ‘Клиенты’ или к конкретному заказу паспорта или активна подписка только на Турсканер');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    if (status !== 404) {
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: [...BASE_UI_PATH, searchId, 'error'],
      }));
      yield put(uiActions.changeUiLoaderFlag({
        status: message,
        path: [...BASE_UI_PATH, searchId, 'message'],
      }));

      yield put(clientClaimActions.deleteSearchFail(error));
    } else {
      yield put(clientClaimActions.deleteSearchSuccess(searchId));
    }
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, searchId, 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, searchId, 'completed'],
    }));
  }
}
