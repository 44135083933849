import * as R from 'ramda';

import { prepareAdminBookingValues } from 'bus/booking/saga/workers/searchBooking/helpers';

const optionsMapper = (fieldValue, fieldName) => R.cond([
  [value => Array.isArray(value), R.join(',')],
  [value => typeof value === 'boolean', Boolean],
  [R.T, String]
])(fieldValue, fieldName);
export const prepareDataForApi = R.pipe(
  prepareAdminBookingValues,
  R.omit(['order']),
  R.toPairs,
  R.map(([key, value]) => [key, optionsMapper(value, key)]),
  R.fromPairs
);

export const prepareBookingsSearchToAPI = R.when(
  result => R.prop('op_claim_id', result) || R.prop('booking_id', result),
  R.ifElse(
    R.prop('op_claim_id'),
    R.pipe(
      R.pick(['op_claim_id', 'page']),
      R.over(R.lensProp('op_claim_id'), value => value.replaceAll(', ', ','))
    ),
    R.pick(['booking_id', 'page'])
  )
);
