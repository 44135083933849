import { put, call, race, take, takeEvery, all } from 'redux-saga/effects';

import { commonActions } from 'bus/common/actions';

import { getErrorMessage } from '../helpers.js';

import { createOperator, updateOperator } from './actions';
import { newInstance } from '../../../../../localization';

function* createTourOperatorSaga({ payload }) {
  const { bodyParams, formik, onSuccess } = payload;

  yield put(commonActions.createOperator(bodyParams, { UI: false }));

  const [success, fail] = yield race([
    take(commonActions.createOperatorSuccess),
    take(commonActions.createOperatorFail)
  ]);

  if (success) {
    yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') });
    onSuccess && (yield call(onSuccess, success.payload.id));
  }

  if (fail) {
    const message = yield call(getErrorMessage, fail, bodyParams, formik);

    yield call(formik.setStatus, { error: true, message });
  }

  yield call(formik.setSubmitting, false);
}

function* updateTourOperatorSaga({ payload }) {
  const { id, bodyParams, formik } = payload;

  yield put(commonActions.updateOperator(id, bodyParams));

  const [success, fail] = yield race([
    take(commonActions.updateOperatorSuccess),
    take(commonActions.updateOperatorFail)
  ]);

  if (success) {
    yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') });
  }

  if (fail) {
    const message = yield call(getErrorMessage, fail, bodyParams, formik);

    yield call(formik.setStatus, { error: true, message });
  }

  yield call(formik.setSubmitting, false);
}

export default function* () {
  yield all([
    takeEvery(createOperator, createTourOperatorSaga),
    takeEvery(updateOperator, updateTourOperatorSaga)
  ]);
}
