import { call, put, select } from 'redux-saga/effects';

// instruments
import { getExtraByDoc, getExtraByOperator } from 'api/methods/tf/docs';
import { uiActions } from 'bus/ui/actions';
import { detailDocsActions } from 'bus/detailDocs/actions';

export function* fetchExtraDocWorker({ payload }) {
  const { docId, operatorId } = payload;
  const apiMethod = docId && getExtraByDoc || operatorId && getExtraByOperator;
  const id = docId || operatorId;
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['detailDocs', 'fetchExtraDoc'] }));

  try {
    const extra = yield call(apiMethod, token, { pathParams: { id } });

    yield put(detailDocsActions.fetchExtraDocSuccess(id, extra));
  } catch (error) {
    yield put(detailDocsActions.fetchExtraDocFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['detailDocs', 'fetchExtraDoc'] }));
  }
}
