import { select, call } from 'redux-saga/effects';
import moment from 'moment';
import * as R from 'ramda';

import { getToken, getUserId } from 'bus/auth/selectors';

import { recognizePassports } from 'api/methods/tourscaner';

const SEX_VALUES_MAP = {
  f: 'MRS',
  m: 'MR',
  c: 'CHD',
};
const DATE_FORMAT = 'DD.MM.YYYY';

const stringifyDate = date => (date ? moment(date).format(DATE_FORMAT) : '');

const setDefault2Null = R.defaultTo(null);
const setDefault2Str = R.defaultTo('');

const passportMapper = ({
  sex,
  country,
  start_date: startDay,
  last_date: lastDay,
  birthday,
  name,
  surname,
  passport,
}) => ({
  sex: setDefault2Null(SEX_VALUES_MAP[sex]),
  citizenship: setDefault2Null(country.id),

  passportGivenDate: stringifyDate(startDay),
  passportExpireDate: stringifyDate(lastDay),
  birthday: stringifyDate(birthday),

  name: setDefault2Str(name),
  surname: setDefault2Str(surname),
  passport: setDefault2Str(passport),
});

export function* recognizePassportWorker({ payload }) {
  const { callbacks, passport } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userId = yield select(getUserId);

  const bodyParams = new FormData();

  bodyParams.append('image', passport[0]);

  try {
    const recognizedPassport = yield call(
      recognizePassports,
      token,
      { pathParams: { userId }, bodyParams }
    );

    const mappedPassport = passportMapper(recognizedPassport);

    console.log(mappedPassport);

    onSuccess && onSuccess(
      mappedPassport
    );
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
