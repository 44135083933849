import { createActions } from 'redux-actions';

export const detailDocsActions = createActions({
  FETCH_DOCS: options => options,
  FETCH_DOCS_SUCCESS: docs => docs,
  FETCH_DOCS_FAIL: error => error,
  CLEAR_DOCS: () => undefined,

  FETCH_DOC: (id, options) => ({ id, options }),
  FETCH_DOC_SUCCESS: (id, doc) => ({ id, doc }),
  FETCH_DOC_FAIL: error => error,
  CLEAR_DOC: id => id,

  PREVIEW_DOC: id => id,
  CLEAR_PREVIEW_DOC: () => null,

  TOGGLE_DOC: id => ({ id }),
  TOGGLE_DOC_SUCCESS: id => id,
  TOGGLE_DOC_FAIL: error => error,

  DELETE_DOC: id => ({ id }),
  DELETE_DOC_SUCCESS: id => ({ id }),
  DELETE_DOC_FAIL: error => error,

  CREATE_DOC: (values, formik, options) => ({ values, formik, options }),
  CREATE_DOC_SUCCESS: (id, doc) => ({ id, doc }),
  CREATE_DOC_FAIL: error => error,

  UPDATE_DOC: (id, values, formik) => ({ values, formik, id }),
  UPDATE_DOC_SUCCESS: (id, doc) => ({ id, doc }),
  UPDATE_DOC_FAIL: error => error,

  FETCH_DOC_VARIABLES: () => undefined,
  FETCH_DOC_VARIABLES_SUCCESS: variables => variables,
  FETCH_DOC_VARIABLES_FAIL: error => error,

  CLEAR_DOC_VARIABLES: () => undefined,

  FETCH_EXTRA_DOC: (docId, operatorId) => ({ docId, operatorId }),
  FETCH_EXTRA_DOC_SUCCESS: (id, extra) => ({ id, extra }),
  FETCH_EXTRA_DOC_FAIL: error => error,

  CLEAR_EXTRA_DOC: id => id,

  FETCH_GUARANTEES: () => undefined,
  FETCH_GUARANTEES_SUCCESS: guarantess => guarantess,
  FETCH_GUARANTEES_FAIL: error => error,

  SET_DOCS_FILTER_VALUE: value => value,
}, { prefix: 'MT_DETAIL_DOCS' });
