import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { epassportsActions as actions } from 'bus/clientClaim/epassports';
import { deletePassport } from 'api/methods/claim/passports';

const BASE_UI_PATH = ['clientClaim', 'epassports', 'delete'];

export function* deleteEpassportBaseWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { id, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, id],
  })));

  try {
    yield call(deletePassport, token, { pathParams: { id } });

    yield put(actions.deleteEpassportSuccess(id));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу ‘Клиенты’ или к конкретному заказу паспорта или активна подписка только на Турсканер');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    if (status !== 404) {
      UI && (yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: [...BASE_UI_PATH, id, 'error'],
      })));
      UI && (yield put(uiActions.changeUiLoaderFlag({
        status: message,
        path: [...BASE_UI_PATH, id, 'message'],
      })));
      yield put(actions.deleteEpassportFail(error, { message }));
    } else {
      yield put(actions.deleteEpassportSuccess(id));
    }
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, id, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, id, 'completed'],
    })));
  }
}
