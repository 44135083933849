import { put, select, call } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { getUniqueAndActiveEpassports } from 'api/methods/client/epassports';
import { clientClaimActions } from '../../actions';

export function* fetchClientPassports({ payload }) {
  const { id } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['clientClaim', 'fetchClientPassports'],
  }));

  try {
    const response = yield call(
      getUniqueAndActiveEpassports,
      token,
      { pathParams: { client: id } }
    );

    yield put(clientClaimActions.fetchClientPassportsSuccess(response));
  } catch (error) {
    yield put(clientClaimActions.fetchClientPassportsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['clientClaim', 'fetchClientPassports'],
    }));
  }
}
