import { select, call, put, take, race } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

// instruments
import { createClaimPaymentIncome } from 'api/methods/claim/updateClaim';
import { financeActions } from 'bus/finance/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createClaimPaymentIncomeWorker({ payload }) {
  const { claimID, payment, letter, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const status = {};

  formik && (yield call(formik.setStatus, null));

  try {
    const response = yield call(
      createClaimPaymentIncome,
      token,
      { pathParams: { id: claimID }, bodyParams: payment }
    );
    const { id: paymentId } = response;

    if (letter) {
      yield put(financeActions.sendPaymentToEmail(paymentId, letter));

      const [failSend, successSend] = yield race([
        take(financeActions.sendPaymentToEmailFail),
        take(financeActions.sendPaymentToEmailSuccess)
      ]);

      if (failSend) {
        status.send = { error: true, message: 'Письмо не отправлено' };
      } else if (successSend) {
        status.send = { success: true, message: 'Письмо отправлено' };
      }
    }

    status.createPayment = { success: true, message: 'Счет создан' };

    yield put(clientClaimActions.createClaimPaymentIncomeSuccess(response));
  } catch (error) {
    const { status: statusCode, body } = error.msg || {};

    let message = null;

    switch (statusCode) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(payment, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление');

        break;
      }
      case 404: {
        message = 'Заказ не найден';

        break;
      }
      default: message = 'Ошибка при создании счета';
    }

    status.createPayment = { error: true, message };
    yield put(clientClaimActions.createClaimPaymentIncomeFail(error));
  } finally {
    formik && (yield call(formik.setStatus, status));
  }
}
