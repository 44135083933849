import * as R from 'ramda';

import { getLocation } from 'connected-react-router';
import { matchPath } from 'react-router-dom';

import { getUI } from 'bus/ui/selectors';

import book from 'routes/book';

import { UI_KEY } from './constants';

export const getBookingPageInitUi = R.partialRight(getUI, [{ key: UI_KEY }]);

export const getBookingId = state => {
  const { pathname } = getLocation(state);

  return R.path(
    ['params', 'id'],
    matchPath(pathname, { path: book.client.children.bookingPage, exact: true })
  );
};
