import { select, call, put } from 'redux-saga/effects';

// insturments
import { deleteOffice } from 'api/methods/common/offices';
import { commonActions } from 'bus/common/actions';

export function* deleteOfficeWorker({ payload: id }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    yield call(deleteOffice, token, { pathParams: { id } });
    yield put(commonActions.deleteOfficeSuccess(id));
  } catch (error) {
    yield put(commonActions.deleteOfficeFail(error));
  }
}
