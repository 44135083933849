import BaseApiClass from 'api/BaseApiClass';

export const getMerchants = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/merchants',
  method: 'GET',
  ...params,
})._load({ token });

export const createMerchant = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/merchants',
  method: 'POST',
  ...params,
})._load({ token });

export const updateMerchant = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/merchants/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });
