import { handleActions, combineActions } from 'redux-actions';
import { Map, List } from 'immutable';

// instruments
import { financeActions as actions } from './actions';

const initialState = Map({
  paymentList: Map({
    data: [],
    total: null,
    count: null,
    query: null,
    page: 1,
  }),
  choosePayment: null,
  paymentTemplate: null,
});

export default handleActions({
  [actions.getPaymentsSuccess]: (state, { payload: { paymentList, total, count } }) => state.setIn(['paymentList', 'data'], paymentList).setIn(['paymentList', 'total'], total).setIn(['paymentList', 'count'], count),
  [combineActions(
    actions.getPaymentSuccess,
    actions.updatePaymentSuccess,
    actions.confirmHoldSuccess,
    actions.cancelHoldSuccess
  )]: (state, { payload: payment }) => state.set('choosePayment', payment),

  [actions.clearPayment]: state => state.set('choosePayment', null),
  [actions.clearPayments]: state => state.setIn(['paymentList', 'data'], []),
  [actions.setQuery]: (state, { payload: query }) => state.setIn(['paymentList', 'query'], query),
  [actions.setPage]: (state, { payload: page }) => state.setIn(['paymentList', 'page'], page),
  [actions.updateFinPayments]: (state, { payload: payment }) => state.updateIn(['paymentList', 'data'], payments => {
    const paymentIndex = payments.findIndex(({ id }) => id === payment.id);

    return List(payments).update(paymentIndex, () => payment).toJS();
  }),
  [combineActions(actions.getPaymentTemplateSuccess, actions.clearPaymentTemplate)]: (state, { payload: template }) => state.set('paymentTemplate', template),
}, initialState);
