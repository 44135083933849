import { put, call, select } from 'redux-saga/effects';

import { getStaticLists } from 'api/methods/claim/staticLists';

import { uiActions } from 'bus/ui/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';

import { getSortedToLabelCurrencyList, replaceUAhCurrencyToHrn } from 'helpers/currency/helpers';

const adapterDate = (...[list, key]) => (
  { value: replaceUAhCurrencyToHrn(key), label: list[replaceUAhCurrencyToHrn(key)] }
);

export function* getStaticListsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({ status: { loading: true, error: false, success: false, message: null }, path: ['clientClaim', 'staticLists'] }));

  try {
    const {
      currencies,
      food,
      transport,
      transfers,
      ...rest
    } = yield call(getStaticLists, token);

    yield put(clientClaimActions.getStaticListsSuccess({
      currencies: getSortedToLabelCurrencyList(currencies)
        .sort()
        .map(adapterDate.bind(null, currencies)),
      food: Object.keys(food).map(adapterDate.bind(null, food)),
      transport: Object
        .keys(transport)
        .map(adapterDate.bind(null, transport))
        .map(({ value, ...item }) => ({ value: Number(value), ...item })),
      transfers: transfers.map((...[, idx]) => adapterDate(transfers, idx)),
      ...rest,
    }));
  } catch (error) {
    yield put(clientClaimActions.getStaticListsFail(error));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'staticLists', 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: 'Ошибка загрузки данных', path: ['clientClaim', 'staticLists', 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['clientClaim', 'staticLists', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'staticLists', 'completed'] }));
  }
}
