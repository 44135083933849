import React from 'react';

// styles
import cx from 'classnames';
import Styles from './styles.scss';

export const CardWrapper = ({ className = null, children }) => {
  return (
    <div className={cx(Styles.root, className)}>
      { children }
    </div>
  );
};

CardWrapper.displayName = 'CardWrapper';
