import { createActions } from 'redux-actions';

export const financeActions = createActions({
  GET_PAYMENTS: formikAPI => formikAPI,
  GET_PAYMENTS_SUCCESS: (paymentList, total, count) => ({ paymentList, total, count }),
  GET_PAYMENTS_FAIL: error => error,

  CLEAR_PAYMENTS: () => undefined,

  SET_QUERY: query => query,
  SET_PAGE: page => page,

  GET_PAYMENT: (id, options) => ({ id, options }),
  CLEAR_PAYMENT: () => undefined,
  GET_PAYMENT_SUCCESS: payment => payment,
  GET_PAYMENT_FAIL: error => error,

  UPDATE_PAYMENT: (claimID, payment, formik) => ({ claimID, payment, formik }),
  UPDATE_PAYMENT_SUCCESS: payment => payment,
  UPDATE_PAYMENT_FAIL: error => error,

  UPDATE_FIN_PAYMENTS: payment => payment,

  SEND_PAYMENT_TO_EMAIL: (id, body, formikAPI) => ({ id, body, formikAPI }),
  SEND_PAYMENT_TO_EMAIL_SUCCESS: () => undefined,
  SEND_PAYMENT_TO_EMAIL_FAIL: error => error,

  CONFIRM_HOLD: (claimID, paymentID, values, formik, workerOptions) => (
    { claimID, paymentID, values, formik, workerOptions }
  ),
  CONFIRM_HOLD_SUCCESS: payment => payment,
  CONFIRM_HOLD_FAIL: error => error,

  CANCEL_HOLD: (claimID, paymentID, formik, workerOptions) => (
    { claimID, paymentID, formik, workerOptions }
  ),
  CANCEL_HOLD_SUCCESS: payment => payment,
  CANCEL_HOLD_FAIL: error => error,

  GET_PAYMENT_TEMPLATE: (paymentId, templateId, workerOptions) => (
    { paymentId, templateId, workerOptions }
  ),
  GET_PAYMENT_TEMPLATE_SUCCESS: template => template,
  GET_PAYMENT_TEMPLATE_FAIL: error => error,

  CLEAR_PAYMENT_TEMPLATE: () => null,

  UPDATE_PAYMENT_TEMPLATE: (paymentId, values, formik) => ({ paymentId, values, formik }),
  UPDATE_PAYMENT_TEMPLATE_SUCCESS: success => success,
  UPDATE_PAYMENT_TEMPLATE_FAIL: fail => fail,
}, { prefix: 'MT_FINANCE' });
