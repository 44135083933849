import { call, put, select } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getDocsByOperators } from 'api/methods/common/getDocsByOperators';

export function* getDocsByOperatorsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'fetchDocsByOperators'] }));

  try {
    const response = yield call(getDocsByOperators, token);

    yield put(commonActions.getDocsByOperatorsSuccess(response));
  } catch (error) {
    yield put(commonActions.getDocsByOperatorsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'fetchDocsByOperators'] }));
  }
}
