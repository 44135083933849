import BaseApiClass from 'api/BaseApiClass';
import { Map } from 'immutable';

export const updateProfile = (token, params = {}) => new BaseApiClass({
  url: 'tfs',
  method: 'PUT',
  ...params,
})._load({ token });

export const fetchProfile = (token, params = {}) => new BaseApiClass({
  url: 'me',
  method: 'GET',
  ...params,
})._load({ token });

export const uploadProfileImage = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/image',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};

export const deleteProfileImage = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/image',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getDistribution = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/features/new_claim_delegating',
  method: 'GET',
  ...params,
})._load({ token });

export const updateDistribution = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/features/new_claim_delegating',
  method: 'PUT',
  ...params,
})._load({ token });
