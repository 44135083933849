export const BASKET_STATUSES_MAP = {
  ARCHIVE: 'ARCHIVE',
  NOT_SEND: 'NOT_SEND',
  SEND: 'SEND',
  ALL: 'ALL',
};
export const BASKET_STATUSES_BY_API = {
  [BASKET_STATUSES_MAP.ALL]: 1,
  [BASKET_STATUSES_MAP.ARCHIVE]: 0,
  [BASKET_STATUSES_MAP.SEND]: 2,
  [BASKET_STATUSES_MAP.NOT_SEND]: 3,
};

export const DEFAULT_BASKET_TYPE = BASKET_STATUSES_MAP.ALL;
