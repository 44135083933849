import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { setInitialized, setShow } from './actions';

const initialState = {
  isShow: false,
  isInitialized: false,
};

export default handleActions({
  [setShow]: (state, { payload: status }) => R.assoc('isShow', status, state),
  [setInitialized]: (state, { payload: isInitialized }) => R.assoc('isInitialized', isInitialized, state),
}, initialState);
