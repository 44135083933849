import { put, call, select, takeEvery, all } from 'redux-saga/effects';

import { createUi, withUIWorker } from 'bus/ui/helpers';
import { getToken, getUserTfId } from 'bus/auth/selectors';
import { commonActions } from 'bus/common/actions';
import { registerUI, updateUI } from 'bus/ui/actions.js';

import { getErrorMessage } from 'api/fn/parseErrors';
import {
  createClosingReasons,
  deleteClosingReasons,
  getClaimClosingReasons,
  updateClosingReasons
} from 'api/methods/common/claimSource';

import { newInstance } from 'localization';

import { CLOSING_REASONS_UI } from './constans';

function* getClosingReasonsWorker() {
  const token = yield select(getToken);
  const id = yield select(getUserTfId);

  try {
    const data = yield call(getClaimClosingReasons, token, { pathParams: { id } });

    yield put(commonActions.closingReasonsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отсутствует доступ к разделу или к ТФ',
        404: 'ТФ не найдена',
      }
    );

    error.message = message ? message : newInstance.t('ERRORS:ERROR');

    yield put(commonActions.closingReasonsFail(error));
  }
}

function* createClosingReasonsWorker({ payload: { closingReasons, onSuccess, formik } }) {
  const uiKey = ['CREATE_CLOSING_REASONS', closingReasons.id];

  yield put(registerUI(createUi({ loading: true }), uiKey));

  try {
    const token = yield select(getToken);
    const userId = yield select(getUserTfId);

    const { id } = yield call(
      createClosingReasons,
      token,
      { pathParams: { userId }, bodyParams: { description: closingReasons.name } }
    );

    onSuccess && onSuccess(id);
  } catch (error) {
    const { body } = error.msg || {};

    const message = getErrorMessage(
      error,
      {
        400: body.description,
        500: body.message,
      }
    );

    const indexErrorField = formik.values.data.findIndex(item => item.id === closingReasons.id);

    yield call(formik.setFieldError, `data.${indexErrorField}.name`, message || newInstance.t('ERRORS:ERROR'));
    yield call(formik.setFieldTouched, `data.${indexErrorField}.name`, true, false);
    yield put(updateUI({ error: true }, uiKey));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}

function* updateClosingReasonsWorker({ payload: { id, description } }) {
  const uiKey = ['UPDATE_CLOSING_REASONS', id];

  yield put(registerUI(createUi({ loading: true }), uiKey));

  try {
    const token = yield select(getToken);
    const userId = yield select(getUserTfId);

    yield call(
      updateClosingReasons,
      token,
      { pathParams: { userId, id }, bodyParams: { description } }
    );
  } catch (error) {
    yield put(updateUI({ message: newInstance.t('ERRORS:ERROR'), error: true }, uiKey));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}

function* removeClosingReasonsWorker({ payload: { id, onSuccess } }) {
  const uiKey = ['REMOVE_CLOSING_REASONS', id];

  yield put(registerUI(createUi({ loading: true }), uiKey));

  try {
    const token = yield select(getToken);
    const userId = yield select(getUserTfId);

    yield call(deleteClosingReasons, token, { pathParams: { userId, id } });

    onSuccess && onSuccess();
  } catch (error) {
    yield put(updateUI({ message: newInstance.t('ERRORS:ERROR'), error: true }, uiKey));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}

const getClosingReasonsWorkerWithUI = withUIWorker(
  [commonActions.closingReasonsSuccess, commonActions.closingReasonsFail],
  CLOSING_REASONS_UI,
)(getClosingReasonsWorker);

export default function* () {
  yield all([
    takeEvery(commonActions.getClosingReasons, getClosingReasonsWorkerWithUI),
    takeEvery(commonActions.createClosingReason, createClosingReasonsWorker),
    takeEvery(commonActions.updateClosingReason, updateClosingReasonsWorker),
    takeEvery(commonActions.removeClosingReason, removeClosingReasonsWorker)
  ]);
}
