export const LOCALES = {
  UA: 'uk_UA',
  RU: 'ru',
  EN: 'en',
  PL: 'pl',
};

export const OTHER_LOCALES = {
  UA: 'ua',
  LT: 'lt',
};

export const LANGS_BY_LOCALE = {
  [LOCALES.UA]: 'ukr',
  [LOCALES.RU]: 'rus',
  [LOCALES.EN]: 'en',
  [LOCALES.PL]: 'pl',
};

export const LOCALE_LOADERS_MAP = {
  [LOCALES.RU]: () => import(/* ru-locale-json */'./locales/ru.json'),
  [LOCALES.UA]: () => import(/* ua-locale-json */'./locales/ua.json'),
  [LOCALES.EN]: () => import(/* en-locale-json */'./locales/en.json'),
  [LOCALES.PL]: () => import(/* pl-locale-json */'./locales/pl.json'),
};
