import React from 'react';
import { Map } from 'immutable';

export const ROOT_KEY = '@@MT@@';

export const GROUPS = {
  auth: 'auth',
  sessions: 'sessions',
  subscription: 'subscription',
  main: 'mainPage',
  clientClaim: 'clientClaim',
  clientProfile: 'clientProfile',
  addClaim: 'addClaim',
  statistic: 'statistic',
  fin: 'fin',
  dump: 'dump',
  calendar: 'calendar',
  savedSearches: 'savedSearches',
  booking: 'booking'
};

const checkStorage = storage => {
  try {
    const isEnabled = Boolean(window[storage]);

    return isEnabled;
  } catch (e) {
    return false;
  }
};

const isStorageEnabled = checkStorage('localStorage');
const isSessionEnabled = checkStorage('sessionStorage');

export const getStorage = () => {
  const initialValue = isStorageEnabled ? window.localStorage.getItem(ROOT_KEY) || '{}' : '{}';
  const initialValueSession = isSessionEnabled ? window.sessionStorage.getItem(ROOT_KEY) || '{}' : '{}';

  try {
    return Map(JSON.parse(initialValue)).mergeDeep(JSON.parse(initialValueSession));
  } catch (error) {
    __DEV__ && console.error(error.message);

    return Map();
  }
};

export const updateStorage = storage => {
  try {
    isStorageEnabled && window.localStorage.setItem(ROOT_KEY, JSON.stringify(storage.toJS()));
  } catch (e) {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.localStorage.setItem(ROOT_KEY, JSON.stringify(storage.deleteAll([GROUPS.fin, GROUPS.statistic, GROUPS.dump]).toJS()));
  }
};

export const saveSessions = session => {
  isSessionEnabled && window.sessionStorage.setItem(ROOT_KEY, JSON.stringify({ [GROUPS.sessions]: [session] }));
};

export const resetStorage = () => {
  const newStorage = getStorage().deleteAll([GROUPS.auth, GROUPS.sessions, GROUPS.dump]);

  updateStorage(newStorage);
  isSessionEnabled && window.sessionStorage.setItem(ROOT_KEY, JSON.stringify({}));
};
export const deleteKeyFromStore = key => {
  const newStorage = getStorage().delete(key);

  updateStorage(newStorage);
};
export const deletePathFromStore = path => {
  const newStorage = getStorage().deleteIn(path);

  updateStorage(newStorage);
};

export const setGroupValue = (groupKey, value) => {
  const newStorage = getStorage().set(groupKey, value);

  updateStorage(newStorage);
};

export const setInGroupValue = (groupPath, value) => {
  const newStorage = getStorage().setIn(groupPath, value);

  updateStorage(newStorage);
};

export const withStorage = () => Component => props => {
  return (
    <Component
      {...props}
      getStorage={getStorage}
      GROUPS={GROUPS}
      setGroupValue={setGroupValue}
      setInGroupValue={setInGroupValue}
    />
  );
};
