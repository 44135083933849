import { select, call, put } from 'redux-saga/effects';

// insturments
import { deleteRegion } from 'api/methods/common/regions';
import { commonActions } from 'bus/common/actions';

export function* deleteRegionWorker({ payload: id }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    yield call(deleteRegion, token, { pathParams: { id } });
    yield put(commonActions.deleteRegionSuccess(id));
  } catch (error) {
    yield put(commonActions.deleteRegionFail(error));
  }
}
