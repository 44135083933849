import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { getNotifyTypes } from 'api/methods/tf/notify';
import { notifyActions } from 'bus/notify';
import { uiActions } from 'bus/ui/actions';

const BASE_UI = ['notify', 'types'];

export function* getTypesWorker({ payload: workerOptions }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const uiEnabled = get(workerOptions, 'UI', false);

  uiEnabled && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI,
  })));

  try {
    const types = yield call(getNotifyTypes, token);

    yield put(notifyActions.getTypesSuccess(types));
  } catch (error) {
    yield put(notifyActions.getTypesFail(error));

    uiEnabled && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI, 'error'],
    })));
    uiEnabled && (yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка загрузки данных',
      path: [...BASE_UI, 'message'],
    })));
  } finally {
    uiEnabled && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI, 'loading'],
    })));
    uiEnabled && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI, 'completed'],
    })));
  }
}
