import { call, select, put } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

// instruments
import { uiActions } from 'bus/ui/actions';
import { statisticActions } from 'bus/statistic/actions';
import { getLogs } from 'api/methods/main/getLogs';
import { removeNull } from 'helpers';
import book from 'routes/book';
import { compile } from 'helpers/hash';

export function* getLogsWorker({ payload }) {
  const { formik } = payload;
  const { token, query, page } = yield select(({ auth, statistic }) => ({
    token: auth.get('token'),
    query: statistic.getIn(['logs', 'query']),
    page: statistic.getIn(['logs', 'page']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['statistic', 'logs'],
  }));

  const { start, end, ...rest } = query;
  const queryParams = removeNull({
    ...rest,
    start: start ? start.startOf('day').format() : null,
    end: end ? end.endOf('day').format() : null,
    page,
  });

  yield put(replace(`${book.stat.children.log}?form=${compile(query)}&page=${page}`));

  try {
    const { results, count, limit } = yield call(getLogs, token, { queryParams });

    yield put(statisticActions.getLogsSuccess(results, count, limit));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Доступ отказано';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    message && formik && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['statistic', 'logs', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['statistic', 'logs', 'completed'],
    }));
    formik && (yield call(formik.setSubmitting, false));
  }
}
