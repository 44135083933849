import { newInstance } from 'localization';

export const PAYMENT_TYPES = {
  PAY_US_FROM_TA: 0,
  PAY_FROM_US_TO: 1,
  PAY_US_FROM_TO: 2,
  PAY_FROM_US_TA: 3,
};

export const PAYMENT_STATUSES = {
  UNPROCESSED: 0,
  PROCESSED: 1,
  SENDING: 3,
  CANCELLED: 2,
};

export const SEVERANCE_PAYMENT_STATUSES = {
  SENDING: 0,
  ERROR: 1,
  SENT: 2,
  CONFIRMED: 3,
  REJECTED: 4,
};

export const PAYMENT_TYPES_OPTIONS = [
  {
    get text() {
      return newInstance.t('BOOKING_PAYMENTS_RECOGNITIONS:PAY_US_FROM_TA');
    },
    value: PAYMENT_TYPES.PAY_US_FROM_TA
  },
  {
    get text() {
      return newInstance.t('BOOKING_PAYMENTS_RECOGNITIONS:PAY_FROM_US_TO');
    },
    value: PAYMENT_TYPES.PAY_FROM_US_TO
  },
  {
    get text() {
      return newInstance.t('BOOKING_PAYMENTS_RECOGNITIONS:PAY_US_FROM_TO');
    },
    value: PAYMENT_TYPES.PAY_US_FROM_TO
  },
  {
    get text() {
      return newInstance.t('BOOKING_PAYMENTS_RECOGNITIONS:PAY_FROM_US_TA');
    },
    value: PAYMENT_TYPES.PAY_FROM_US_TA
  }
];
