import { call, put, select } from 'redux-saga/effects';

import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getOperators } from 'api/methods/common/operators';

export function* getOperatorsWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const tfID = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));
  const { queryParams = {} } = payload;

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'fetchOperators'] }));

  try {
    const { operators, limit, total } = yield call(
      getOperators,
      token,
      { pathParams: { id: tfID }, queryParams }
    );

    yield put(commonActions.getOperatorsMtSuccess(operators));
    yield put(commonActions.setOperatorsPagination(total, limit));
  } catch (error) {
    yield put(commonActions.getOperatorsMtFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'fetchOperators'] }));
  }
}
