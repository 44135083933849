import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { sendDigitalContractToEmail } from 'api/methods/claim/digitalContract';

export function* sendDigitalContractToEmailWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { claimId, contractId, values, formik } = payload;

  try {
    yield call(
      sendDigitalContractToEmail,
      token,
      { pathParams: { id: claimId, contractId }, bodyParams: values }
    );

    yield put(digitalContractActions.sendDigitalContractToEmailSuccess(contractId));
    formik && (yield call(formik.setStatus, { success: true, message: 'Договор отправлен' }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Неверный запрос');

        break;
      }
      case 402: {
        message = get(body, 'message', 'Недостаточно средств на счету');

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление. Запрещено если активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = get(body, 'message', 'Заказ не существует');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
