import { call, put, select } from 'redux-saga/effects';

import { updateUserImage } from 'api/methods/tf/user';
import { getErrorMessage } from 'api/fn/parseErrors';

import { withUI } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { tfUsersActions } from '../../../actions';

function* updateUserImageSaga({ payload: { userId, image } }) {
  const token = yield select(getToken);

  try {
    const bodyParams = new FormData();

    bodyParams.append('file', image);

    const { photo_url: photoUrl } = yield call(updateUserImage, token, { pathParams: { userId }, bodyParams });

    yield put(tfUsersActions.updateUserImageSuccess(photoUrl));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(tfUsersActions.updateUserImageFail(error));
  }
}

export const {
  saga: updateUserImageSagaWithUI,
  uiSelector: updateUserImageSelector
} = withUI(
  'UPDATE_USER_IMAGE',
  [
    tfUsersActions.updateUserImageSuccess,
    tfUsersActions.updateUserImageFail
  ],
  updateUserImageSaga
);
