import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// actions
import { newsActions as actions } from 'bus/news';

const initialState = Map({
  many: {
    data: [],
    total: 0,
    limit: 0,
  },
  single: {
    result: null,
  },
  newsSelections: [],
});

export const newsReducer = handleActions({
  [actions.getNewsSuccess]: (state, { payload: news }) => state.mergeIn(['many'], news),
  [actions.getNewsSelectionsSuccess]: (state, { payload: newsSelections }) => state.set('newsSelections', newsSelections),
  [actions.clearNews]: state => {
    return state
      .setIn(['many', 'data'], [])
      .setIn(['many', 'total'], 0)
      .setIn(['many', 'limit'], 0)
      .setIn(['many', 'page'], 1);
  },
  [actions.getSingleNewsSuccess]: (state, { payload: news }) => state.setIn(['single', 'result'], news),
  [actions.clearSingleNews]: state => state.setIn(['single', 'result'], null),
  [actions.viewedNewsSuccess]: (state, { payload: id }) => {
    const updateNewsIndex = state.getIn(['many', 'data']).findIndex(news => news.id === id);

    return state.setIn(['many', 'data', updateNewsIndex, 'is_viewed'], true);
  },
}, initialState);
