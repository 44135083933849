import * as R from 'ramda';

import { LOCALES, LOCALE_LOADERS_MAP } from './constants';

export const getLocalesList = locale => R.prop(locale, LOCALE_LOADERS_MAP);

export const convertBrowserLangToLocale = () => R.call(
  R.cond([
    [
      lang => R.includes(lang, ['en', 'en-us', 'en-gb']),
      R.always(LOCALES.EN)
    ],
    [
      R.equals('uk'),
      R.always(LOCALES.UA)
    ],
    [
      lang => R.includes(lang, ['ru', 'ru-mo']),
      R.always(LOCALES.RU)
    ],
    [
      R.T,
      R.always(LOCALES.EN)
    ]
  ]),
  R.toLower(global.navigator.language)
);

export const prepareStringWithHtmlSymbols = str => str.replaceAll('&amp;', '&');
