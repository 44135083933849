import BaseApiClass from 'api/BaseApiClass';

export const getFiles = (token, params) => new BaseApiClass({
  url: 'tfs/{:tf}/uploads',
  method: 'GET',
  ...params,
})._load({ token });

export const deleteFile = (token, params) => new BaseApiClass({
  url: 'tfs/{:tf}/upload',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
