import { race, put, take, takeEvery, all } from 'redux-saga/effects';

import { commonActions } from 'bus/common/actions';
import { tfActions } from 'bus/tf/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { bootstrapPage } from './actions';
import { BOOTSTRAP_UI } from '../constants';

function* bootstrapSaga() {
  yield put(registerUI(createUi({ loading: true }), BOOTSTRAP_UI));

  yield all([
    put(tfActions.getOperatorCredentials()),
    put(commonActions.getOperatorsMt({ with_settings: true }))
  ]);

  const [[operatorCredentialsSuccess], [operatorsMtSuccess]] = yield all([
    race([
      take(tfActions.getOperatorCredentialsSuccess),
      take(tfActions.getOperatorCredentialsFail)
    ]),
    race([
      take(commonActions.getOperatorsMtSuccess),
      take(commonActions.getOperatorsMtFail)
    ])
  ]);

  if (operatorCredentialsSuccess && operatorsMtSuccess) {
    yield put(updateUI({ loading: false, completed: true }, BOOTSTRAP_UI));
  } else {
    yield put(updateUI({ error: true }, BOOTSTRAP_UI));
  }
}

export default function* () {
  yield takeEvery(bootstrapPage, bootstrapSaga);
}
