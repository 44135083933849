import { takeEvery } from 'redux-saga/effects';

// actions
import { apiKeysActions as actions } from '../actions';

// workers
import { getApiKeysWorker } from './workers/getApiKeys';
import { createApiKeyWorker } from './workers/createApiKey';
import { deleteApiKeyWorker } from './workers/deleteApiKey';
import { sendApiKeyWorker } from './workers/sendApiKey';

export const apiKeysWatchers = Object.freeze({
  * getApiKeysWatcher() {
    yield takeEvery(actions.getApiKeys, getApiKeysWorker);
  },
  * createApiKeyWatcher() {
    yield takeEvery(actions.createApiKey, createApiKeyWorker);
  },
  * deleteApiKeyWatcher() {
    yield takeEvery(actions.deleteApiKey, deleteApiKeyWorker);
  },
  * sendApiKeyWorker() {
    yield takeEvery(actions.sendApiKey, sendApiKeyWorker);
  },

});
