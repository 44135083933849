import { call, select, put } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { showToastError, showToastSuccess } from 'services/toaster';

import { calculateEnd2EndBooking } from 'api/methods/booking';

import { CALCULATE_END2END_BOOKING } from '../../constants';

import { getBookingId } from '../../selectors';
import { bookingActions } from '../../actions';

export function* calculateEnd2EndBookingSaga() {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  yield put(registerUI(createUi({ loading: true, success: false }), CALCULATE_END2END_BOOKING));

  try {
    const { message, content } = yield call(
      calculateEnd2EndBooking,
      token,
      { pathParams: { bookingId } }
    );

    yield put(updateUI({ success: true }, CALCULATE_END2END_BOOKING));
    yield put(bookingActions.setEnd2EndBookingData(content));

    showToastSuccess(message);
  } catch (error) {
    const { body } = error.msg || {};
    showToastError(body?.message);
    yield put(updateUI({ error: true }, CALCULATE_END2END_BOOKING));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, CALCULATE_END2END_BOOKING));
  }
}
