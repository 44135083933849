import { put, race, select, take } from 'redux-saga/effects';
import * as R from 'ramda';

import { commonActions } from 'bus/common/actions';
import { getManagers } from 'bus/common/selectors';
import { getUserId, getUserName, getDirectorRoles } from 'bus/auth/selectors';

import { newInstance } from 'localization';

const normalizeManagers = (managers, id) => R.map(
  R.applySpec({
    id: R.prop('id'),
    name: R.prop('name'),
    initialSelected: R.propEq(id, 'id'),
  }),
  managers
);

export function* getManagersSaga({ payload: callbacks }) {
  const { onSuccess, onFail } = callbacks;

  const isDirector = yield select(getDirectorRoles);
  const id = yield select(getUserId);

  if (isDirector) {
    yield put(commonActions.getManagers());

    const [isSuccess, isFail] = yield race([
      take(commonActions.getManagersSuccess),
      take(commonActions.getManagersFail)
    ]);

    if (isSuccess) {
      const managers = yield select(getManagers);

      const normalizedManagers = R.prepend(
        { id: 0, name: newInstance.t('GLOBAL:ALL') },
        normalizeManagers(managers, id)
      );

      onSuccess(normalizedManagers);
    } else if (isFail) {
      onFail(isFail.payload);
    }
  } else {
    const name = yield select(getUserName);

    onSuccess([{ id, name, initialSelected: true }]);
  }
}
