import React, { useCallback, useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import debounce from 'lodash.debounce';

import arrowIcon from 'assets/icons/materialIcons/arrow_upward_24.svg';

import { IconButton } from 'components/buttons/IconButton';

import styles from './styles.scss';

export const ToUpButton = () => {
  const [isShow, setIsShow] = useState(false);

  const handleClick = useCallback(() => animateScroll.scrollToTop(), []);

  useEffect(() => {
    const listener = debounce(() => {
      setIsShow(global.window.scrollY >= global.window.innerHeight);
    }, 150);

    global.window.addEventListener('scroll', listener);

    return () => global.window.removeEventListener('scroll', listener);
  }, []);

  return isShow && (
    <IconButton
      className={styles.goUpButton}
      svg={arrowIcon}
      handleClick={handleClick}
    />
  );
};
