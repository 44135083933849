import { call, put } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { authActions as actions } from 'bus/auth/actions';
import { forget } from 'api/methods/auth/user';
import { i18authGlobalInstance } from '../../../../localization/otherInstance';

export function* forgetWorker({ payload }) {
  const { values, formik } = payload;

  try {
    yield call(forget, { token: null }, { bodyParams: values });

    yield put(actions.forgetSuccess());
    formik && (yield call(formik.setStatus, { success: true, message: i18authGlobalInstance.t(
      'FORGOT_PASSWORD_EMAIL_SEND'
    ) }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', i18authGlobalInstance.t(
          'FORGOT_PASSWORD_INVALID_SEND'
        ));

        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    yield put(actions.forgetFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
