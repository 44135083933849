const isAvailableLocalStorage = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');

    return true;
  } catch (e) {
    return false;
  }
};

const customInterface = {
  memory: {},
  getItem(key) {
    return this.memory[key];
  },
  removeItem(key) {
    delete this.memory[key];
  },
  setItem(key, value) {
    this.memory[key] = value;
  },
};

export const localStorageInterface = isAvailableLocalStorage()
  ? global.localStorage
  : customInterface;
