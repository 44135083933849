import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insturuments
import { uiActions } from 'bus/ui/actions';
import { financeActions } from 'bus/finance/actions';
import { getPaymentTemplate } from 'api/methods/finance/getPaymentTemplate';

const BASE_UI_PATH = ['finance', 'paymentTemplate'];

export function* getPaymentTemplateWorker({ payload }) {
  const { paymentId, templateId, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { completed: false, errors: false, loading: true, message: null },
    path: BASE_UI_PATH,
  })));

  try {
    const template = yield call(getPaymentTemplate, token, { pathParams: { id: paymentId }, queryParams: { template_id: templateId, template: 'invoice' } });

    yield put(financeActions.getPaymentTemplateSuccess(template));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу');

        break;
      }
      case 404: {
        message = 'Платеж или передан шаблон ТФ не найден';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));

    yield put(financeActions.getPaymentTemplateFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
