import { select, call, put, take } from 'redux-saga/effects';
import { get } from 'immutable';

// instrumetns
import { updateProfile } from 'api/methods/tf/profile';
import { tfActions } from 'bus/tf/actions';
import { authActions } from 'bus/auth/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';
import { newInstance } from '../../../../../localization';

export function* updateTfProfileWorker({ payload }) {
  const { values, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const response = yield call(updateProfile, token, { bodyParams: values });

    yield put(tfActions.updateTfProfileSuccess(response));
    yield put(authActions.fetchMyself());

    yield take(authActions.fetchMyselfSuccess);

    formik && (yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);

        if (violations.length && formik) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          yield call(formik.setErrors, errorsByValues);

          if (otherErrors.length) {
            message = otherErrors.map(item => item.message).join('. ');
          }
        } else {
          message = get(body, 'detail', 'Ошибка сервера');
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права обновлять турфирму');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(tfActions.updateTfProfileFail(error));
    formik && message && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
