import { handleActions, combineActions } from 'redux-actions';
import { Map } from 'immutable';
import { tfUsersActions as actions } from './actions';

const initialState = Map({
  users: {
    data: [],
    filterValue: null,
  },
  user: null,
  defaultSignTemplate: null,
});

export default handleActions({
  [actions.fetchUsersSuccess]: (state, { payload: users }) => {
    return state.setIn(['users', 'data'], users);
  },
  [actions.toggleTfUserStatusSuccess]: (state, { payload }) => {
    const updateElementIndex = state.getIn(['users', 'data']).findIndex(({ id }) => id === payload.id);

    return state.setIn(['users', 'data', updateElementIndex, 'active'], payload.active);
  },
  [actions.fetchUserSuccess]: (state, { payload: user }) => state.set('user', user),
  [actions.updateUserInfoSuccess]: (state, { payload: user }) => state.set('user', user),
  [actions.updateUserImageSuccess]: (state, { payload: image }) => state.setIn(['user', 'userImage'], image),
  [actions.removeUserImageSuccess]: state => state.setIn(['user', 'userImage'], null),
  [actions.resetUser]: state => state.set('user', null),
  [combineActions(actions.getDefaultTemplateSuccess, actions.clearDefaultTemplate)]: (state, { payload: template }) => {
    return state.set('defaultSignTemplate', template || null);
  },
  [actions.setUsersFilterValue]: (state, { payload: filterValue }) => {
    return state.setIn(['users', 'filterValue'], filterValue);
  },
  [actions.addUserSuccess]: (state, { payload: user }) => state.updateIn(['users', 'data'], users => [...users, user]),
}, initialState);
