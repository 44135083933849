import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { tfActions as actions } from 'bus/tf/actions';
import { getToken, getUserTfId } from 'bus/auth/selectors';

import { getCommission } from 'api/methods/tf/counterparty';

const BASE_UI_PATH = ['tf', 'commission'];

export function* getCommissionWorker({ payload: queryParams = {} }) {
  const token = yield select(getToken);
  const tf = yield select(getUserTfId);

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const {
      id,
      name,
      items,
      description,
      show_contacts: showContacts
    } = yield call(getCommission, token, { pathParams: { tf }, queryParams });

    yield put(actions.getCommissionSuccess(id, name, items, description, showContacts));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 204: {
        message = 'Группа не назначена';
        break;
      }
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default:
        message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
    yield put(actions.getCommissionFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
