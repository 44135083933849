import { put, call, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { statisticActions } from 'bus/statistic/actions';
import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { getBonuses } from 'api/methods/search/getBonuses';
import { getErrorMessage } from 'api/fn/parseErrors';

import { getDataFromSelectors, onSaveSearchToCache, withSetQuery2Url } from 'services/formCreator/saga';

import {
  GET_BONUSES_STAT_COOKIE_KEY,
  GET_BONUSES_STAT_UI,
} from './constants';

import { filterQuery } from './helpers';

import { getBonusesPage, getBonusesQuery } from '../selectors';

const prepareBody = (query, page) => R.pipe(
  filterQuery,
  filters => ({ filters, page })
)(query);
const prepareQuerySearchString = (query, page) => R.pipe(
  filterQuery,
  filters => R.mergeAll([filters, page === 1 ? {} : { page }])
)(query);

function* getBonusesWorker() {
  const token = yield select(getToken);
  const query = yield select(getBonusesQuery);
  const page = yield select(getBonusesPage);

  try {
    const {
      data: results,
      total: count,
      limit
    } = yield call(getBonuses, token, { bodyParams: prepareBody(query, page) });

    yield put(statisticActions.getBonusesSuccess(results, count, limit));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        400: 'Ошибка валидации',
        403: 'Необходимо иметь доступ к разделу "Клиенты". Запрещен или активна подписка только на Турсканер',
        404: 'Ошибка. Страницы не найдено',
        500: 'Ошибка сервера 500',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(statisticActions.getBonusesFail(error));
  }
}

const actions = [statisticActions.getBonusesSuccess, statisticActions.getBonusesFail];

export const getBonusesStat = R.pipe(
  withUIWorker(actions, GET_BONUSES_STAT_UI),
  withSetQuery2Url(
    actions,
    {
      onGetData: getDataFromSelectors([getBonusesQuery, getBonusesPage], prepareQuerySearchString),
      onSuccess: onSaveSearchToCache(GET_BONUSES_STAT_COOKIE_KEY)
    }
  )
)(getBonusesWorker);
