import { takeEvery } from 'redux-saga/effects';

import {
  fetchScenariosListWorker,
  fetchRolesListWorker,
  deleteTagWorker,
  createTagWorker,
  updateTagWorker,
  createRegionWorker,
  deleteRegionWorker,
  updateRegionWorker,
  createOfficeWorker,
  updateOfficeWorker,
  deleteOfficeWorker,
  fetchTfProfileWorker,
  updateTfProfileWorker,
  uploadTfImageWorker,
  deleteTfImageWorker,
  updateTfProfileAndImageWorker,
  createDetailWorker,
  updateDetailWorker,
  getTfFieldsWorker,
  getOperatorCredentialsWorker,
  createOperatorCredentialsWorker,
  deleteOperatorCredentialWorker,
  updateOperatorCredentialWorker,
  getDistributionWorker,
  updateDistributionWorker,
  getCommissionWorker,
  getMerchantsWorker,
  createMerchantWorker,
  updateMerchantWorker

} from './workers';
import { apiKeysWatchers } from '../apiKeys/saga/watchers';
import { tfActions as actions } from '../actions';
import { getCommissionCounterpartiesWorker } from './workers/getCommissionCounterparties';
import getGroupsWorker from './workers/getGroups';

export const tfWathcers = Object.freeze({
  ...apiKeysWatchers,

  * fetchScenarioListWatcher() {
    yield takeEvery(actions.fetchScenariosList, fetchScenariosListWorker);
  },
  * fetchRolesListWatcher() {
    yield takeEvery(actions.fetchRolesList, fetchRolesListWorker);
  },
  * deleteTagWatcher() {
    yield takeEvery(actions.deleteTag, deleteTagWorker);
  },
  * createTagWatcher() {
    yield takeEvery(actions.createTag, createTagWorker);
  },
  * updateTagWatcher() {
    yield takeEvery(actions.updateTag, updateTagWorker);
  },
  * createRegionWatcher() {
    yield takeEvery(actions.createRegion, createRegionWorker);
  },
  * deleteRegionWatcher() {
    yield takeEvery(actions.deleteRegion, deleteRegionWorker);
  },
  * updateRegionWatcher() {
    yield takeEvery(actions.updateRegion, updateRegionWorker);
  },
  * createOfficeWatcher() {
    yield takeEvery(actions.createOffice, createOfficeWorker);
  },
  * updateOfficeWatcher() {
    yield takeEvery(actions.updateOffice, updateOfficeWorker);
  },
  * deleteOfficeWatcher() {
    yield takeEvery(actions.deleteOffice, deleteOfficeWorker);
  },
  * fetchTfProfile() {
    yield takeEvery(actions.fetchTfProfile, fetchTfProfileWorker);
  },
  * updateTfProfile() {
    yield takeEvery(actions.updateTfProfile, updateTfProfileWorker);
  },
  * uploadTfImage() {
    yield takeEvery(actions.uploadTfImage, uploadTfImageWorker);
  },
  * deleteTfImage() {
    yield takeEvery(actions.deleteTfImage, deleteTfImageWorker);
  },
  * updateTfProfileAndImage() {
    yield takeEvery(actions.updateTfProfileAndImage, updateTfProfileAndImageWorker);
  },
  * createDetailWatcher() {
    yield takeEvery(actions.createDetail, createDetailWorker);
  },
  * updateDetailWatcher() {
    yield takeEvery(actions.updateDetail, updateDetailWorker);
  },
  * getTfFieldsWatcher() {
    yield takeEvery(actions.getTfFields, getTfFieldsWorker);
  },
  * getOperatorsCredentialsWatcher() {
    yield takeEvery(actions.getOperatorCredentials, getOperatorCredentialsWorker);
  },
  * createOperatorCredentialsWatcher() {
    yield takeEvery(actions.createOperatorCredentials, createOperatorCredentialsWorker);
  },
  * deleteOperatorsCredentialWatcher() {
    yield takeEvery(actions.deleteOperatorCredential, deleteOperatorCredentialWorker);
  },
  * updateOperatorCredentialWatcher() {
    yield takeEvery(actions.updateOperatorCredential, updateOperatorCredentialWorker);
  },
  * getDistributionWatcher() {
    yield takeEvery(actions.getDistribution, getDistributionWorker);
  },
  * updateDistributionWatcher() {
    yield takeEvery(actions.updateDistribution, updateDistributionWorker);
  },
  * getCommissionWatcher() {
    yield takeEvery(actions.getCommission, getCommissionWorker);
  },
  * getCommissionCounterpartiesWatcher() {
    yield takeEvery(actions.getCommissionCounterparties, getCommissionCounterpartiesWorker);
  },
  * getMerchantsWatcher() {
    yield takeEvery(actions.getMerchants, getMerchantsWorker);
  },
  * createMerchantWatcher() {
    yield takeEvery(actions.createMerchant, createMerchantWorker);
  },
  * updateMerchantWatcher() {
    yield takeEvery(actions.updateMerchant, updateMerchantWorker);
  },
  getGroupsWorker,
});
