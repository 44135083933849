// instruments
import BaseApiClass from 'api/BaseApiClass';

export const createBookingTourist = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/tourists',
  method: 'POST',
  ...params,
})._load({ token });

export const updateBookingTourist = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/tourists/{:touristId}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteBookingTourist = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/tourists/{:touristId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
