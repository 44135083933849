import { put, call, select } from 'redux-saga/effects';
import { getToken } from 'bus/auth/selectors';

import { sendAkt } from 'api/methods/accounting';

import { registerUI, updateUI } from 'bus/ui/actions.js';
import { createUi } from 'bus/ui/helpers.js';

export const SEND_AKT_UI = 'SEND_AKT_UI';

export function* sendAktWorker({ payload: { id, emails, onSuccess } }) {
  const token = yield select(getToken);

  yield put(registerUI(createUi({ loading: true }), SEND_AKT_UI));

  try {
    const { send } = yield call(sendAkt, token, { pathParams: { id }, bodyParams: { emails } });

    yield put(updateUI({ success: send }, SEND_AKT_UI));
  } catch (error) {
    yield put(updateUI({ message: 'Ошибка сервера', error: true }, SEND_AKT_UI));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, SEND_AKT_UI));
    onSuccess && onSuccess(false);
  }
}
