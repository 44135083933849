import { call, put, select } from 'redux-saga/effects';
import { getLocation, replace } from 'connected-react-router';
import { get } from 'immutable';

import book from 'routes/book';

import { resetStorage } from 'helpers/storage';
import { logout } from 'api/methods/auth/user';
import { uiActions } from 'bus/ui/actions';
import { authActions } from 'bus/auth/actions';

import { createRefLink, loadLimitedUserModal } from '../../helpers';

import { getLocale } from '../../selectors';

function* baseLogOut() {
  yield call(resetStorage);
  yield put(authActions.logOutSuccess());
}

export function* logOutWorker({ payload }) {
  const { generateRef, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));
  const { pathname, search } = yield select(({ router }) => router.location);

  const ref = generateRef ? `?${createRefLink(pathname, search)}` : '';

  if (UI) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['auth', 'logout', 'loading'],
    }));
    try {
      yield call(logout, token);

      yield call(resetStorage);

      window.location.replace(book.login);

      return;
    } catch (error) {
      __DEV__ && console.log(error);
    }
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['auth', 'logout', 'loading'],
    }));
  }

  yield call(baseLogOut);

  if (ref) {
    yield put(replace(`${book.login}${ref}`));
  }
}

export function* showLimitUserModalWorker() {
  const { pathname } = yield select(getLocation);

  const isAdminPath = pathname.includes(book.admin);

  yield put(
    loadLimitedUserModal({
      onClose: dispatch => (isAdminPath
        ? global.open(book.adminLogin, '_self')
        : dispatch(authActions.logOutPure()))
      ,
    })
  );
}

export function* logOutPureWorker() {
  const userLocale = yield select(getLocale);

  yield call(baseLogOut);

  yield put(replace(`${book.login}?lang=${userLocale}`));
}
