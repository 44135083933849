import loader from 'containers/modalsDispatcher/loader';

const MODAL_LOADER_KEY = 'CONFIRM_MODAL';

export const showConfirmModal = loader({
  key: MODAL_LOADER_KEY,
  getModal: () => import(/* confirm-modal */'./ConfirmModal'),
});

export { MODAL_LOADER_KEY };
