import { put, call } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { authActions as actions } from 'bus/auth/actions';
import { createRegisterCode } from 'api/methods/auth/user';

const BASE_UI_PATH = ['auth', 'registerCode'];

export function* createRegisterCodeWorker({ payload: { phone, isShowRepeatMessage }, notifications }) {
  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, success: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    yield call(createRegisterCode, { bodyParams: { phone } });

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'success'] }));

    if (isShowRepeatMessage) {
      yield put(uiActions.changeUiLoaderFlag({ status: 'Новый код отправлен на ваш номер повторно.', path: [...BASE_UI_PATH, 'message'] }));
    }

    yield put(actions.createRegisterCodeSuccess());
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 406: {
        message = get(body, 'message', 'Ваш телефон в черном списке. Чтобы исправить это свяжитесь с нами');
        break;
      }
      case 429: {
        message = get(body, 'message', 'Код не отправлен, слишком частые запросы.');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(actions.createRegisterCodeFail(error));

    notifications?.onShowFailMsg(message);

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
