import BaseApiClass from 'api/BaseApiClass';

export const sendPaymentToEmail = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/payments/{:id}/send',
    method: 'PUT',
    ...params,
  });

  instance.convertBodyToServerSchema = ({ to, subject, message }) => {
    return {
      to,
      title: subject,
      text: message,
    };
  };

  return instance._load({ token });
};
