import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { setIdentifier } from './actions';

const initialState = {
  identifier: null,
};

export default handleActions({
  [setIdentifier]: (state, { payload: identifier }) => R.assoc('identifier', identifier, state),
}, initialState);
