// instruments
import BaseApiClass from 'api/BaseApiClass';

export const getCalendarEvents = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/calendars',
  method: 'GET',
  ...params,
})._load({ token });

export const getCalendarEvent = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/calendars/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const createCalendarEvent = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/calendars',
  method: 'POST',
  ...params,
})._load({ token });

export const updateCalendarEvent = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/calendars/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteCalendarEvent = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/calendars/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
