import * as R from 'ramda';

import { removeEmptyArray, removeEmptyString, removeNull, replaceMoment2String } from 'helpers';

import { CATEGORIES_MAP } from 'containers/form/booking/Search/constants';

const ALL_SELECTED_OPTION = 'all';

const filterQuery = R.pipe(
  removeEmptyArray,
  result => removeNull(result, ['performer', 'curator', 'curator_tf']),
  removeEmptyString,
  replaceMoment2String
);

const getManager = (manager, key) => R.cond([
  [R.equals(null), R.always({ [key]: null })],
  [R.equals('none'), R.always({ [key]: null })],
  [R.complement(Boolean), R.always({})],
  [R.T, value => ({ [key]: value })]
])(manager);

export const prepareClientBookingValues = R.pipe(
  filterQuery,
  ({ performer, statuses, ...rest }) => R.mergeAll([
    rest,
    getManager(performer, 'performer'),
    statuses ? { statuses: statuses.includes(ALL_SELECTED_OPTION) ? [0, 1, 2, 3, 5, 4, 6, 7, 8] : statuses } : {}
  ])
);

export const prepareClientBookingQuery = (query, page, sort) => R.pipe(
  filterQuery,
  ({ performer, ...rest }) => R.mergeAll([
    rest,
    page === 1 ? {} : { page },
    getManager(performer, 'performer'),
    { sort }
  ])
)(query);

export const prepareAdminBookingValues = R.pipe(
  filterQuery,
  ({ curator, curator_tf: curatorTf, categories, booking_id: bookingId, ...rest }) => R.mergeAll([
    rest,
    getManager(curator, 'curator'),
    getManager(curatorTf, 'curator_tf'),
    bookingId ? { booking_id: bookingId.replace(/\D/g, '') } : {},
    R.equals(CATEGORIES_MAP.MESSAGES, categories) ? { has_new_messages: 1 } : {},
  ])
);
export const prepareAdminBookingQuery = (query, page, order) => R.pipe(
  filterQuery,
  ({ curator, curator_tf: curatorTf, categories, ...rest }) => R.mergeAll([
    rest,
    page === 1 ? {} : { page },
    categories === CATEGORIES_MAP.MESSAGES ? { categories } : {},
    getManager(curator, 'curator'),
    getManager(curatorTf, 'curator_tf'),
    { order }
  ])
)(query);
