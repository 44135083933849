import { createActions } from 'redux-actions';

const PREFIX = 'BOOKING_COMMON';

export const {
  updateBooking,
  updateBookingWithReload
} = createActions({
  updateBooking: (values, formik, onSuccess) => ({ values, formik, onSuccess }),
  updateBookingWithReload: values => values,
}, { prefix: PREFIX });
