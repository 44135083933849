import React, { memo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getInitialized } from '../bus/auth/selectors';

import { newInstance } from './index';

const LocalizationProviderPure = ({ children }) => {
  const isInitialized = useSelector(getInitialized);

  return (
    isInitialized
      ? (
        <I18nextProvider i18n={newInstance}>
          {children}
        </I18nextProvider>
      )
      : children
  );
};

export const LocalizationProvider = memo(LocalizationProviderPure);
