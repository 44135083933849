import TagManager from 'react-gtm-module';
import { always, call, mergeLeft, pipe, when } from 'ramda';

const addLabel = label => when(
  always(label),
  mergeLeft({ eventLabel: label })
);

const addParams = params => when(
  always(params),
  mergeLeft(params)
);

// eslint-disable-next-line require-yield
export function* sendGAEventWorker({ payload }) {
  const { category, action, label, params } = payload;

  try {
    const dataLayer = call(
      pipe(
        addLabel(label),
        addParams(params)
      ),
      {
        event: 'GAevent',
        eventCategory: category,
        eventAction: action,
      }
    );

    !__DEV__
      ? TagManager.dataLayer({
        dataLayer,
        dataLayerName: 'dataLayer',
      })
      : console.log('GA_EVENT', dataLayer);
  } catch (err) {
    console.log(err);
  }
}
