import { call, put } from 'redux-saga/effects';

import { uiActions } from 'bus/ui/actions';

import { getAuctionOffers } from 'api/methods/otpusk';

import { FETCH_AUCTION_ORDERS_BASE_UI_PATH } from 'pages/MainPage/components/AuctionOrders/constants';

import { setAuctionOrders } from '../../actions';

export function* fetchAuctionOrdersWorker({ payload: { locale, region } }) {
  yield put(uiActions.changeUiLoaderFlag({ status: {
    loading: true,
    error: false,
    completed: false,
    message: null },
  path: FETCH_AUCTION_ORDERS_BASE_UI_PATH }));

  try {
    const orders = yield call(getAuctionOffers, { queryParams: { region, locale } });

    yield put(setAuctionOrders(orders));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...FETCH_AUCTION_ORDERS_BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: error.message, path: [...FETCH_AUCTION_ORDERS_BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...FETCH_AUCTION_ORDERS_BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...FETCH_AUCTION_ORDERS_BASE_UI_PATH, 'completed'] }));
  }
}
