import { select, call, put } from 'redux-saga/effects';

// insturments
import { deleteTag } from 'api/methods/common/tags';
import { commonActions } from 'bus/common/actions';

export function* deleteTagWorker({ payload: id }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    yield call(deleteTag, token, { pathParams: { id } });
    yield put(commonActions.deleteTagSuccess(id));
  } catch (error) {
    yield put(commonActions.deleteTagFail(error));
  }
}
