export const THEME = {
  WHITE: 'white',
  BLUE: 'blue',
  RED: 'red',
  TRANSPARENT: 'transparent',
  SILVER: 'silver',
  THEMED: 'themed',
};

export const TITLE_POSITION = {
  TOP_CENTER: 'top center',
  TOP_LEFT: 'top left',
  RIGHT_CENTER: 'right center',
};
