import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

// instruments
import { clientClaimActions } from 'bus/clientClaim/actions';
import { uiActions } from 'bus/ui/actions';
import { deleteClaim } from 'api/methods/claim/updateClaim';
import book from 'routes/book';

export function* deleteClaimWorker() {
  const { token, id } = yield select(({ auth, clientClaim }) => ({
    token: auth.get('token'),
    id: clientClaim.getIn(['claim', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['clientClaim', 'deleteClaim'],
  }));

  try {
    yield call(deleteClaim, token, { pathParams: { id } });
    yield put(replace(book.client.path));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Пользователь не имеет права на удаление';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    if (status !== 404) {
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['clientClaim', 'deleteClaim', 'error'],
      }));
      yield put(uiActions.changeUiLoaderFlag({
        status: message,
        path: ['clientClaim', 'deleteClaim', 'message'],
      }));
      yield put(clientClaimActions.deleteClaimFail(error));
    } else {
      yield put(replace(book.client.path));
    }
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['clientClaim', 'deleteClaim', 'loading'],
    }));
  }
}
