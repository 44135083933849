import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { accountingActions as actions } from 'bus/accounting/actions';
import { bookingActions } from 'bus/booking/actions';
import { deleteAccountingInvoice } from 'api/methods/accounting';

const BASE_UI_PATH = ['accounting', 'invoice', 'delete'];

export function* deleteInvoiceWorker({ payload }) {
  const { id, workerOptions } = payload;
  const isBooking = get(workerOptions, 'isBooking', false);

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...BASE_UI_PATH, id],
  }));

  try {
    yield call(deleteAccountingInvoice, token, { pathParams: { id } });

    if (isBooking) {
      yield put(bookingActions.deleteBookingInvoiceSuccess(id));
    } else {
      yield put(actions.deleteInvoiceSuccess(id));
    }
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, id, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, id, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, id, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, id, 'completed'] }));
  }
}
