import { put, race, take, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { epassportsActions as actions } from 'bus/clientClaim/epassports';

export function* createEpassportFormikWorker({ payload }) {
  const { passport, tempId, claimId, formik } = payload;

  yield put(actions.createEpassport(passport, tempId, claimId, { UI: false }));

  const fetchingResults = yield race([
    take(actions.createEpassportSuccess),
    take(actions.createEpassportFail)
  ]);

  const [success, fail] = fetchingResults;

  if (success) {
    yield call(formik.resetForm);
    yield call(formik.setStatus, {
      success: true,
      message: 'Паспорт успешно создан',
    });

    return;
  }

  if (fail) {
    const { meta } = fail;
    const { errorsByValues, otherErrors, message } = meta;

    errorsByValues && (yield call(formik.setErrors, errorsByValues));

    yield call(formik.setSubmitting, false);
    yield call(formik.setStatus, {
      error: true,
      message: get(otherErrors, 'length') ? otherErrors.join('. ') : message,
    });
  }
}
