import { select, call, put } from 'redux-saga/effects';
import { getIn, get } from 'immutable';

// instruments
import { clientClaimActions } from 'bus/clientClaim/actions';
import { createClaimPaymentExpense } from 'api/methods/claim/updateClaim';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createClaimPaymentExpenseWorker({ payload }) {
  const { claimID, payment, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const response = yield call(
      createClaimPaymentExpense,
      token,
      { pathParams: { id: claimID }, bodyParams: payment }
    );

    yield put(clientClaimActions.createClaimPaymentExpenseSuccess(response));

    formik && (yield call(formik.setStatus, { success: true, message: 'Счет создан' }));
  } catch (error) {
    const { status: statusCode, body } = error.msg;

    let message = '';

    switch (statusCode) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(payment, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        }
        break;
      }
      case 403: {
        message = get(body, message, 'Пользователь не имеет права на добавление');
        break;
      }
      case 404: {
        message = 'Заказ не найден';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    yield put(clientClaimActions.createClaimPaymentExpenseFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
