import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { createSubscription } from 'api/methods/subscription';
import { subscriptionActions } from 'bus/subscription';

export function* createSubscriptionWorker({ payload }) {
  const { params, formik } = payload;

  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);

  try {
    const subscription = yield call(createSubscription, token, { pathParams: { tf }, bodyParams: params });

    yield put(subscriptionActions.createSubscriptionSuccess(subscription));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = 'Ошибка валидации';

        break;
      }
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отказано в доступе');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(subscriptionActions.createSubscriptionFail(error));
    formik && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
