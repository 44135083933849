import { call, select } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { addBasket } from 'api/methods/tourscaner/baskets';

import { addOfferToBasketSaga } from './addOfferToBasket';

export function* addBasketSaga() {
  const token = yield select(getToken);
  const userId = yield select(getUserId);

  return yield call(
    addBasket,
    token,
    { pathParams: { userId } }
  );
}
export function* addBasketWithOfferSaga(offerId, currency) {
  const basket = yield call(addBasketSaga);

  return yield call(
    addOfferToBasketSaga,
    offerId,
    currency,
    basket.id
  );
}

export function* addBasketWorker({ payload: callbacks = {} }) {
  const { onSuccess, onFail } = callbacks;

  try {
    const basket = yield call(addBasketSaga);

    onSuccess && onSuccess(basket);
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
