import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { roomsActions as actions } from 'bus/common/rooms';
import { createRoom } from 'api/methods/common/rooms';

const BASE_UI_PATH = ['common', 'rooms', 'create'];

export function* createRoomBaseWorker({ payload }) {
  const { roomName, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));
  const tf = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: BASE_UI_PATH,
  })));

  try {
    const room = yield call(
      createRoom,
      token,
      { pathParams: { tf }, bodyParams: { name: roomName } }
    );

    yield put(actions.createRoomSuccess(room));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Отказано в доступе к турфирме или отсутствует подписка на CRM';

        break;
      }
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(actions.createRoomFail(error, { message }));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, 'completed'],
    })));
  }
}
