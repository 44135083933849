import { select, put, call } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { getDocVariables } from 'api/methods/tf/docs';
import { detailDocsActions } from '../../actions';

export function* fetchDocVariablesWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['detailDocs', 'fetchDocVariables'],
  }));

  try {
    const response = yield call(getDocVariables, token);

    yield put(detailDocsActions.fetchDocVariablesSuccess(response));
  } catch (error) {
    yield put(detailDocsActions.fetchDocVariablesFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['detailDocs', 'fetchDocVariables'],
    }));
  }
}
