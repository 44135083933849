import { Map, getIn, setIn } from 'immutable';
import { handleActions, combineActions } from 'redux-actions';
import * as R from 'ramda';

import { bookingServicesReducer } from './reducers/bookingServices';
import { bookingPaymentsReducer } from './reducers/bookingPayments';
import { bookingInvoicesReducer } from './reducers/bookingInvoices';
import { bookingTouristsReducer } from './reducers/bookingTourists';
import { bookingSchedulesReducer } from './reducers/bookingSchedules';

import { bookingActions as actions } from './actions';
import { getSortFromStorage } from './helpers';

const DEFAULT_BOOKING_STATE = {
  data: null,
  end2EndBookingData: {},
  bookingChanges: {
    changes: [],
    count: null,
  },
  bookingMessages: [],
  managingBooking: {},
  defaultInvoice: null,
  sendStatuses: {},
  schedules: [],
  payments: [],
  invoices: [],
  documents: [],
  templates: [],
  messagesCountUnread: null,
  views: [],
};

const DEFAULT_SEARCH_STATE = {
  data: {
    results: [],
    total: null,
    limit: null,
  },
  query: null,
  adminOrder: 'priority',
  userOrder: getSortFromStorage() || 'date',
  page: null,
};

const initialState = Map({
  search: R.clone(DEFAULT_SEARCH_STATE),

  booking: DEFAULT_BOOKING_STATE,

  messagesCountUnread: null,
  bookingsStat: null,
  resultMoney: null,
  rateHash: '',
});

export default handleActions({
  ...bookingServicesReducer,
  ...bookingPaymentsReducer,
  ...bookingInvoicesReducer,
  ...bookingTouristsReducer,
  ...bookingSchedulesReducer,

  [actions.searchSuccess]: (state, { payload: data }) => state.setIn(['search', 'data'], data),
  [actions.setQuery]: (state, { payload: query }) => state.setIn(['search', 'query'], query),
  [actions.setPage]: (state, { payload: page }) => state.setIn(['search', 'page'], page),
  [actions.setAdminOrder]: (state, { payload: order }) => state.setIn(['search', 'adminOrder'], order),
  [actions.setUserOrder]: (state, { payload: order }) => state.setIn(['search', 'userOrder'], order),

  [combineActions(
    actions.getBookingSuccess,
    actions.updateBookingSuccess,
    actions.fillClaimFromBookingSuccess,
  )]: (state, { payload: booking }) => state.setIn(['booking', 'data'], booking),
  [actions.getBookingCashSuccess]: (state, { payload: cash }) => state.setIn(['booking', 'data', 'cash_flow'], cash),
  [actions.mergeBookingData]: (state, { payload: data }) => state.mergeIn(['booking', 'data'], data),
  [actions.setBookingViews]: (state, { payload: views }) => state.setIn(['booking', 'views'], views),
  [combineActions(
    actions.clearBooking,
    actions.deleteBookingSuccess
  )]: state => state.setIn(['booking'], DEFAULT_BOOKING_STATE),
  [actions.clearBookings]: state => state.set('search', DEFAULT_SEARCH_STATE),

  [actions.updateBookingFromListSuccess]: (state, { payload: booking }) => {
    const bookingIdx = state.getIn(['search', 'data', 'results'])
      .findIndex(({ id }) => id === booking.id);

    return state.setIn(['search', 'data', 'results', bookingIdx], booking);
  },
  [combineActions(actions.managingBookingSuccess, actions.clearManagingBooking)]: (state, { payload: result }) => state.setIn(['booking', 'managingBooking'], result),

  [actions.getBookingChangesSuccess]: (state, { payload: changes }) => {
    return state
      .updateIn(['booking', 'bookingChanges', 'changes'], bookingChanges => [...bookingChanges, ...changes])
      .setIn(['booking', 'bookingChanges', 'count'], changes.length);
  },
  [actions.getBookingMessagesSuccess]: (state, { payload: messages }) => state.setIn(['booking', 'bookingMessages'], messages),
  [actions.setEnd2EndBookingData]: (state, { payload: data }) => state.setIn(['booking', 'end2EndBookingData'], data),
  [actions.createBookingMessageSuccess]: (state, { payload: message }) => {
    return state.updateIn(['booking', 'bookingMessages'], messages => [message, ...messages]);
  },
  [actions.deleteBookingMessageSuccess]: (state, { payload: messageId }) => {
    return state.updateIn(['booking', 'bookingMessages'], messages => messages.filter(({ id }) => id !== messageId));
  },
  [actions.setAktToInvoice]: (state, { payload }) => {
    const { invoiceId, akt } = payload;

    const invoiceIndex = state.getIn(['booking', 'invoices']).findIndex(({ id }) => id === invoiceId);

    return state.setIn(['booking', 'invoices', invoiceIndex, 'akt'], akt);
  },
  [actions.updateMessageViewedSuccess]: (state, { payload }) => {
    const { view, messageId } = payload;
    const bookingMessages = state.getIn(['booking', 'bookingMessages']);

    const messageIdx = bookingMessages.findIndex(({ id }) => id === messageId);

    if (messageIdx === -1) return state;

    const viewIdx = getIn(bookingMessages, [messageIdx, 'views'], []).findIndex(({ id }) => id === view.id);

    return state.updateIn(['booking', 'bookingMessages', messageIdx, 'views'], views => {
      return viewIdx >= 0 ? setIn(views, [viewIdx], view) : [...views, view];
    });
  },
  [actions.removeMessageViewedSuccess]: (state, { payload: { viewId, messageId } }) => {
    const bookingMessages = state.getIn(['booking', 'bookingMessages']);

    const messageIdx = bookingMessages.findIndex(({ id }) => id === messageId);

    return state.updateIn(['booking', 'bookingMessages', messageIdx, 'views'], views => views.filter(({ id }) => id !== viewId));
  },
  [actions.messagesCountUnread]: (state, { payload: count }) => state.set('messagesCountUnread', count),
  [actions.setRateHash]: (state, { payload: rateHash }) => state.set('rateHash', rateHash),
  [actions.getBookingsStatSuccess]: (state, { payload: statistic }) => state.set('bookingsStat', statistic),
  [actions.getMessagesTemplatesSuccess]: (state, { payload: templates }) => state.set('templates', templates),
  [actions.getMessagesCountUnreadSuccess]: (state, { payload: count }) => state.set('messagesCountUnread', count),
  [combineActions(actions.addDocumentsSuccess, actions.getDocumentsSuccess)]: (state, { payload: documents }) => state.setIn(
    ['booking', 'documents'], documents
  ),
  [actions.deleteDocumentSuccess]: (state, { payload: documentId }) => {
    return state.updateIn(['booking', 'documents'], documents => documents.filter(({ id }) => id !== documentId));
  },
  [actions.updateDocumentSuccess]: (state, { payload: { documentId, document } }) => {
    const index = state.getIn(['booking', 'documents']).findIndex(({ id }) => id === documentId);

    return state.setIn(['booking', 'documents', index], document);
  },
  [actions.removeClaimFromBookingSuccess]: state => {
    return state.setIn(['booking', 'data', 'claim'], null);
  },
}, initialState);
