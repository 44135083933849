import BaseApiClass from 'api/BaseApiClass';

export const getComments = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/events',
  method: 'GET',
  ...params,
})._load({ token });

export const createComment = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/events',
  method: 'POST',
  ...params,
})._load({ token });

export const deleteComment = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:claimID}/events/{:eventID}/remove',
  method: 'PUT',
  ...params,
})._load({ token });

export const updateComment = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:claimID}/events/{:eventID}',
  method: 'PUT',
  ...params,
})._load({ token });

export const dismissComment = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:claimID}/events/{:eventID}/dismiss',
  method: 'PUT',
  ...params,
})._load({ token });
