import { select, call, put } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { authActions } from 'bus/auth/actions';
import { toggleUserStatus } from 'api/methods/tf/user';
import { tfUsersActions } from '../../actions';

export function* toggleTfUserStatusWorker({ payload }) {
  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['tf', 'loadingToggleStatus', payload.user],
  }));
  try {
    const token = yield select(({ auth }) => auth.get('token'));

    const response = yield call(toggleUserStatus, token, { pathParams: { id: payload.user } });

    yield put(tfUsersActions.toggleTfUserStatusSuccess(response));

    const { active } = response;

    if (active) {
      yield put(authActions.incrementActiveUsers());
    } else {
      yield put(authActions.dicrementActiveUsers());
    }
  } catch (error) {
    yield put(tfUsersActions.toggleTfUserStatusFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['tf', 'loadingToggleStatus', payload.user],
    }));
  }
}
