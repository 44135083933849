import * as R from 'ramda';

import { removeEmptyArray, removeEmptyString, removeNull, replaceMoment2String } from 'helpers';

export const filterQuery = R.pipe(
  removeEmptyArray,
  removeNull,
  removeEmptyString,
  replaceMoment2String
);
