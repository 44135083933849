import { select, put, all, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { getBirthdaysByProfile, getBirthdaysByPassports } from 'api/methods/main/birthdays';
import { uiActions } from 'bus/ui/actions';
import { mainActions } from 'bus/main/actions';

export function* fetchBirthdaysWorker({ payload: date }) {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({ status: { loading: true, error: false, success: false, message: null }, path: ['main', 'birthdays'] }));

  try {
    const [byProfiles, byPassports] = yield all([
      call(getBirthdaysByProfile, token, { pathParams: { date } }),
      call(getBirthdaysByPassports, token, { pathParams: { date } })
    ]);

    const normalizedByPassports = byPassports.map(client => {
      const { claims, id: clientID, name: clientName } = client;

      return claims
        .map(({ id: claimID, epassports }) => epassports
          .map(epassport => ({ ...epassport, claimID, clientID, clientName })))
        .flat();
    }).flat();

    yield put(mainActions.fetchBirthdaysSuccess(byProfiles, normalizedByPassports));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Необходимо иметь разрешение на доступ к разделу "Клиенты". Запрещено если активна подписка только на Турсканер');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(mainActions.fetchBirthdaysFail(error));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['main', 'birthdays', 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['main', 'birthdays', 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['main', 'birthdays', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['main', 'birthdays', 'success'] }));
  }
}
