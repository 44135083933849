import { select, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';
import uuid from 'uuid/v4';

import { clientClaimActions } from 'bus/clientClaim/actions';
import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';

import { getClaim } from 'api/methods/claim/getClaim';
import { getErrorMessage } from 'api/fn/parseErrors';

export function* getClaimWorker({ payload: claimId }) {
  const token = yield select(getToken);

  try {
    const claim = yield call(getClaim, token, { pathParams: { id: claimId } });

    const processedClaim = Map(claim)
      .update('epassports', epassports => epassports.map(o => ({ ...o, key: uuid() })))
      .toObject();

    yield put(clientClaimActions.getClaimSuccess(processedClaim));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: ({ body }) => body.message || 'Отказано в доступе',
        404: 'Заказ не найден',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(clientClaimActions.getClaimFail(error.message));
  }
}

export const getClaimWithUISaga = withUIWorker(
  [clientClaimActions.getClaimSuccess, clientClaimActions.getClaimFail],
  ['clientClaim', 'fetchClaim'],
)(getClaimWorker);
