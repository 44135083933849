import React, { memo, useRef } from 'react';
import { createPortal } from 'react-dom';
import uuid from 'uuid/v4';

// tags
import { TitleComponent } from './tags/Title';
import { FaviconComponent } from './tags/Favicon';

export const HelmetComponent = memo(() => {
  const { current: [titleKey, faviconKey] } = useRef(new Array(2).fill(0).map(() => uuid()));

  return createPortal([
    <TitleComponent key={titleKey} />,
    <FaviconComponent key={faviconKey} />
  ], document.head);
});
