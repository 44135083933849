import * as R from 'ramda';
import moment from 'moment';
import { getCurrencyByCode } from '@otpusk/json-api/dist/dictionary';
import { TOUR_OPTIONS } from '@otpusk/json-api/dist/static';
import { keys } from 'ramda';

import { declOfNum } from 'helpers/declension';

import { newInstance } from 'localization';
import { LOCALES } from 'localization/constants';

import { BUS, BUS_TICKETS, FLIGHT, FLIGHT_TICKETS, HOTEL_ONLY, OTHER_TYPE, BOOKING_TRANSPORT_DIRECTION_MAP } from 'modules/booking/constants';

import { BOOKING_CALCULATION_TYPES, OPERATORS_BY_TF_GROUP, TF_GROUPS, OPTIONS_CONTROLS_TYPES, BOOKING_OPTIONS_BY_GROUPS } from './constants';

const DATE_FORMAT = 'DD.MM.YYYY';
const EMPTY_FIELD_VALUE = '_';

const AIR_TRANSFER = 'air';
const BUS_TRANSFER = 'bus';
const TRAIN_TRANSFER = 'train';
const EMPTY_TRANSFER = 'no';

const printOutbound = fly => {
  if (!fly) return '';
  const { begin, portFr, portTo, line, code } = fly;

  return R.join(' ', [
    `${moment(begin).format('DD MMM HH:mm')}`,
    `${portFr}-${portTo},`,
    `${line}, ${code}`
  ]);
};
const printInbound = fly => {
  if (!fly) return '';
  const { begin, portFr, portTo, line, code } = fly;

  return R.join(' ', [
    `${moment(begin).format('DD')}`,
    `${moment(begin).format('MMM HH:mm')}`,
    `${portFr}-${portTo},`,
    `${line}, ${code}`
  ]);
};
const createRoadMap = flights => {
  const { inbound, outbound } = flights;

  if (R.isEmpty(inbound) && R.isEmpty(outbound)) {
    return '';
  }
  const mappedInbound = R.map(printInbound, inbound);
  const mappedOutbound = R.map(printOutbound, outbound);

  return R.join('\r\n', R.concat(mappedOutbound, mappedInbound));
};

const createRoadMapFromActiveFlights = ({ inbound, outbound }) => {
  if (!inbound && !outbound) {
    return '';
  }

  return `${printOutbound(outbound)}\r\n${printInbound(inbound)}`;
};

const createDuration = (nights, nightsInHotel) => {
  const additionalNights = R.call(
    R.ifElse(
      diff => diff > 0,
      diff => diff,
      R.F
    ),
    nights - nightsInHotel
  );
  const finallyNights = additionalNights ? nightsInHotel : nights;

  return R.add(additionalNights, finallyNights);
};

const createDurationStr = (nights, nightsInHotel) => {
  const additionalNights = nights - nightsInHotel;

  return `${nightsInHotel}${additionalNights ? `+${additionalNights}` : ''}`;
};

const normalizeDateToAPI = date => moment(date, DATE_FORMAT).format('YYYY-MM-DD');

const SEX_TYPES = {
  MR: 0,
  MRS: 2,
  CHD: 3,
};
const mapPassport = ({ passportExpireDate, passportIssued, birthday, passportGivenDate, sex, ...params }) => ({
  ...params,
  birthday: normalizeDateToAPI(birthday),
  passport_expire_date: normalizeDateToAPI(passportExpireDate),
  passport_issued: passportIssued,
  badge: SEX_TYPES[sex],
  ...passportGivenDate ? { passport_given_date: normalizeDateToAPI(passportGivenDate) } : {},
});

const createPeopleString = ({ adults, children }) => `${adults} ${declOfNum(adults, newInstance.t('SETS:ADULT_SET', { returnObjects: true }))} + ${children.length} ${declOfNum(children, newInstance.t('SETS:CHILD_SET', { returnObjects: true }))} (${R.join(', ', children)})`;

export const getDefaultOptions = R.pipe(
  R.filter(({ isDefault, value }) => R.and(Boolean(isDefault), Boolean(value))),
  R.map(({ id }) => id)
);

const convertValueToComment = value => (typeof value === 'boolean'
  ? newInstance.t(value ? 'ADVERB:YES' : 'ADVERB:NO')
  : value);

export const getOptionsLabels = R.pipe(
  R.map(({ content, value }) => `${content} - ${convertValueToComment(value)}`),
  R.join('\n')
);
const stringifyBookingServices = (bookingServices, currency) => {
  return R.map(
    service => {
      const touristWord = declOfNum(service.activeCountOfService, newInstance.t('SETS:TOURISTS_SET', { returnObjects: true }));

      return R.join(
        ', ',
        R.filter(Boolean, [
          service.name,
          R.isEmpty(service.price) ? null : `${service.price[currency] * service.activeCountOfService} ${getCurrencyByCode(currency).label}`,
          `${R.isEmpty(service.selectedTourists) ? null : R.join(', ', service.selectedTourists)} ${touristWord}`
        ])
      );
    },
    bookingServices
  );
};

export const computeFullBookingServicesPrice = (currency, activeBookingServices) => {
  return R.reduce(
    (total, service) => {
      if (R.isEmpty(service.price)) {
        return total;
      }

      const totalByService = service.price[currency] * service.activeCountOfService;

      return total + totalByService;
    },
    0,
    activeBookingServices
  );
};

const stringifyOfferPrice = (offer, query, recalculatedPrice) => {
  return R.join(' ', [
    recalculatedPrice?.price?.[query.currency] ?? offer.price[query.currency],
    query.currency,
    `(=${recalculatedPrice?.price?.[offer.currency] ?? offer.priceByOperator[offer.currency]}`,
    `${offer.currency},`,
    `курс ${recalculatedPrice?.rate ?? (offer.currencyOperatorRate || offer.currencyRate)})`
  ]);
};

export const createFullDesc = ({
  offer,
  hotel,
  query,
  booking: { options, paymentType },
  activeFlights,
  activeBookingServices,
  recalculatedPrice,
  operatorSettings: { bookingDescription }
}) => {
  return R.join('\n', [
    R.join(', ', [`${hotel.name} ${hotel.stars}*`, hotel.country.name, hotel.city.name]),
    R.join(', ', [offer.room.name, offer.foodFullName || offer.food, `${createDurationStr(offer.nights, offer.nightsInHotel)} ${declOfNum(createDuration(offer.nights, offer.nightsInHotel),
      newInstance.t('SETS:NIGHT_SET', { returnObjects: true }))}`]),
    (activeFlights ? createRoadMapFromActiveFlights(activeFlights) : createRoadMap(offer.flights)) || EMPTY_FIELD_VALUE,
    createPeopleString(offer.people),
    stringifyOfferPrice(offer, query, recalculatedPrice),
    ...activeBookingServices
      ? stringifyBookingServices(activeBookingServices, query.currency)
      : [],
    getOptionsLabels(options),
    R.pathOr(EMPTY_FIELD_VALUE, [paymentType, 'content'], BOOKING_CALCULATION_TYPES),
    bookingDescription
  ]);
};

export const isSetViolations = errorBody => R.call(
  R.pipe(R.path(['validation', 'violations']), violations => violations && !R.isEmpty(violations)),
  errorBody
);

export const mapValuesToApi = (values, operatorOptions) => R.pipe(
  ({ options, passports, ...rest }) => R.mergeAll([
    {
      options: R.call(
        R.pipe(
          R.toPairs,
          R.map(([key, value]) => {
            const id = R.pipe(R.split('-'), R.last, Number)(key);
            const { title, field_type: type } = operatorOptions[id];

            return {
              isDefault: type === OPTIONS_CONTROLS_TYPES.BASE_DEFAULT,
              content: title,
              value,
              id,
            };
          })
        ),
        options),
      passports: R.map(mapPassport, passports),
    },
    rest]),
  R.dissoc('isAcceptBookingConditions')
)(values);

const DATE_TIME_FORMAT = 'DD MMMM YYYY HH:mm:ss';

export const getTicketPrice = ticket => R.or(
  ticket.to.price,
  R.or(
    ticket.from.price,
    ticket.price
  )
);

const getOtherOptions = R.pipe(
  R.filter(({ isDefault }) => !isDefault),
  R.map(({ content, value }) => `${content} - ${convertValueToComment(value)}`),
  R.join('\n')
);

export const createCommentForBookingTicket = (ticket, booking) => R.join(
  '\n',
  R.filter(
    Boolean,
    [
      global.location.href,
      ticket.to.departureDatetimeTo && newInstance.t('BOOKING_TICKET_INTEGRATION:TO_TICKET_TEXT', {
        departureIATACode: ticket.to.departureAirport.iataCode,
        flightNumber: ticket.to.number || '-',
        departureDateTime: moment(ticket.to.departureDatetimeTo).utc(false).format(DATE_TIME_FORMAT),
        arrivalCountry: ticket.to.arrivalCountry.name,
        arrivalCity: ticket.to.arrivalCity.name,
        arrivalIATACode: ticket.to.arrivalAirport.iataCode,
        arrivalDateTime: moment(ticket.to.arrivalDatetimeTo).utc(false).format(DATE_TIME_FORMAT),
      }),
      ticket.from.departureDatetimeFrom && newInstance.t('BOOKING_TICKET_INTEGRATION:FROM_TICKET_TEXT', {
        departureIATACode: ticket.from.departureAirport.iataCode,
        flightNumber: ticket.from.number || '-',
        departureDateTime: moment(ticket.from.departureDatetimeFrom).utc(false).format(DATE_TIME_FORMAT),
        arrivalCountry: ticket.from.arrivalCountry.name,
        arrivalCity: ticket.from.arrivalCity.name,
        arrivalIATACode: ticket.from.arrivalAirport.iataCode,
        arrivalDateTime: moment(ticket.from.arrivalDatetimeFrom).utc(false).format(DATE_TIME_FORMAT),
      }),
      newInstance.t('BOOKING_TICKET_INTEGRATION:PEOPLE_TEXT', {
        adults: ticket.adults,
        adultsWord: declOfNum(ticket.adults, newInstance.t('SETS:ADULT_SET', { returnObjects: true })),
      }),
      !R.isEmpty(ticket.infants) && newInstance.t('BOOKING_TICKET_INTEGRATION:INFANTS_TEXT', {
        infants: ticket.infants.length,
        infantsWord: declOfNum(ticket.infants.length, newInstance.t('SETS:INFANTS_SET', { returnObjects: true })),
      }),
      !!booking.comment && booking.comment,
      booking.phone,
      booking.payerEmail,
      `${getTicketPrice(ticket)} ${ticket.currency}`,
      getOtherOptions(booking.options)
    ]
  )
);

const convertServiceType = R.cond([
  [R.equals(TOUR_OPTIONS.TRANSFER), R.always(2)],
  [R.equals(TOUR_OPTIONS.INSURANCE), R.always(3)],
  [R.equals(TOUR_OPTIONS.EXCURSION), R.always(5)],
  [R.equals(TOUR_OPTIONS.LUGGAGE), R.always(6)],
  [R.T, R.always(-1)],
]);
const convertTransportType = R.cond([
  [R.equals(AIR_TRANSFER), R.always(1)],
  [R.equals(BUS_TRANSFER), R.always(2)],
]);

export const mapBookingServices = (services, hasOwnBookingServices) => R.map(({
  activeCountOfService,
  selectedTourists,
  type,
  name,
  id,
  external_type: externalType
}) => ({
  count: activeCountOfService,
  description: name,
  type: convertServiceType(type),
  external_data: {
    id,
    name,
    type: externalType,
    number: hasOwnBookingServices ? selectedTourists : activeCountOfService
  }
}), services);

// export const getIsCreateServicesFromFlights = ({ transport }) => [AIR_TRANSFER, BUS_TRANSFER].includes(transport);
// TODO w8 for new API
export const getIsCreateServicesFromFlights = () => false;

export const convertFlights2Array = flights => {
  const { inbound, outbound } = flights;

  return [
    inbound && R.mergeAll([
      inbound,
      {
        direction: BOOKING_TRANSPORT_DIRECTION_MAP.INBOUND.value
      }
    ]),
    outbound && R.mergeAll([
      outbound,
      {
        direction: BOOKING_TRANSPORT_DIRECTION_MAP.OUTBOUND.value
      }
    ])
  ].filter(Boolean);
};

const TRANSPORT_TYPE = 1;

export const mapFlightsServices = (flights, transport, peopleCount) => R.map(({
  direction,
  code,
  line,
  begin,
  end,
  portFr,
  portTo
}) => ({
  type: TRANSPORT_TYPE,
  count: peopleCount,
  service_data: {
    type: convertTransportType(transport),
    direction,
    code,
    line,
    begin,
    end,
    port_fr: portFr,
    port_to: portTo
  }
}), flights);

const getGroupByOperator = operatorID => R.cond([
  [R.includes(R.__, OPERATORS_BY_TF_GROUP.JOIN_UP), R.always(TF_GROUPS.JOIN_UP)],
  [R.T, R.always(null)],
])(operatorID);

export const getOperatorSettingsByTfGroup = (operatorID, groups) => R.pipe(
  getGroupByOperator,
  R.ifElse(
    R.prop(R.__, groups),
    R.prop(R.__, BOOKING_OPTIONS_BY_GROUPS),
    R.always({})
  )
)(operatorID);

export const getBookingType = ({ transport, hotelId }) => R.cond(
  [
    [(transfer, hotel) => transfer === AIR_TRANSFER && hotel, R.always(FLIGHT)],
    [(transfer, hotel) => [BUS_TRANSFER, TRAIN_TRANSFER].includes(transfer) && hotel, R.always(BUS)],
    [(transfer, hotel) => transfer === EMPTY_TRANSFER && hotel, R.always(HOTEL_ONLY)],
    [R.T, R.always(OTHER_TYPE)],
  ]
)(transport, hotelId);

export const getBookingFromTicket = ({ transport }) => R.cond(
  [
    [R.equals(AIR_TRANSFER), R.always(FLIGHT_TICKETS)],
    [transfer => [BUS_TRANSFER, TRAIN_TRANSFER].includes(transfer), R.always(BUS_TICKETS)],
    [R.T, R.always(OTHER_TYPE)],
  ]
)(transport);

export const getLangForOtpuskApi = R.ifElse(
  R.equals(LOCALES.UA),
  R.always('uk'),
  locale => locale
);
export const generateBookingMassage = values => {
  const bodyParams = new FormData();

  keys(values).forEach(name => {
    bodyParams.append(name, values[name]);
  });

  return bodyParams;
};
