import { call, select, put } from 'redux-saga/effects';

// instruments
import { createOffice } from 'api/methods/common/offices';
import { commonActions } from 'bus/common/actions';

export function* createOfficeWorker({ payload: name }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const office = yield call(createOffice, token, { bodyParams: { name } });

    yield put(commonActions.createOfficeSuccess(office));
  } catch (error) {
    yield put(commonActions.createOfficeFail(error));
  }
}
