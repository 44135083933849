import { fork, take, cancel, takeEvery } from 'redux-saga/effects';

// instruments
import { claimActions as actions } from 'bus/claim/actions';
import { commentsActions } from 'bus/clientClaim/comments';
import {
  fetchCountClaimsWorker,
  combineClaimWithOfferWorker,
  combineClaimWithOffersWorker,
  combineClaimWithSearchWorker
} from './workers';

export const claimWatchers = Object.freeze({
  * fetchCountClaims() {
    while (yield take(actions.startAutoFetchCountClaims)) {
      const bgAutoFetchClaim = yield fork(fetchCountClaimsWorker);

      yield take(actions.stopAutoFetchCountClaims);

      yield cancel(bgAutoFetchClaim);
    }
  },
  * updateCountClaims() {
    yield takeEvery([
      commentsActions.createCommentSuccess,
      commentsActions.updateCommentSuccess
    ], fetchCountClaimsWorker);
  },
  * combineClaimWithOfferWatcher() {
    yield takeEvery(actions.combineClaimWithOffer, combineClaimWithOfferWorker);
  },
  * combineClaimWithOffersWatcher() {
    yield takeEvery(actions.combineClaimWithOffers, combineClaimWithOffersWorker);
  },
  * combineClaimWithSearchWatcher() {
    yield takeEvery(actions.combineClaimWithSearch, combineClaimWithSearchWorker);
  },
});
