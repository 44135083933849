import createDOMPurify from 'dompurify';
import { Map } from 'immutable';
import * as R from 'ramda';
import moment from 'moment';

const DOMPurify = createDOMPurify(window);
const IS_ENABLED_CLIPBOARD_ITEM = Boolean(global.ClipboardItem);

export const removeNull = (object, allowNullableFields = []) => {
  return Map(object).filter((value, key) => {
    return allowNullableFields.includes(key) || value !== null;
  }).toObject();
};

export const removeEmptyArray = (object = {}) => R.call(
  R.pipe(
    R.toPairs,
    R.filter(([, value]) => (Array.isArray(value) ? !R.isEmpty(value) : true)),
    R.fromPairs
  ),
  object);

export const replaceMoment2String = (object = [], format = 'YYYY-MM-DD') => R.call(
  R.pipe(
    R.toPairs,
    R.map(([key, value]) => [key, moment.isMoment(value) ? value.format(format) : value]),
    R.fromPairs
  ),
  object);

export const removeEmptyString = (object, excludeFields = []) => {
  return Object
    .keys(object)
    .reduce((results, key) => {
      const value = object[key];

      if (typeof value === 'string') {
        if (excludeFields.includes(key)) {
          return { ...results, [key]: value };
        }

        if (value.trim() === '') return results;

        return { ...results, [key]: value.trim() };
      }

      return { ...results, [key]: value };
    }, {});
};

export const parseErrorPath = path => path.replace(/\[(\d+)\]/g, '.$1');

export const decodeHTML = (str, decodeConfig = {}) => {
  return DOMPurify.sanitize(str, decodeConfig);
};

export const round = (value, decimals) => Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

export const toCamelCase = values => {
  return Map(values).mapEntries(([key, value]) => {
    const camelKey = key.replace(/_\w/g, text => {
      return text.toUpperCase().replace(/_/, '');
    });

    return [camelKey, value];
  }).toObject();
};

export const removeSpaces = object => R.call(
  R.pipe(
    R.toPairs,
    R.map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value]),
    R.fromPairs
  ), object
);

const copyToBufferWithoutClipboardItem = string => global.navigator.clipboard.writeText(string);
const copyToBufferDynamicContentWithoutClipboardItem = getDynamicContent => getDynamicContent().then(copyToBufferWithoutClipboardItem);

export const copyToBuffer = IS_ENABLED_CLIPBOARD_ITEM
  ? value => global.navigator.clipboard.write([
    new global.ClipboardItem({
      'text/plain': new Blob([value], { type: 'text/plain' }),
    })
  ]) : copyToBufferWithoutClipboardItem;

export const copyToBufferDynamicContent = IS_ENABLED_CLIPBOARD_ITEM
  ? getDynamicContent => new Promise((resolve, reject) => {
    global.navigator.clipboard.write([
      new global.ClipboardItem({
        'text/plain': getDynamicContent()
          .then(content => {
            resolve(content);

            return new Blob([content], { type: 'text/plain' });
          })
          .catch(reject),
      })
    ]);
  }) : copyToBufferDynamicContentWithoutClipboardItem;
