import { select, put, call } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

// instruments
import { createBalanceCharge } from 'api/methods/subscription';
import { subscriptionActions } from 'bus/subscription';
import { createAccessCookie } from 'api/methods/auth/createAccessCookie';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createBalanceChargeWorker({ payload }) {
  const { values, formik } = payload;

  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);

  try {
    const charge = yield call(
      createBalanceCharge,
      token,
      { pathParams: { tf }, bodyParams: values }
    );

    yield call(createAccessCookie, token);
    yield put(subscriptionActions.createBalanceChargeSuccess(charge));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    let errorsByValues = {};

    let otherErrors = [];

    switch (status) {
      case 400: {
        const isSetViolations = getIn(body, ['violations', 'length'], false);

        if (isSetViolations) {
          const violations = get(body, 'violations');
          const compiledErrors = parseErrorsByFormik(values, violations);

          errorsByValues = compiledErrors.errorsByValues;
          otherErrors = compiledErrors.otherErrors;
        } else {
          message = 'Ошибка сервера';
        }
        break;
      }
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отказано в доступе');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && errorsByValues && (yield call(formik.setErrors, errorsByValues));

    yield put(subscriptionActions.createBalanceChargeFail(error));
    formik && (yield call(formik.setStatus, {
      error: true,
      message: get(otherErrors, 'length') ? otherErrors.join('. ') : message,
    }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
