import { get } from 'immutable';
import { select, put, call } from 'redux-saga/effects';

// instruments
import { getFields } from 'api/methods/tf/fields';
import { uiActions } from 'bus/ui/actions';
import { documentFieldsActions as actions } from 'bus/documentFields';

export function* fetchFieldsWorker({ payload: workerOptions }) {
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['documentFields', 'fetchFields'],
  })));

  try {
    const fields = yield call(getFields, token);

    yield put(actions.fetchFieldsSuccess(fields));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Активна подписка только на Турсканер';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['documentFields', 'fetchFields', 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['documentFields', 'fetchFields', 'message'],
    })));
    yield put(actions.fetchFieldsFail(error, { message }));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['documentFields', 'fetchFields', 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['documentFields', 'fetchFields', 'completed'],
    })));
  }
}
