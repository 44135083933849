import { put, call, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';
import { deleteBooking } from 'api/methods/booking';
import { clientClaimActions } from 'bus/clientClaim/actions';
import book from 'routes/book';

const BASE_UI_PATH = ['booking', 'delete'];

export function* deleteBookingWorker({ payload }) {
  const { bookingId, workerOptions } = payload;

  const { token, pathname } = yield select(({ auth, router }) => ({
    token: auth.get('token'),
    pathname: router.location.pathname,
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...BASE_UI_PATH, bookingId],
  }));
  try {
    yield call(deleteBooking, token, { pathParams: { id: bookingId } });

    if (matchPath(pathname, { path: book.adminBooking.children.booking })) {
      yield put(replace(book.adminBooking.path));
    }

    if (matchPath(pathname, { path: book.main })) {
      yield put(actions.search());
    }

    if (matchPath(pathname, { path: book.clientClaim.children.claim })) {
      yield put(clientClaimActions.deleteClaimBookingSuccess());
    }

    if (workerOptions.onSuccess) {
      workerOptions.onSuccess();
    }

    yield put(actions.deleteBookingSuccess());
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, bookingId, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, bookingId, 'message'] }));
    yield put(actions.deleteBookingFail());
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, bookingId, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, bookingId, 'completed'] }));
  }
}
