import BaseApiClass from 'api/BaseApiClass';

import { removeEmptyString } from 'helpers';

export const mergeClients = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'client/merge/{:id}',
    method: 'PUT',
    ...params,
  });

  instance.convertBodyToServerSchema = body => removeEmptyString(body);

  return instance._load({ token });
};
