import { takeEvery } from 'redux-saga/effects';

import { subscriptionActions as actions } from 'bus/subscription';

import { getSubscriptionWorker } from './workers/getSubscription';
import { getBalancesWorker } from './workers/getBalances';
import { getBalanceAmountWorker } from './workers/getBalanceAmount';
import { getBalanceInvoicesWorker } from './workers/getBalanceInvoices';
import { createSubscriptionWorker } from './workers/createSubscription';
import { createBalanceChargeWorker } from './workers/createBalanceCharge';
import { getBalanceInfoWorker } from './workers/getBalanceInfo';
import { unsubscriptionWorker } from './workers/unsubscription';

export const subscriptionWatchers = Object.freeze({
  * getSubscriptionWatcher() {
    yield takeEvery(actions.getSubscription, getSubscriptionWorker);
  },
  * getBalancesWatcher() {
    yield takeEvery(actions.getBalances, getBalancesWorker);
  },
  * getBalanceAmountWatcher() {
    yield takeEvery(actions.getBalanceAmount, getBalanceAmountWorker);
  },
  * getBalanceInvoicesWatcher() {
    yield takeEvery(actions.getBalanceInvoices, getBalanceInvoicesWorker);
  },
  * createSubscriptionWatcher() {
    yield takeEvery(actions.createSubscription, createSubscriptionWorker);
  },
  * createUnsubscriptionWatcher() {
    yield takeEvery(actions.unsubscription, unsubscriptionWorker);
  },
  * createChargeWatcher() {
    yield takeEvery(actions.createBalanceCharge, createBalanceChargeWorker);
  },
  * getBalanceInfoWatcher() {
    yield takeEvery(actions.getBalanceInfo, getBalanceInfoWorker);
  },
});
