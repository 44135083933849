import { put, call, select, delay } from 'redux-saga/effects';

import { getMessagesCountUnread } from 'api/methods/booking/messages';

import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';
import { getToken } from 'bus/auth/selectors.js';

const BASE_UI_PATH = ['booking', 'messagesCountUnread'];

const DELAY = 60 * 1000;

export function* getMessagesCountUnreadWorker() {
  while (true) {
    const token = yield select(getToken);

    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, message: null, completed: false },
      path: BASE_UI_PATH,
    }));

    try {
      const booking = yield call(getMessagesCountUnread, token);

      yield put(actions.getMessagesCountUnreadSuccess(booking));
    } catch (error) {
      // just go to next iteration
    } finally {
      yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
      yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
    }

    yield delay(DELAY);
  }
}
