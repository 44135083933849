import { call, select } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { updateOfferInBasket } from 'api/methods/tourscaner/baskets';

export function* updateOfferInBasketSaga(basketId, offerId, offer) {
  const token = yield select(getToken);
  const userId = yield select(getUserId);

  const basket = yield call(
    updateOfferInBasket,
    token,
    { pathParams: { userId, basketId, offerId }, bodyParams: offer }
  );

  return basket;
}

export function* updateOfferInBasketWorker({ payload }) {
  const { callbacks, basketId, offerId, offer } = payload;
  const { onSuccess, onFail } = callbacks;

  try {
    const basket = yield call(
      updateOfferInBasketSaga,
      basketId,
      offerId,
      offer
    );

    onSuccess && onSuccess(basket);
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
