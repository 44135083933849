import { handleActions, combineActions } from 'redux-actions';
import { Map } from 'immutable';

import { authActions as actions } from './actions';

const initialState = Map({
  token: null,
  profile: null,
  isAuthenticate: false,
  initialized: false,
  otpusk: Map({
    token: { access_token: null, charters_access_token: null },
    lang: 'rus',
  }),
  locale: null,
  isRegisterCode: false,
});

export default handleActions({
  [combineActions(actions.authenticateSuccess, actions.registerSuccess)]: (state, { payload: token }) => {
    return state
      .set('token', token)
      .set('isAuthenticate', true);
  },
  [actions.logOutSuccess]: state => {
    return state
      .set('token', null)
      .set('initialized', false)
      .set('isAuthenticate', false)
      .set('profile', null)
      .set('locale', null);
  },
  [actions.fetchMyselfSuccess]: (state, { payload: profile }) => {
    return state
      .set('profile', profile)
      .set('initialized', true);
  },
  [actions.setOtpuskToken]: (state, { payload: token }) => state.setIn(['otpusk', 'token', 'access_token'], token),
  [actions.setChartersToken]: (state, { payload: token }) => state.setIn(['otpusk', 'token', 'charters_access_token'], token),
  [actions.setLocale]: (state, { payload: locale }) => state.set('locale', locale),
  [actions.incrementActiveUsers]: state => state.updateIn(['profile', 'tf', 'activeUsersCount'], count => count + 1),
  [actions.dicrementActiveUsers]: state => state.updateIn(['profile', 'tf', 'activeUsersCount'], count => count - 1),
  [actions.removeFacebookSuccess]: state => state.setIn(['profile', 'tf', 'facebooks'], []),
}, initialState);
