import { call, put, race, take } from 'redux-saga/effects';
import { get, getIn, Map } from 'immutable';
import { push } from 'connected-react-router';

import { clientClaimActions } from 'bus/clientClaim/actions';
import { financeActions } from 'bus/finance/actions';

import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { PAYMENT_METHODS } from 'containers/Payments/constants';

import { newInstance } from 'localization';

const ONLINE_METHODS = [PAYMENT_METHODS[4].value, PAYMENT_METHODS[5].value];

export function* createClaimPaymentIncomeAndUpdateWorker({ payload }) {
  const { claimID, payment, formik } = payload;

  const {
    income,
    assignment,
    date_to: dateTo,
    rate,
    method,
    hold,
    client_pays_commission: clientCommission
  } = payment;

  yield put(clientClaimActions.createClaimPaymentIncome(claimID, {
    income,
    assignment,
    date_to: dateTo,
    rate,
    method,
    hold,
    client_pays_commission: clientCommission
  }, null, null));

  // eslint-disable-next-line prefer-const
  let [successCreated, fail] = yield race([
    take(clientClaimActions.createClaimPaymentIncomeSuccess),
    take(clientClaimActions.createClaimPaymentIncomeFail)
  ]);

  if (successCreated) {
    const { id, claim } = successCreated.payload;

    const updatedPayment = Map(payment)
      .deleteAll(['hold', 'client_pays_commission', 'template'])
      .toObject();

    yield put(financeActions.updatePayment(claim.id, { id, ...updatedPayment }));

    const [successUpdate, failUpdate] = yield race([
      take(financeActions.updatePaymentSuccess),
      take(financeActions.updatePaymentFail)
    ]);

    if (successUpdate) {
      formik && (yield call(formik.setStatus, {
        success: true,
        message: newInstance.t('SUCCESS_MESSAGES:PAYMENT_SAVED')
      }));

      if (ONLINE_METHODS.includes(method)) {
        const search = new URLSearchParams(window.location.search);

        search.append('editPayment', id);

        yield put(push(`?${search.toString()}`));
      }
    }

    fail = failUpdate;
  }

  if (fail) {
    const { status: statusCode, body } = fail.payload.msg || {};

    let message = null;

    switch (statusCode) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(payment, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = newInstance.t('LIQPAY:GET_LINK_TO_PAY_ERROR');
        }
        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление');

        break;
      }
      case 404: {
        message = 'Заказ не найден';

        break;
      }
      default:
        message = 'Ошибка при создании счета';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
  }

  formik && (yield call(formik.setSubmitting, false));
}

export function* createClaimPaymentExpenseAndUpdateWorker({ payload }) {
  const { claimID, payment, formik } = payload;

  const { expense, assignment, date_to: dateTo, in_id: inId, rate, method } = payment;

  yield put(clientClaimActions.createClaimPaymentExpense(claimID, {
    expense,
    assignment,
    date_to: dateTo,
    in_id: inId,
    rate,
    method
  }, null, null));

  // eslint-disable-next-line prefer-const
  let [successCreated, fail] = yield race([
    take(clientClaimActions.createClaimPaymentExpenseSuccess),
    take(clientClaimActions.createClaimPaymentExpenseFail)
  ]);

  if (successCreated) {
    const { id, claim } = successCreated.payload;

    yield put(financeActions.updatePayment(claim.id, { id, ...payment }));

    const [successUpdate, failUpdate] = yield race([
      take(financeActions.updatePaymentSuccess),
      take(financeActions.updatePaymentFail)
    ]);

    if (successUpdate) {
      formik && (yield call(formik.setStatus, {
        success: true,
        message: newInstance.t('SUCCESS_MESSAGES:PAYMENT_SAVED')
      }));
    }

    fail = failUpdate;
  }

  if (fail) {
    const { status: statusCode, body } = fail.payload.msg || {};

    let message = null;

    switch (statusCode) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(payment, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        }
        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление');

        break;
      }
      case 404: {
        message = 'Заказ не найден';

        break;
      }
      default:
        message = 'Ошибка при создании счета';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
  }

  formik && (yield call(formik.setSubmitting, false));
}
