import { select, call } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors.js';

import { sendMessageToClient } from 'api/methods/client/sendMessageToClient';
import { getErrorMessage } from 'api/fn/parseErrors.js';

export function* sendMessageToClientWorker({ payload: { id, values, formik } }) {
  const token = yield select(getToken);

  try {
    const { sent } = yield call(
      sendMessageToClient,
      token,
      { pathParams: { id }, bodyParams: values }
    );

    if (sent) {
      yield call(formik.setStatus, { success: true, message: 'Отправлено' });
    } else {
      yield call(formik.setStatus, { error: true, message: 'Ошибка сервера' });
    }
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отсутвует доступ к клиенту или к удалению клиента',
        404: 'Клиент не найден',
      }
    );

    yield call(formik.setStatus, { error: true, message: message ? message : 'Ошибка сервера' });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
