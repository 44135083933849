import { put, race, take } from 'redux-saga/effects';

import { clientClaimActions } from 'bus/clientClaim/actions';
import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { withUIWorker } from 'bus/ui/helpers';

const SHOW_SUCCESS = false;
const UI_KEY = 'FETCH_CONTRACT_KEY';

function* ContractAndFetchContractTextWorker({ payload }) {
  const { claimId, values, formik, fieldsDataForError } = payload;

  yield put(
    clientClaimActions.updateContractClaim(
      claimId, values, formik, fieldsDataForError, SHOW_SUCCESS
    )
  );

  const [success] = yield race([
    take(clientClaimActions.updateContractClaimSuccess),
    take(clientClaimActions.updateContractClaimFail)
  ]);

  if (success) {
    yield put(digitalContractActions.fetchDigitalContractText(
      claimId,
      { UI: true, formik, fieldsDataForError }
    ));
  }

  formik.setFieldValue('isCreatingStep', false);
}

export const withUiUpdateContractAndFetchContractTextWorker = withUIWorker(
  [
    digitalContractActions.fetchDigitalContractTextSuccess,
    digitalContractActions.fetchDigitalContractTextFail
  ],
  UI_KEY,
)(ContractAndFetchContractTextWorker);
