import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { deleteProfileImage } from 'api/methods/tf/profile';
import { tfActions } from 'bus/tf/actions';

export function* deleteTfImageWorker({ payload }) {
  const { type, imagePath, workerOptions, formik } = payload;
  const { resetForm, clearField } = formik || {};
  const UI = get(workerOptions, 'UI', false);

  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['tf', 'deleteTfImage'],
  })));

  try {
    yield call(deleteProfileImage, token, { pathParams: { tf }, bodyParams: { type } });

    yield put(tfActions.deleteTfImageSuccess(imagePath));
    resetForm && (yield call(resetForm));
    clearField && (yield call(clearField));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = 'Невалидный запрос';

        break;
      }
      case 403: {
        message = 'Отказано в доступе. Отсутствует доступ к загрузкам данной турфирмы';

        break;
      }
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['tf', 'deleteTfImage', 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['tf', 'deleteTfImage', 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['tf', 'deleteTfImage', 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['tf', 'deleteTfImage', 'completed'] })));
  }
}
