import { select, call, put } from 'redux-saga/effects';

// instruments
import { getUsers } from 'api/methods/tf/user';
import { tfUsersActions as actions } from 'bus/tfUsers/actions';
import { uiActions } from 'bus/ui/actions';

export function* fetchUsersWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['tf', 'users'],
  }));

  try {
    const users = yield call(getUsers, token);

    yield put(actions.fetchUsersSuccess(users));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'users', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка при загрузке данных',
      path: ['tf', 'users', 'message'],
    }));
    yield put(actions.fetchUsersFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['tf', 'users', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'users', 'completed'],
    }));
  }
}
