import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

export const getDeclarations = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'countries/{:country}/declarations',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = declarations => R.map(({ is_link: isLink, ...rest }) => ({
    isLink,
    ...rest,
  }), declarations);

  return instance._load({ token });
};
