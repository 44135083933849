import BaseApiClass from 'api/BaseApiClass';

export const deleteSearch = (token, params = {}) => new BaseApiClass({
  url: 'claims/searches/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const createSearch = (token, params = {}) => new BaseApiClass({
  url: 'claims/tourscanners/searches',
  method: 'POST',
  ...params,
})._load({ token });
