import { put, call, select } from 'redux-saga/effects';

import { accountingActions } from 'bus/accounting/actions';
import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';

import { getOutboxInvoicesBase } from 'api/methods/accounting';

const BASE_UI_PATH = ['accounting', 'outboxInvoiceBase'];

function* getOutboxInvoiceBaseWorker({ payload: id }) {
  const token = yield select(getToken);

  try {
    const invoices = yield call(getOutboxInvoicesBase, token, { pathParams: { id } });

    yield put(accountingActions.getOutboxInvoiceBaseSuccess(invoices));
  } catch (error) {
    yield put(accountingActions.getOutboxInvoiceBaseFail(error));
  }
}

export default withUIWorker(
  [accountingActions.getOutboxInvoiceBaseSuccess, accountingActions.getOutboxInvoiceBaseFail],
  BASE_UI_PATH,
)(getOutboxInvoiceBaseWorker);
