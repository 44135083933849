import { put, race, take, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { clientClaimActions } from 'bus/clientClaim/actions';
import { uiActions } from 'bus/ui/actions';

const BASE_UI_PATH = ['clientClaim', 'prepareContractClaimToAction'];

export function* prepareContractClaimToActionWorker({ payload }) {
  const { claimId, values, formik, action, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const { type, action: callback } = action;

  UI && (yield put(uiActions.changeUiLoaderFlag({
    path: [...BASE_UI_PATH, type],
    status: { loading: true, error: false, completed: false, message: null },
  })));

  yield put(clientClaimActions.updateContractClaim(claimId, values, formik));

  const resultsFetching = yield race([
    take(clientClaimActions.updateContractClaimSuccess),
    take(clientClaimActions.updateContractClaimFail)
  ]);

  const [success, fail] = resultsFetching;

  if (fail) {
    // if you want =)
  }

  if (success) {
    yield race([
      take(clientClaimActions.fetchClaimCommentsSuccess),
      take(clientClaimActions.fetchClaimCommentsFail)
    ]);
    yield call(callback);
  }

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: [...BASE_UI_PATH, type, 'loading'],
  })));
  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: [...BASE_UI_PATH, type, 'completed'],
  })));
  yield call(formik.setFieldValue, 'META.action', null);
}
