import { put, call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

import { bookingActions as actions } from 'bus/booking/actions';
import { getBookingId } from 'bus/booking/selectors';
import { getToken } from 'bus/auth/selectors';

import { createBookingMessage } from 'api/methods/booking/messages';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createBookingMessageWorker({ payload }) {
  const { values, formik, onSuccess } = payload;

  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  try {
    const bodyParams = new FormData();

    Object.keys(values).forEach(name => {
      if (name === 'files') {
        values[name].forEach(file => {
          bodyParams.append('files[]', file);
        });
      } else {
        bodyParams.append(name, values[name]);
      }
    });

    const message = yield call(
      createBookingMessage,
      token,
      { pathParams: { id: bookingId }, bodyParams }
    );

    yield put(actions.createBookingMessageSuccess(message));
    formik && (yield call(formik.setStatus, { success: true }));

    if (onSuccess) onSuccess(message);
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      case 409: {
        message = 'Не выбран отдел обработки заявок';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
