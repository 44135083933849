import { get } from 'immutable';
import { compose } from 'redux';
import * as R from 'ramda';
import { number as numberYup } from 'yup';

import { stringToNumber } from 'components/controls/InputField/helpers';

export const fixedInputDigits = (value, config = {}) => {
  const { countDigits = 0, separator = '.' } = config;

  const stringifyNumber = String(value);

  if (stringifyNumber.includes(separator)) {
    const numberParts = stringifyNumber.split(separator);

    if (get(numberParts, 1)) {
      const number = numberParts[0];
      const digits = numberParts[1].slice(0, countDigits);

      return `${number}${separator}${digits}`;
    }
  }

  return value;
};

export const formatingSum = (value, options = {}) => {
  const { handleErrorValue = true, minimumFractionDigits = 2, maximumFractionDigits = 2 } = options;
  const type = typeof value;
  const normalizer = str => str.toLocaleString('ru-RU', { minimumFractionDigits, maximumFractionDigits });

  switch (type) {
    case 'number': {
      if (Number.isNaN(value)) {
        return handleErrorValue ? normalizer(0) : value;
      }

      return normalizer(value);
    }
    case 'string': {
      const isNumber = compose(Number.isNaN, Number)(value);

      if (isNumber) {
        return normalizer(value);
      }

      return handleErrorValue ? normalizer(0) : value;
    }
    default: return handleErrorValue ? normalizer(0) : value;
  }
};

export const handleComma = R.replace(',', '.');

export const validateValueAsNumber = string => R.call(
  R.pipe(
    handleComma,
    processingString => numberYup()
      .nullable()
      .transform((valuesAsNumber, originalValue) => {
        return originalValue === '' ? null : valuesAsNumber;
      })
      .validateSync(processingString)
  ),
  string
);

export const fixedValueAndConvertToNumber = (value, options) => {
  const processValue = typeof value === 'string' ? Number(value) : value;

  return stringToNumber(formatingSum(processValue, options));
};
