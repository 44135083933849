import { takeEvery } from 'redux-saga/effects';

import { withDefaultNotifications } from 'services/toaster';

import { bookingActions as actions } from '../actions';

import {
  adminBookingSearchWithReplaceQuery,
  clientBookingSearchWithReplaceQuery,
  searchBookingsWorker,
} from './workers/searchBooking/searchBookings';
import { getBookingWorker } from './workers/getBooking';
import { updateBookingWorker } from './workers/updateBooking';
import { updateBookingFromListWorker } from './workers/updateBookingFromList';
import { createBookingWorker } from './workers/createBooking';
import { deleteBookingWorker } from './workers/deleteBooking';
import { createClaimFromBookingWorker } from './workers/createClaimFromBooking';
import { fillClaimFromBookingWorker } from './workers/fillClaimFromBooking';
import { createAndFillClaimWorker } from './workers/createAndFillClaim';
import { getBookingChangesWorker } from './workers/getBookingChanges';
import { markBookingViewedWorker } from './workers/markBookingViewed';
import { refreshBookingWorker } from './workers/refreshBooking';
import { saveBookingWorker } from './workers/saveBooking';
import { getBookingsStatWorker } from './workers/getBookingsStat';
import { getBookingPaymentsWorker } from './workers/getBookingPayments';
import { getBookingInvoicesWorker } from './workers/getBookingInvoices';

import {
  getBookingMessagesWorker,
  createBookingMessageWorker,
  deleteBookingMessageWorker,
  getMessagesCountUnreadWorker,
  updateMessageViewedWorker,
  removeMessageViewWorker,
  getMessagesTemplatesSagaWithUI
} from './workers/bookingMessages';

import { updateBookingWithCash, updateCashWorker } from './workers/bookingMoney/updateCash';

// invoices
import { createOperatorInvoiceWorker } from './workers/bookingInvoices/createOperatorInvoice';
import { updateOperatorInvoiceWorker } from './workers/bookingInvoices/updateOperatorInvoice';

// services workers
import { createServicesWorker } from './workers/bookingServices/createServices';
import { updateServicesWorker } from './workers/bookingServices/updateServices';
import { deleteServicesWorker } from './workers/bookingServices/deleteServices';

// tourists worker
import {
  createBookingTouristWorker,
  updateBookingTouristWorker,
  deleteBookingTouristWorker
} from './workers/bookingTourists';

import {
  getBookingSchedulesWorker,
  createBookingSchedulesWorker,
  createSchedulesFromTemplate,
  updateBookingSchedulesWorker
} from './workers/bookingSchedules';

import {
  getBookingCashWorkerWithUI,
} from './workers/getBookingCash';

import { updateAllMessagesViewsWorker } from './workers/bookingMessages/updateMessageViewed';
import { getDocumentsWithUI } from './workers/documents/getDocuments';
import { addDocumentsSagaWithUI } from './workers/documents/addDocuments';
import { updateDocumentSaga } from './workers/documents/updateDocument';
import { deleteDocumentSaga } from './workers/documents/deleteDocument';
import {
  showCreateInvoiceModalSaga
} from './workers/bookingInvoices/showCreateInvoiceModal';
import { removeClaimFromBookingSagaWithUI } from './workers/removeClaimFromBooking';
import { calculateEnd2EndBookingSaga } from './workers/calculateEnd2EndBooking';
import { bookingEnd2EndBookingSaga } from './workers/bookingEnd2EndBooking';
import { getBookingViewsSaga } from './workers/getBookingViews';
import { autoGenerateRootSaga } from './workers/bookingInvoices/autoGenerateInvoice';

export const bookingWatchers = Object.freeze({
  * searchClientsBookingsWatcher() {
    yield takeEvery(actions.search, clientBookingSearchWithReplaceQuery);
  },
  * searchAdminBookingsWatcher() {
    yield takeEvery(actions.searchAdminBooking, adminBookingSearchWithReplaceQuery);
  },
  * searchBookingPureWatcher() {
    yield takeEvery(actions.searchBookingPure, searchBookingsWorker);
  },
  * getBookingWatcher() {
    yield takeEvery(actions.getBooking, getBookingWorker);
  },
  * getDocuments() {
    yield takeEvery(actions.getDocuments, getDocumentsWithUI);
  },
  * addDocuments() {
    yield takeEvery(actions.addDocuments, addDocumentsSagaWithUI);
  },
  * updateDocument() {
    yield takeEvery(actions.updateDocument, updateDocumentSaga);
  },
  * deleteDocument() {
    yield takeEvery(actions.deleteDocument, deleteDocumentSaga);
  },
  * getBookingResultMoneyWatcher() {
    yield takeEvery(actions.getBookingCash, getBookingCashWorkerWithUI);
  },
  * updateBookingWatcher() {
    yield takeEvery(actions.updateBooking, updateBookingWorker);
  },
  * createBookingWatcher() {
    yield takeEvery(actions.createBooking, createBookingWorker);
  },
  * updateBookingFromListWatcher() {
    yield takeEvery(actions.updateBookingFromList, updateBookingFromListWorker);
  },
  * deleteBookingWatcher() {
    yield takeEvery(actions.deleteBooking, deleteBookingWorker);
  },
  * createClaimFromBookingWatcher() {
    yield takeEvery(actions.createClaimFromBooking, createClaimFromBookingWorker);
  },
  * fillClaimFromBookingWatcher() {
    yield takeEvery(actions.fillClaimFromBooking, fillClaimFromBookingWorker);
  },
  * removeClaimFromBookingSagaWatcher() {
    yield takeEvery(actions.removeClaimFromBooking, removeClaimFromBookingSagaWithUI);
  },
  * createAndFillClaimWatcher() {
    yield takeEvery(actions.createAndFillClaim, createAndFillClaimWorker);
  },
  * getBookingChangesWatcher() {
    yield takeEvery(actions.getBookingChanges, getBookingChangesWorker);
  },
  * getBookingMessagesWatcher() {
    yield takeEvery(actions.getBookingMessages, getBookingMessagesWorker);
  },
  * createBookingMessageWatcher() {
    yield takeEvery(actions.createBookingMessage, createBookingMessageWorker);
  },
  * deleteBookingMessageWatcher() {
    yield takeEvery(actions.deleteBookingMessage, deleteBookingMessageWorker);
  },
  * getMessagesCountUnreadWatcher() {
    yield takeEvery(actions.getMessagesCountUnread, getMessagesCountUnreadWorker);
  },
  * updateMessageViewedWatcher() {
    yield takeEvery(actions.updateMessageViewed, updateMessageViewedWorker);
  },
  * updateAllMessagesViewedWatcher() {
    yield takeEvery(actions.updateAllMessagesViews, updateAllMessagesViewsWorker);
  },
  * getMessagesTemplatesSagaWatcher() {
    yield takeEvery(actions.getMessagesTemplates, getMessagesTemplatesSagaWithUI);
  },
  * removeMessageView() {
    yield takeEvery(actions.removeMessageViewed, removeMessageViewWorker);
  },
  * markBookingViewedWatcher() {
    yield takeEvery(actions.markBookingViewed, markBookingViewedWorker);
  },
  * refreshBookingWatcher() {
    yield takeEvery(actions.refreshBooking, refreshBookingWorker);
  },
  * saveBookingWatcher() {
    yield takeEvery(actions.saveBooking, saveBookingWorker);
  },
  * getBookingsStatWatcher() {
    yield takeEvery(actions.getBookingsStat, getBookingsStatWorker);
  },
  * getBookingPaymentsWatcher() {
    yield takeEvery(actions.getBookingPayments, getBookingPaymentsWorker);
  },
  * getBookingInvoicesWatcher() {
    yield takeEvery(actions.getBookingInvoices, getBookingInvoicesWorker);
  },

  * updateCashWatcher() {
    yield takeEvery(actions.updateCash, updateCashWorker);
  },
  * updateBookingCashWatcher() {
    yield takeEvery(actions.updateBookingWithCash, updateBookingWithCash);
  },

  // invoices
  * createOperatorInvoiceWatcher() {
    yield takeEvery(actions.createOperatorInvoice, createOperatorInvoiceWorker);
  },
  * updateOperatorInvoiceWatcher() {
    yield takeEvery(actions.updateOperatorInvoice, updateOperatorInvoiceWorker);
  },
  * showCreateInvoiceModal() {
    yield takeEvery(actions.showCreateInvoiceModal, showCreateInvoiceModalSaga);
  },
  autoGenerateRootSaga,

  // services
  * createServicesWatcher() {
    yield takeEvery(actions.createBookingServices, createServicesWorker);
  },
  * updateServicesWatcher() {
    yield takeEvery(actions.updateBookingServices, updateServicesWorker);
  },
  * deleteServicesWatcher() {
    yield takeEvery(actions.deleteBookingServices, withDefaultNotifications(deleteServicesWorker));
  },

  // tourists
  * createBookingTouristWatcher() {
    yield takeEvery(actions.createBookingTourist, createBookingTouristWorker);
  },
  * updateBookingTouristWatcher() {
    yield takeEvery(actions.updateBookingTourist, updateBookingTouristWorker);
  },
  * deleteBookingTouristWatcher() {
    yield takeEvery(actions.deleteBookingTourist, withDefaultNotifications(deleteBookingTouristWorker));
  },

  // schedules
  * getBookingSchedulesWatcher() {
    yield takeEvery(actions.getBookingSchedules, getBookingSchedulesWorker);
  },
  * createBookingScheduleWatcher() {
    yield takeEvery(actions.createBookingSchedules, createBookingSchedulesWorker);
  },
  * updateBookingScheduleWatcher() {
    yield takeEvery(actions.updateBookingSchedules, updateBookingSchedulesWorker);
  },
  * createSchedulesFromTemplateWatcher() {
    yield takeEvery(actions.createSchedulesFromTemplate, createSchedulesFromTemplate);
  },
  * calculateEnd2EndBookingWatcher() {
    yield takeEvery(actions.calculateEnd2EndBooking, calculateEnd2EndBookingSaga);
  },
  * bookingEnd2EndBookingWatcher() {
    yield takeEvery(actions.bookingEnd2EndBooking, bookingEnd2EndBookingSaga);
  },
  * getBookingViewsWatcher() {
    yield takeEvery(actions.getBookingViews, getBookingViewsSaga);
  },
});
