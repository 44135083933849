import { createActions } from 'redux-actions';

export const clientLeadActions = createActions({
  SEARCH: () => undefined,
  SEARCH_SUCCESS: (results, total) => ({ results, total }),
  SEARCH_FAIL: error => error,

  SET_QUERY: query => query,
  SET_PAGE: page => page,
  SET_ORDER: order => order,
}, { prefix: 'MT_SEARCH_CLIENT_LEAD' });
