import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getDefaultLangForCrm } from 'crms/helpers';

import ru from './locales/globalRu.json';
import ua from './locales/globalUa.json';
import en from './locales/globalEn.json';
import pl from './locales/globalPl.json';

import { LOCALES } from './constants';

const baseInstance = i18next.createInstance(
  {
    lng: getDefaultLangForCrm(),
    fallbackLng: LOCALES.UA,
    resources: {
      [LOCALES.EN]: { translation: en },
      [LOCALES.RU]: { translation: ru },
      [LOCALES.UA]: { translation: ua },
      [LOCALES.PL]: { translation: pl },
    },
    initImmediate: false,
  },
  err => {
    if (err && __DEV__) {
      console.log(err);
    }
  }
).use(initReactI18next);

export const i18authGlobalInstance = baseInstance;
