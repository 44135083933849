import * as R from 'ramda';

import { CRM_TYPES } from 'crms/constants';

import { formatingSum } from '../inputs';

import { CURRENCIES, UAH_CURRENCY, UAH_CURRENCY_OTPUSK } from './index';

export const UAH_CURRENCY_GROUP = [UAH_CURRENCY, UAH_CURRENCY_OTPUSK];

export const isUahCurrency = currencyCode => UAH_CURRENCY_GROUP.some(({ code }) => code === currencyCode);

export const getCurrencyByCode = currencyCode => CURRENCIES.find(({ code }) => code === currencyCode);

export const replaceUAhCurrencyToHrn = currencyCode => (currencyCode === UAH_CURRENCY_OTPUSK.code ? UAH_CURRENCY.code : currencyCode);
export const replaceHrnToUah = currencyCode => (currencyCode === UAH_CURRENCY.code ? UAH_CURRENCY_OTPUSK.code : currencyCode);

export const getSortedToLabelCurrencyList = array => R.call(R.pipe(
  R.values,
  R.map(val => val.slice(0, 3).toLowerCase())
), array);

export const getCurrencyLabel = currencyCode => R.prop('symbol', getCurrencyByCode(currencyCode));

// TODO move to crms
const roundingOptions = __CRM__ === CRM_TYPES.easybooking
  ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  : undefined;

export const renderSumWithCurrency = (sum = 0, currency = '') => {
  return `${currency} ${formatingSum(sum, roundingOptions)}`;
};
