// actions
import { claimActions as actions } from 'bus/clientClaim/claim';

export const claimReducer = Object.freeze({
  [actions.updateClaimSuccess]: (state, { payload: claim }) => {
    const claims = state.getIn(['client', 'claims']);

    if (!claims) return state;

    const index = claims.findIndex(({ id }) => id === claim.id);

    return state
      .set('claim', claim)
      .mergeIn(['client', 'claims', index >= 0 ? index : 0], claim);
  },
  [actions.updateDateCreatedSuccess]: (state, { payload }) => {
    const { date } = payload;

    return state.setIn(['claim', 'created'], date);
  },
});
