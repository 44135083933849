import { select, call, put, take, race } from 'redux-saga/effects';
import { Map, get, getIn, List } from 'immutable';
import { captureException, withScope } from '@sentry/browser';

import { showToastError, showToastSuccess } from 'services/toaster';

import { updateContractClaim } from 'api/methods/claim/updateClaim';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { parseErrorPath } from 'helpers';
import { MyError } from 'api/fn/Error';

import { newInstance } from 'localization';

const extractRoomType = (roomType, newRoomTypes) => {
  if (roomType && roomType.new) {
    const newRoomType = newRoomTypes.length
      ? newRoomTypes.find(data => data.name === roomType.text.trim())
      : null;

    return newRoomType ? newRoomType.id : null;
  }

  return roomType;
};

export function* updateContractClaimWorker({ payload }) {
  const {
    claimId,
    values,
    formik,
    fieldsDataForError,
    showSuccess,
    isNeedResetTouchedClaim
  } = payload;
  const { claim, locations = [], comment, ...restValues } = values;
  const token = yield select(({ auth }) => auth.get('token'));

  formik && (yield call(formik.setStatus, null));

  try {
    const roomsTypeIsNotSave = [claim.room_type, ...locations.map(item => item.room_type)]
      .filter(type => type && type.new);

    if (roomsTypeIsNotSave.length) {
      const uniqaRoomTypes = [...new Set(roomsTypeIsNotSave.map(item => item.text.trim()))];

      const types = [];

      for (let i = 0; i < uniqaRoomTypes.length; i++) {
        yield put(clientClaimActions.createRoomType(uniqaRoomTypes[i]));

        const [successCreated, failCreated] = yield race([
          take(clientClaimActions.createRoomTypeSuccess),
          take(clientClaimActions.createRoomTypeFail)
        ]);

        if (failCreated) {
          throw new MyError({ status: 505 });
        }

        types.push(successCreated.payload);
      }

      // eslint-disable-next-line camelcase
      claim.room_type = extractRoomType(claim.room_type, types);
      formik && formik.setFieldValue('claim.room_type', claim.room_type);

      values.locations = values.locations ? values.locations.map((item, index) => {
        const roomType = extractRoomType(item.room_type, types);

        formik && formik.setFieldValue(`locations.${index}.room_type`, roomType);

        return {
          ...item,
          room_type: roomType,
        };
      }) : [];
    }

    const response = yield call(updateContractClaim, token, {
      pathParams: { id: claimId },
      bodyParams: {
        claim,
        locations: values.locations,
        ...comment ? { comment } : {},
        ...restValues,
      },
    });

    yield put(clientClaimActions.updateContractClaimSuccess(response.claim));
    isNeedResetTouchedClaim && (yield put(clientClaimActions.resetTouchedClaim()));

    showSuccess && showToastSuccess(newInstance.t('GLOBAL:SAVE_SUCCESS'));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    let formikErrors = Map();

    switch (status) {
      case 400: {
        const errorsInstances = [
          { key: 'claim', instance: get(body, 'claim', null) },
          { key: 'locations', instance: get(body, 'locations', null) },
          { key: 'extra', instance: get(body, 'extra', null) },
          { key: 'fields_data', instance: get(body, 'fields_data', null) },
          { key: 'root', instance: get(body, 'root', null) }
        ].filter(item => item.instance);

        if (errorsInstances.length) {
          formikErrors = Map({ ...errorsInstances }).mapEntries(([, { key, instance }]) => {
            let value = null;

            switch (key) {
              case 'locations': {
                value = instance.map(location => {
                  return Map({ ...getIn(location, ['validation', 'violations'], []) }).reduce((results, item) => {
                    return results.setIn(parseErrorPath(item.property_path).split('.'), item.message);
                  }, Map());
                });

                break;
              }
              case 'fields_data': {
                value = instance.reduce((res, fieldData) => {
                  const { entity, validation } = fieldData;
                  const allErrorsMessagesByEntity = validation.violations.map(item => item.message.replace('.', '')).join('. ');
                  const fieldIndex = fieldsDataForError.findIndex(
                    ({ field }) => field === entity.field.id
                  );

                  return res.set(
                    fieldIndex >= 0 ? fieldIndex : 0, { value: allErrorsMessagesByEntity }
                  );
                }, List([]));

                break;
              }
              case 'root': {
                const violations = get(instance, 'violations', {});

                value = Map({ ...violations }).reduce((results, item) => {
                  return results.setIn(parseErrorPath(item.property_path).split('.'), item.message);
                }, Map());

                break;
              }
              default: {
                const violations = getIn(instance, ['validation', 'violations'], []);

                value = Map({ ...violations }).reduce((results, item) => {
                  return results.setIn(parseErrorPath(item.property_path).split('.'), item.message);
                }, Map());
              }
            }

            return [key, value];
          });
        } else {
          message = 'Ошибка сервера';

          withScope(scope => {
            scope.setExtra('bodyParams', values);

            captureException('UpdateContractClaim');
          });
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на обновление заказа или доступ к разделу ‘Клиенты’');
        break;
      }
      case 404: {
        message = 'Заказ не найден';
        break;
      }
      case 505: {
        message = 'Данный "тип номера отеля" существует. Выберите из списка';
        break;
      }
      default: {
        message = 'Ошибка сервера';

        withScope(scope => {
          scope.setExtra('bodyParams', values);

          captureException('UpdateContractClaim');
        });
      }
    }

    let commentError = Map();

    if (status === 400 && get(body, 'root', null)) {
      const violations = get(get(body, 'root', null), 'violations', []);

      commentError = Map({ ...violations }).reduce((results, item) => {
        return results.setIn(parseErrorPath(item.property_path).split('.'), item.message);
      }, Map());
    }

    const mergedErrors = formikErrors.merge(commentError);

    !mergedErrors.isEmpty() && formik && (yield call(formik.setErrors, mergedErrors.toJS()));

    showToastError(message);
    yield put(clientClaimActions.updateContractClaimFail(error));
  } finally {
    formik && formik.setSubmitting(false);
  }
}
