import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

// instruments
import { statisticActions } from 'bus/statistic/actions';
import { uiActions } from 'bus/ui/actions';
import { getCalls } from 'api/methods/main/getCalls';
import { removeNull } from 'helpers';
import { compile } from 'helpers/hash';
import book from 'routes/book';

export const prepareData = values => {
  const { start, end, ...rest } = values;

  return removeNull({
    start: start ? start.format('YYYY-MM-DD') : null,
    end: end ? end.format('YYYY-MM-DD') : null,
    ...rest,
  });
};

export function* getCallsWorker({ payload }) {
  const { formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const query = yield select(({ statistic }) => statistic.getIn(['calls', 'query']));
  const page = yield select(({ statistic }) => statistic.getIn(['calls', 'page']));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['statistic', 'calls'],
  }));
  yield put(replace(`${book.stat.children.calls}?form=${compile(query)}&page=${page}`));

  try {
    const {
      results,
      count,
      statistics,
      limit
    } = yield call(getCalls, token, { queryParams: { ...prepareData(query), page } });

    yield put(statisticActions.getCallsSuccess(results, count, limit, statistics));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Доступ отказано';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && message && (yield call(formik.setStatus, { error: true, message }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['statistic', 'calls', 'error'],
    }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['statistic', 'calls', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['statistic', 'calls', 'completed'],
    }));
  }
}
