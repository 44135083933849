import { select, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { getFields } from 'api/methods/tf/fields';
import { detailsFieldsActions } from '../../actions';

export function* fetchFieldWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['detailFields', 'fetchFields'],
  }));

  try {
    const tfFields = yield call(getFields, token);

    const computedTfFields = tfFields
      .reduce((acc, field) => {
        const scenarioKey = field.object;

        return acc.updateIn([scenarioKey], (arr = []) => [...arr, field]);
      }, Map())
      .map(fields => fields.sort((a, b) => a.order - b.order));

    yield put(detailsFieldsActions.fetchFieldsSuccess(computedTfFields));
  } catch (error) {
    yield put(detailsFieldsActions.fetchFieldsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['detailFields', 'fetchFields'],
    }));
  }
}
