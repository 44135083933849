import BaseApiClass from 'api/BaseApiClass';
import { Map } from 'immutable';

export const createPassport = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/epassports',
  method: 'POST',
  ...params,
})._load({ token });

export const deletePassport = (token, params = {}) => new BaseApiClass({
  url: 'claims/epassports/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const updatePassport = (token, params = {}) => new BaseApiClass({
  url: 'claims/epassports/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const recognizePassport = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}/epassports/recognize',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};

export const getDeclarationForPassport = (params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/epassorts/{:epassportId}/declarations/{:declarationId}.pdf',
  method: 'GET',
  ...params,
});

export const getEmptyDeclarationForPassport = (params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/declarations/{:declarationId}/empty.pdf',
  method: 'GET',
  ...params,
});
