import { takeEvery } from 'redux-saga/effects';

// instruments
import { calendarActions } from 'bus/calendar/actions';
import { getCalendarEventsWorker } from './workers/getCalendarEvents';
import { createCalendarEventWorker } from './workers/createCalendarEvent';
import { getCalendarEventWorker } from './workers/getCalendarEvent';
import { deleteCalendarEventWorker } from './workers/deleteCalendarEvent';
import { updateCalendarEventWorker } from './workers/updateCalendarEvent';

export const calendarWatchers = Object.freeze({
  * getCalendarEvents() {
    yield takeEvery(calendarActions.getCalendarEvents, getCalendarEventsWorker);
  },
  * createCalendarEvents() {
    yield takeEvery(calendarActions.createCalendarEvent, createCalendarEventWorker);
  },
  * getCalendarEvent() {
    yield takeEvery(calendarActions.getCalendarEvent, getCalendarEventWorker);
  },
  * deleteCalendarEvent() {
    yield takeEvery(calendarActions.deleteCalendarEvent, deleteCalendarEventWorker);
  },
  * updateCalendarEvent() {
    yield takeEvery(calendarActions.updateCalendarEvent, updateCalendarEventWorker);
  },

});
