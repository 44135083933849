import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { fillClaimFromBooking } from 'api/methods/booking';
import { bookingActions as actions } from 'bus/booking/actions';

const BASE_UI_PATH = ['booking', 'managingBooking'];

export function* fillClaimFromBookingWorker({ payload }) {
  const { bookingId, claimId, workerOptions, onSuccess } = payload;
  const UI = get(workerOptions, 'UI', true);

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  })));

  try {
    const booking = yield call(fillClaimFromBooking, token, { pathParams: { id: bookingId, claimId } });

    yield put(actions.fillClaimFromBookingSuccess(booking));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Заказ не заполнен! Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заказ не заполнен! Заявка/заказ не найдена или заявка удалена');
        break;
      }
      default: message = 'Заказ не заполнен! Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));
    yield put(actions.fillClaimFromBookingFail(message));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
