import { createActions } from 'redux-actions';

export const tourscanerActions = createActions({
  GET_OFFER_PUBLIC_URL: id => id,
  GET_OFFER_PUBLIC_URL_SUCCESS: (id, url) => ({ id, url }),
  GET_OFFER_PUBLIC_URL_FAIL: error => error,

  LOG_SEARCH: () => undefined,
  SEND_GA_EVENT: (category, action, label, params) => ({ category, action, label, params }),
  GET_COPY_TEMPLATE: (orderBasketKey, onCopySuccess) => ({ orderBasketKey, onCopySuccess }),

  saveOfferToClaim: (callbacks, claimID, clientID, hotel, offer, query, claim, onCloseModal) => ({
    callbacks, claimID, clientID, hotel, offer, query, onCloseModal, claim
  }),

  getClients: (callbacks, searchString) => ({ callbacks, searchString }),

  getBaskets: (callbacks, params) => ({ callbacks, params }),
  getBasketsSimplified: (callbacks, queryParams) => ({ callbacks, queryParams }),
  addBasket: callbacks => callbacks,
  removeBasket: (callbacks, basketId) => ({ callbacks, basketId }),
  addBasketToClaim: (callbacks, basketId, claimId, clientId) => ({ callbacks, basketId, claimId, clientId }),
  updateBasket: (callbacks, basketId, basket) => ({ callbacks, basketId, basket }),
  updateBasketStatus: (callbacks, basketId, basket) => ({ callbacks, basketId, basket }),
  addOfferToBasket: (callbacks, offer, basketId, query) => ({ callbacks, basketId, offer, query }),
  sendBasket: (callbacks, basketId, values) => ({ callbacks, basketId, values }),
  updateOfferInBasket: (callbacks, basketId, offerId, offer) => ({ callbacks, basketId, offerId, offer }),
  moveOfferInBasket: (callbacks, basketId, offerId, position) => ({ callbacks, basketId, offerId, position }),
  removeOfferFromBasket: (callbacks, id, basketID, offer) => ({ callbacks, id, basketID, offer }),
  removeClaimFromBasket: (callbacks, basketID, claimID) => ({ callbacks, basketID, claimID }),
  getBasketTemplate: (callbacks, basket) => ({ callbacks, basket }),
  generateBasketPreview: (callbacks, basket) => ({ callbacks, basket }),
  bookingOfferByOperator: (
    callbacks,
    bookingEntity,
    offer,
    hotel,
    query,
    activeFlights,
    activeBookingServices,
    recalculatedPrice
  ) => ({
    callbacks,
    bookingEntity,
    offer,
    hotel,
    query,
    activeFlights,
    activeBookingServices,
    recalculatedPrice,
  }),
  getBookingOperators: callbacks => callbacks,
  getCountries: callbacks => callbacks,
  getManagers: callbacks => callbacks,
  copyOfferToBuffer: (callbacks, offer, hotel) => ({ callbacks, offer, hotel }),
  copyOfferFromBasketToBuffer: (callbacks, { offer, basket, extra }) => ({
    callbacks, offer, basket, extra
  }),
  copyOfferURLToBuffer: (callbacks, baseOfferURL, offer) => ({ callbacks, baseOfferURL, offer }),
  bookingTicketByOperator: (callbacks, bookingEntity, ticket) => ({ callbacks, bookingEntity, ticket }),
  recognizePassport: (callbacks, passport) => ({ callbacks, passport }),
  getConnectedBookings: (callbacks, operatorID) => ({ callbacks, operatorID }),
  getOffersReviewsLinks: callbacks => callbacks,
  getBasketTemplates: callbacks => callbacks,
  updateCommissions: (callbacks, commissions) => ({ callbacks, commissions }),
  getBookingServices: (callbacks, offerID, currency) => ({ callbacks, currency, offerID }),
  recalculateBookingServices: (callbacks, { offer, currency, services, bookingEntity }) => ({ callbacks, currency, offer, services, bookingEntity }),
}, { prefix: 'MT_TOURSCANER' });
