import { select, call, put } from 'redux-saga/effects';

// instruments
import { createRoomType } from 'api/methods/claim/roomTypes';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { MyError } from 'api/fn/Error';

export function* createRoomTypeWorker({ payload: name }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const id = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  try {
    const response = yield call(
      createRoomType,
      token,
      { bodyParams: { name }, pathParams: { id } }
    );

    if (response.error) {
      throw new MyError({ status: 500 });
    }

    yield put(clientClaimActions.createRoomTypeSuccess(response));
  } catch (error) {
    yield put(clientClaimActions.createRoomTypeFail(error));
  }
}
