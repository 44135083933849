import { createActions } from 'redux-actions';

export const calendarActions = createActions({
  GET_CALENDAR_EVENTS: () => undefined,
  GET_CALENDAR_EVENTS_SUCCESS: result => result,
  GET_CALENDAR_EVENTS_FAIL: error => error,

  SET_CALENDAR_VALUES: data => data,

  CREATE_CALENDAR_EVENT: (values, formik) => ({ values, formik }),
  CREATE_CALENDAR_EVENT_SUCCESS: event => event,
  CREATE_CALENDAR_EVENT_FAIL: error => error,

  UPDATE_CALENDAR_EVENT: (values, id, formik) => ({ values, id, formik }),
  UPDATE_CALENDAR_EVENT_SUCCESS: event => event,
  UPDATE_CALENDAR_EVENT_FAIL: error => error,

  SET_OPEN_EDIT_EVENT: bool => bool,
  SET_ID_EDIT_EVENT: id => id,

  CLEAR_ID_EDIT_EVENT: () => null,

  GET_CALENDAR_EVENT: id => id,
  GET_CALENDAR_EVENT_SUCCESS: event => event,
  GET_CALENDAR_EVENT_FAIL: error => error,

  CLEAR_CALENDAR_EVENT: () => ({}),

  DELETE_CALENDAR_EVENT: id => id,
  DELETE_CALENDAR_EVENT_SUCCESS: id => id,
  DELETE_CALENDAR_EVENT_FAIL: error => error,

}, { prefix: 'MT_CALENDAR' });
