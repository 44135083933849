import { takeEvery } from 'redux-saga/effects';

// instruments
import { templatesActions } from 'bus/templates/actions';
import { getTemplatesWorker } from './workers/getTemplates';
import { createTemplateWorker } from './workers/createTemplate';
import { updateTemplateWorker } from './workers/updateTemplate';
import { deleteTemplateWorker } from './workers/deleteTemplate';
import { fetchTemplateWorker } from './workers/fetchTemplate';
import { getDefaultTemplateWorker } from './workers/getDefaultTemplate';
import { getTemplateVariablesWorker } from './workers/getTemplateVariables';

export const templatesWatchers = Object.freeze({
  * getTemplates() {
    yield takeEvery(templatesActions.getTemplates, getTemplatesWorker);
  },
  * createTemplate() {
    yield takeEvery(templatesActions.createTemplate, createTemplateWorker);
  },
  * updateTemplate() {
    yield takeEvery(templatesActions.updateTemplate, updateTemplateWorker);
  },
  * deleteTemplate() {
    yield takeEvery(templatesActions.deleteTemplate, deleteTemplateWorker);
  },
  * fetchTemplate() {
    yield takeEvery(templatesActions.fetchTemplate, fetchTemplateWorker);
  },
  * getDefaultTemplate() {
    yield takeEvery(templatesActions.getDefaultTemplate, getDefaultTemplateWorker);
  },
  * getTemplateVariables() {
    yield takeEvery(templatesActions.getTemplateVariables, getTemplateVariablesWorker);
  },
});
