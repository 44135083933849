import { takeEvery } from 'redux-saga/effects';
import { fetchAuctionOrders } from '../auction/actions';
import { fetchAuctionOrdersWorker } from '../auction/saga/workers/fetchAuctionOrdersWorker';

const otpuskWatchers = Object.freeze({
  * fetchAuctionOrdersWatcher() {
    yield takeEvery(fetchAuctionOrders, fetchAuctionOrdersWorker);
  },
});

export default otpuskWatchers;
