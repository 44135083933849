import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { filesActions } from 'bus/files/actions';
import { getFiles } from 'api/methods/files/uploads';

export function* getUploadsFilesWorker({ payload }) {
  const { options } = payload;
  const UI = get(options, 'UI', false);
  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['editor', 'filebrowser'],
  })));

  try {
    const { host, uploads } = yield call(getFiles, token, { pathParams: { tf } });
    const files = uploads.length ? uploads.map(({ web_path: path, ...rest }) => ({ web_path: `${host}${path}`, ...rest })) : null;

    yield put(filesActions.getUploadsFilesSuccess(files));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Отказано в доступе. Отсутствует доступ к загрузкам данной турфирмы';

        break;
      }
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['editor', 'filebrowser', 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['editor', 'filebrowser', 'message'] })));
    yield put(filesActions.getUploadsFilesFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['editor', 'filebrowser', 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['editor', 'filebrowser', 'completed'] })));
  }
}
