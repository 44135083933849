import { call, select, put } from 'redux-saga/effects';
import { getIn, get } from 'immutable';
import { captureException } from '@sentry/browser';

// instruments
import { createComment } from 'api/methods/claim/comment';
import { commentsActions as actions } from 'bus/clientClaim/comments';
import { uiActions } from 'bus/ui/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['clientClaim', 'comments', 'create'];

export function* createCommentBaseWorker({ payload }) {
  const { claimID, comment, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: BASE_UI_PATH,
  })));

  try {
    const response = yield call(
      createComment,
      token,
      { pathParams: { id: claimID }, bodyParams: comment }
    );

    yield put(actions.createCommentSuccess(claimID, response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    let errorsByValues = {};

    let otherErrors = [];

    switch (status) {
      case 400: {
        const isSetViolations = getIn(body, ['violations', 'length'], false);

        if (isSetViolations) {
          const violations = get(body, 'violations');
          const compiledErrors = parseErrorsByFormik(comment, violations);

          errorsByValues = compiledErrors.errorsByValues;
          otherErrors = compiledErrors.otherErrors;
        } else {
          message = 'Ошибка сервера';

          // sent to sentry
          captureException(error.msg);
        }
        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права просматривать эту заявку или доступа к разделу ‘Клиенты’');
        break;
      }
      case 404: {
        message = 'Заказ не найден';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(actions.createCommentFail(error, { message, errorsByValues, otherErrors }));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message || otherErrors.map(o => o.message).join('. '),
      path: [...BASE_UI_PATH, 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, 'completed'],
    })));
  }
}
