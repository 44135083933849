import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { accountingActions } from 'bus/accounting/actions';
import { getToken } from 'bus/auth/selectors';
import { createUi } from 'bus/ui/helpers.js';
import { registerUI, updateUI } from 'bus/ui/actions.js';

import { deleteAkts, sendAktsList } from 'api/methods/accounting';

import { RESULT_SEND_MANY_AKTS_TYPE, RESULT_SEND_MANY_AKTS_UI } from '../../constants.js';

import { deleteAkts as deleteAktsAction, sendAkts as sendAktsAction } from './actions';

export const DELETE_AKTS_UI = 'DELETE_AKTS_UI';

function* sendAktsWorker({ payload: akts }) {
  const token = yield select(getToken);

  yield put(registerUI(createUi({ loading: true }), RESULT_SEND_MANY_AKTS_UI));

  try {
    const results = yield call(sendAktsList, token, { bodyParams: { akts } });

    yield put(accountingActions.setInvoiceSendingModalValues({ results, type: RESULT_SEND_MANY_AKTS_TYPE, entity: 'Акт' }));

    yield put(accountingActions.searchAkt());
  } catch (error) {
    yield put(updateUI({ message: 'Ошибка сервера', error: true }, RESULT_SEND_MANY_AKTS_UI));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, RESULT_SEND_MANY_AKTS_UI));
  }
}
function* deleteAktsWorker({ payload: akts }) {
  const token = yield select(getToken);

  yield put(registerUI(createUi({ loading: true }), DELETE_AKTS_UI));

  try {
    yield call(deleteAkts, token, { bodyParams: { akts } });

    yield put(accountingActions.searchAkt());
  } catch (error) {
    yield put(updateUI({ message: 'Ошибка сервера', error: true }, DELETE_AKTS_UI));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, DELETE_AKTS_UI));
  }
}

export default function* () {
  yield all([
    takeEvery(sendAktsAction, sendAktsWorker),
    takeEvery(deleteAktsAction, deleteAktsWorker)
  ]);
}
