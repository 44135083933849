import { put, race, take, call } from 'redux-saga/effects';
import { get } from 'immutable';

// actions
import { epassportsActions as actions } from 'bus/clientClaim/epassports';

export function* updateEpassportFormikWorker({ payload }) {
  const { passport, id, claimId, formik } = payload;

  yield put(actions.updateEpassport(passport, id, claimId, { UI: false }));

  const fetchingResults = yield race([
    take(actions.updateEpassportSuccess),
    take(actions.updateEpassportFail)
  ]);

  const [success, fail] = fetchingResults;

  if (success) {
    yield call(formik.resetForm);
    yield call(formik.setStatus, {
      success: true,
      message: 'Паспорт успешно обновлен',
    });

    return;
  }

  if (fail) {
    const { meta } = fail;
    const { errorsByValues, otherErrors, message } = meta;

    errorsByValues && (yield call(formik.setErrors, errorsByValues));

    yield call(formik.setSubmitting, false);
    yield call(formik.setStatus, {
      error: true,
      message: get(otherErrors, 'length') ? otherErrors.join('. ') : message,
    });
  }
}
