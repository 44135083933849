import { newInstance } from 'localization';

// eslint-disable-next-line require-yield
export function* getOffersReviewsLinksWorker({ payload: callbacks }) {
  const { onSuccess, onFail } = callbacks;

  try {
    onSuccess([
      { content: newInstance.t('TS:BASKETS_URL_TYPES:TURPRAVDA'), value: 'turpravda' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:TURPRAVDARU'), value: 'turpravdaru' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:TURPRAVDACOM'), value: 'turpravdacom' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:TURPRAVDALT'), value: 'turpravdalt' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:TRIPADVISOR'), value: 'tripadvisor' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:BOOKING'), value: 'booking' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:SITE'), value: 'site' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:SITEVARIABLES'), value: 'sitevariables' },
      { content: newInstance.t('TS:BASKETS_URL_TYPES:NONE'), value: 'none' },
    ]);
  } catch (error) {
    onFail && onFail(error);
  }
}
