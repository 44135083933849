import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import cx from 'classnames';

import { Header } from 'components/parts/Header';
import Footer from 'components/parts/Footer';
import { NotifyEndingSubscriptionMini } from 'components/subscription/NotifyEndingSubscription';

import NewsOverlay from 'containers/news/Overlay';
import { NotifyEndingSubscriptionContainer } from 'containers/subscription/NotifyEndingSubscription';
import { NotifyLimitUsersContainer } from 'containers/subscription/NotifyLimitUsers';
import { NotifyAddLimitUsersContainer } from 'containers/subscription/NotifyAddLimitUsers';

import book from 'routes/book';

import { useAutoAuth } from 'providers/autoAuth';

import { ErrorBoundary } from 'services/errorBoundary';

import { useAutoLogOutFromOtherTab } from './hooks/useAutoLogOutFromOtherTab';

export const MainLayout = ({ children }) => {
  const [initialized] = useSelector(({ auth }) => [
    auth.get('initialized')
  ], shallowEqual);
  const { pathname } = useLocation();

  const isTourscannerPage = useMemo(() => [
    book.tourscanner.path,
    book.tourscanner.children.cart
  ].some(path => matchPath(pathname, { path, exact: true })), [pathname]);

  useAutoAuth();
  useAutoLogOutFromOtherTab();

  return initialized && (
    <>
      <div className={cx({ tourscannerPage: isTourscannerPage }, 'mtContainer')}>
        <div>
          <Header />
          <div>
            <ErrorBoundary withResetError>
              { children }
            </ErrorBoundary>
          </div>
        </div>

        <div>
          <Footer visibleOldVersionLink={false} />
        </div>
      </div>

      <NewsOverlay />
      <NotifyEndingSubscriptionContainer />
      <NotifyEndingSubscriptionMini />
      <NotifyLimitUsersContainer />
      <NotifyAddLimitUsersContainer />
    </>
  );
};

MainLayout.displayName = 'MainLayout';
