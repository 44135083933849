import { call, select, put } from 'redux-saga/effects';
import { getIn, get } from 'immutable';

import { closeClaim, openClaim } from 'api/methods/claim/updateClaim';

import { clientClaimActions } from 'bus/clientClaim/actions';
import { uiActions } from 'bus/ui/actions';
import { getToken } from 'bus/auth/selectors';
import { getClaimId } from 'bus/clientClaim/selectors';

const createErrorMessage = error => {
  const { status, body } = error.msg || {};

  let message = null;

  switch (status) {
    case 400: {
      const claimErrors = getIn(body, ['claim', 'violations']);
      const eventErrors = getIn(body, ['event', 'violations']);

      message = [...claimErrors, ...eventErrors].map(item => item.message.replace('.', '')).join('. ');

      break;
    }
    case 403: {
      message = get(body, 'message', 'Отсутствует доступ к разделу ‘Заказы’ или к заказу для закрытия.');
      break;
    }
    case 404: {
      message = 'Заказ отсутствует.';
      break;
    }
    default: {
      message = 'Ошибка сервера.';
    }
  }

  return message;
};

export function* closeClaimWorker({ payload: reasonId }) {
  const token = yield select(getToken);
  const id = yield select(getClaimId);

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['clientClaim', 'toggleActiveClaim'],
  }));

  try {
    yield call(closeClaim, token, { pathParams: { id, reasonId } });
    yield put(clientClaimActions.closeClaimSuccess());
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['clientClaim', 'toggleActiveClaim', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: createErrorMessage(error),
      path: ['clientClaim', 'toggleActiveClaim', 'message'],
    }));
    yield put(clientClaimActions.closeClaimFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['clientClaim', 'toggleActiveClaim', 'loading'],
    }));
  }
}

export function* openClaimWorker({ payload: message }) {
  const token = yield select(getToken);
  const id = yield select(getClaimId);

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['clientClaim', 'toggleActiveClaim'],
  }));

  try {
    yield call(openClaim, token, { pathParams: { id }, bodyParams: { message } });
    yield put(clientClaimActions.openClaimSuccess());
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['clientClaim', 'toggleActiveClaim', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: createErrorMessage(error),
      path: ['clientClaim', 'toggleActiveClaim', 'message'],
    }));
    yield put(clientClaimActions.openClaimFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['clientClaim', 'toggleActiveClaim', 'loading'],
    }));
  }
}
