import { put, call, select } from 'redux-saga/effects';

import { uiActions } from 'bus/ui/actions';
import { accountingActions } from 'bus/accounting/actions';
import { withUIWorker } from 'bus/ui/helpers';

import { getAccountingCounterparties } from 'api/methods/accounting';
import { getErrorMessage } from 'api/fn/parseErrors';

import { COUNTERPARTIES_UI } from '../../constants';

const BASE_UI_PATH = ['accounting', 'counterparties'];

function* getCounterparties() {
  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const payload = yield call(getAccountingCounterparties, token);

    yield put(accountingActions.getCounterpartiesSuccess(payload));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Заявка не существует или уже удалена',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(accountingActions.getCounterpartiesFail(error));
  }
}

export const getCounterpartiesWorker = withUIWorker(
  [
    accountingActions.getCounterpartiesSuccess,
    accountingActions.getCounterpartiesFail
  ],
  COUNTERPARTIES_UI,
)(getCounterparties);
