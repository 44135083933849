import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { notifyActions as actions } from './actions';

const initialState = Map({
  types: [],
  notifies: {},
  editNotify: null,
  '@editNotify': null,
  notifiesValueByType: {},
});

export const notifyReducer = handleActions({
  [actions.getTypesSuccess]: (state, { payload: types }) => state.set('types', types),
  [actions.getNotifiesSuccess]: (state, { payload: notifies }) => state.set('notifies', notifies),
  [actions.updateNotifySuccess]: (state, { payload: notify }) => {
    const { type } = notify;
    const updateNotifyIndex = state.getIn(['notifies', type]).findIndex(item => item.id === notify.id);

    return state.mergeIn(['notifies', type, updateNotifyIndex], notify);
  },
  [actions.refreshNotify]: (state, { payload }) => {
    const { notify } = payload;
    const { type } = notify;

    return state.updateIn(['notifies', type], (list = []) => [notify, ...list]);
  },
  [actions.setEditNotify]: (state, { payload }) => {
    const { type, locale } = payload;

    return state.set('@editNotify', { type, locale });
  },
  [actions.resetEditNotify]: state => {
    return state.set('@editNotify', null).set('editNotify', null);
  },
  [actions.changeEditNotifyLocale]: (state, { payload: locale }) => {
    return state.setIn(['@editNotify', 'locale'], locale);
  },
  [actions.prepareEditNotify]: (state, { payload: type }) => state.set('editNotify', { type }),
  [actions.setNotifyValue]: (state, { payload }) => {
    const { notify, type } = payload;

    return state.setIn(['notifiesValueByType', type], notify);
  },
}, initialState);
