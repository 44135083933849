import BaseApiClass from 'api/BaseApiClass';
import * as R from 'ramda';

import { OTPUSK_API_URL, OTPUSK_AUCTION_URL } from './constants';

export const getAuctionOffers = (params = {}) => {
  const instance = new BaseApiClass({
    method: 'GET',
    outerResource: `${OTPUSK_AUCTION_URL}api/requests`,
    ...params,
  });

  instance.parseBodyToClientSchema = orders => R.map(
    order => R.over(R.lensProp('dateFrom'), R.prop('date'), order),
    orders
  );

  return instance._load({ token: null });
};

// /suggests?text=Hotel%20Rivijera&access_token=752ba-e523d-db04a-bc336-69a32&lang=ukr&with=price
export const getHotels = (_, params = {}) => {
  const instance = new BaseApiClass({
    method: 'GET',
    outerResource: `${OTPUSK_API_URL}tours/suggests`,
    ...params,
  });

  instance.parseBodyToClientSchema = R.pipe(
    R.prop('response'),
    R.values,
    R.filter(({ type }) => type === 'hotel'),
    R.map(
      R.over(
        R.lensProp('stars'),
        R.toUpper
      )
    )
  );

  return instance._load({ token: null });
};
