import { handleActions, combineActions } from 'redux-actions';
import { Map, List } from 'immutable';

import { epassportsReducer } from 'bus/clientClaim/epassports';
import { claimReducer } from 'bus/clientClaim/claim';
import { commentsReducer } from 'bus/clientClaim/comments';
import { stepersReducer } from 'bus/clientClaim/stepers';
import { digitalContractState, digitalContractReducer } from 'bus/clientClaim/digitalContract';

import { clientClaimActions as actions } from './actions';
import { getStorage, GROUPS } from '../../helpers/storage';

const initialState = Map({
  client: Map(),
  fields: List(),
  passports: List(),
  conflictClients: null,
  claim: null,
  simplifiedClaim: {},
  touchedClaim: null,
  bonusClaim: null,
  staticLists: Map(),
  addCommentMeta: null,
  activeClaimStep: null,
  isCommentShow: getStorage().getIn([GROUPS.clientProfile, 'commentView']),
  declarations: [],

  ...digitalContractState,
});

export default handleActions({
  ...epassportsReducer,
  ...claimReducer,
  ...commentsReducer,
  ...stepersReducer,
  ...digitalContractReducer,

  [actions.fetchClientSuccess]: (state, { payload }) => state.set('client', Map(payload.client)),
  [actions.clearClient]: state => state.set('client', Map()),
  [actions.updateClientSuccess]: (state, { payload: client }) => state.set('client', Map(client)),
  [actions.getDeclarationsSuccess]: (state, { payload: declarations }) => state.set('declarations', declarations),
  [actions.fetchTfFieldsForClientSuccess]: (state, { payload }) => state.set('fields', List(payload.fields)),
  [actions.fetchClientPassportsSuccess]: (state, { payload }) => state.set('passports', List(payload.passports)),
  [actions.clearClientPassports]: state => state.set('passports', List()),
  [actions.setConflictClients]: (state, { payload: { oldClient, currentClient } }) => state.set('conflictClients', { oldClient, currentClient }),
  [actions.getClaimSuccess]: (state, { payload: claim }) => state.set('claim', claim),
  [actions.getClaimSimplifiedSuccess]: (state, { payload: simplifiedClaim }) => state.set('simplifiedClaim', simplifiedClaim),
  [actions.clearSimplifiedClaim]: state => state.set('simplifiedClaim', {}),
  [actions.clearClaim]: state => state.set('claim', null).set('touchedClaim', null).set('bonusClaim', null),
  [actions.deleteBookingFromClaim]: state => state.setIn(['claim', 'booking'], null),
  [actions.updateClaimPerformer]: (state, { payload: performer }) => {
    const lastClaim = state.getIn(['client', 'claims', 'length']) - 1;

    return state.setIn(['client', 'claims', lastClaim, 'performer'], performer);
  },
  [actions.resetConflictClients]: state => state.set('conflictClients', null),
  [actions.setTouchedClaimSuccess]: (state, { payload: claim }) => {
    const prevClaim = state.get('claim');

    return state.set('touchedClaim', { ...prevClaim, ...claim });
  },
  [actions.resetTouchedClaim]: state => state.set('touchedClaim', null),
  [actions.deleteSearchSuccess]: (state, { payload: searchId }) => {
    return state.updateIn(['claim', 'search'], searchList => searchList.filter(({ id }) => id !== searchId));
  },
  [actions.deleteBasket]: (state, { payload: basketId }) => {
    return state.updateIn(['claim', 'baskets'], offerList => offerList.filter(({ id }) => id !== basketId));
  },
  [actions.removeOfferFromBasket]: (state, { payload: { basketId, offerID } }) => {
    const index = state.getIn(['claim', 'baskets'], []).findIndex(({ id }) => id === basketId);

    return state.updateIn(['claim', 'baskets', index, 'offers'], offerList => offerList.filter(({ id }) => offerID !== id));
  },
  [actions.setBasket]: (state, { payload: { basketId, basket } }) => {
    const index = state.getIn(['claim', 'baskets'], []).findIndex(({ id }) => id === basketId);

    return state.setIn(['claim', 'baskets', index], basket);
  },
  [actions.deletePassportSuccess]: (state, { payload: passportId }) => state.updateIn(['claim', 'epassports'], passportsList => passportsList.filter(item => item.id !== passportId)),
  [actions.getStaticListsSuccess]: (state, { payload: lists }) => state.mergeIn(['staticLists'], lists),
  [actions.updateContractClaimSuccess]: (state, { payload: data }) => {
    const index = state.getIn(['client', 'claims'], []).findIndex(({ id }) => id === data.id);

    if (index >= 0) {
      return state
        .updateIn(['claim'], claim => (claim ? { ...claim, ...data } : { ...data }))
        .mergeIn(['client', 'claims', index], data);
    }

    return state.updateIn(['claim'], claim => (claim ? { ...claim, ...data } : { ...data }));
  },
  [actions.createRoomTypeSuccess]: (state, { payload: type }) => state.setIn(['staticLists', 'rooms', state.getIn(['staticLists', 'rooms', 'length'], 0)], type),

  [combineActions(
    actions.createClaimBookingSuccess,
    actions.updateClaimBookingSuccess
  )]: (state, { payload: booking }) => state.setIn(['claim', 'booking'], booking),
  [actions.deleteClaimBookingSuccess]: state => state.setIn(['claim', 'booking'], null),
  [actions.fetchClaimBonusSuccess]: (state, { payload: bonus }) => state.set('bonusClaim', bonus),
  [actions.clearClaimBonus]: state => state.set('bonusClaim', null),
  [actions.openAddCommentComponent]: (state, { payload }) => state.set('addCommentMeta', payload),
  [actions.closeAddCommentComponent]: state => state.set('addCommentMeta', null),
  [actions.fetchClaimCommentsSuccess]: (state, { payload: comments }) => {
    if (!state.get('claim')) {
      return state;
    }

    return state.setIn(['claim', 'events'], comments);
  },
  [actions.closeClaimSuccess]: state => state.setIn(['claim', 'active'], false),
  [actions.setIsCommentShow]: (state, { payload: isShow }) => state.set('isCommentShow', isShow),
  [actions.openClaimSuccess]: state => {
    return state
      .setIn(['claim', 'active'], true)
      .setIn(['claim', 'active_changed_date'], null);
  },
}, initialState);
