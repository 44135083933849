import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getUI } from '../ui/selectors';

export const domain = ({ tf }) => tf;

const DEFAULT_VALUE = [];
const DEFAULT_OBJ_VALUE = [];

export const getTFProfile = createSelector(domain, tf => tf.get('profile'));

export const getCommissionsBase = R.pipe(domain, tf => tf.get('commission'));
export const getCommissions = createSelector(getCommissionsBase, R.prop('results'));
export const getCommissionShowContacts = createSelector(getCommissionsBase, R.prop('showContacts'));
export const getGroups = createSelector(domain, tf => tf.get('groups') || DEFAULT_VALUE);
export const getCommissionsGroups = createSelector(domain, tf => tf.get('commissionsGroups') || DEFAULT_VALUE);

export const getOperatorCredentials = createSelector(domain, tf => tf.get('operatorCredentials'));
export const getCredentials = createSelector(getOperatorCredentials, ({ credentials }) => credentials || DEFAULT_OBJ_VALUE);
export const getCredentialsResult = createSelector(getCredentials, ({ results }) => results);

export const getCredentialsResultWithNameSort = createSelector(getCredentialsResult, R.sortBy(R.compose(R.toLower, R.path(['operator', 'title']))));

export const getGroupsUI = R.partialRight(getUI, [{ key: 'GET_GROUPS' }]);
export const getCommissionGroupsUI = R.partialRight(getUI, [{ key: 'GET_COMMISSION_GROUPS' }]);
