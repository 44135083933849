import { put, delay, take, race, call } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

// actions
import { uiActions } from 'bus/ui/actions';
import { dumpActions } from 'bus/dump/actions';
import { filesActions } from 'bus/files/actions';

// instruments
import { MyError } from 'api/fn/Error';
import { setGroupValue, GROUPS } from 'helpers/storage';

const BASE_UI_PATH = ['dump', 'import', 'job'];
const STORAGE_KEY = 'importJob';
const DELAY = 2 * 1000; // 2 seconds

export function* importProgressWorker({ payload }) {
  const { id, type } = payload;

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'loading'] }));

  try {
    while (true) {
      yield put(filesActions.getStatusJobs(id));

      const [successJobs, failJobs] = yield race([
        take(filesActions.getStatusJobsSuccess),
        take(filesActions.getStatusJobsFail)
      ]);

      if (successJobs) {
        const successState = getIn(successJobs, ['payload', 'state']);

        yield call(setGroupValue, GROUPS.dump, { [STORAGE_KEY]: { type, ...successJobs.payload } });
        yield put(dumpActions.importProgressSuccess({ type, ...successJobs.payload }));

        if (successState === 'fail') {
          throw new MyError({ status: 500 });
        }
      }

      if (failJobs) {
        throw failJobs.payload;
      }

      yield delay(DELAY);
    }
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к задаче');

        break;
      }
      case 404: {
        message = get(body, 'message', 'Задача не найдена');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
  }
}
