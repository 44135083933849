import { handleActions } from 'redux-actions';

// instruments
import { setAuctionOrders } from './actions';

const initialState = {
  auctionOrders: [],
};

export default handleActions({
  [setAuctionOrders]: (state, { payload: orders }) => {
    return { ...state, auctionOrders: orders };
  },
}, initialState);
