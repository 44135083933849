import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { calendarActions } from 'bus/calendar/actions';
import { createCalendarEvent } from 'api/methods/calendar';

export function* createCalendarEventWorker({ payload }) {
  const { values, formik } = payload;

  const { token, user } = yield select(({ auth }) => ({
    token: auth.get('token'),
    user: auth.getIn(['profile', 'id']),
  }));

  try {
    const event = yield call(
      createCalendarEvent,
      token,
      { pathParams: { user }, bodyParams: values }
    );

    yield put(calendarActions.createCalendarEventSuccess(event));
    yield call(formik.setStatus, { success: true });
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Ошибка валидации');
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
