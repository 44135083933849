import { select, put, call } from 'redux-saga/effects';
import { getIn, get, Map } from 'immutable';
import { matchPath } from 'react-router-dom';
import book from 'routes/book';

// instruments
import { financeActions } from 'bus/finance/actions';
import { updatePayment } from 'api/methods/finance/updatePayment';
import { convertToClient } from 'api/methods/finance/convert/payment';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { newInstance } from '../../../../localization';

export function* updatePaymentWorker({ payload }) {
  const { claimID, formik, payment } = payload;
  const { token, pathname } = yield select(({ auth, router }) => ({
    token: auth.get('token'),
    pathname: router.location.pathname,
  }));

  try {
    const { id: paymentID, ...bodyParams } = payment;
    const response = yield call(
      updatePayment,
      token,
      { pathParams: { claimID, paymentID }, bodyParams }
    );

    yield put(financeActions.updatePaymentSuccess(convertToClient(response)));

    if (matchPath(pathname, { path: book.fin.path })) {
      yield put(financeActions.updateFinPayments(convertToClient(response)));
    }

    if (matchPath(pathname, { path: book.clientClaim.children.claim })) {
      yield put(clientClaimActions.getClaim(claimID));
    }

    formik && (yield call(formik.setStatus, { success: true, message: newInstance.t('SUCCESS_MESSAGES:PAYMENT_SAVED') }));
  } catch (error) {
    const { body, status } = error.msg || {};

    let message = null;

    switch (status) {
      case 404: {
        message = 'Заказ или платеж отсутствует';
        break;
      }
      case 403: {
        message = 'Пользователь не имеет доступа к заказу платежа или отсутствует подписка на CRM или отсутствует разрешение на доступ к платежей';
        break;
      }
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const errorsList = Map({ ...get(body, 'violations', []) }).mapEntries(([, value]) => [value.property_path, value.message]);
          const formikError = errorsList.filter((...[, key]) => Boolean(payment[key])).toObject();

          message = errorsList.filter((...[, key]) => !payment[key]).toList().map(str => str.replace('..', '.')).join('. ');
          formik && (yield call(formik.setErrors, formikError));
        } else {
          message = 'Ошибка сервера';
        }
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(financeActions.updatePaymentFail(error));
    formik && message && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
