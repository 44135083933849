import { createActions } from 'redux-actions';

export const { reExposePayments, sendInvoicesList, sendActs } = createActions(
  {
    reExposePayments: (invoice, invoicesBaseId, bookingId) => ({
      invoice, invoicesBaseId, bookingId
    }),
    sendInvoicesList: invoices => invoices,
    sendActs: (invoices, demo, date) => ({ invoices, demo, date }),
  }
);
