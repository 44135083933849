import { all, call, put, race, take } from 'redux-saga/effects';
import * as R from 'ramda';

import { tfActions } from 'bus/tf/actions';
import { getErrorMessage, parseErrorsByFormik, isAPIError } from 'api/fn/parseErrors';

import { updateTfImage, updateTFProfileInfo } from '../../actions';
import { newInstance } from '../../../../../../localization';

const LOGO_TYPE = 'logo_image';
const IMAGE_PATH = 'logo_image_url';

const isSetViolations = errorBody => R.call(
  R.pipe(R.prop('violations'), violations => violations && !R.isEmpty(violations)),
  errorBody
);

// TODO_ERROR
export function* updateTfProfileWorkerMediator({ payload }) {
  const { file, formik, nextProfile } = payload;

  yield put(updateTFProfileInfo(nextProfile));
  file && (yield put(updateTfImage(file, LOGO_TYPE, IMAGE_PATH)));

  const effects = [
    race([
      take(tfActions.updateTfProfileSuccess),
      take(tfActions.updateTfProfileFail)
    ]),
    file
      ? race([
        take(tfActions.uploadTfImageSuccess),
        take(tfActions.uploadTfImageFail)
      ])
      : []
  ];

  const [
    [successedUpdateTfProfile, failedUpdateTfProfile], [, failedUploadTfImage]
  ] = yield all(effects);

  const errors = [];

  failedUploadTfImage && errors.push(
    getErrorMessage(
      failedUploadTfImage.payload,
      {
        400: 'Ошибка при загрузке изображения',
        403: 'Пользователь не имеет права обновлять турфирму',
      }
    )
  );

  failedUpdateTfProfile && errors.push(
    getErrorMessage(
      failedUpdateTfProfile.payload,
      {
        400: ({ body }) => R.or(
          R.call(
            R.when(
              isSetViolations,
              ({ violations }) => R.call(
                ({ otherErrors }) => !R.isEmpty(otherErrors) && R.call(
                  R.pipe(R.map(R.prop('message')), R.join('. ')),
                  otherErrors
                ),
                parseErrorsByFormik(formik.values, violations)
              )
            ),
            body
          ),
          R.prop('detail', body)
        ),
        403: 'Пользователь не имеет права обновлять турфирму',
      }
    )
  );

  if (failedUpdateTfProfile && isAPIError(failedUpdateTfProfile.payload)) {
    const { body } = failedUpdateTfProfile.payload.msg;

    R.call(
      R.when(
        isSetViolations,
        ({ violations }) => R.call(
          ({ errorsByValues }) => !R.isEmpty(errorsByValues) && formik.setErrors(errorsByValues),
          parseErrorsByFormik(formik.values, violations)
        )
      ),
      body
    );
  }

  const completedErrors = R.filter(Boolean, errors);

  successedUpdateTfProfile && R.isEmpty(completedErrors) && (
    yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') })
  );

  !R.isEmpty(completedErrors) && (
    yield call(formik.setStatus, { error: true, message: R.join(' ', completedErrors) })
  );

  yield call(formik.setSubmitting, false);
}
