import { Map } from 'immutable';
import moment from 'moment';

// instruments
import { removeNull } from 'helpers';

const DATE_FORMAT = 'DD.MM.YYYY';

export const createQueryFromApi = query => {
  const isCheckEmpty = (q, key, prefix = 'Empty') => (q[`${key}${prefix}`] ? null : true);
  const dateCreator = value => (moment(value, DATE_FORMAT, true).isValid() ? moment(value, DATE_FORMAT).format('YYYY-MM-DD') : null);

  const QUERY_CREATORS = {
    claimManager: (key, value) => [key, Number(value) ? value : null],
    clientManager: (key, value) => {
      if (value === 'none') {
        return ['clientManagerEmpty', true];
      }

      return [key, isCheckEmpty(query, 'clientManager') && Number(value) ? value : null];
    },
    location: (key, value) => (value.apiParam ? [value.apiParam, isCheckEmpty(query, 'locationIs') && value[value.apiParam]] : [key, null]),
    countryId: (key, value) => [key, isCheckEmpty(query, 'claimCountryIs') && Number(value) ? value : null],
    hotel: (key, value) => (value.apiParam ? [value.apiParam, isCheckEmpty(query, 'claimHotelIs') && value[value.apiParam]] : [key, null]),
    claimCreatedFrom: (key, value) => [key, dateCreator(value)],
    claimCreatedTo: (key, value) => [key, dateCreator(value)],
    clientCreatedFrom: (key, value) => [key, dateCreator(value)],
    clientCreatedTo: (key, value) => [key, dateCreator(value)],
    bonusDateFrom: (key, value) => [key, dateCreator(value)],
    bonusDateTo: (key, value) => [key, dateCreator(value)],
    flightDateFrom: (key, value) => [key, isCheckEmpty(query, 'flightDate') && dateCreator(value)],
    flightDateTo: (key, value) => [key, isCheckEmpty(query, 'flightDate') && dateCreator(value)],
    returnDateFrom: (key, value) => [key, isCheckEmpty(query, 'returnDate') && dateCreator(value)],
    returnDateTo: (key, value) => [key, isCheckEmpty(query, 'returnDate') && dateCreator(value)],
    clientCity: (key, value) => (value.apiParam
      ? [value.apiParam, isCheckEmpty(query, key) && value[value.apiParam]]
      : [key, null]),
    birthdayFrom: (key, value) => [key, isCheckEmpty(query, 'birthday') && dateCreator(value)],
    birthdayTo: (key, value) => [key, isCheckEmpty(query, 'birthday') && dateCreator(value)],
    lastEventFrom: (key, value) => [key, isCheckEmpty(query, 'lastEvent') && dateCreator(value)],
    lastEventTo: (key, value) => [key, isCheckEmpty(query, 'lastEvent') && dateCreator(value)],
    claimPassport: (key, value) => [key, isCheckEmpty(query, key) && value],
    contractDateFrom: (key, value) => [key, isCheckEmpty(query, 'contractDate') && dateCreator(value)],
    contractDateTo: (key, value) => [key, isCheckEmpty(query, 'contractDate') && dateCreator(value)],
    operatorId: (key, value) => [key, isCheckEmpty(query, 'operator') && Number(value) ? value : null],
    claimSourceId: (key, value) => [key, isCheckEmpty(query, 'claimSource') && Number(value) ? value : null],
    offices: (key, value) => [key, isCheckEmpty(query, 'office') && value],
    regions: (key, value) => [key, isCheckEmpty(query, 'region') && value],
    claimActive: (key, value) => [key, value === 'all' ? null : value],
  };

  return Map(query)
    .filter((value, key) => {
      const excludedParams = ['claimActive', 'claimExists', 'notSendMarketing', 'emailEmpty', 'canSendSMS', 'bonus_date_empty'];

      return excludedParams.includes(key) || value;
    })
    .mapEntries(([key, value]) => {
      return QUERY_CREATORS[key] ? QUERY_CREATORS[key](key, value) : [key, value];
    })
    .toJS();
};

export const createGroupTypesData = groupTypes => {
  const [firstType, secondType] = groupTypes;

  return [removeNull({
    name: firstType,
    children: secondType ? createGroupTypesData(groupTypes.slice(1)) : null,
  })];
};
