import { createActions } from 'redux-actions';

export const mainActions = createActions({
  FETCH_BIRTHDAYS: date => date,
  FETCH_BIRTHDAYS_SUCCESS: (byProfiles, byPassports) => ({ byProfiles, byPassports }),
  FETCH_BIRTHDAYS_FAIL: error => error,

  FETCH_STOPPED_EPASSPORTS: date => date,
  FETCH_STOPPED_EPASSPORTS_SUCCESS: data => data,
  FETCH_STOPPED_EPASSPORTS_FAIL: error => error,

  FETCH_CALLS: (startDate, page) => ({ startDate, page }),
  FETCH_CALLS_SUCCESS: (count, limit, statistics, calls) => ({ count, limit, statistics, calls }),
  FETCH_CALLS_FAIL: error => error,

  FETCH_REMINDS: () => undefined,
  FETCH_REMINDS_SUCCESS: (reminds, total) => ({ reminds, total }),
  FETCH_REMINDS_FAIL: error => error,

  SET_REMINDS_QUERY: (name, value) => ({ name, value }),

  FETCH_FAST_STATISTIC: date => date,
  FETCH_FAST_STATISTIC_SUCCESS: statistic => statistic,
  FETCH_FAST_STATISTIC_FAIL: error => error,

  dismissRemind: (claimID, remindID, onSuccess) => ({ claimID, remindID, onSuccess }),

  RESET_PAGE: () => undefined,
}, { prefix: 'MT_MAIN' });
