import React, { memo, useCallback, useMemo } from 'react';
import { Dropdown, Divider } from 'semantic-ui-react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link, matchPath, generatePath } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { authActions } from 'bus/auth/actions';

import book from 'routes/book';

import { ContextMenuWrapper } from 'components/wrappers/ContextMenuWrapper';
import { CopyWrapper } from 'components/wrappers/CopyWrapper';

import { getCrmsPermission } from 'crms/utils';

import SettingsIcon from 'assets/icons/settings.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import ContactIcon from 'assets/icons/contacts.svg';
import ProfileIcon from 'assets/icons/user.svg';
import OutIcon from 'assets/icons/out.svg';
import CommissionIcon from 'assets/icons/commission.svg';
import NewsIcon from 'assets/icons/news.svg';
import guardIcon from 'assets/icons/guard.svg';
import YoutubeIcon from 'assets/icons/youtube.svg';
import AccessIcon from 'assets/icons/access.svg';
import CopyIcon from 'assets/icons/materialIcons/content_copy_20.svg';
import SettingsDesktop from 'assets/icons/materialIcons/settings_20.svg';
import SettingsMobile from 'assets/icons/materialIcons/settings_24.svg';

import { CRM_TYPES } from 'crms/constants';

import schoolIcon from './img/school.svg';
import bankIcon from './img/bank.svg';
import tourIcon from './img/tourIcon.svg';
import reports from './img/reports.svg';
import malling from './img/malling.svg';

import styles from './styles.scss';

const getUrls = () => {
  return [
    {
      text: 'TF_SETTINGS',
      url: book.details.path,
      permissionCrm: [],
      icon: <div
        className={classnames('c-icon', styles.settingsIcon)}
        dangerouslySetInnerHTML={{ __html: SettingsIcon }}
      />,
    },
    {
      text: __CRM__ === CRM_TYPES.nakanikuly ? 'MY_COMMISSIONS_NK' : 'MY_COMMISSIONS',
      url: book.commission,
      permissionCrm: ['tat', 'nakanikuly', 'multitour'],
      icon: <div
        className={classnames('c-icon', styles.commissionIcon)}
        dangerouslySetInnerHTML={{ __html: CommissionIcon }}
      />,
    },
    {
      text: __CRM__ === CRM_TYPES.nakanikuly ? 'TO_ACCESS_NK' : 'TO_ACCESS',
      url: (__CRM__ === CRM_TYPES.nakanikuly || __CRM__ === CRM_TYPES.multitour)
        ? book.commissionAccess
        : book.details.children.downloadBooking.children.auto,
      permissionCrm: ['tat', '5stars', 'nakanikuly', 'multitour'],
      icon: <div
        className={classnames('c-icon', styles.accessIcon)}
        dangerouslySetInnerHTML={{ __html: AccessIcon }}
      />,
    },
    {
      text: 'NEWS',
      url: __CRM__ === CRM_TYPES.tat ? `${book.news.path}?id=1` : book.news.path,
      permissionCrm: [],
      icon: <div
        className={classnames('c-icon', styles.newsIcon)}
        dangerouslySetInnerHTML={{ __html: NewsIcon }}
      />,
    },
    {
      text: __CRM__ === CRM_TYPES.nakanikuly ? 'NK-School' : 'TRAINING',
      url: `${book.news.path}?news_selection=3`,
      permissionCrm: ['tat', 'nakanikuly', 'multitour'],
      icon: <div
        className="c-icon"
        dangerouslySetInnerHTML={{ __html: schoolIcon }}
      />,
    },
    {
      text: 'PROMOTIONAL_TOURS',
      url: generatePath(book.news.children.single, { id: 157 }),
      permissionCrm: ['tat'],
      icon: <div
        className="c-icon"
        dangerouslySetInnerHTML={{ __html: tourIcon }}
      />,
    },
    {
      text: 'INSURANCE',
      url: generatePath(book.news.children.single, { id: 78 }),
      permissionCrm: ['tat'],
      icon: <div
        className="c-icon"
        dangerouslySetInnerHTML={{ __html: guardIcon }}
      />,
    },
    {
      text: 'BANK_GUARANTEE',
      url: generatePath(book.news.children.single, { id: 107 }),
      permissionCrm: ['tat'],
      icon: <div
        className="c-icon"
        dangerouslySetInnerHTML={{ __html: bankIcon }}
      />,
    },
    {
      text: 'MT_YOUTUBE',
      url: 'https://www.youtube.com/channel/UCv8iXGKpc1DIXMmnQpVhZHQ',
      permissionCrm: ['tat', 'mt'],
      icon: <div
        className="c-icon"
        dangerouslySetInnerHTML={{ __html: YoutubeIcon }}
      />,
      externalSource: true,
    },
    {
      text: 'CALENDAR',
      url: book.calendar,
      permissionCrm: [],
      icon: <div
        className={classnames('c-icon', styles.calendarIcon)}
        dangerouslySetInnerHTML={{ __html: CalendarIcon }}
      />,
    },
    {
      text: 'REPORTS',
      url: book.stat.path,
      permissionCrm: ['nakanikuly', 'multitour'],
      icon: <div
        className={classnames('c-icon')}
        dangerouslySetInnerHTML={{ __html: reports }}
      />,
    },
    {
      text: 'MAILING_LISTS',
      url: book.marketing.path,
      permissionCrm: ['nakanikuly', 'multitour'],
      icon: <div
        className={classnames('c-icon')}
        dangerouslySetInnerHTML={{ __html: malling }}
      />,
    },
    {
      text: 'CO_CONTACTS',
      url: generatePath(book.news.children.single, { id: 1 }),
      permissionCrm: ['5stars', 'nakanikuly', 'multitour'],
      icon: <div
        className={classnames('c-icon')}
        dangerouslySetInnerHTML={{ __html: ContactIcon }}
      />,
    }
  ];
};

const isMobile = matchMedia('(max-width: 767px)').matches;

export const UserMenu = () => {
  const [tfID, userID, username, logoutUI] = useSelector(({ auth, ui }) => [
    auth.getIn(['profile', 'tf', 'id']),
    auth.getIn(['profile', 'id']),
    auth.getIn(['profile', 'username']),
    ui.getIn(['auth', 'logout'])
  ], shallowEqual);

  const urls = useMemo(() => {
    return getUrls().filter(({ permissionCrm }) => getCrmsPermission(permissionCrm));
  }, []);

  // methods
  const dispatch = useDispatch();
  const logOut = useCallback(e => {
    e.stopPropagation();

    dispatch(authActions.logOut(false, { UI: true }));
  });
  const { t } = useTranslation(['USER_MENU', 'SERVICES_TYPES']);

  return (
    <div className={styles.root}>
      <ContextMenuWrapper className={styles.wrapper}>
        <Dropdown
          icon={(
            <div
              className={styles.userLogo}
              dangerouslySetInnerHTML={{ __html: isMobile ? SettingsMobile : SettingsDesktop }}
              title={t('NEWS_AND_SETTING')}
            />
          )}
          id={styles.userDropdown}
          pointing="top left"
        >
          <Dropdown.Menu
            direction="left"
          >
            {urls.map(({ text, url, icon, externalSource }, idx) => (
              <Dropdown.Item
                active={Boolean(matchPath(window.location.pathname, { path: url, exact: true }))}
                as={externalSource ? 'a' : Link}
                content={t(text)}
                icon={icon}
                key={idx}
                {...externalSource ? { href: url, target: '_blank' } : { to: url }}
              />
            ))}
            <Dropdown.Item
              content={t('EXIT')}
              disabled={logoutUI.loading}
              icon={logoutUI.loading
                ? { name: 'spinner', loading: logoutUI.loading }
                : (
                  <div
                    className={classnames('c-icon', styles.outIcon)}
                    dangerouslySetInnerHTML={{ __html: OutIcon }}
                  />
                )}
              onClick={logOut}
            />
            <Divider />
            <div className={styles.dropdownFooter}>
              {userID && (
                <Link
                  className={styles.userName}
                  title={t('MY_PROFILE')}
                  to={generatePath(book.details.children.settingUser, { id: userID })}
                >
                  <div
                    className={classnames('c-icon', styles.profileIcon)}
                    dangerouslySetInnerHTML={{ __html: ProfileIcon }}
                  />
                  { username }
                </Link>
              )}
              <CopyWrapper timeout={500} copyText={tfID}>
                <div className={styles.id} onClick={e => e.stopPropagation()}>
                  ID
                  {' '}
                  {tfID}
                  <span
                    className={classnames('c-icon', styles.copyIcon)}
                    dangerouslySetInnerHTML={{ __html: CopyIcon }}
                  />
                </div>
              </CopyWrapper>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </ContextMenuWrapper>
    </div>
  );
};

export default memo(UserMenu);
