import React, { memo } from 'react';
import { Grid } from 'semantic-ui-react';

// assets
import cx from 'classnames';
import Octopus from './assets/octopus.svg';

// styles
import Styles from './styles.scss';

export const ErrorWrapper = memo(({ className, children }) => {
  return (
    <div className="mtContainer">
      <Grid className={cx(Styles.root, className, 'wrapper')}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column className={Styles.content} width={9}>
            { children }
          </Grid.Column>
          <Grid.Column textAlign="right" width={7}>
            <div className={Styles.octopus} dangerouslySetInnerHTML={{ __html: Octopus }} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
});
