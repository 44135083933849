import { put, call, select } from 'redux-saga/effects';

import { bookingActions as actions } from 'bus/booking/actions';
import { getToken } from 'bus/auth/selectors';

import { removeMessageViewed } from 'api/methods/booking/messages';

export function* removeMessageViewWorker({ payload }) {
  const { viewId, messageId, onSuccess } = payload;

  const token = yield select(getToken);

  try {
    yield call(removeMessageViewed, token, { pathParams: { messageId, viewId } });

    yield put(actions.removeMessageViewedSuccess(messageId, viewId));
    onSuccess && onSuccess();
  } catch (error) {
    console.log(error);
  }
}
