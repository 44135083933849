export const base = '/tf';
export const admin = '/admin';

export default Object.freeze({
  main: base,
  client: {
    path: `${base}/client`,
    children: {
      lead: `${base}/client/lead`,
      booking: `${base}/client/booking`,
      bookingPage: `${base}/client/booking/:id(\\d+)`,
      payments: `${base}/client/payments`,
      courses: `${base}/client/courses`,
    },
  },
  addClient: `${base}/client/add`,
  details: {
    path: `${base}/details`,
    children: {
      profile: {
        path: `${base}/details/profile`,
        children: {
          office: `${base}/details/profile/office`,
        },
      },
      templates: {
        path: `${base}/details/templates`,
        children: {
          docNumber: `${base}/details/templates/docNumber`,
          fields: `${base}/details/templates/fields`,
          invoices: `${base}/details/templates/invoices`,
          collections: `${base}/details/templates/collections`,
          tourOperators: `${base}/details/templates/tourOperators`,
        },
      },
      notifications: {
        path: `${base}/details/notifications`,
        children: {
          notify: `${base}/details/notifications/notify`,
          sms: `${base}/details/notifications/sms`,
          mailing: `${base}/details/notifications/mailing`,
          others: `${base}/details/notifications/others`,
        },
      },
      orders: {
        path: `${base}/details/orders`,
        children: {
          claimSource: `${base}/details/orders/claimSource`,
          tags: `${base}/details/orders/tags`,
          closeReasons: `${base}/details/orders/closeReasons`,
        },
      },
      requisites: {
        path: `${base}/details/requisites`,
        children: {
          docs: `${base}/details/requisites/docs`,
          merchants: `${base}/details/requisites/merchants`,
          payments: `${base}/details/requisites/payments`,
          bonuses: `${base}/details/requisites/bonuses`,
        },
      },
      integration: {
        path: `${base}/details/integration`,
        children: {
          ip: `${base}/details/integration/ip`,
          facebook: `${base}/details/integration/facebook`,
          emails: `${base}/details/integration/emails`,
          api: `${base}/details/integration/api`,
        },
      },

      settingUser: `${base}/details/user/:id(\\d+)`,
      addUser: `${base}/details/user/add`,
      editDoc: `${base}/details/editdoc/:id?`,
      editInvoice: `${base}/details/invoice/:id?`,
      notify: `${base}/details/notify`,
      dump: {
        path: `${base}/details/dump`,
        children: {
          import: `${base}/details/dump/import`,
          export: `${base}/details/dump/export`,
          facebook: `${base}/details/dump/facebook`,
          emails: `${base}/details/dump/emails`,
        },
      },
      downloadBooking: {
        path: `${base}/details/downloadBooking`,
        children: {
          auto: `${base}/details/downloadBooking/auto`,
          plugin: `${base}/details/downloadBooking/plugin`,
        },
      },
      subscription: `${base}/details/subscription`,
      importAndExport: `${base}/details/importAndExport`,
    },
  },
  login: `${base}/login`,
  register: `${base}/register`,
  forget: `${base}/forget`,
  clientClaim: {
    path: `${base}/client/:clientId`,
    children: {
      claim: `${base}/client/:clientId(\\d+)/claim/:claimId(\\d+)`,
      profile: `${base}/client/:clientId(\\d+)/profile`,
      file: `${base}/client/:clientId(\\d+)/file`,
      createClaim: `${base}/client/:clientId(\\d+)/addClaim`,
    },
  },
  fin: {
    path: `${base}/fin`,
    children: {
      type: `${base}/fin/:type`,
      expense: `${base}/fin/expense/:id`,
      income: `${base}/fin/income/:id`,
      rates: `${base}/fin/rates`,
    },
  },
  tourscanner: {
    path: `${base}/tourscanner`,
    children: {
      cart: `${base}/tourscanner/cart`,
      rates: `${base}/tourscanner/rates`,
      collections: `${base}/tourscanner/collections`,
      offer: `${base}/tourscanner/offer/:hotelId(\\d+)/:offerId`,
      offerV2: `${base}/tourscanner/offer/:offerId`,
    },
  },
  stat: {
    path: `${base}/stat`,
    children: {
      report: `${base}/stat`,
      calls: `${base}/stat/calls`,
      log: `${base}/stat/log`,
      directors: `${base}/stat/directors`,
      salesFunnel: `${base}/stat/salesFunnel`,
      bonuses: {
        path: `${base}/stat/bonuses`,
        children: {
          managers: `${base}/stat/bonuses`,
          calculation: `${base}/stat/bonuses/calculation`,
        },
      },
    },
  },
  marketing: {
    path: `${base}/marketing`,
    children: {
      add: {
        path: `${base}/marketing/add`,
        children: {
          update: `${base}/marketing/add/:id`,
        },
      },
      preview: `${base}/marketing/preview/:id`,
    },
  },
  news: {
    path: `${base}/news`,
    children: {
      single: `${base}/news/:id`,
    },
  },
  editor: {
    children: {
      filebrowser: `${base}/editor/filebrowser`,
    },
  },
  commission: `${base}/commission`,
  commissionAccess: `${base}/commission/access`,
  calendar: `${base}/calendar`,
  admin: `${admin}`,
  adminLogin: `${admin}/login`,
  adminBooking: {
    path: `${admin}/booking`,
    children: {
      booking: `${admin}/booking/:id(\\d+)`,
      add: `${admin}/booking/add`,
    },
  },
  adminAccounting: {
    path: `${admin}/accounting`,
    children: {
      invoices: `${admin}/accounting/invoices`,
      akt: `${admin}/accounting/akt`,
    },
  },
  apiDoc: {
    login: '/api/doc/login',
  },
  charters: {
    path: `${base}/charters`,
  },
  busTickets: {
    path: `${base}/bus_tickets`,
  },
  airtickets: {
    path: `${base}/airtickets`,
  },
  rates: {
    path: `${base}/rates`,
  },
  privacyPolicy: {
    path: '/privacy_policy',
  }
});
