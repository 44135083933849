import React, { memo } from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { getCrmTypes } from 'crms/utils';

import styles from 'components/employees/card/styles.scss';
import { CardWrapper } from 'components/ui/wrappers/CardWrapper';

import book from 'routes/book';

const BlockAccessTSBanner = () => {
  const { t } = useTranslation(['TS', 'ADVERB']);
  const { is5Stars, isMt, isTat, isNakanikuly } = getCrmTypes();

  return (
    <div className="wrapper">
      <Grid style={{ color: 'red' }}>
        <Grid.Row>
          <Grid.Column centered className={styles.root} textAlign="center" width={16}>
            <CardWrapper className={styles.wrapper}>
              {is5Stars && (
                <>
                  { t('SUBSCRIPTION_IS_LIMITED') }
                  <br />
                  { t('EASY_TO_FIX') }
                  {' '}
                  <Link to={generatePath(book.news.children.single, { id: 1 })}>
                    { t('CALL_US') }
                  </Link>
                  .
                </>
              )}
              {(isTat || isMt) && (
                <>
                  <p>{t('SUB_NOT_ALLOW_TS')}</p>
                  <p>{t('SEND_TO_US')}</p>
                  <p>
                    {t('OR')}
                    {' '}
                    <Link to={book.details.children.subscription}>
                      {' '}
                      {t('PAY_TO_SUB')}
                    </Link>
                    {' '}
                  </p>
                  <p>{t('IN_BOTTOM_PAGE')}</p>
                </>
              )}
              {isNakanikuly && (
                <>
                  <p>{t('SUB_NOT_ALLOW_TS_NK')}</p>
                  <p>
                    {t('SEND_TO_US_NK')}
                    <a href="mailto:support@nakanikuly.com.ua">support@nakanikuly.com.ua</a>
                    {t('SEND_TO_US_NK_SECOND')}
                  </p>
                  <p className={styles.phones}>
                    <a href="tel:+380986614000">
                      +380986614000
                    </a>
                    <a href="tel:+380996614000">
                      +380996614000
                    </a>
                    <a href="tel:+380936614000">
                      +380936614000
                    </a>
                  </p>
                  <p>{t('BOTTOM_TEXT_NK')}</p>
                </>
              )}
            </CardWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default memo(BlockAccessTSBanner);
