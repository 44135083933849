import { put, take, race } from 'redux-saga/effects';

// instuments
import { notifyActions } from 'bus/notify';
import { uiActions } from 'bus/ui/actions';

export function* prepareEmptyNotifyWorker({ payload }) {
  const { type, notify: emptyNotify } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['notify', 'prepareNotify', emptyNotify.id],
  }));
  yield put(notifyActions.getDefaultNotifyValue(type));

  const [success, fail] = yield race([
    take(notifyActions.getDefaultNotifyValueSuccess),
    take(notifyActions.getDefaultNotifyValueFail)
  ]);

  if (success) {
    yield put(notifyActions.prepareEditNotify({ ...emptyNotify, ...success.payload }));
  }

  if (fail) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['notify', 'prepareNotify', emptyNotify.id, 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['notify', 'prepareNotify', emptyNotify.id, 'message'],
    }));
  }

  yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: ['notify', 'prepareNotify', emptyNotify.id, 'loading'],
  }));
}
