import { call, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { copyToBufferDynamicContent } from 'helpers';

import { getToken, getUserOrTfLocale, getUserTfCurrencyCode } from 'bus/auth/selectors';

import { getOfferFromBasketTemplate } from 'api/methods/tourscaner/baskets';

import { prepareStringToCopy } from '../../../helpers';

const TEMPLATE_NAME = 'tours6';

const createBaseError = error => {
  return new Error(error.msg.body.message);
};

export function* copyOfferFromBasketToBufferSaga({
  payload: { callbacks, offer, basket, extra },
}) {
  const token = yield select(getToken);
  const defaultUserCurrency = yield select(getUserTfCurrencyCode);
  const defaultLocale = yield select(getUserOrTfLocale);

  try {
    yield call(
      copyToBufferDynamicContent,
      () => getOfferFromBasketTemplate(
        token,
        {
          pathParams: {
            offer: extra.id,
            basketId: basket.id,
            type: TEMPLATE_NAME
          },
          queryParams: {
            currency: R.propOr(defaultUserCurrency, 'currencyLocal', offer),
            locale: R.propOr(defaultLocale, 'locale', basket)
          },
        }
      )
        .then(prepareStringToCopy)
        .then(R.tap(callbacks.onSuccess))
    );
  } catch (error) {
    callbacks && callbacks.onFail(
      createBaseError(error)
    );
  }
}
