import { deletePathFromStore, getStorage, GROUPS, setInGroupValue } from '../storage';

import { LAST_CLAIM_UPDATE, CLAIMS_STEPS } from './constants';

export const getLasClaimsUpdate = () => getStorage().getIn([GROUPS.clientClaim, LAST_CLAIM_UPDATE]);
export const setLastClaimsUpdate = date => setInGroupValue(
  [GROUPS.clientClaim, LAST_CLAIM_UPDATE],
  date
);

export const getClaimStep = claimID => getStorage().getIn([GROUPS.clientClaim, CLAIMS_STEPS, claimID]);

export const setClaimStep = (claimID, step) => setInGroupValue(
  [GROUPS.clientClaim, CLAIMS_STEPS, claimID],
  step
);
export const deleteClaimsSteps = () => deletePathFromStore([GROUPS.clientClaim, CLAIMS_STEPS]);

export const setClaimStepToStorage = (claimID, step) => {
  setClaimStep(claimID, step);
};
