import { call, select, put } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getNotificationTypes } from 'api/methods/common/notifications';

export function* getNotificationTypesWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: '' },
    path: ['common', 'notificationTypes'],
  }));

  try {
    const types = yield call(getNotificationTypes, token);

    yield put(commonActions.getNotificationTypesSuccess(types));
  } catch (error) {
    yield put(commonActions.getNotificationTypesFail(error));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['common', 'notificationTypes', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['common', 'notificationTypes', 'message'],
    }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['common', 'notificationTypes', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['common', 'notificationTypes', 'completed'],
    }));
  }
}
