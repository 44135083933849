import BaseApiClass from 'api/BaseApiClass';
import * as R from 'ramda';

export const createDigitalContract = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts',
  method: 'POST',
  ...params,
})._load({ token });

export const getDigitalContracts = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts',
  method: 'GET',
  ...params,
})._load({ token });

export const getDigitalContract = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}/digital_contracts/{:contractId}',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = ({ sign, ...rest }) => ({
    ...rest,
    sign: R.call(
      R.when(
        Boolean,
        ({
          client_name: clientName,
          date_sign: dateSign,
          ...otherParams
        }) => ({ clientName, dateSign, ...otherParams })
      ),
      sign),
  });

  return instance._load({ token });
};
export const updateDigitalContract = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}/digital_contracts/{:contractId}',
    method: 'PUT',
    ...params,
  });

  instance.parseBodyToClientSchema = ({ sign, ...rest }) => ({
    ...rest,
    sign: R.call(
      R.when(
        Boolean,
        ({
          client_name: clientName,
          date_sign: dateSign,
          ...otherParams
        }) => ({ clientName, dateSign, ...otherParams })
      ),
      sign),
  });

  return instance._load({ token });
};

export const fetchDigitalContractText = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/html_text',
  method: 'GET',
  ...params,
})._load({ token });

export const sendDigitalContractToEmail = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/{:contractId}/send_email',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteDigitalContract = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/{:contractId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getDiiaKeyFile = (params = {}) => new BaseApiClass({
  outerResource: '/files/tf_files/digital_contracts/{:id}/{:file}',
  method: 'POST',
  ...params,
});

export const generateDigitalContractLink = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/digital_contracts/{:contractId}/generate_link',
  method: 'PUT',
  ...params,
})._load({ token });

export const getContractToFormat = (params = {}) => new BaseApiClass({
  url: 'claims/{:claimId}/digital_contracts/{:contractId}.{:format}',
  method: 'GET',
  ...params,
});
