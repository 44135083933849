import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { MyError } from 'api/fn/Error';
import { financeActions } from 'bus/finance/actions';
import { sendPaymentToEmail } from 'api/methods/finance/sendPaymentToEmail';

export function* sendPaymentToEmailWorker({ payload }) {
  const { id, body, formikAPI } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  yield call(formikAPI.setSubmitting, true);

  try {
    const { mail_send: isSend } = yield call(
      sendPaymentToEmail,
      token,
      { pathParams: { id }, bodyParams: body }
    );

    if (!isSend) {
      throw new MyError({ status: 500 });
    }

    yield put(financeActions.sendPaymentToEmailSuccess());
    yield call(formikAPI.setStatus, { success: true });
  } catch (error) {
    const { status, body: bodyError } = error.msg;

    let message = null;

    switch (status) {
      case 403: {
        message = get(bodyError, 'message', 'Отсутствует доступ');

        break;
      }
      case 500: {
        message = 'Ошибка при отправке письма';

        break;
      }
      default: message = 'Ошибка при отправке письма';
    }

    yield put(financeActions.sendPaymentToEmailFail(error));
    yield call(formikAPI.setStatus, { error: { message } });
  } finally {
    yield call(formikAPI.setSubmitting, false);
  }
}
