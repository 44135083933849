import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';
import { getBookingId } from 'bus/booking/selectors';
import { getToken } from 'bus/auth/selectors';

import { deleteBookingMessage } from 'api/methods/booking/messages';

const BASE_UI_PATH = ['booking', 'deleteMessage'];

export function* deleteBookingMessageWorker({ payload: messageId }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...BASE_UI_PATH, messageId],
  }));

  try {
    yield call(deleteBookingMessage, token, { pathParams: { id: bookingId, messageId } });

    yield put(actions.deleteBookingMessageSuccess(messageId));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Пользователь не имеет права удалять это сообщение или оно уже удалено');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, messageId, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, messageId, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, messageId, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, messageId, 'completed'] }));
  }
}
