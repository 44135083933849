import { put, race, take, call } from 'redux-saga/effects';
import { getIn } from 'immutable';

// instruments
import { commonActions } from 'bus/common/actions';
import { uiActions } from 'bus/ui/actions';

export function* createTagWorker({ payload }) {
  const { tag, formik } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: '' },
    path: ['tf', 'createTags', tag.id],
  }));
  yield put(commonActions.createTag(tag.name));

  const [success, fail] = yield race([
    take(commonActions.createTagSuccess),
    take(commonActions.createTagFail)
  ]);

  if (fail) {
    const { payload: error } = fail;
    const { status, body } = error.msg || {};

    let message = '';

    switch (status) {
      case 400: {
        const isHaveErrors = getIn(body, ['violations', 'length']);

        if (isHaveErrors) {
          const violations = getIn(body, ['violations']);
          const errorByName = violations.find(item => item.property_path === 'name');
          const otherErrors = violations.filter(item => item.property_path !== 'name');

          if (errorByName) {
            const indexErrorField = formik.values.data.findIndex(item => item.id === tag.id);

            yield call(formik.setFieldError, `data.${indexErrorField}.name`, errorByName.message);
            yield call(formik.setFieldTouched, `data.${indexErrorField}.name`, true, false);
          }

          if (otherErrors.length) {
            message = otherErrors.map(item => item.message.replace('.', '')).join('. ');
          }
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'createTags', tag.id, 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['tf', 'createTags', tag.id, 'message'],
    }));
  }

  if (success) {
    const { payload: newTag } = success;
    const updateTagIndex = formik.values.data.findIndex(item => item.id === tag.id);

    yield call(formik.setFieldValue, `data.${updateTagIndex}`, newTag);
  }

  yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: ['tf', 'createTags', tag.id, 'loading'],
  }));
}
