import { select, put, call } from 'redux-saga/effects';

// instruments
import { commonActions } from 'bus/common/actions';
import { updateRegion } from 'api/methods/common/regions';

export function* updateRegionWorker({ payload }) {
  const { id, name } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const office = yield call(updateRegion, token, { pathParams: { id }, bodyParams: { name } });

    yield put(commonActions.updateRegionSuccess(office.id, office.name));
  } catch (error) {
    yield put(commonActions.updateRegionFail(error));
  }
}
