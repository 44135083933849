import { select, call } from 'redux-saga/effects';
import * as R from 'ramda';

import { getToken, getUserId } from 'bus/auth/selectors';

import { sendBasket } from 'api/methods/tourscaner/baskets';

export function* sendBasketWorker({ payload }) {
  const { callbacks, basketId, values } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userId = yield select(getUserId);

  try {
    yield call(
      sendBasket,
      token,
      { pathParams: { userId, basketId }, bodyParams: R.pick(['emails', 'title'], values) }
    );

    onSuccess && onSuccess();
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
