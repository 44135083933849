import { select, call, put } from 'redux-saga/effects';
import { Map, get } from 'immutable';

import { updateClient } from 'api/methods/client/updateClient';

import { clientClaimActions } from 'bus/clientClaim/actions';

import { parseErrorPath } from 'helpers';

import { newInstance } from 'localization';

export function* updateClientWorker({ payload }) {
  const { clientID, values, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const { client } = yield call(
      updateClient,
      token,
      { pathParams: { id: clientID }, bodyParams: values }
    );

    yield put(clientClaimActions.updateClientSuccess(client));
    formik && (yield call(formik.setStatus, { success: { message: newInstance.t('GLOBAL:SAVE_SUCCESS') } }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const { client, ipassport } = body;

        let formikErrors = Map();

        const createErrorObject = (res, item) => {
          const path = parseErrorPath(item.property_path).split('.');

          return res.setIn(path, item.message);
        };

        if (client && client.violations) {
          formikErrors = client.violations.filter(item => !parseErrorPath(item.property_path).split('.').includes('fields_data')).reduce(createErrorObject, formikErrors);
          formikErrors = client.violations
            .filter(item => parseErrorPath(item.property_path).split('.').includes('fields_data'))
            .map(item => {
              const [key, id, param] = parseErrorPath(item.property_path).split('.');
              const index = values.fields_data.findIndex(({ field }) => field === Number(id));

              return { property_path: `${key}[${index}].${param}`, message: item.message };
            }).reduce(createErrorObject, formikErrors);
          formikErrors = client.violations
            .filter(item => parseErrorPath(item.property_path).split('.').includes('fullphones'))
            .map(item => {
              const [, id] = parseErrorPath(item.property_path).split('.');

              return { property_path: `phones[${id}].number`, message: item.message };
            })
            .reduce(createErrorObject, formikErrors);
        }

        if (ipassport && ipassport.violations) {
          formikErrors = ipassport.violations
            .map(item => ({ property_path: `ipassport.${item.property_path}`, message: item.message }))
            .reduce(createErrorObject, formikErrors);
        }

        if (!formikErrors.isEmpty()) {
          formik && (yield call(formik.setErrors, formikErrors.toJS()));
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Нет доступа к данному клиенту');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Клиент не найден');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && message && (yield call(formik.setStatus, { error: { message } }));
    yield put(clientClaimActions.updateClientFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
