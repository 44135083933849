import moment from 'moment';
import { List, Map, Set } from 'immutable';
import { createQuery, QUERY_PARAMS } from '@otpusk/apisearch-toolbox/dist/queries/fn';

export const convertOfferAndHotelToInternalOffer = (
  hotel,
  offer,
  operator,
  currency = offer.currency
) => {
  const children = offer.people?.children?.length
    ? offer.people.children.map(String).map(age => `0${age}`.slice(-2)).join('')
    : '';
  const people = `${offer.people.adults}${children}`;

  return {
    last: moment().format('YYYY-MM-DD HH:mm:ss'),
    i: offer.id,
    oi: offer.operator,
    s: offer.promo,
    c: offer.departure,
    d: offer.date,
    o: offer.includes,
    dt: moment(offer.date).add(offer.nights, 'days').format('YYYY-MM-DD'),
    y: offer.room.type,
    a: offer.adults,
    h: offer.children,
    ha: offer.childrenAge,
    l: offer.days,
    n: offer.nights,
    nih: offer.nightsInHotel,
    f: offer.food,
    r: offer.room.name,
    p: currency in offer.price ? offer.price[currency] : null,
    u: currency,
    pl: offer.price[offer.currencyLocal],
    t: offer.transport,
    to: {
      from: offer.flights.outbound,
      to: offer.flights.inbound,
    },
    ss: offer.stopsale,
    dc: moment().format('YYYY-MM-DD HH:mm:ss'),
    hi: hotel.id,
    ti: hotel.country.id,
    frc: offer.departure,
    ppl: people,
    op: operator ? operator.name : '',
    cti: hotel.city.id,
    cnm: hotel.country.name,
    ccc: hotel.country.isoCode,
    ctn: hotel.city.name,
    cnt: hotel.city.code,
    hnm: hotel.name,
    hnt: hotel.code,
    ul: offer.currencyLocal,
    nh: offer.nightsInHotel,
    ah: people
  };
};

export function convertStringToQuery(raw, countries) {
  const params = Map(
    decodeURIComponent(raw)
      .split('&')
      .map(pair => pair.split('='))
  );
  const toSet = v => Set(v.split(',').filter(id => Boolean(id)));
  const toCheckboxesMap = (param, value) => createQuery().get(param).map((v, k) => value.split(',').includes(k));

  const convertParams = (query, value, name) => {
    switch (name) {
      case 'af': return query.set(QUERY_PARAMS.FLIGHT_AVAILABILITY, toSet(value));
      case 'c': return query.setIn([QUERY_PARAMS.DATES, 'from'], moment(value, 'YYYY-MM-DD+HH:mm:ss'));
      case 'v': return query.setIn([QUERY_PARAMS.DATES, 'to'], moment(value, 'YYYY-MM-DD+HH:mm:ss'));
      case 'e': return query.set(QUERY_PARAMS.SERVICES, toSet(value));
      case 'f': return query.set(QUERY_PARAMS.DEPARTURES, List().push(value));
      case 'i': return query.set(QUERY_PARAMS.COUNTRY, value);
      case 't': {
        const country = countries.find(({ name: countryName }) => countryName === value);

        return country
          ? query.set(QUERY_PARAMS.COUNTRY, country.id).set(QUERY_PARAMS.CURRENCY, country.currency)
          : query;
      }
      case 'l': return query.setIn([QUERY_PARAMS.DURATION, 'from'], Number(value) + 1);
      case 'lt': return query.setIn([QUERY_PARAMS.DURATION, 'to'], Number(value) + 1);
      case 'o': return query.set(QUERY_PARAMS.FOOD, toCheckboxesMap(QUERY_PARAMS.FOOD, value));
      case 'r': return query.set(
        QUERY_PARAMS.TRANSPORTS,
        createQuery()
          .get(QUERY_PARAMS.TRANSPORTS)
          .map(transports => transports.map((_, code) => value.split(',').includes(code)))
      );
      case 'pc': return query.set(QUERY_PARAMS.CURRENCY, value);
      case 'pf': return Number(value) ? query.setIn([QUERY_PARAMS.PRICE, 'from'], Number(value)) : query;
      case 'pt': return Number(value) ? query.setIn([QUERY_PARAMS.PRICE, 'to'], Number(value)) : query;
      case 'ss': return query.set(QUERY_PARAMS.HOTEL_AVAILABILITY, toSet(value));
      case 'noPromo': return query.set(QUERY_PARAMS.WITHOUT_SPO, value === 'true');
      case 'st': return query.set(QUERY_PARAMS.CATEGORY, toCheckboxesMap(QUERY_PARAMS.CATEGORY, value));
      case 'tc': return query.set(QUERY_PARAMS.CITIES, toSet(value));
      case 'tf': return query.set(QUERY_PARAMS.OPERATORS, toSet(value));
      case 'th': return query.set(QUERY_PARAMS.HOTELS, toSet(value));
      case 'rt':
        const [from = 0, to = 10] = value.split(',');

        return query.setIn([QUERY_PARAMS.RATING, 'from'], from)
          .setIn([QUERY_PARAMS.RATING, 'to'], to);
      case 'p':
        const adults = Number(value.slice(0, 1));
        const children = value.slice(1).match(/.{2}/g);

        return query
          .set(QUERY_PARAMS.ADULTS, adults)
          .set(QUERY_PARAMS.CHILDREN, List(children ? children : []).map(Number));
      default: return query;
    }
  };

  return params
    .reduce(convertParams, createQuery())
    .filter(value => Boolean(value))
    .toObject();
}
