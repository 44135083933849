import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getUI } from 'bus/ui/selectors';

import { GET_BONUSES_STAT_UI } from './workers/constants';

const domain = ({ statistic }) => statistic;

export const getSalesFunnel = createSelector(domain, statistic => statistic.get('salesFunnel'));
export const getBonusesQuery = createSelector(domain, statistic => statistic.getIn(['bonuses', 'query']));
export const getBonusesPage = createSelector(domain, statistic => statistic.getIn(['bonuses', 'page']));

export const getSalesFunnelData = createSelector(getSalesFunnel, R.prop('data'));

export const getReportBonusesUI = R.partialRight(getUI, [{ key: GET_BONUSES_STAT_UI }]);
