import React, { useMemo, useCallback } from 'react';
import { Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Styles from './styles.scss';

const parseCountry = ({ country, location_label: locationLabel }) => (country ? country.name : locationLabel);
const parsePeople = ({ adults, cnt_child: children }) => `${adults}+${children}`;
const parseDate = ({ date_start: dateStart }) => (dateStart ? moment(dateStart).format('DD MMMM YYYY') : '');
const parseHotel = ({ hotel }) => (hotel ? `, ${hotel.name}` : '');
const parseHotelWithCountry = ({ hotel, location_label: locationLabel, country }) => (!hotel && country && locationLabel !== country.name ? `, ${locationLabel}` : '');

export const createLabel = data => `${parseCountry(data)} ${parsePeople(data)} ${parseDate(data)}${parseHotel(data)}${parseHotelWithCountry(data)}`;

export const ClaimPicker = ({ activeClaim, claims, onChange }) => {
  const { t } = useTranslation('CLAIM');
  const options = useMemo(() => {
    return claims
      .sort((a, b) => moment(b.created) - moment(a.created))
      .sort((a, b) => b.active - a.active)
      .map(({ id, active, ...rest }) => {
        const label = createLabel(rest);

        return {
          value: id,
          text: label,
          content: (
            <>
              <div className={cx(Styles.option, { [Styles.active]: active })}>
                { label }
                <span className={Styles.orderStatus}>{ t(active ? 'IS_OPEN' : 'IS_CLOSE') }</span>
              </div>
            </>
          ),
        };
      });
  }, [claims, t]);

  const isOne = useMemo(() => options.length === 1, [options]);
  const icon = useMemo(
    () => (isOne ? null : { name: 'chevron down', className: Styles.iconArrow }),
    [isOne]
  );
  const activeClaimTitle = useMemo(() => {
    const claim = options.find(option => Number(activeClaim) === option.value);

    return claim ? claim.text : '';
  }, [activeClaim, claims]);

  const handleChange = useCallback((...[, { value }]) => onChange(value), [onChange]);

  return (
    <div className={cx(Styles.root, !isOne && Styles.multipl)}>
      <Dropdown
        fluid
        icon={icon}
        id={Styles.dropdown}
        open={isOne ? false : undefined}
        options={options}
        selectOnBlur={false}
        selectOnNavigation={false}
        trigger={(
          <div className={Styles.trigger}>
            <span className={Styles.selectedOption}>{ activeClaimTitle }</span>
          </div>
        )}
        value={Number(activeClaim)}
        onChange={handleChange}
      />
    </div>
  );
};

ClaimPicker.displayName = 'ClaimPicker';
