import { put, call, select } from 'redux-saga/effects';

import { accountingActions as actions } from 'bus/accounting/actions';
import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { getCounterpartyInfo } from 'api/methods/accounting';
import { getErrorMessage } from 'api/fn/parseErrors';

import { GET_COUNTERPARTY_INFO_UI } from '../../constants';

function* getCounterpartyInfoSaga({ payload: counterpartyId }) {
  const token = yield select(getToken);

  try {
    const data = yield call(getCounterpartyInfo, token, { pathParams: { counterpartyId } });

    yield put(actions.geCounterpartyInfoSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Контрагент не найден',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(actions.geCounterpartyInfoFail(error));
  }
}

export default withUIWorker(
  [actions.geCounterpartyInfoSuccess, actions.geCounterpartyInfoFail],
  GET_COUNTERPARTY_INFO_UI,
)(getCounterpartyInfoSaga);
