import { select, call } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { removeOfferFromBasket } from 'api/methods/tourscaner/baskets';

export function* removeOfferFromBasketWorker({ payload }) {
  const { callbacks, id, basketID } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userId = yield select(getUserId);

  try {
    yield call(
      removeOfferFromBasket,
      token,
      { pathParams: { userId, basketId: basketID, offerId: id } }
    );

    onSuccess && onSuccess();
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
