export const NEW_MONEY_TYPES = {
  SOURCE_PRICE: 'source_price', // spo_to_ver
  SOURCE_PRICE_LOCAL: 'source_price_local', // spo_to_ver_l
  SOURCE_TO_PAY: 'source_to_pay', // to_price_to_ver
  SOURCE_TO_PAY_LOCAL: 'source_to_pay_local', // to_price_to_ver_l
  SOURCE_PAID: 'source_paid', // to_paid
  SOURCE_PAID_LOCAL: 'source_paid_local', // to_paid_l
  SOURCE_TO_COMMISSION: 'source_to_commission', // to_commission
  SOURCE_TO_PAY_CO_TO: 'source_to_pay_co_to', // e_to_price_co_ver
  PRICE: 'price', // e_spo_co_ver
  PRICE_LOCAL: 'price_local', // e_spo_co_ver_l
  SERVICES_WITH_NO_PERCENT: 'services_with_no_percent', // e_services_no_pc
  CO_COMMISSION_PERCENT: 'co_commission_percent', // e_co_percent_pc
  TA_COMMISSION_PERCENT: 'ta_commission_percent', // e_tf_percent_pc
  RATE: 'rate', // e_rate
  TO_PAY_CO_TA: 'to_pay_co_ta', // payment_co_ta
  TO_PAY_TO_CO: 'to_pay_to_co', // payment_to_co
  TO_PAY_TA_CO: 'to_pay_ta_co', // e_co_price
  TO_PAY_CO_TO: 'to_pay_co_to', // e_to_price_co_pay
  CURRENCY: 'currency',

  PAID_TA_CO: 'paid_ta_co',
  PAID_TA_CO_LOCAL: 'paid_ta_co_local',

  PAID_CO_TA: 'paid_co_ta',
  PAID_CO_TA_LOCAL: 'paid_co_ta_local',

  PAID_CO_TO: 'paid_co_to',
  PAID_CO_TO_LOCAL: 'paid_co_to_local',

  PAID_TO_CO: 'paid_to_co',
  PAID_TO_CO_LOCAL: 'paid_to_co_local',

  CALCULATED_TARGET_MARGIN: 'calculated_target_margin',
  CALCULATED_PROFIT: 'calculated_profit',
  CALCULATED_PROFIT_LOCAL: 'calculated_profit_local',
};
export const EXCLUDE_MONEY_TO_SERVER = [
  NEW_MONEY_TYPES.PAID_TA_CO,
  NEW_MONEY_TYPES.PAID_CO_TA,
  NEW_MONEY_TYPES.PAID_CO_TO,
  NEW_MONEY_TYPES.PAID_TO_CO,
  NEW_MONEY_TYPES.CALCULATED_TARGET_MARGIN,
  NEW_MONEY_TYPES.CALCULATED_PROFIT,
  NEW_MONEY_TYPES.CALCULATED_PROFIT_LOCAL,
  NEW_MONEY_TYPES.PAID_TA_CO_LOCAL,
  NEW_MONEY_TYPES.PAID_CO_TA_LOCAL,
  NEW_MONEY_TYPES.PAID_CO_TO_LOCAL,
  NEW_MONEY_TYPES.PAID_TO_CO_LOCAL,
  'debt_co_ta',
  'debt_co_to',
  'debt_ta_co',
  'debt_to_co',
  'calculated_target_margin_local'
];
