import BaseApiClass from 'api/BaseApiClass';
import { Map } from 'immutable';

export const deleteFile = (token, params) => new BaseApiClass({
  url: 'clients/files/{:fileId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getFiles = (token, params) => new BaseApiClass({
  url: 'clients/{:clientId}/files',
  method: 'GET',
  ...params,
})._load({ token });

export const createFiles = (token, params) => {
  const instance = new BaseApiClass({
    url: 'clients/{:clientId}/files',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};

export const updateFile = (token, params) => {
  const instance = new BaseApiClass({
    url: 'clients/files/{:fileId}',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};
