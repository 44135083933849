import BaseApiClass, { LOCALE_HEADER_KEY } from 'api/BaseApiClass';

export const logining = ({ token, locale }, params = {}) => {
  const instance = new BaseApiClass({
    url: 'login',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => ({ ...defaultHeaders, [LOCALE_HEADER_KEY]: locale });

  return instance._load({ token });
};

export const logout = (token, params = {}) => new BaseApiClass({
  url: 'v2/logout',
  method: 'POST',
  ...params,
})._load({ token });

export const getInfo = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'me',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = ({ collections_api: collectionsAPI, ...rest }) => ({
    ...rest,
    collectionsAPI,
  });

  return instance._load({ token });
};

export const register = (params = {}) => new BaseApiClass({
  url: 'public/tfs',
  method: 'POST',
  ...params,
})._load({ token: null });

export const validateEmail = (params = {}) => {
  return new BaseApiClass({
    url: 'public/tfs/check_email',
    method: 'GET',
    ...params,
  })._load({ token: null });
};

export const createRegisterCode = (params = {}) => new BaseApiClass({
  url: 'public/tfs/code',
  method: 'POST',
  ...params,
})._load({ token: null });

export const forget = ({ token }, params = {}) => new BaseApiClass({
  url: 'forget',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });

export const removeFacebook = (token, params = {}) => new BaseApiClass({
  url: 'settings/users/{:user}/facebook',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
