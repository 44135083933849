import { select } from 'redux-saga/effects';

import { getLocale } from 'bus/auth/selectors';

function* copyOfferURLToBufferSaga({ payload }) {
  const { callbacks, baseOfferURL, offer } = payload;

  const locale = yield select(getLocale);

  callbacks.onSuccess(`${baseOfferURL}?locale=${locale}&currency=${offer.currency}`);
}

export { copyOfferURLToBufferSaga };
