import { select, call, put, race, take } from 'redux-saga/effects';

import { authActions } from 'bus/auth/actions';

import { getToken, getUserId } from 'bus/auth/selectors.js';
import { deleteUserTelegram } from 'api/methods/tf/user';
import { registerUI, updateUI } from 'bus/ui/actions.js';
import { createUi } from 'bus/ui/helpers.js';

import { getErrorMessage } from 'api/fn/parseErrors.js';

export const TELEGRAM_UI_KEY = 'TELEGRAM';

export function* deleteTfUserTelegramWorker() {
  const token = yield select(getToken);
  const id = yield select(getUserId);

  try {
    yield put(registerUI(createUi({ loading: true }), TELEGRAM_UI_KEY));
    yield call(deleteUserTelegram, token, { pathParams: { id } });
    yield put(authActions.fetchMyself());

    yield race([
      take(authActions.fetchMyselfSuccess),
      take(authActions.logOut)
    ]);
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Пользователь не найден',
      }
    );

    yield put(updateUI({ message, error: true }, TELEGRAM_UI_KEY));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, TELEGRAM_UI_KEY));
  }
}
