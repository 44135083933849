import * as R from 'ramda';

export const getIsInitialSchedules = R.pipe(
  R.head,
  R.prop('IS_INITIAL'),
);

export const prepareSchedules2Create = R.map(
  R.applySpec({
    date: R.prop('date'),
    amount: R.prop('amount'),
    /* need to reset template_id */
    template_id: R.always(null),
  }),
);
export const prepareSchedules2Update = R.map(
  R.applySpec({
    id: R.prop('id'),
    date: R.prop('date'),
    amount: R.prop('amount'),
    /* need to reset template_id */
    template_id: R.always(null),
  }),
);
