import { createActions } from 'redux-actions';

export const subscriptionActions = createActions({
  GET_SUBSCRIPTION: workerOptions => ({ workerOptions }),
  GET_SUBSCRIPTION_SUCCESS: (defaultPlans, activePlan, lastPlan, subscriptionTypes, currency) => (
    { defaultPlans, activePlan, lastPlan, subscriptionTypes, currency }
  ),
  GET_SUBSCRIPTION_FAIL: [error => error, (...[, processedError]) => processedError],
  RESET_SUBSCRIPTION_PLANS: () => undefined,

  GET_BALANCES: workerOptions => ({ workerOptions }),
  GET_BALANCES_SUCCESS: balances => balances,
  GET_BALANCES_FAIL: [error => error, (...[, processedError]) => processedError],
  RESET_BALANCES: () => [],

  GET_BALANCE_AMOUNT: workerOptions => ({ workerOptions }),
  GET_BALANCE_AMOUNT_SUCCESS: amount => amount,
  GET_BALANCE_AMOUNT_FAIL: [error => error, (...[, processedError]) => processedError],
  RESET_BALANCE_AMOUNT: () => [],

  GET_BALANCE_INVOICES: workerOptions => ({ workerOptions }),
  GET_BALANCE_INVOICES_SUCCESS: balances => balances,
  GET_BALANCE_INVOICES_FAIL: error => error,
  RESET_BALANCE_INVOICES: () => [],

  CREATE_SUBSCRIPTION: (params, formik) => ({ params, formik }),
  CREATE_SUBSCRIPTION_SUCCESS: subscription => subscription,
  CREATE_SUBSCRIPTION_FAIL: error => error,
  RESET_SUBSCRIPTION: () => null,

  UNSUBSCRIPTION: () => null,

  CREATE_BALANCE_CHARGE: (values, formik) => ({ values, formik }),
  CREATE_BALANCE_CHARGE_SUCCESS: charge => charge,
  CREATE_BALANCE_CHARGE_FAIL: error => error,
  RESET_BALANCE_CHARGE: () => null,

  GET_BALANCE_INFO: balanceId => balanceId,
  GET_BALANCE_INFO_SUCCESS: balance => balance,
  GET_BALANCE_INFO_FAIL: error => error,
  RESET_BALANCE_INFO: () => null,

}, { prefix: 'MT_SUBSRIPTION' });
