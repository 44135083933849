import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insturments
import { importAnalyze } from 'api/methods/dump';
import { dumpActions } from 'bus/dump/actions';
import { uiActions } from 'bus/ui/actions';

const BASE_UI_PATH = ['dump', 'import', 'analyze'];

export function* importAnalyzeWorker({ payload }) {
  const { id, page, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  })));

  try {
    const response = yield call(
      importAnalyze,
      token,
      { pathParams: { id }, queryParams: { page } }
    );

    yield put(dumpActions.importAnalyzeSuccess(response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      case 410: {
        message = get(body, 'message', 'Задача устарела. Необходимо запустить новый анализ импорта');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
