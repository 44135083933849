import { MyError } from './fn/Error';

export const AUTH_TOKEN_HEADER_KEY = 'X-AUTH-TOKEN';
export const LOCALE_HEADER_KEY = 'X-LOCALE';

const DEV_HOSTS = ['new.moituristy.dev07.odev.io', 'localhost'];

const AUTHORIZATION = DEV_HOSTS.includes(window.location.hostname) ? { Authorization: 'Basic ZGV2OmRldg==' } : {};

const DEFAULT_HEADERS = {
  Accept: 'application/json;q=0.9',
  'Content-Type': 'application/json',
  ...AUTHORIZATION,
};
const API = '/api/';
const DELAY_SERVER_UNAVAILABLE = 10 * 1000; // 10 seconds
const LIMIT_REQUEST = 4;

export default class BaseApiClass {
  constructor({
    url,
    method,
    successCode = 200,
    pathParams = null,
    queryParams = null,
    bodyParams = null,
    customHeaders = {},
    outerResource
  }) {
    this._url = outerResource ? outerResource : API + url;
    this.method = method;
    this._successCode = successCode;
    this._pathParams = pathParams;
    this._queryParams = queryParams;
    this._bodyParams = bodyParams;
    this.customHeaders = customHeaders;
  }

  _load = async ({ token }, countRequest = 1) => {
    const url = this._proccessUrl();
    const body = this._proccessBody();

    const TOKEN_HEADER = token ? { [AUTH_TOKEN_HEADER_KEY]: token } : {};

    try {
      const response = await fetch(url, {
        method: this.method,
        headers: { ...this.proccessHeaders(DEFAULT_HEADERS), ...TOKEN_HEADER, ...this.customHeaders },
        ...body,
      });

      const { status } = response;

      if (status === BaseApiClass.statusServerUnavailable && LIMIT_REQUEST > countRequest) {
        await BaseApiClass.delay(DELAY_SERVER_UNAVAILABLE);

        const res = await this._load({ token }, Number(countRequest + 1));

        return res;
      }

      if (status === this._successCode) {
        const responseBody = await BaseApiClass.getResponseBody(response);

        return this.parseBodyToClientSchema(responseBody);
      }

      throw response;
    } catch (responseError) {
      const { status } = responseError;
      const responseBody = await BaseApiClass.getResponseBody(responseError);

      throw new MyError({ status, body: responseBody });
    }
  }

  _proccessUrl() {
    let url = this._url;

    if (this._pathParams) {
      url = BaseApiClass.compilePathParams(url, this._pathParams);
    }

    if (this._queryParams) {
      const queryParams = this.convertQueryToServerSchema(this._queryParams);

      url += '?';
      url = Object.keys(queryParams).reduce((string, paramKey, index) => {
        const value = queryParams[paramKey];
        const ampersand = index ? '&' : '';

        return `${string}${ampersand}${paramKey}=${value}`;
      }, url);
    }

    return url;
  }

  getURL = () => {
    return this._proccessUrl();
  }

  _proccessBody = () => {
    if (!this._bodyParams) {
      return {};
    }

    if (this._bodyParams instanceof FormData) {
      return { body: this._bodyParams };
    }

    if (typeof this._bodyParams !== 'object') {
      throw new Error('[bodyParams] is not a Object!!!');
    }

    return { body: JSON.stringify(this.convertBodyToServerSchema(this._bodyParams)) };
  }

  convertBodyToServerSchema = bodyParams => {
    // this is abstract method
    return bodyParams;
  }

  setConvertBodyToServerSchema = cb => {
    this.convertBodyToServerSchema = cb;

    return this;
  }

  convertQueryToServerSchema = queryParams => {
    // this is abstract method
    return queryParams;
  }

  setConvertQueryToServerSchema = cb => {
    this.convertQueryToServerSchema = cb;

    return this;
  }

  parseBodyToClientSchema = data => {
    // this is abstract method
    return data;
  }

  setParseBodyToClientSchema = cb => {
    this.parseBodyToClientSchema = cb;

    return this;
  }

  setProccessHeaders = cb => {
    this.proccessHeaders = cb;

    return this;
  }

  proccessHeaders = baseHeaders => {
    // this is abstract method

    return baseHeaders;
  }

  static delay = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static statusServerUnavailable = 503;

  static compilePathParams = (url, pathParams) => {
    if (typeof pathParams !== 'object') {
      // eslint-disable-next-line no-throw-literal
      throw new Error('[pathParams] is not a Object');
    }

    return Object.keys(pathParams).reduce((string, paramKey) => {
      const value = pathParams[paramKey];

      if (!string.includes(`{:${paramKey}}`)) {
        // eslint-disable-next-line no-throw-literal
        throw new Error(`This paramKey [${paramKey}] dont find in this string [${string}]`);
      }

      return string.replace(`{:${paramKey}}`, value);
    }, url);
  }

  static getResponseBody = async response => {
    const { status, headers } = response;
    const contentType = headers.get('content-type');

    if (status === 204) {
      return null;
    }

    let parseMethods = '';

    switch (contentType) {
      case 'application/json':
      case 'application/json; charset=utf-8':
      {
        parseMethods = 'json';
        break;
      }
      default:
        parseMethods = 'text';
    }

    try {
      const body = await response[parseMethods]();

      return body;
    } catch (error) {
      __DEV__ && console.info(error);

      return null;
    }
  }
}
