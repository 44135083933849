import { newInstance } from 'localization';

export const SEND_INVOICES_UI_KEY = 'SEND_INVOICES_UI_KEY';
export const RESULT_SEND_MANY_AKTS_UI = 'RESULT_SEND_MANY_AKTS_TYPE';

export const RESULT_INVOICES_TYPE = 'RESULT_INVOICES_TYPE';
export const RESULT_AKTS_DEMO_TYPE = 'RESULT_AKTS_DEMO_TYPE';
export const RESULT_SEND_MANY_AKTS_TYPE = 'RESULT_SEND_MANY_AKTS_TYPE';

export const LIMIT_OPTIONS = [
  { text: 50, value: 50 },
  { text: 100, value: 100 },
  { text: 200, value: 200 }
];

export const ORDER_ITEMS = [
  {
    icon: 'sort content ascending',
    get text() {
      return newInstance.t('INVOICES_ADMIN_PAGE:BY_ACT_CREATION_DATE'); // замените на нужный ключ
    },
    value: 'date.desc'
  },
  {
    icon: 'sort content ascending',
    get text() {
      return newInstance.t('INVOICES_ADMIN_PAGE:COLUMN_COUNTERPARTY_AZ'); // замените на нужный ключ
    },
    value: 'counterparty.asc'
  },
  {
    icon: 'sort content ascending',
    get text() {
      return newInstance.t('INVOICES_ADMIN_PAGE:COLUMN_COUNTERPARTY_ZA'); // замените на нужный ключ
    },
    value: 'counterparty.desc'
  }
];
