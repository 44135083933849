import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { GOOGLE_TAG_MANAGER_ID } from './constants';

const GoogleTagManagerProvider = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({
        gtmId: GOOGLE_TAG_MANAGER_ID,
        dataLayerName: 'dataLayer',
      });
    }
  }, []);

  useEffect(() => {
    if (pathname && GOOGLE_TAG_MANAGER_ID) {
      TagManager.dataLayer({
        dataLayer: {
          page: pathname,
        },
        dataLayerName: 'dataLayer',
      });
    }
  }, [pathname]);

  return null;
};

export default memo(GoogleTagManagerProvider);
