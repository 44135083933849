import { createActions } from 'redux-actions';

const PREFIX = 'SAVED_SEARCHES';

export const { startSaveSearch, startRemoveSearch, saveSearch, removeSearch } = createActions({
  startSaveSearch: (title, onSuccess) => ({ title, onSuccess }),
  startRemoveSearch: searchId => searchId,
  saveSearch: searchEntity => searchEntity,
  removeSearch: searchId => searchId,
}, { prefix: PREFIX });
