import { call, put } from 'redux-saga/effects';

// instruments
import { validateEmail } from 'api/methods/auth/user';
import { authActions as actions } from 'bus/auth/actions';

export function* validateEmailWorker({ payload: email }) {
  try {
    const { available } = yield call(validateEmail, { queryParams: { email } });

    if (available) {
      yield put(actions.validateEmailSuccess());
    } else {
      throw new Error('Пользователь с таким email уже существует');
    }
  } catch (error) {
    yield put(actions.validateEmailFailed(error));
  }
}
