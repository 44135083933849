import * as R from 'ramda';

export const userMapper = ({ notifications = [], photo_url: userImage, ...rest }) => ({
  notifications: R.call(
    R.map(({ status_email: statusEmail, status_telegram: statusTelegram, ...restOptions }) => ({
      statusEmail,
      statusTelegram,
      ...restOptions,
    })),
    notifications
  ),
  userImage,
  ...rest,
});
