import { Map } from 'immutable';

// instruments
import BaseApiClass from 'api/BaseApiClass';

export const getBookingMessages = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/messages',
  method: 'GET',
  ...params,
})._load({ token });

export const getBookingMessagesTemplates = (token, params = {}) => new BaseApiClass({
  url: 'messages/users/{:userID}',
  method: 'GET',
  ...params,
})._load({ token });

export const createBookingMessage = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'bookings/{:id}/messages',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};

export const deleteBookingMessage = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/messages/{:messageId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getMessagesCountUnread = (token, params = {}) => new BaseApiClass({
  url: 'bookings/messages/count_unread',
  method: 'GET',
  ...params,
})._load({ token });

export const updateMessageViewed = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/messages/{:messageId}/viewed',
  method: 'POST',
  ...params,
})._load({ token });

export const removeMessageViewed = (token, params = {}) => new BaseApiClass({
  url: 'message/{:messageId}/view/{:viewId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
