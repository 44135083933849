import * as R from 'ramda';

import { RATES_OF_CONFIRMATION, EXTERNAL_FIELDS_PROPS, REDUCED_PASSPORT_FORMAT, STANDARD_WITH_TIN_AND_CYRILLIC_PASSPORT_FORMAT, STANDARD_WITH_TIN_PASSPORT_FORMAT } from './constants';

export const getExternalFieldsByType = R.cond([
  [R.equals(STANDARD_WITH_TIN_PASSPORT_FORMAT), () => R.pick(['id_number'], EXTERNAL_FIELDS_PROPS)],
  [R.equals(STANDARD_WITH_TIN_AND_CYRILLIC_PASSPORT_FORMAT), () => R.pick(
    ['id_number', 'name_local', 'surname_local', 'patronymic_local'], EXTERNAL_FIELDS_PROPS)
  ],
  [R.T, R.always({})],
]);

export const getDisabledFields = R.cond([
  [R.equals(REDUCED_PASSPORT_FORMAT), R.always({
    passportGivenDate: true,
    passportIssued: true,
  })],
  [R.T, R.always({})],
]);

export const getRateOfConfirmation = R.cond([
  [R.equals(1), R.always(RATES_OF_CONFIRMATION.LOW)],
  [R.equals(2), R.always(RATES_OF_CONFIRMATION.HIGH)],
  [R.T, R.always(null)],
]);
