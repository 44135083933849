import { call, put, select } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors.js';

import { commonActions } from 'bus/common/actions';
import { withUIWorker } from 'bus/ui/helpers';
import { GET_OPERATOR_REQUEST_LOGIN_UI } from 'bus/common/constants.js';

import { getOperatorRequestLogin } from 'api/methods/common/operators';

import { showToastError, showToastSuccess } from 'services/toaster';

import { newInstance } from 'localization';

function* getOperatorRequestLoginSaga({ payload: operatorId }) {
  const token = yield select(getToken);

  try {
    yield call(getOperatorRequestLogin, token, { pathParams: { operatorId } });

    yield put(commonActions.getOperatorRequestLoginSuccess());

    showToastSuccess(newInstance.t('COMMISSION:REQUEST_RECEIVED'), { duration: 8000 });
  } catch (error) {
    yield put(commonActions.getOperatorRequestLoginError(error));
    showToastError();
  }
}

export const getOperatorRequestLoginSagaWithUI = withUIWorker(
  [commonActions.getOperatorRequestLoginSuccess, commonActions.getOperatorRequestLoginError],
  GET_OPERATOR_REQUEST_LOGIN_UI,
)(getOperatorRequestLoginSaga);
