import { Map } from 'immutable';
import { handleActions, combineActions } from 'redux-actions';

// instruments
import { marketingActions as actions } from './actions';

const initialState = Map({
  mailings: {
    data: {
      results: [],
      limit: null,
      total: null,
      newSubscribers: {},
    },
  },
  newSubscribers: {},
  subscribers: [],
  mailing: {
    data: null,
    search: [],
    totalClients: null,
  },
  mailsOffers: '',
});

export default handleActions({
  [actions.getMailingsSuccess]: (state, { payload: data }) => state.setIn(['mailings', 'data'], data),
  [actions.clearMailings]: state => state.setIn(['mailings', 'data'], { results: [], limit: null, count: null, newSubscribers: [] }),
  [actions.deleteMailingSuccess]: (state, { payload: mailingId }) => {
    return state
      .updateIn(['mailings', 'data', 'results'], results => results.filter(({ id }) => id !== mailingId))
      .updateIn(['mailings', 'data', 'total'], total => total - 1);
  },
  [actions.refreshMailingSuccess]: (state, { payload: { id: mailingId, mailing } }) => {
    const index = state
      .getIn(['mailings', 'data', 'results'])
      .findIndex(({ id }) => id === mailingId);

    return state
      .setIn(['mailings', 'data', 'results', index], mailing);
  },

  [combineActions(
    actions.getMailingSubscribersSuccess,
    actions.clearMailingSubscribers
  )]: (state, { payload: subscribers }) => state.set('subscribers', subscribers),

  [actions.searchMailingClientsSuccess]: (state, { payload: { values, total } }) => {
    return state
      .setIn(['mailing', 'search'], values)
      .setIn(['mailing', 'totalClients'], total);
  },
  [combineActions(
    actions.createMailingSuccess,
    actions.getMailingSuccess,
    actions.updateMailingSuccess
  )]: (state, { payload: { search, ...data } }) => {
    return state
      .setIn(['mailing', 'data'], data)
      .setIn(['mailing', 'search'], search);
  },
  [actions.clearMailing]: state => state.set('mailing', { data: null, search: [], totalClients: null }),
  [actions.sendMailingUpdateSuccess]: (state, { payload: id }) => state.updateIn(['mailings', 'data', 'newSubscribers'], items => {
    delete items[id];

    return items;
  }),
  [actions.getNewMailingSubscribersSuccess]: (state, { payload: { newSubscribers, id } }) => state.setIn(['newSubscribers', id], newSubscribers),
  [combineActions(actions.getMailsOffersSuccess, actions.clearMailsOffers)]: (state, { payload: text }) => state.set('mailsOffers', text),

}, initialState);
