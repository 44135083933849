import { select, put, call, delay, take, race } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

// actions
import { uiActions } from 'bus/ui/actions';
import { filesActions } from 'bus/files/actions';

// instruments
import { createFinanceJob } from 'api/methods/files';
import { MyError } from 'api/fn/Error';

const BASE_UI_PATH = ['files', 'financeJob'];

const DELAY = 5 * 1000; // 5 seconds

export function* createFinanceJobWorker({ payload }) {
  const { params = {} } = payload;

  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: BASE_UI_PATH,
  }));

  try {
    const { id } = yield call(createFinanceJob, token, { bodyParams: params });

    while (true) {
      yield delay(DELAY);

      yield put(filesActions.getStatusJobs(id));

      const [successJobs, failJobs] = yield race([
        take(filesActions.getStatusJobsSuccess),
        take(filesActions.getStatusJobsFail)
      ]);
      const successState = getIn(successJobs, ['payload', 'state']);

      if (successState === 'success') {
        break;
      }
      if (successState === 'fail') {
        throw new MyError({ status: 500 });
      }
      if (failJobs) {
        throw failJobs.payload;
      }
    }

    yield put(filesActions.createFinanceJobSuccess(id));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Необходимо иметь разрешение на доступ к разделу "Клиенты". Запрещено если активна подписка только на Турсканер');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
  }
}
