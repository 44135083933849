import { createActions } from 'redux-actions';

export const accountingActions = createActions({
  SEARCH: formik => ({ formik }),
  SEARCH_SUCCESS: data => data,
  SEARCH_FAIL: error => error,

  SET_QUERY: query => query,
  SET_PAGE: page => page,
  SET_LIMIT: limit => limit,
  setOrder: order => order,
  setSort: order => order,

  GET_PAYMENTS_RESULTS: () => undefined,
  GET_PAYMENTS_RESULTS_SUCCESS: results => results,
  GET_PAYMENTS_RESULTS_FAIL: error => error,

  GET_PAYMENT: id => ({ id }),
  GET_PAYMENT_SUCCESS: payment => payment,
  GET_PAYMENT_FAIL: error => error,

  CLEAR_PAYMENT: () => null,

  GET_DEFAULT_PAYMENT: values => ({ values }),
  GET_DEFAULT_PAYMENT_SUCCESS: defaultPayment => defaultPayment,
  GET_DEFAULT_PAYMENT_FAIL: error => error,

  mergePayment: payment => payment,

  getOutboxInvoiceBase: id => id,
  getOutboxInvoiceBaseSuccess: outboxInvoiceBase => outboxInvoiceBase,
  getOutboxInvoiceBaseFail: error => error,

  clearOutboxInvoiceBase: () => undefined,

  CREATE_PAYMENT: (values, formik, onSuccess) => ({ values, formik, onSuccess }),
  CREATE_PAYMENT_SUCCESS: payment => payment,
  CREATE_PAYMENT_FAIL: error => error,

  UPDATE_PAYMENT: (values, id, formik, onSuccess) => ({ values, id, formik, onSuccess }),
  UPDATE_PAYMENT_SUCCESS: payment => payment,
  UPDATE_PAYMENT_FAIL: error => error,

  DELETE_PAYMENT: (id, workerOptions) => ({ id, workerOptions }),
  DELETE_PAYMENT_SUCCESS: id => id,
  DELETE_PAYMENT_FAIL: error => error,

  GET_ACCOUNTS: () => undefined,
  GET_ACCOUNTS_SUCCESS: accounts => accounts,
  GET_ACCOUNTS_FAIL: error => error,

  GET_COUNTERPARTIES: () => undefined,
  GET_COUNTERPARTIES_SUCCESS: payload => payload,
  GET_COUNTERPARTIES_FAIL: error => error,

  geCounterpartyInfo: counterpartyId => counterpartyId,
  geCounterpartyInfoSuccess: data => data,
  geCounterpartyInfoFail: error => error,

  GET_CASH_FLOWS: () => undefined,
  GET_CASH_FLOWS_SUCCESS: payload => payload,
  GET_CASH_FLOWS_FAIL: error => error,

  GET_ORGANIZATIONS: () => undefined,
  GET_ORGANIZATIONS_SUCCESS: payload => payload,
  GET_ORGANIZATIONS_FAIL: error => error,

  GET_CONTRACTS: counterpartyId => counterpartyId,
  GET_CONTRACTS_SUCCESS: payload => payload,
  GET_CONTRACTS_FAIL: error => error,

  CLEAR_CONTRACTS: () => [],

  // invoices

  SEARCH_INVOICES: formik => ({ formik }),

  searchAkt: formik => ({ formik }),

  deleteAkt: id => id,
  deleteAktSuccess: list => list,

  getAkt: id => id,
  getAktSuccess: template => template,
  getAktFail: error => error,
  resetAkt: () => undefined,

  sendAkt: (id, emails, onSuccess) => ({ id, emails, onSuccess }),

  sendAkts: (invoices, demo, date) => ({ invoices, demo, date }),
  sendAktsSuccess: acts => acts,
  sendAktsFail: error => error,

  SWITCH_INVOICE_TEMPLATE_MODAL: bool => bool,
  SET_INVOICE_TEMPLATE_MODAL_VALUES: values => values,

  setInvoiceSendingModalValues: values => values,
  resetInvoiceSendingModalValues: () => undefined,

  GET_INVOICE_TEMPLATE: invoiceId => ({ invoiceId }),
  GET_INVOICE_TEMPLATE_SUCCESS: invoice => invoice,
  GET_INVOICE_TEMPLATE_FAIL: error => error,

  GET_INVOICE_DEFAULT: values => values,
  GET_INVOICE_DEFAULT_SUCCESS: defaultInvoice => defaultInvoice,
  GET_INVOICE_DEFAULT_FAIL: error => error,

  CLEAR_INVOICE: () => null,
  CLEAR_INVOICE_TEMPLATE: () => null,

  CREATE_INVOICE: (values, formik, callbacks) => ({ values, formik, callbacks }),
  CREATE_INVOICE_SUCCESS: invoice => invoice,
  CREATE_INVOICE_FAIL: error => error,

  reExposeInvoice: (values, invoiceId, formik, callbacks) => ({ values, invoiceId, formik, callbacks }),

  UPDATE_INVOICE: (values, invoiceId, formik) => ({ values, invoiceId, formik }),
  UPDATE_INVOICE_SUCCESS: invoice => invoice,
  UPDATE_INVOICE_FAIL: error => error,

  SEND_INVOICE: (values, invoiceId, formik) => ({ values, invoiceId, formik }),
  SEND_INVOICE_SUCCESS: (status, invoiceId) => ({ status, invoiceId }),
  SEND_INVOICE_FAIL: error => error,

  DELETE_INVOICE: (id, workerOptions) => ({ id, workerOptions }),
  DELETE_INVOICE_SUCCESS: invoiceId => invoiceId,
  DELETE_INVOICE_FAIL: error => error,

  GET_INVOICE_BASE: counterpartyId => counterpartyId,
  GET_INVOICE_BASE_SUCCESS: invoices => invoices,
  GET_INVOICE_BASE_CONTRACTS_FAIL: error => error,

  CLEAR_INVOICE_BASE: () => [],

  GET_FRANCHISE_INVOICES: () => undefined,
  GET_FRANCHISE_INVOICES_SUCCESS: franchise => franchise,
  GET_FRANCHISE_INVOICES_FAIL: error => error,

  SEND_FRANCHISE_INVOICES: (values, formik) => ({ values, formik }),
  SEND_FRANCHISE_INVOICES_SUCCESS: response => response,
  SEND_FRANCHISE_INVOICES_FAIL: error => error,

  CLEAR_FRANCHISE_INVOICES: () => null,

  SWITCH_FRANCHISE_MODAL: bool => bool,

  CREATE_INVOICE_PAYMENT_SUCCESS: () => undefined,

}, { prefix: 'MT_ACCOUNTING' });
