import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insruments
import { uiActions } from 'bus/ui/actions';
import { mainActions } from 'bus/main/actions';
import { getCalls } from 'api/methods/main/getCalls';

export function* fetchCallsWorker({ payload }) {
  const { startDate, page } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({ status: { loading: true, error: false, completed: false, message: null }, path: ['main', 'calls'] }));

  try {
    const {
      results,
      count,
      limit,
      statistics
    } = yield call(getCalls, token, { queryParams: { page, start: startDate } });

    yield put(mainActions.fetchCallsSuccess(count, limit, statistics, results));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', null) || 'Ошибка сервера';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(mainActions.fetchCallsFail(error));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['main', 'calls', 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['main', 'calls', 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['main', 'calls', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['main', 'calls', 'completed'] }));
  }
}
