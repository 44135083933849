import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { authActions } from 'bus/auth/actions';

export const useAutoAuth = () => {
  const isAuthenticate = useSelector(({ auth }) => auth.get('isAuthenticate'), shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticate) {
      dispatch(authActions.autoAuthenticate());
    }
  }, []);

  return null;
};
