import * as R from 'ramda';

export const UI_KEY_SUMMARY = 'UI_KEY_SUMMARY';
export const CLIENTS_SORT_TYPE = 'clientsSortType';
export const SEARCH_CLIENT_UI_KEY = 'searchClientAndClaim';
export const SEARCH_CLIENT_COOKIE_KEY = 'clientsFormFilters';
export const SEARCH_DIRECTOR_COOKIE_KEY = 'searchDirectorCookieKey';

export const EMAIL_VALUES = {
  1: { emailEmpty: true },
  2: { emailEmpty: false },
  3: { notSendMarketing: false },
  4: { notSendMarketing: true },
};

export const DEFAULT_CLIENTS_CLAIMS_VALUES = {
  s: '',
  tags: [],
  clientCityId: '',
  birthdayFrom: null,
  birthdayTo: null,
  claimPassport: '',
  email: null,

  claimManager: null,
  claimActive: 'all',
  claimCreatedFrom: null,
  claimCreatedTo: null,
  claimSourceId: '',
  claimScenarios: [],
  claimSteps: [],
  location: null,
  offices: [],
  regions: [],
  closing_reasons: [],

  countryId: null,
  hotel: '',
  flightDateFrom: null,
  flightDateTo: null,
  returnDateFrom: null,
  returnDateTo: null,

  operatorId: null,
  bookingClaimId: '',
  bonusDateFrom: null,
  bonusDateTo: null,
  claimStatus: 1,
  contractDateFrom: null,
  contractDateTo: null,
  contract_number: '',
};

export const ALLOW_CLIENTS_CLAIMS_FIELDS = R.keys(DEFAULT_CLIENTS_CLAIMS_VALUES);
