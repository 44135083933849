import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

import { subscriptionActions } from 'bus/subscription';
import { uiActions } from 'bus/ui/actions';

import { getSubscription } from 'api/methods/subscription';

const BASE_UI_PATH = ['subscription', 'fetch'];
const SUBSCRIPTION_TYPES = [{ name: 'CRM', value: 0 }, { name: 'CRM_TS', value: 1 }, { name: 'TS', value: 2 }];

export function* getSubscriptionWorker({ payload }) {
  const { workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  })));

  try {
    const { plans, active, last } = yield call(getSubscription, token);

    yield put(subscriptionActions.getSubscriptionSuccess(
      plans, active, last, SUBSCRIPTION_TYPES)
    );
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ закрыт');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, 'message'],
    })));

    yield put(subscriptionActions.getSubscriptionFail(error, { message }));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, 'completed'],
    })));
  }
}
