import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { getDoc } from 'api/methods/tf/docs';
import { uiActions } from 'bus/ui/actions';
import { detailDocsActions } from 'bus/detailDocs/actions';

export function* fetchDocWorker({ payload }) {
  const { id, options } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(options, 'UI', false);

  UI && (
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, completed: false, message: null },
      path: ['detailDocs', 'fetchDoc', id],
    }))
  );

  try {
    const response = yield call(getDoc, token, { pathParams: { id } });

    yield put(detailDocsActions.fetchDocSuccess(id, response));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Отсутствует разрешение доступа к шаблонам договоров или активна подписка только на Турсканер';

        break;
      }
      case 404: {
        message = 'Договор не найден';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(detailDocsActions.fetchDocFail(error));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['detailDocs', 'fetchDoc', id, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['detailDocs', 'fetchDoc', id, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['detailDocs', 'fetchDoc', id, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['detailDocs', 'fetchDoc', id, 'completed'] })));
  }
}
