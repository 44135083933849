import { bookingActions as actions } from 'bus/booking/actions';

export const bookingInvoicesReducer = {
  [actions.getBookingInvoicesSuccess]: (state, { payload: invoices }) => state.setIn(['booking', 'invoices'], invoices),
  [actions.createBookingInvoiceSuccess]: (state, { payload: invoice }) => {
    return state.updateIn(['booking', 'invoices'], invoices => [...invoices, invoice]);
  },
  [actions.deleteBookingInvoiceSuccess]: (state, { payload: invoiceId }) => {
    const idx = state
      .getIn(['booking', 'invoices'])
      .findIndex(({ id }) => id === invoiceId);
    const newInvoice = state.getIn(['booking', 'invoices', idx]);

    return state.setIn(['booking', 'invoices', idx], { ...newInvoice, is_deleted: true });
  },
  [actions.createOperatorInvoiceSuccess]: (state, { payload: invoice }) => {
    return state.updateIn(['booking', 'data', 'operator_invoices'], invoices => [...invoices, invoice]);
  },
  [actions.updateOperatorInvoiceSuccess]: (state, { payload: invoice }) => {
    const idx = state
      .getIn(['booking', 'data', 'operator_invoices'])
      .findIndex(({ id }) => id === invoice.id);

    return state.setIn(['booking', 'data', 'operator_invoices', idx], invoice);
  },
};
