import { Map } from 'immutable';
import { handleActions, combineActions } from 'redux-actions';

// instruments
import { statisticActions as actions } from './actions';

const initialState = Map({
  flexibleReport: Map({
    query: null,
    data: Map({
      results: null,
      rows: [],
      columns: [],
    }),
  }),
  calls: {
    data: {
      results: [],
      count: null,
      statistics: null,
      limit: null,
    },
    query: null,
    page: null,
  },
  logs: {
    data: {
      results: [],
      limit: null,
      count: null,
    },
    query: null,
    page: null,
  },
  bonuses: {
    data: {
      results: [],
      limit: null,
      count: null,
    },
    query: null,
    page: null,
  },
  salesFunnel: {
    data: {
      total: null,
      totalWithOpened: null,
      results: [],
    },
    query: null,
    page: 1,
  },
});

export default handleActions({
  [combineActions(
    actions.searchFlexibleReportSuccess,
    actions.clearFlexibleReport
  )]: (state, { payload: data }) => {
    const { results, rows, columns } = data;

    return state
      .setIn(['flexibleReport', 'data', 'results'], results)
      .setIn(['flexibleReport', 'data', 'rows'], rows)
      .setIn(['flexibleReport', 'data', 'columns'], columns);
  },
  [actions.setFlexibleQuery]: (state, { payload: query }) => state.setIn(['flexibleReport', 'query'], query),
  [actions.setCallsQuery]: (state, { payload: query }) => state.setIn(['calls', 'query'], query),
  [actions.setCallsPage]: (state, { payload: page }) => state.setIn(['calls', 'page'], page),
  [actions.getCallsSuccess]: (state, { payload: { results, count, limit, statistics } }) => state.setIn(['calls', 'data'], { results, count, limit, statistics }),
  [actions.getLogsSuccess]: (state, { payload: { results, count, limit } }) => state.setIn(['logs', 'data'], { results, count, limit }),
  [actions.setLogsQuery]: (state, { payload: query }) => state.setIn(['logs', 'query'], query),
  [actions.setLogsPage]: (state, { payload: page }) => state.setIn(['logs', 'page'], page),
  [actions.getBonusesSuccess]: (state, { payload: { results, count, limit } }) => state.setIn(['bonuses', 'data'], { results, count, limit }),
  [actions.setBonusesQuery]: (state, { payload: query }) => state.setIn(['bonuses', 'query'], query),
  [actions.setBonusesPage]: (state, { payload: page }) => state.setIn(['bonuses', 'page'], page),
  [actions.getSalesFunnelSuccess]: (state, { payload: { total, results, totalWithOpened } }) => state.setIn(['salesFunnel', 'data'], { total, results, totalWithOpened }),
  [actions.setSalesFunnelQuery]: (state, { payload: query }) => state.setIn(['salesFunnel', 'query'], query),
  [actions.setSalesFunnelPage]: (state, { payload: page }) => state.setIn(['salesFunnel', 'page'], page),
}, initialState);
