import { call, race, delay, take, put } from 'redux-saga/effects';

import { close } from 'containers/modalsDispatcher/store/actions';
import { showUpdateAppNotifyModal, UPDATE_APP_NOTIFY_MODAL_KEY } from 'components/modals/UpdateAppNotifyModal';

import { authActions } from '../../actions';

const UPDATE_TIME_OUT = 1000 * 60 * 10; // 10 minutes

function* updateAppNotifySaga() {
  while (true) {
    const [shouldCheckNextVersion, isLogout] = yield race([
      delay(UPDATE_TIME_OUT),
      take(authActions.logOut)
    ]);

    if (shouldCheckNextVersion) {
      try {
        const response = yield call(fetch, __PATH_OF_NEXT_VERSION_APP__);
        const isSetNextVersionOfApp = response.headers.get('content-type') === 'application/javascript';

        if (isSetNextVersionOfApp) {
          yield put(showUpdateAppNotifyModal());
          yield take(action => {
            return action.type === close.toString() && action.payload === UPDATE_APP_NOTIFY_MODAL_KEY;
          });
        }
      } catch (error) {
        // console.log(error);
      }
    } else if (isLogout) {
      return;
    }
  }
}

export { updateAppNotifySaga };
