import { call, put, select } from 'redux-saga/effects';

// instruments
import { getRoles } from 'api/methods/common/roles';
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';

export function* getRolesWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['common', 'roles'],
  }));

  try {
    const roles = yield call(getRoles, token);

    yield put(commonActions.getRolesSuccess(roles));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['common', 'roles', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка загрузки данных',
      path: ['common', 'roles', 'message'],
    }));
    yield put(commonActions.getRolesFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['common', 'roles', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['common', 'roles', 'completed'],
    }));
  }
}
