import { select, call } from 'redux-saga/effects';
import * as R from 'ramda';

import { getToken, getUserId } from 'bus/auth/selectors';

import { addOfferToBasket } from 'api/methods/tourscaner/baskets';

export function* addOfferToBasketSaga(offerId, currency, basketId) {
  const token = yield select(getToken);
  const userId = yield select(getUserId);

  return yield call(
    addOfferToBasket,
    token,
    { pathParams: { userId, basketId }, bodyParams: { offerId, currency } }
  );
}

export function* addOfferToBasketWorker({ payload }) {
  const { callbacks, basketId, offer, query } = payload;
  const { onSuccess, onFail } = callbacks;

  try {
    const basket = yield call(
      addOfferToBasketSaga,
      offer.id,
      query.currency,
      basketId
    );

    const offerEntityFromBasket = R.find(({ offer: { id } }) => offer.id === id, basket.offers);

    onSuccess && onSuccess({
      id: offerEntityFromBasket.id,
      offer: offerEntityFromBasket.offer,
      basket,
    });
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
