import { takeEvery } from 'redux-saga/effects';

import { commonActions as actions } from 'bus/common/actions';
import { roomsWatchers } from 'bus/common/rooms';
import { getOperatorsWorker } from './workers/operators/getOperators';
import { createOperatorWorker } from './workers/operators/createOperator';
import { deleteOperatorWorker } from './workers/operators/deleteOperator';
import { updateOperatorWorker } from './workers/operators/updateOperator';
import { getOperatorsDocsWorker } from './workers/operators/getOperatorsDocs.js';
import { getManagersWorker } from './workers/getManagers';
import { getTagsWorker } from './workers/getTags';
import { deleteTagWorker } from './workers/deleteTag';
import { createTagWorker } from './workers/createTag';
import { updateTagWorker } from './workers/updateTag';
import { getOfficesWorker } from './workers/getOffices';
import { createOfficeWorker } from './workers/createOffice';
import { deleteOfficeWorker } from './workers/deleteOffice';
import { updateOfficeWorker } from './workers/updateOffice';
import { getRegionsWorker } from './workers/getRegions';
import { createRegionWorker } from './workers/createRegion';
import { deleteRegionWorker } from './workers/deleteRegion';
import { updateRegionWorker } from './workers/updateRegion';
import { createSourceWorker } from './workers/createSource';
import {
  getDetailsWorker,
  createDetailWorker,
  deleteDetailWorker,
  updateDetailWorker,
  uploadDetailStampWorker,
  deleteDetailStampWorker
} from './workers/details';
import { getDocsByOperatorsWorker } from './workers/getDocsByOperators';
import { getIpassportFieldSetWorker } from './workers/getIpassportFieldSet';
import { getPatternsWorker } from './workers/getPatterns';
import { getRolesWorker } from './workers/getRoles';
import { getNotificationTypesWorker } from './workers/getNotificationTypes';
import { getCountriesWithUI } from './workers/getCountries';
import { getUniqueActiveEpassportsWorker } from './workers/getUniqueActiveEpassports';
import { getSmsPricesWorker } from './workers/getSmsPrices';
import { getDepartmentsWorker } from './workers/getDepartments';
import { getClaimSourceWorker } from './workers/getClaimSource';
import { deleteClaimSourceWorker } from './workers/deleteClaimSource';
import { updateClaimSourceWorker } from './workers/updateClaimSource';
import { createClaimSourceWorker } from './workers/createClaimSource';

// watchers
import getClosingReasonsSaga from './workers/closeReasons/saga';
import { getDocumentsTypesWithUI } from './workers/getDocumentsTypes';
import { getOperatorRequestLoginSagaWithUI } from './workers/operators/getOperatorRequestLogin';

export const commonWatchers = Object.freeze({
  ...roomsWatchers,

  * getOperatorsWatcher() {
    yield takeEvery(actions.getOperatorsMt, getOperatorsWorker);
  },
  * getOperatorsMtDocsWatcher() {
    yield takeEvery(actions.getOperatorsMtDocs, getOperatorsDocsWorker);
  },
  * createOperatorWatcher() {
    yield takeEvery(actions.createOperator, createOperatorWorker);
  },
  * deleteOperatorWatcher() {
    yield takeEvery(actions.deleteOperator, deleteOperatorWorker);
  },
  * updateOperatorWatcher() {
    yield takeEvery(actions.updateOperator, updateOperatorWorker);
  },
  * getOperatorRequestLogin() {
    yield takeEvery(actions.getOperatorRequestLogin, getOperatorRequestLoginSagaWithUI);
  },
  * getManagersWatcher() {
    yield takeEvery(actions.getManagers, getManagersWorker);
  },
  * getDocumentsTypes() {
    yield takeEvery(actions.getDocumentsTypes, getDocumentsTypesWithUI);
  },
  * getTagsWatcher() {
    yield takeEvery(actions.getTags, getTagsWorker);
  },
  * deleteTagWatcher() {
    yield takeEvery(actions.deleteTag, deleteTagWorker);
  },
  * createTagWatcher() {
    yield takeEvery(actions.createTag, createTagWorker);
  },
  * updateTagWatcher() {
    yield takeEvery(actions.updateTag, updateTagWorker);
  },
  * getOfficesWatcher() {
    yield takeEvery(actions.getOffices, getOfficesWorker);
  },
  * deleteOfficeWatcher() {
    yield takeEvery(actions.deleteOffice, deleteOfficeWorker);
  },
  * createOfficeWatcher() {
    yield takeEvery(actions.createOffice, createOfficeWorker);
  },
  * updateOfficeWatcher() {
    yield takeEvery(actions.updateOffice, updateOfficeWorker);
  },
  * getRegionsWatcher() {
    yield takeEvery(actions.getRegions, getRegionsWorker);
  },
  * createRegionWatcher() {
    yield takeEvery(actions.createRegion, createRegionWorker);
  },
  * deleteRegionWatcher() {
    yield takeEvery(actions.deleteRegion, deleteRegionWorker);
  },
  * updateRegionWatcher() {
    yield takeEvery(actions.updateRegion, updateRegionWorker);
  },
  * createSourceWatcher() {
    yield takeEvery(actions.createSource, createSourceWorker);
  },
  * getDetailsWatcher() {
    yield takeEvery(actions.getDetails, getDetailsWorker);
  },
  * createDetailWatcher() {
    yield takeEvery(actions.createDetail, createDetailWorker);
  },
  * deleteDetailWatcher() {
    yield takeEvery(actions.deleteDetail, deleteDetailWorker);
  },
  * updateDetailWatcher() {
    yield takeEvery(actions.updateDetail, updateDetailWorker);
  },
  * uploadDetailStampWatcher() {
    yield takeEvery(actions.uploadDetailStamp, uploadDetailStampWorker);
  },
  * deleteDetailStampWatcher() {
    yield takeEvery(actions.deleteDetailStamp, deleteDetailStampWorker);
  },
  * getDocsByOperatorsWatcher() {
    yield takeEvery(actions.getDocsByOperators, getDocsByOperatorsWorker);
  },
  * fetchIpassportFieldSet() {
    yield takeEvery(actions.fetchIpassportFieldSet, getIpassportFieldSetWorker);
  },
  * getPatterns() {
    yield takeEvery(actions.getPatterns, getPatternsWorker);
  },
  * getRoles() {
    yield takeEvery(actions.getRoles, getRolesWorker);
  },
  * getNotificationTypes() {
    yield takeEvery(actions.getNotificationTypes, getNotificationTypesWorker);
  },
  * getCountries() {
    yield takeEvery(actions.getCountries, getCountriesWithUI);
  },
  * getUniqueActiveEpassportsWatcher() {
    yield takeEvery(actions.getUniqueActiveEpassports, getUniqueActiveEpassportsWorker);
  },
  * getSmsPrices() {
    yield takeEvery(actions.getSmsPrices, getSmsPricesWorker);
  },
  * getDepartmentsWatcher() {
    yield takeEvery(actions.getDepartments, getDepartmentsWorker);
  },
  * getClaimSourceWatcher() {
    yield takeEvery(actions.getClaimSource, getClaimSourceWorker);
  },
  * deleteClaimSourceWatcher() {
    yield takeEvery(actions.deleteClaimSource, deleteClaimSourceWorker);
  },
  * updateClaimSourceWatcher() {
    yield takeEvery(actions.updateClaimSource, updateClaimSourceWorker);
  },
  * createClaimSourceWatcher() {
    yield takeEvery(actions.createClaimSource, createClaimSourceWorker);
  },
  getClosingReasonsSaga,
});
