import { Map } from 'immutable';
import { handleActions, combineActions } from 'redux-actions';

// instruments
import { dumpActions as actions } from './actions';

const initialState = Map({
  import: {
    runResult: null,
    analyze: null,
    job: null,
  },
  export: {

  },
});

export default handleActions({
  [combineActions(
    actions.uploadImportFileSuccess,
    actions.importProgressSuccess,
    actions.importRunSuccess,
    actions.clearJob
  )]: (state, { payload: job }) => state.setIn(['import', 'job'], job),
  [combineActions(actions.importAnalyzeSuccess, actions.clearAnalyze)]: (state, { payload: response }) => state.setIn(['import', 'analyze'], response),
  [combineActions(actions.importRunResultSuccess, actions.clearRunResult)]: (state, { payload: response }) => state.setIn(['import', 'runResult'], response),
}, initialState);
