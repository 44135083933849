import { takeEvery, take, fork, cancel } from 'redux-saga/effects';

// actions
import { newsActions as actions } from 'bus/news';

import { getNewsWithSavingQuerySaga, getNewsWorker } from './workers/getNews';
import { getSingleNewsWorker } from './workers/getSingleNews';
import { getNewsByTimeoutWorker } from './workers/getNewsByTimeout';
import { viewedNewsWorker } from './workers/viewedNews';
import getNewsSelectionsWorker from './workers/getNewsSelections';

export const newsWatchers = Object.freeze({
  * getNews() {
    yield takeEvery(actions.getNews, getNewsWorker);
  },
  * getNewsWithSavingQuery() {
    yield takeEvery(actions.getNewsWithSavingQuery, getNewsWithSavingQuerySaga);
  },
  * getNewsSelections() {
    yield takeEvery(actions.getNewsSelections, getNewsSelectionsWorker);
  },
  * getSingleNews() {
    yield takeEvery(actions.getSingleNews, getSingleNewsWorker);
  },
  * viewedNews() {
    yield takeEvery(actions.viewedNews, viewedNewsWorker);
  },
  * getNewsByTimeout() {
    while (yield take(actions.startAutoFetchNews)) {
      const task = yield fork(getNewsByTimeoutWorker);

      yield take(actions.endAutoFetchNews);
      yield cancel(task);
    }
  },
});
