import { put, select, call } from 'redux-saga/effects';

// instruments
import { updateNotify } from 'api/methods/tf/notify';
import { uiActions } from 'bus/ui/actions';
import { notifyActions } from 'bus/notify';

export function* updateNotifyWorker({ payload }) {
  const { notifyID, values, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const tfID = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['notify', 'updateNotify', notifyID],
  }));

  formik && (yield call(formik.setStatus, null));

  try {
    const notify = yield call(
      updateNotify,
      token,
      { pathParams: { tfID, notifyID }, bodyParams: values }
    );

    yield put(notifyActions.updateNotifySuccess(notify));

    formik && (yield call(formik.setStatus, { updateNotify: { success: true } }));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['notify', 'updateNotify', notifyID, 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['notify', 'updateNotify', notifyID, 'message'],
    }));
    yield put(notifyActions.updateNotifyFail(error));
    formik && (yield call(formik.setStatus, { updateNotify: { error: true, message: 'Ошибка сервера' } }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['notify', 'updateNotify', notifyID, 'loading'],
    }));
    formik && (yield call(formik.setSubmitting, false));
  }
}
