import moment from 'moment';
import * as R from 'ramda';

import { getQueryFromCache } from 'helpers/cookies';

export const getStrFromCacheOrUrl = cacheKey => (window.location.search
  ? window.location.search
  : getQueryFromCache(cacheKey) || '');

export const createUrlSearchParams = searchStr => {
  return [...new URLSearchParams(searchStr).entries()];
};

export const createMomentValue = stringDate => (stringDate ? moment(stringDate) : null);
export const createArrayValue = R.pipe(
  R.split(','),
  R.map(Number)
);
export const createBoolValue = value => value === 'true';
