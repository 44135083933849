import { call, select, put } from 'redux-saga/effects';

// instruments
import { logSearchStatistics } from 'api/methods/tourscaner';
import { authActions } from 'bus/auth/actions';

export function* logSearchStatisticsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    yield call(logSearchStatistics, token);
  } catch (error) {
    const { status } = error.msg || {};

    if (status === 403) {
      return yield put(authActions.logOut(true));
    }
  }
}
