import BaseApiClass from 'api/BaseApiClass';
import { Map, getIn, get } from 'immutable';
import moment from 'moment';

export const getMailings = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/mailings',
  method: 'GET',
  ...params,
})._load({ token });

export const getMailingSubscribers = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/mailings/{:id}/subscribers',
  method: 'GET',
  ...params,
})._load({ token });

export const getNewMailingSubscribers = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/mailings/{:id}/count_new_subscribers',
  method: 'GET',
  ...params,
})._load({ token });

export const deleteMailing = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/mailings/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getMailing = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/mailings/{:id}',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = ({ search, ...rest }) => {
    return {
      ...rest,
      search: {
        ...search,
        claim_manager: getIn(search, ['claim_manager', 'id'], null),
        tags: get(search, 'tags', []).map(tag => Number(tag)),
      },
    };
  };

  return instance._load({ token });
};

export const sendTestMailing = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/mailings/{:id}/email',
  method: 'PUT',
  ...params,
})._load({ token });

export const sendMailing = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/mailings/{:id}/send',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });

export const sendMailingUpdate = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/mailings/{:id}/update',
  method: 'POST',
  ...params,
})._load({ token });

const preparedValues = values => Map(values)
  .map(item => (moment.isMoment(item) ? item.format() : item))
  .toObject();

export const createMailing = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/mailings',
    method: 'POST',
    ...params,
  });

  instance.convertBodyToServerSchema = body => {
    return {
      ...preparedValues(body),
    };
  };

  return instance._load({ token });
};

export const updateMailing = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/mailings/{:id}',
    method: 'PUT',
    ...params,
  });

  instance.convertBodyToServerSchema = body => {
    return {
      ...preparedValues(body),
    };
  };

  return instance._load({ token });
};

export const getMailsOffers = (token, params = {}) => new BaseApiClass({
  url: 'mails/offers/{:hash}',
  method: 'GET',
  ...params,
})._load({ token });
