import BaseApiClass from 'api/BaseApiClass';

export const updatePayment = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:claimID}/payments/{:paymentID}',
    method: 'PUT',
    ...params,
  });

  return instance._load({ token });
};
