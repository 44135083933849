import { put, race, take, all, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { bookingActions } from 'bus/booking/actions';
import { getUserFinancesPermission } from 'bus/auth/selectors';

const gerErrorMsg = R.pathOr('Ошибка сервера', ['payload', 'msg', 'body', 'message']);
// return [success,error]
export function* getInitialDetailsForBookingSaga(bookingId) {
  yield all([
    put(bookingActions.getBooking(bookingId)),
    put(bookingActions.getBookingMessages(bookingId)),
    put(bookingActions.getBookingInvoices(bookingId)),
    put(bookingActions.getBookingSchedules(bookingId)),
  ]);

  const [[, errorBooking]] = yield all([
    race([
      take(bookingActions.getBookingSuccess),
      take(bookingActions.getBookingFail)
    ])
  ]);

  const userFinancesPermission = yield select(getUserFinancesPermission);

  if (userFinancesPermission) {
    yield put(bookingActions.getBookingPayments(bookingId));
  }

  if (errorBooking) {
    return [undefined, gerErrorMsg(errorBooking)];
  }

  return [true, undefined];
}
