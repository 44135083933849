import { put, select, race, take, call } from 'redux-saga/effects';
import { getRouter } from 'connected-react-router';

import { getClaimStep } from 'helpers/claim/storage';

import { clientClaimActions } from '../../actions';
import { getStepMarket } from '../../selectors';
import { stepersActions } from '../../stepers';

const getStepByPriority = (stepFromStorage, stepFromUrl, defaultStep) => stepFromUrl || stepFromStorage || defaultStep;

export function* bootstrapClaimSaga({ payload: claimId }) {
  yield put(clientClaimActions.getClaim(claimId));

  const [isSuccess] = yield race([
    take(clientClaimActions.getClaimSuccess),
    take(clientClaimActions.getClaimFail),
  ]);

  if (isSuccess) {
    const defaultStep = yield select(getStepMarket);
    const stepFromStorage = yield call(getClaimStep, claimId);

    const { location } = yield select(getRouter);

    yield put(stepersActions.setActiveStep(
      getStepByPriority(stepFromStorage, location.query?.active_step, defaultStep),
    ));
  }
}
