import { localStorageInterface } from './services/localStorageInterface';
import { STORAGE_KEY } from './constants';

export const saveSearchesToStorage = searches => localStorageInterface.setItem(STORAGE_KEY, JSON.stringify(searches));

export const getSearchesFromStorage = () => {
  const values = localStorageInterface.getItem(STORAGE_KEY);

  return values ? JSON.parse(values) : [];
};
