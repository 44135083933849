import { put, call } from 'redux-saga/effects';

// instruments
import { authActions } from 'bus/auth/actions';
import { getStorage, GROUPS } from 'helpers/storage';

export function* autoAuthenticateWorker() {
  const storage = yield call(getStorage);
  const isRememberMe = storage.getIn([GROUPS.auth, 'remember']);
  const hasActiveSessions = storage.getIn([GROUPS.sessions, 'length']);

  if (isRememberMe || hasActiveSessions) {
    const token = storage.getIn([GROUPS.auth, 'token']);

    yield put(authActions.authenticateSuccess(token));
    yield put(authActions.fetchMyself());
  } else {
    yield put(authActions.logOut(true));
  }
}
