import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import { NotifyAddLimitUsers } from 'components/subscription/NotifyAddLimitUsers';

// instruments
import { uiActions } from 'bus/ui/actions';

export const useNotifyAddLimitUsers = () => {
  const [activeUsers, limitUsers, isTrialSubscription, ui] = useSelector(state => [
    state.auth.getIn(['profile', 'tf', 'activeUsersCount']),
    state.auth.getIn(['profile', 'tf', 'subscription', 'cnt_man']),
    state.auth.getIn(['profile', 'tf', 'subscription', 'is_trial']),
    state.ui.getIn(['subscription', 'notifyAddLimitUsers'])
  ]);

  const isLimited = activeUsers >= limitUsers && !isTrialSubscription;

  const dispatch = useDispatch();
  const onShow = useCallback(() => dispatch(uiActions.changeUiLoaderFlag({ status: true, path: ['subscription', 'notifyAddLimitUsers', 'visible'] })), []);
  const onHide = useCallback(() => dispatch(uiActions.changeUiLoaderFlag({ status: false, path: ['subscription', 'notifyAddLimitUsers', 'visible'] })), []);

  return {
    ui,
    limitUsers,
    isLimited,
    onShow,
    onHide,
  };
};

export const NotifyAddLimitUsersContainer = memo(() => {
  const {
    ui,
    limitUsers,
    onHide,
  } = useNotifyAddLimitUsers();

  return ui.visible && (
    <NotifyAddLimitUsers limitUsers={limitUsers} onUnmount={onHide} />
  );
});
