import { takeEvery } from 'redux-saga/effects';

import { tfUsersActions as actions } from '../actions';

import {
  fetchUsersWorker,
  toggleTfUserStatusWorker,
  fetchUserWorker,
  addUserWorker,
  getDefaultTemplateWorker,
  deleteTfUserTelegramWorker,
  updateUserImageSagaWithUI,
  removeUserImageSagaWithUI,
  updateTfUserWorker,
} from './workers';

export const tfUserWatchers = Object.freeze({
  * fetchUsers() {
    yield takeEvery(actions.fetchUsers, fetchUsersWorker);
  },
  * toggleTfUserStatus() {
    yield takeEvery(actions.toggleTfUserStatus, toggleTfUserStatusWorker);
  },
  * fetchUser() {
    yield takeEvery(actions.fetchUser, fetchUserWorker);
  },
  * updateUserInfo() {
    yield takeEvery(actions.updateUserInfo, updateTfUserWorker);
  },
  * updateUserImage() {
    yield takeEvery(actions.updateUserImage, updateUserImageSagaWithUI);
  },
  * removeUserImage() {
    yield takeEvery(actions.removeUserImage, removeUserImageSagaWithUI);
  },
  * addUser() {
    yield takeEvery(actions.addUser, addUserWorker);
  },
  * getDefaultTemplate() {
    yield takeEvery(actions.getDefaultTemplate, getDefaultTemplateWorker);
  },
  * deleteUserTelegram() {
    yield takeEvery(actions.deleteUserTelegram, deleteTfUserTelegramWorker);
  },
});
