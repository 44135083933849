import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

// instuments
import { viewedNews } from 'api/methods/news';
import { newsActions } from 'bus/news';
import { uiActions } from 'bus/ui/actions';

export function* viewedNewsWorker({ payload }) {
  const { options, id } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(options, 'UI', false);
  const START_AUTO_FETCH_NEWS = get(options, 'START_AUTO_FETCH_NEWS', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['news', 'viewed', id],
  })));

  try {
    yield call(viewedNews, token, { pathParams: { id } });
    yield put(newsActions.viewedNewsSuccess(id));
    START_AUTO_FETCH_NEWS && (yield newsActions.startAutoFetchNews());
  } catch (error) {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['news', 'viewed', id, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: 'Ошибка сервера', path: ['news', 'viewed', id, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['news', 'viewed', id, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['news', 'viewed', id, 'completed'] })));
  }
}
