import BaseApiClass from 'api/BaseApiClass';

export const getApiKeys = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/api-keys',
  method: 'GET',
  ...params,
})._load({ token });

export const createApiKey = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/api-keys',
  method: 'POST',
  ...params,
})._load({ token });

export const deleteApiKey = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/api-keys/{:name}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const sendApiKey = (token, params = {}) => new BaseApiClass({
  url: 'users/{:user}/api-keys/{:name}/send-to-email',
  method: 'GET',
  ...params,
})._load({ token });
