import { select, call, put } from 'redux-saga/effects';

// instruments
import { getIpassportFieldSet } from 'api/methods/tf/fields';
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';

export function* getIpassportFieldSetWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['common', 'ipassportFieldSet', 'loading'],
  }));

  try {
    const response = yield call(getIpassportFieldSet, token);

    yield put(commonActions.fetchIpassportFieldSetSuccess(response));
  } catch (error) {
    yield put(commonActions.fetchIpassportFieldSetFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['common', 'ipassportFieldSet', 'loading'],
    }));
  }
}
