import { createActions } from 'redux-actions';

export const tfActions = createActions({
  FETCH_SCENARIOS_LIST: () => undefined,
  FETCH_SCENARIOS_LIST_SUCCESS: scenarios => ({ scenarios }),
  FETCH_SCENARIOS_LIST_FAIL: error => error,

  FETCH_ROLES_LIST: () => undefined,
  FETCH_ROLES_LIST_SUCCESS: roles => ({ roles }),
  FETCH_ROLES_LIST_FAIL: error => error,

  CREATE_TAG: (tag, formik) => ({ tag, formik }),
  DELETE_TAG: (id, formik) => ({ id, formik }),
  UPDATE_TAG: (id, name, formik) => ({ id, name, formik }),

  CREATE_REGION: (region, formik) => ({ region, formik }),
  DELETE_REGION: (id, formik) => ({ id, formik }),
  UPDATE_REGION: (id, name, formik) => ({ id, name, formik }),

  CREATE_OFFICE: (office, formik) => ({ office, formik }),
  DELETE_OFFICE: (id, formik) => ({ id, formik }),
  UPDATE_OFFICE: (id, name, onSuccessUpdate, onFailUpdate) => ({ id, name, onSuccessUpdate, onFailUpdate }),

  FETCH_TF_PROFILE: options => options,
  FETCH_TF_PROFILE_SUCCESS: profile => profile,
  FETCH_TF_PROFILE_FAIL: error => error,
  CLEAR_TF_PROFILE: () => undefined,

  UPDATE_TF_PROFILE: (values, formik) => ({ values, formik }),
  UPDATE_TF_PROFILE_SUCCESS: profile => profile,
  UPDATE_TF_PROFILE_FAIL: error => error,

  UPLOAD_TF_IMAGE: (file, type, imagePath, formik) => ({ file, type, imagePath, formik }),
  UPLOAD_TF_IMAGE_SUCCESS: (url, path) => ({ url, path }),
  UPLOAD_TF_IMAGE_FAIL: error => error,

  DELETE_TF_IMAGE: (type, imagePath, workerOptions, formik) => ({ type, imagePath, workerOptions, formik }),
  DELETE_TF_IMAGE_SUCCESS: path => ({ url: null, path }),

  UPDATE_TF_PROFILE_AND_IMAGE: (values, file, fileType, imagePath, formik) => ({ values, file, fileType, imagePath, formik }),

  CREATE_DETAIL: (tempID, detail, file, formik) => ({ tempID, detail, file, formik }),
  UPDATE_DETAIL: (id, detail, file, formik) => ({ id, detail, file, formik }),

  GET_TF_FIELDS: () => undefined,
  GET_TF_FIELDS_SUCCESS: fields => fields,
  GET_TF_FIELDS_FAIL: error => error,

  GET_OPERATOR_CREDENTIALS: page => ({ page }),
  GET_OPERATOR_CREDENTIALS_SUCCESS: results => results,
  GET_OPERATOR_CREDENTIALS_FAIL: error => error,

  CREATE_OPERATOR_CREDENTIALS: (values, operator, formik) => ({ values, operator, formik }),
  CREATE_OPERATOR_CREDENTIALS_SUCCESS: credential => credential,
  CREATE_OPERATOR_CREDENTIALS_FAIL: error => error,

  UPDATE_OPERATOR_CREDENTIAL: (values, credentialId) => ({ values, credentialId }),
  UPDATE_OPERATOR_CREDENTIAL_SUCCESS: credential => credential,
  UPDATE_OPERATOR_CREDENTIAL_FAIL: error => error,

  DELETE_OPERATOR_CREDENTIAL: credentialId => credentialId,
  DELETE_OPERATOR_CREDENTIAL_SUCCESS: credentialId => credentialId,
  DELETE_OPERATOR_CREDENTIAL_FAIL: error => error,

  GET_DISTRIBUTION: () => undefined,
  GET_DISTRIBUTION_SUCCESS: distribution => distribution,
  GET_DISTRIBUTION_FAIL: error => error,

  CLEAR_DISTRIBUTION: () => null,

  UPDATE_DISTRIBUTION: (values, formik) => ({ values, formik }),
  UPDATE_DISTRIBUTION_SUCCESS: distribution => distribution,
  UPDATE_DISTRIBUTION_FAIL: error => error,

  GET_COMMISSION: queryParams => queryParams,
  GET_COMMISSION_SUCCESS: (id, name, results, description, showContacts) => ({ id, name, results, description, showContacts }),
  GET_COMMISSION_FAIL: error => error,

  GET_COMMISSION_COUNTERPARTIES: (id, operatorId) => ({ id, operatorId }),
  GET_COMMISSION_COUNTERPARTIES_SUCCESS: commission => commission,
  GET_COMMISSION_COUNTERPARTIES_FAIL: error => error,

  GET_MERCHANTS: () => undefined,
  GET_MERCHANTS_SUCCESS: merchants => merchants,
  GET_MERCHANTS_FAIL: error => error,

  CREATE_MERCHANT: (values, type, formik) => ({ values, type, formik }),
  CREATE_MERCHANT_SUCCESS: (merchant, type) => ({ merchant, type }),
  CREATE_MERCHANT_FAIL: error => error,

  UPDATE_MERCHANT: (values, id, formik) => ({ values, id, formik }),
  UPDATE_MERCHANT_SUCCESS: merchant => merchant,
  UPDATE_MERCHANT_FAIL: error => error,

  getGroups: () => undefined,
  getGroupsSuccess: groups => groups,
  getGroupsFail: error => error,

  getCommissionGroups: () => undefined,
  getCommissionGroupsSuccess: commissionGroups => commissionGroups,
  getCommissionGroupsFail: error => error,

  resetOperatorCredentials: () => undefined,
}, { prefix: 'MT_TF' });
