import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';
import { markBookingViewed } from 'api/methods/booking';

const BASE_UI_PATH = ['booking', 'bookingViewed'];

export function* markBookingViewedWorker({ payload }) {
  const { bookingId, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  })));

  try {
    yield call(markBookingViewed, token, { pathParams: { id: bookingId } });

    yield put(actions.markBookingViewedSuccess());
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(actions.markBookingViewedFail(error));

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
