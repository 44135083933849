import { call, select, put } from 'redux-saga/effects';
import * as R from 'ramda';

import { getLocale, getToken, getUserTfCountryCode } from 'bus/auth/selectors';
import { commonActions } from 'bus/common/actions';

import { getCountries } from 'api/methods/common/getCountries';

export function* getCountriesWorker({ payload: callbacks = {} }) {
  const { onSuccess, onFail } = callbacks;

  try {
    const token = yield select(getToken);
    const userLocale = yield select(getLocale);
    const userTfCountryCode = yield select(getUserTfCountryCode);

    const countries = yield call(getCountries, token, {
      queryParams: {
        default_first: true,
      },
    });

    yield put(commonActions.getCountriesSuccess(countries));

    const mappedCountries = R.pipe(
      R.sort((a, b) => {
        if (a.code === userTfCountryCode) return -1;
        if (b.code === userTfCountryCode) return 1;

        return a.name.localeCompare(b.name);
      }),
      R.map(({ translations, name, id }) => ({
        name: translations[userLocale] ?? name,
        id
      }))
    )(countries);

    onSuccess && onSuccess(mappedCountries);
  } catch (e) {
    onFail && onFail(e);
  }
}
