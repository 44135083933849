import { takeEvery } from 'redux-saga/effects';

import { claimWatchers, claimActions } from 'bus/clientClaim/claim';
import { commentsWatchers } from 'bus/clientClaim/comments';
import { stepersWatchers, stepersActions } from 'bus/clientClaim/stepers';
import { digitalContractWatchers } from 'bus/clientClaim/digitalContract';
import { epassportsActions, epassportsWatchers } from 'bus/clientClaim/epassports';
import { sendMessageToClientWorker } from './workers/sendMessageToClientWorker.js';

import { clientClaimActions as actions } from '../actions';
import {
  fetchClientWorker,
  fetchClientFieldsWorker,
  fetchClientPassports,
  updateClientWorker,
  createClientWorker,
  mergeConflictClientsWorker,
  createClaimWorker,
  createClientAndClaimWorker,
  createClaimByFreshClientWorker,
  deleteSearchWorker,
  getStaticListsWorker,
  updateContractClaimWorker,
  createRoomTypeWorker,
  prepareContractClaimToActionWorker,
  deleteClientWorker,
  createClaimPaymentIncomeWorker,
  createClaimPaymentExpenseWorker,
  createClaimBookingWorker,
  deleteClaimBookingWorker,
  updateClaimBookingWorker,
  fetchClaimBonusWorker,
  updateClaimBonusWorker,
  fetchClaimCommentsWorker,
  closeClaimWorker,
  openClaimWorker,
  deleteClaimWorker,
  setTouchedClaimWorker,
  createClaimPaymentIncomeAndUpdateWorker,
  createClaimPaymentExpenseAndUpdateWorker,
  getDeclarationsWorker,
  getClaimWithUISaga
} from './workers';

import getClaimSimplifiedWorker from './workers/getClaimSimplified';
import { bootstrapClaimSaga } from './workers/bootstrapClaimSaga';

export const clientClaimWatchers = Object.freeze({
  ...epassportsWatchers,
  ...claimWatchers,
  ...commentsWatchers,
  ...stepersWatchers,
  ...digitalContractWatchers,

  * fetchClient() {
    yield takeEvery(actions.fetchClient, fetchClientWorker);
  },
  * fetchClientFields() {
    yield takeEvery(actions.fetchTfFieldsForClient, fetchClientFieldsWorker);
  },
  * fetchClientPassports() {
    yield takeEvery(actions.fetchClientPassports, fetchClientPassports);
  },
  * updateClient() {
    yield takeEvery(actions.updateClient, updateClientWorker);
  },
  * createClient() {
    yield takeEvery(actions.createClient, createClientWorker);
  },
  * deleteClient() {
    yield takeEvery(actions.deleteClient, deleteClientWorker);
  },
  * createClaim() {
    yield takeEvery(actions.createClaim, createClaimWorker);
  },
  * mergeClient() {
    yield takeEvery(actions.mergeConflictClients, mergeConflictClientsWorker);
  },
  * createClientAndClaim() {
    yield takeEvery(actions.createClientAndClaim, createClientAndClaimWorker);
  },
  * createClaimByFreshClient() {
    yield takeEvery(actions.createClaimByFreshClient, createClaimByFreshClientWorker);
  },
  * getClaimWithUISaga() {
    yield takeEvery(actions.getClaim, getClaimWithUISaga);
  },
  * getClaimSimplified() {
    yield takeEvery(actions.getClaimSimplified, getClaimSimplifiedWorker);
  },
  * deleteSearch() {
    yield takeEvery(actions.deleteSearch, deleteSearchWorker);
  },
  * getStaticLists() {
    yield takeEvery(actions.getStaticLists, getStaticListsWorker);
  },
  *  updateContractClaim() {
    yield takeEvery(actions.updateContractClaim, updateContractClaimWorker);
  },
  *  createRoomType() {
    yield takeEvery(actions.createRoomType, createRoomTypeWorker);
  },
  *  prepareContractClaimToAction() {
    yield takeEvery(actions.prepareContractClaimToAction, prepareContractClaimToActionWorker);
  },
  *  createClaimPaymentIncome() {
    yield takeEvery(actions.createClaimPaymentIncome, createClaimPaymentIncomeWorker);
  },
  * createClaimPaymentExpense() {
    yield takeEvery(actions.createClaimPaymentExpense, createClaimPaymentExpenseWorker);
  },
  * createClaimBooking() {
    yield takeEvery(actions.createClaimBooking, createClaimBookingWorker);
  },
  * deleteClaimBooking() {
    yield takeEvery(actions.deleteClaimBooking, deleteClaimBookingWorker);
  },
  * updateClaimBooking() {
    yield takeEvery(actions.updateClaimBooking, updateClaimBookingWorker);
  },
  * fetchClaimBonus() {
    yield takeEvery(actions.fetchClaimBonus, fetchClaimBonusWorker);
  },
  * updateClaimBonus() {
    yield takeEvery(actions.updateClaimDateBonus, updateClaimBonusWorker);
  },
  * getDeclarationsWatcher() {
    yield takeEvery(actions.getDeclarations, getDeclarationsWorker);
  },
  * autoReloadClaimComments() {
    yield takeEvery([
      claimActions.updateClaimSuccess,
      claimActions.updateDateCreatedSuccess,
      epassportsActions.createEpassportSuccess,
      epassportsActions.deleteEpassportSuccess,
      epassportsActions.updateEpassportSuccess,
      actions.updateContractClaimSuccess,
      actions.createClaimPaymentIncomeSuccess,
      actions.createClaimPaymentExpenseSuccess,
      actions.createClaimBookingSuccess,
      actions.deleteClaimBookingSuccess,
      actions.updateClaimBookingSuccess,
      actions.updateClaimDateBonusSuccess,
      actions.closeClaimSuccess,
      actions.openClaimSuccess,
      stepersActions.toggleDoneStepSuccess
    ], fetchClaimCommentsWorker);
  },
  * closeClaim() {
    yield takeEvery(actions.closeClaim, closeClaimWorker);
  },
  * openClaim() {
    yield takeEvery(actions.openClaim, openClaimWorker);
  },
  * deleteClaim() {
    yield takeEvery(actions.deleteClaim, deleteClaimWorker);
  },
  * setTouchedClaimWatcher() {
    yield takeEvery([actions.setTouchedClaim, actions.setTouchedClaimFromBasket], setTouchedClaimWorker);
  },
  * createClaimPaymentIncomeAndUpdateWatcher() {
    yield takeEvery(
      actions.createClaimPaymentIncomeAndUpdate, createClaimPaymentIncomeAndUpdateWorker
    );
  },
  * createClaimPaymentExpenseAndUpdateWatcher() {
    yield takeEvery(
      actions.createClaimPaymentExpenseAndUpdate, createClaimPaymentExpenseAndUpdateWorker
    );
  },
  * sendMessageToClientWatcher() {
    yield takeEvery(actions.sendMessageToClient, sendMessageToClientWorker);
  },
  * bootstrapClaim() {
    yield takeEvery(actions.bootstrapClaim, bootstrapClaimSaga);
  },
});
