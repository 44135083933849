import { call, put, select } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors';
import { getDeclarations } from 'api/methods/claim/docs';
import { withUIWorker } from 'bus/ui/helpers';
import { DECLARATION_UI } from 'components/clientClaim/ClaimStepersForms/Docs/constants';

import { clientClaimActions } from '../../actions';

export function* getDeclarationsWorker({ payload: country }) {
  const token = yield select(getToken);

  try {
    const declarations = yield call(getDeclarations, token, { pathParams: { country } });

    yield put(clientClaimActions.getDeclarationsSuccess(declarations));
  } catch (error) {
    yield put(clientClaimActions.getDeclarationsFail(error));
  }
}

export const getDeclarationsWorkerWithUI = withUIWorker(
  [clientClaimActions.getDeclarationsSuccess, clientClaimActions.getDeclarationsFail],
  DECLARATION_UI,
)(getDeclarationsWorker);
