import BaseApiClass from 'api/BaseApiClass';

export const updateClaim = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}',
    method: 'PUT',
    ...params,
  });

  return instance._load({ token });
};

export const updateDateCreated = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/{:id}/created',
    method: 'PUT',
    successCode: 204,
    ...params,
  });

  return instance._load({ token });
};

export const updateContractClaim = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/contract',
  method: 'PUT',
  ...params,
})._load({ token });

export const createClaimPaymentIncome = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/payments/incomes',
  method: 'POST',
  ...params,
})._load({ token });

export const createClaimPaymentExpense = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/payments/expenses',
  method: 'POST',
  ...params,
})._load({ token });

export const closeClaim = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/close/{:reasonId}',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });

export const openClaim = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/open',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });

export const deleteClaim = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
