import BaseApiClass from 'api/BaseApiClass';
import { Map } from 'immutable';

export const uploadImportFile = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'clients/import-analyze',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};

export const importAnalyze = (token, params = {}) => new BaseApiClass({
  url: 'clients/import-analyze/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const importRun = (token, params = {}) => new BaseApiClass({
  url: 'clients/import/{:id}/run',
  method: 'GET',
  ...params,
})._load({ token });

export const importRunResult = (token, params = {}) => new BaseApiClass({
  url: 'clients/import-run/{:id}',
  method: 'GET',
  ...params,
})._load({ token });
