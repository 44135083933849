import { Map } from 'immutable';
import { handleActions } from 'redux-actions';

// instruments
import { clientLeadActions as actions } from './actions';

const initialState = Map({
  data: {
    results: [],
    total: null,
  },
  query: null,
  page: null,
  order: 'client_creat_desc',
});

export default handleActions({
  [actions.searchSuccess]: (state, { payload: { results, total } }) => state.setIn(['data', 'results'], results).setIn(['data', 'total'], total),
  [actions.setQuery]: (state, { payload: query }) => state.set('query', query),
  [actions.setPage]: (state, { payload: page }) => state.set('page', page),
  [actions.setOrder]: (state, { payload: order }) => state.set('order', order),

}, initialState);
