import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

// instrumetns
import { updateDistribution } from 'api/methods/tf/profile';
import { tfActions } from 'bus/tf/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';
import { newInstance } from '../../../../../localization';

export function* updateDistributionWorker({ payload }) {
  const { values, formik } = payload;
  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  try {
    const distribution = yield call(updateDistribution, token, { pathParams: { tf }, bodyParams: values });

    yield put(tfActions.updateDistributionSuccess(distribution));
    formik && (yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);

        if (violations.length && formik) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          yield call(formik.setErrors, errorsByValues);

          if (otherErrors.length) {
            message = otherErrors.map(item => item.message).join('. ');
          }
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права обновлять турфирму');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(tfActions.updateDistributionFail(error));
    formik && message && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
