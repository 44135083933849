import * as R from 'ramda';

import { removeEmptyArray, removeEmptyString, removeNull, replaceMoment2String } from 'helpers';
import { DEFAULT_SORT_TYPE } from './constants';

const filterQuery = R.pipe(
  removeEmptyArray,
  removeNull,
  removeEmptyString,
  replaceMoment2String
);

export const prepareQuerySearchString = (query, page, order) => new URLSearchParams(
  R.pipe(
    filterQuery,
    result => R.mergeAll([
      result,
      order === DEFAULT_SORT_TYPE ? {} : { order },
      page === 1 ? {} : { page }
    ])
  )(query)
);

export const prepareBodyParams = (query, page, order) => R.pipe(
  filterQuery,
  ({ clientManager, ...rest }) => R.mergeAll([
    {
      filters: {
        ...rest,
        ...clientManager ? clientManager === 'none' ? { clientManagerEmpty: true } : { clientManager } : {},
        order,
      },
    },
    { page }
  ])
)(query);
