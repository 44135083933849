import * as R from 'ramda';

import { CRM_TYPES } from 'crms/constants';

import { LOCALES } from 'localization/constants';

const getWidgetsPathWithhash = widgetHash => `//widgets.binotel.com/chat/widgets/${widgetHash}.js`;
const getHashForMT = R.cond([
  [R.equals(LOCALES.UA), R.always('Jqp9gkDUxOSl5pUqNNqk')],
  [R.equals(LOCALES.RU), R.always('qQ0CYboHNyoEw5HcRMzp')],
  [R.equals(LOCALES.EN), R.always('z9HDGy4380iVLiaHylTA')]
]);

export const generateBinotelScriptPath = locale => R.pipe(
  R.cond([
    [R.equals(CRM_TYPES.tat), R.always('eJMVMtbf8HoJeh6WKJow')],
    [R.equals(CRM_TYPES['5stars']), R.always('2KMBNW7aKnwXHrThKiWk')],
    [R.equals(CRM_TYPES.mt), () => getHashForMT(locale)]
  ]),
  getWidgetsPathWithhash
)(__CRM__);
