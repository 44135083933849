import { select, put, call } from 'redux-saga/effects';

// instruments
import { createTag } from 'api/methods/common/tags';
import { commonActions } from 'bus/common/actions';

export function* createTagWorker({ payload: name }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const tag = yield call(createTag, token, { bodyParams: { name } });

    yield put(commonActions.createTagSuccess(tag));
  } catch (error) {
    yield put(commonActions.createTagFail(error));
  }
}
