import { useEffect, memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

export const ScrollToTop = memo(() => {
  const pathname = useSelector(({ router }) => router.location.pathname, shallowEqual);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});

ScrollToTop.displayName = 'ScrollToTop';
