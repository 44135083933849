const SCOPE_SETTINGS = 'public_profile,email,ads_management,leads_retrieval,pages_show_list,pages_read_engagement,pages_manage_ads,pages_manage_metadata';

export const getMe = async () => {
  const response = await new Promise(resolve => window.FB.api('/me', resolve));

  return response;
};

export const subscribeApp = async (pageId, accessToken) => {
  const subscribeLink = `/${pageId}/subscribed_apps?subscribed_fields=leadgen&access_token=${accessToken}`;

  const response = await new Promise(resolve => window.FB.api(subscribeLink, 'post', resolve));

  return response;
};

export const unsubscribeApp = async (pageId, accessToken) => {
  const subscribeLink = `/${pageId}/subscribed_apps?subscribed_fields=leadgen&access_token=${accessToken}`;

  const response = await new Promise(resolve => window.FB.api(subscribeLink, 'delete', resolve));

  return response;
};

export const getSubscriptionsApp = async (pageId, accessToken) => {
  const subscribeLink = `/${pageId}/subscribed_apps?access_token=${accessToken}`;

  const response = await new Promise(resolve => window.FB.api(subscribeLink, resolve));

  return response;
};

export const getFbPage = async ({ userID, accessToken }) => {
  const accessPagesLink = `/${userID}/accounts?access_token=${accessToken}`;

  const { data = [], error } = await new Promise(resolve => window.FB.api(accessPagesLink, resolve));

  if (data.length) {
    const [firstPage] = data;

    return firstPage;
  }

  return error ? { error } : null;
};

export const loginFb = async () => {
  const response = await new Promise(resolve => window.FB.login(resolve, { scope: SCOPE_SETTINGS }));

  return response;
};
