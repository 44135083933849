import BaseApiClass from 'api/BaseApiClass';

export const getTags = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:id}/tags',
  method: 'GET',
  ...params,
})._load({ token });

export const deleteTag = (token, params = {}) => new BaseApiClass({
  url: 'tfs/tags/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const createTag = (token, params = {}) => new BaseApiClass({
  url: 'tfs/tags',
  method: 'POST',
  ...params,
})._load({ token });

export const updateTag = (token, params = {}) => new BaseApiClass({
  url: 'tfs/tags/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });
