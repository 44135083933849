import { call, put, race, take } from 'redux-saga/effects';

// instruments
import { commentsActions } from 'bus/clientClaim/comments';
import { clientClaimActions } from 'bus/clientClaim/actions';

export function* submitCommentFormWorker({ payload }) {
  const { claimID, commentID, comment, formik } = payload;

  !commentID && (yield put(commentsActions.createComment(claimID, comment, { UI: false })));
  commentID && (
    yield put(commentsActions.updateComment(claimID, commentID, comment, { UI: false }))
  );

  const [success, fail] = yield race(!commentID ? [
    take(commentsActions.createCommentSuccess),
    take(commentsActions.createCommentFail)
  ] : [
    take(commentsActions.updateCommentSuccess),
    take(commentsActions.updateCommentFail)
  ]);

  if (success) {
    yield put(clientClaimActions.closeAddCommentComponent());
  }

  if (fail) {
    const { errorsByValues, otherErrors, message } = fail.meta;

    if (Object.keys(errorsByValues).length) {
      yield call(formik.setErrors, errorsByValues);
    } else if (otherErrors.length) {
      yield call(formik.setStatus, { error: true, message: otherErrors.map(o => o.message).join('. ') });
    } else {
      yield call(formik.setStatus, { error: true, message });
    }

    yield call(formik.setSubmitting, false);
  }
}
