import withModalAPI from './withModalAPI';

const cache = {};

export const isDownloadedComponent = key => Boolean(cache[key]);

export const getComponent = key => cache[key];

export const saveComponent = (key, Component) => {
  cache[key] = withModalAPI(Component);
};
