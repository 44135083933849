import { put, race, take, call, all } from 'redux-saga/effects';
import { get, getIn, Map, List } from 'immutable';

// instruments
import { claimActions } from 'bus/clientClaim/claim';
import { commonActions } from 'bus/common/actions';
import { newInstance } from '../../../../../../../localization';

export function* orderStepFormWorker({ payload }) {
  const { claimID, claim, formik } = payload;
  const { created, source, ...restClaim } = claim;

  yield call(formik.setStatus, null);

  let sourceID = get(source, 'id', null);

  try {
    if (source && !sourceID) {
      yield put(commonActions.createSource(source.description));
      const [success, fail] = yield race([
        take(commonActions.createSourceSuccess),
        take(commonActions.createSourceFail)
      ]);

      if (fail) {
        throw new Error('Ошибка при создании источника');
      }

      if (success) {
        sourceID = success.payload.id;
      }
    }

    yield put(claimActions.updateClaim(claimID, { ...restClaim, source: sourceID }, { UI: false }));
    yield put(claimActions.updateDateCreated(claimID, created, { UI: false }));

    const [updateClaimFetchingResult, updateDateCreatedFetchingResult] = yield all([
      race([
        take(claimActions.updateClaimSuccess),
        take(claimActions.updateClaimFail)
      ]),
      race([
        take(claimActions.updateDateCreatedSuccess),
        take(claimActions.updateDateCreatedFail)
      ])
    ]);

    const processedErrors = [getIn(updateClaimFetchingResult, [1, 'meta']), getIn(updateDateCreatedFetchingResult, [1, 'meta'])].filter(o => o);

    if (processedErrors.length) {
      const combineErrors = processedErrors.reduce((acc, error) => {
        const { errorsByValues, otherErrors, message } = error;

        return {
          errorsByValues: acc.errorsByValues.merge(errorsByValues),
          otherErrors: acc.otherErrors.merge(otherErrors),
          messages: acc.messages.merge(message),
        };
      }, { errorsByValues: Map(), otherErrors: List(), messages: List() });

      if (!combineErrors.errorsByValues.isEmpty()) {
        yield call(formik.setErrors, combineErrors.errorsByValues.toObject());
      } else if (!combineErrors.otherErrors.isEmpty()) {
        yield call(formik.setStatus, { error: true, message: combineErrors.otherErrors.map(({ message }) => message).join('. ') });
      } else {
        yield call(formik.setStatus, { error: true, message: combineErrors.messages.toSet().toArray().join('. ') });
      }
    } else {
      yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') });
    }
  } catch (error) {
    yield call(formik.setStatus, { error: true, message: error.message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
