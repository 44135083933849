import { get, getIn, List, Map } from 'immutable';
import { select, put, call } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { claimActions } from 'bus/clientClaim/claim';
import { updateClaim } from 'api/methods/claim/updateClaim';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['clientClaim', 'claim', 'update'];

export function* updateClaimBaseWorker({ payload }) {
  const { claimID, claim, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, claimID],
  })));

  try {
    const response = yield call(
      updateClaim,
      token, { pathParams: { id: claimID }, bodyParams: claim }
    );

    yield put(claimActions.updateClaimSuccess(response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    let errorsByValues = Map();

    let otherErrors = List();

    switch (status) {
      case 400: {
        const isSetViolations = getIn(body, ['violations', 'length'], false);

        if (isSetViolations) {
          const resultsParseErrors = parseErrorsByFormik(claim, body.violations);

          errorsByValues = errorsByValues.merge(resultsParseErrors.errorsByValues);
          otherErrors = otherErrors.merge(resultsParseErrors.otherErrors);

          if (errorsByValues.isEmpty() && otherErrors.isEmpty()) {
            message = 'Ошибка сервера.';
          }
        } else {
          message = 'Ошибка сервера.';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу ‘Заказы’ или к заказу для закрытия.');
        break;
      }
      case 404: {
        message = 'Заказ отсутствует.';
        break;
      }
      default: {
        message = 'Ошибка сервера.';
      }
    }

    yield put(claimActions.updateClaimFail(error, { message, errorsByValues, otherErrors }));

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, claimID, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, claimID, 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, claimID, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, claimID, 'completed'],
    })));
  }
}
