import React, { memo } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// instruments
import { declOfNum } from 'helpers/declension';
import book from 'routes/book';

export const NotifyLimitUsers = memo(({ onUnmount, activeUsers, limitUsers }) => {
  const { t } = useTranslation(['USER_LIMIT', 'SETS']);

  return (
    <Modal open size="small" onClose={onUnmount}>
      <Modal.Header content={t('LIMIT_EXCEEDED')} />
      <Modal.Content>
        { t('SUBSCRIPTION_IS_PAID') }
        {' '}
        { limitUsers }
        {' '}
        { declOfNum(limitUsers, t('USER_SET', { returnObjects: true })) }
        ,
        {' '}
        { t('BUT_NOW') }
        {' '}
        { activeUsers }
        {' '}
        { declOfNum(activeUsers, t('ACTIVE_SET', { returnObjects: true })) }
        {' '}
        { declOfNum(activeUsers, t('USER_SET', { returnObjects: true })) }
        .
        {' '}
        { t('PLEASE') }
        {' '}
        <Link to={book.details.children.subscription} onClick={onUnmount}>{ t('EXPAND_SUBSCRIPTION') }</Link>
        {' '}
        { t('OR') }
        {' '}
        <Link to={book.details.path} onClick={onUnmount}>{ t('TURN_OFF_USERS') }</Link>
        .
      </Modal.Content>
      <Modal.Actions>
        <Button content="Ок" type="button" onClick={onUnmount} />
      </Modal.Actions>
    </Modal>
  );
});
