import { select, call, put } from 'redux-saga/effects';

import { commonActions } from 'bus/common/actions';
import { getToken } from '../../../../auth/selectors.js';
import { updateOperator } from '../../../../../api/methods/common/operators';

export function* updateOperatorWorker({ payload: { id, bodyParams } }) {
  const token = yield select(getToken);

  try {
    const data = yield call(updateOperator, token, { pathParams: { id }, bodyParams });

    yield put(commonActions.updateOperatorSuccess(data));
  } catch (error) {
    yield put(commonActions.updateOperatorFail(error));
  }
}
