import React, { memo } from 'react';
import cx from 'classnames';
import { generatePath, Link } from 'react-router-dom';

import { ClaimDescription } from 'components/clientClaim/ClaimDescription';

import book from 'routes/book';

import Styles from './styles.scss';

const C = ({ claim, className, handleClick }) => {
  const { active, id, client, performer } = claim;

  return (
    <p
      className={cx(Styles.root, className, {
        [Styles.disable]: !active
      }
      )}
      onClick={handleClick}
    >
      {id
        ? (
          <ClaimDescription
            claim={claim}
            showIcon={false}
            disabledLink
          />
        )
        : (
          <Link
            to={generatePath(book.clientClaim.children.profile, { clientId: client.id })}
          >
            {client.name}
            {' '}
            {client.mail}
          </Link>
        )}
      { performer && (
        <span className={Styles.manager}>
          {' '}
          { performer.username }
        </span>
      ) }
    </p>
  );
};

C.defaultProps = {
  claim: '',
  className: null,
  handleClick: () => {},
};

C.displayName = 'SearchClaimItem';
export const SearchClaimItem = memo(C);
