import { call, takeEvery, put } from 'redux-saga/effects';
import * as R from 'ramda';

import { removeBasketWorker } from 'bus/tourscaner/saga/workers/baskets/removeBasket';
import { tourscanerActions } from 'bus/tourscaner';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { removeOfferFromBasketWorker } from 'bus/tourscaner/saga/workers/baskets/removeOfferFromBasket';
import { updateOfferInBasketSaga } from 'bus/tourscaner/saga/workers/baskets/updateOfferInBasket';

import { removeBasket, removeOfferFromBasket, updateOfferInBasket } from './actions';

function* removeBasketSaga({ payload }) {
  const { callbacks, basketId } = payload;

  yield call(removeBasketWorker, tourscanerActions.removeBasket(callbacks, basketId));

  yield put(clientClaimActions.deleteBasket(basketId));
}

function* removeOfferFromBasketSaga({ payload }) {
  const { callbacks, id, basketID, offer } = payload;

  yield call(
    removeOfferFromBasketWorker,
    tourscanerActions.removeOfferFromBasket(callbacks, id, basketID, offer)
  );

  yield put(clientClaimActions.removeOfferFromBasket(basketID, id));
}

const basketMapper = basket => R.pipe(
  R.over(R.lensProp('claim'), R.always(null)),
  R.over(R.lensProp('user'), R.always(null))
)(basket);
function* updateOfferInBasketWorker({ payload }) {
  const { callbacks, basketId, offerId, offer } = payload;
  const { onSuccess, onFail } = callbacks;

  try {
    const basket = yield call(updateOfferInBasketSaga, basketId, offerId, offer);

    const mappedBasket = basketMapper(basket);

    onSuccess && onSuccess(mappedBasket);
    yield put(clientClaimActions.setBasket(basketId, mappedBasket));
  } catch (error) {
    console.log(error);
    onFail && onFail(error);
  }
}

export function* tourFormRootSaga() {
  yield takeEvery(removeBasket, removeBasketSaga);
  yield takeEvery(removeOfferFromBasket, removeOfferFromBasketSaga);
  yield takeEvery(updateOfferInBasket, updateOfferInBasketWorker);
}
