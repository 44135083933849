import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// actions
import { uiActions } from 'bus/ui/actions';
import { epassportsActions } from 'bus/clientClaim/epassports';

// instruments
import { updatePassport } from 'api/methods/claim/passports';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['clientClaim', 'epassports', 'update'];

export function* updateEpassportBaseWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { passport, id, claimId, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, id],
  })));

  try {
    const response = yield call(
      updatePassport,
      token,
      { bodyParams: { ...passport, claim_id: claimId }, pathParams: { id } }
    );

    yield put(epassportsActions.updateEpassportSuccess(response, id));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    const processedErrors = {};

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);
        const { errorsByValues, otherErrors } = parseErrorsByFormik(passport, violations);

        processedErrors.errorsByValues = errorsByValues;
        processedErrors.otherErrors = otherErrors;

        break;
      }
      case 404: {
        message = 'Такого загранпаспорта не существует';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу ‘Клиенты’ или к конкретному заказу паспорта или активна подписка только на Турсканер');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    processedErrors.message = message;

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, id, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, id, 'message'],
    })));
    yield put(epassportsActions.updateEpassportFail(error, processedErrors));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, id, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, id, 'completed'],
    })));
  }
}
