import { bookingActions as actions } from 'bus/booking/actions';

export const bookingPaymentsReducer = {
  [actions.getBookingPaymentsSuccess]: (state, { payload: payments }) => state.setIn(['booking', 'payments'], payments),
  [actions.createBookingPaymentSuccess]: (state, { payload: payment }) => {
    return state.updateIn(['booking', 'payments'], payments => [...payments, payment]);
  },
  [actions.updateBookingPaymentSuccess]: (state, { payload: payment }) => {
    const serviceIdx = state
      .getIn(['booking', 'payments'])
      .findIndex(({ id }) => id === payment.id);

    return state.setIn(['booking', 'payments', serviceIdx], payment);
  },
};
