import { select, call } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { moveOfferInBasket } from 'api/methods/tourscaner/baskets';

export function* moveOfferInBasketWorker({ payload }) {
  const { callbacks, basketId, offerId, position } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userId = yield select(getUserId);

  try {
    yield call(
      moveOfferInBasket,
      token,
      { pathParams: { userId, basketId, offerId }, bodyParams: { position } }
    );

    onSuccess && onSuccess();
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
