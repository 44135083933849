import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import * as R from 'ramda';

import { statisticActions } from 'bus/statistic/actions';
import { getToken, getUserTfId } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';
import { getPathName } from 'bus/router/selectors';

import { getErrorMessage } from 'api/fn/parseErrors';
import { getSalesFunnel } from 'api/methods/main/getSalesFunnel';

import { setQueryToCache } from 'helpers/cookies';

import { getSalesFunnel as getSalesFunnelSelector } from '../selectors';

import { filterQuery } from './helpers';

import { SALES_FUNNEL_COOKIE_KEY } from './constants';

export const GET_SALES_FUNNEL_UI = 'GET_SALES_FUNNEL';

const prepareQuerySearchString = (query, page) => new URLSearchParams(
  R.pipe(
    filterQuery,
    ({ location, ...rest }) => R.mergeAll([
      rest,
      page === 1 ? {} : { page },
      location ? { location: [location.value, location.type, location.title] } : {}
    ])
  )(query)
);

const prepareBodyParams = query => R.pipe(
  filterQuery,
  ({ location, ...rest }) => ({
    ...rest,
    ...location ? { [location.type]: location.value } : {},
  })
)(query);

function* setSearchParamsToQuery(query, page) {
  const pathname = yield select(getPathName);

  const querySearchString = prepareQuerySearchString(query, page);

  yield call(setQueryToCache, SALES_FUNNEL_COOKIE_KEY, querySearchString, { expires: 0.20 });

  yield put(replace(`${pathname}?${querySearchString}`));
}

function* getSalesFunnelWorker({ payload: onSuccess }) {
  const token = yield select(getToken);
  const { page, query } = yield select(getSalesFunnelSelector);
  const id = yield select(getUserTfId);

  yield call(setSearchParamsToQuery, query, page);

  try {
    const { total, results, total_with_opened: totalWithOpened } = yield call(getSalesFunnel, token, { bodyParams: { filters: prepareBodyParams(query), page }, pathParams: { id }, });

    yield put(statisticActions.getSalesFunnelSuccess(R.values(results), total, totalWithOpened));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Отсутствует доступ к разделу или активна подписка только на Турсканер',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(statisticActions.getSalesFunnelFail(error));
  } finally {
    onSuccess && onSuccess(false);
  }
}

export default withUIWorker(
  [statisticActions.getSalesFunnelSuccess, statisticActions.getSalesFunnelFail],
  GET_SALES_FUNNEL_UI,
)(getSalesFunnelWorker);
