import { select, put, call } from 'redux-saga/effects';

// instruments
import { getOffices } from 'api/methods/common/offices';
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';

export function* getOfficesWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: '' },
    path: ['common', 'offices'],
  }));

  try {
    const response = yield call(getOffices, token, {});

    yield put(commonActions.getOfficesSuccess(response));
  } catch (error) {
    yield put(commonActions.getOfficesFail(error));
    yield put(uiActions.changeUiLoaderFlag({ status: 'Ошибка сервера', path: ['common', 'offices', 'message'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'offices', 'error'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'offices', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'offices', 'completed'] }));
  }
}
