import { put, call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { bookingActions } from 'bus/booking/actions';

import { getBookings } from 'api/methods/booking';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { getDataFromSelectors, onSaveSearchToCache, withSetQuery2Url } from 'services/formCreator/saga';

import { getBookingAdminSearchOrder, getBookingSearchPage, getBookingUserSearchOrder, getQuery } from '../../../selectors';

import {
  prepareAdminBookingQuery,
  prepareAdminBookingValues,
  prepareClientBookingQuery,
  prepareClientBookingValues
} from './helpers';

import { BOOKING_FORM_SEARCH_COOKIE_KEY } from './constants';

const BASE_UI_PATH = ['booking', 'search'];

export function* searchBookingsWorker({ type }) {
  const { token, query, page } = yield select(({ auth, router, booking }) => ({
    token: auth.get('token'),
    pathname: router.location.pathname,
    search: router.location.search,
    query: booking.getIn(['search', 'query']),
    page: booking.getIn(['search', 'page']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  const isAdminBooking = type === bookingActions.searchAdminBooking.toString();

  const processQuery = isAdminBooking
    ? prepareAdminBookingValues(query)
    : prepareClientBookingValues(query);

  const sort = yield select(isAdminBooking ? getBookingAdminSearchOrder : getBookingUserSearchOrder);

  try {
    const { bookings, total, limit } = yield call(getBookings, token, { queryParams: { ...processQuery, page, sort } });

    yield put(bookingActions.searchSuccess({ results: bookings, total, limit }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(query, violations);

          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));

    yield put(bookingActions.searchFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}

const actions = [bookingActions.searchSuccess, bookingActions.searchFail];

export const clientBookingSearchWithReplaceQuery = withSetQuery2Url(
  actions,
  {
    onGetData: getDataFromSelectors([getQuery, getBookingSearchPage, getBookingUserSearchOrder], prepareClientBookingQuery),
    onSuccess: onSaveSearchToCache(BOOKING_FORM_SEARCH_COOKIE_KEY)
  }
)(searchBookingsWorker);

export const adminBookingSearchWithReplaceQuery = withSetQuery2Url(
  actions,
  {
    onGetData: getDataFromSelectors(
      [getQuery, getBookingSearchPage, getBookingAdminSearchOrder],
      prepareAdminBookingQuery
    ),
  }
)(searchBookingsWorker);
