import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

export const getRoles = (token, params = {}) => new BaseApiClass({
  url: 'roles',
  ...params,
})
  .setParseBodyToClientSchema(R.map(
    R.over(R.lensProp('translations'), R.indexBy(R.prop('locale')))
  ))
  ._load({ token });
