import { select, call, put } from 'redux-saga/effects';

// instruments
import { getGuarantees } from 'api/methods/tf/docs';
import { uiActions } from 'bus/ui/actions';
import { detailDocsActions } from 'bus/detailDocs/actions';

const uiErrorPath = ['detailDocs', 'fetchGuarantees', 'error'];
const uiSuccessPath = ['detailDocs', 'fetchGuarantees', 'success'];

export function* fetchGuaranteesWorker() {
  const token = yield select(({ auth }) => auth.get('token'));
  const hasError = yield select(({ ui }) => ui.getIn(uiErrorPath));

  hasError && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: uiErrorPath })));
  yield put(uiActions.changeUiLoaderFlag({ status: true, path: uiSuccessPath }));

  try {
    const response = yield call(getGuarantees, token);

    yield put(detailDocsActions.fetchGuaranteesSuccess(response));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: uiErrorPath }));
    yield put(detailDocsActions.fetchGuarantessFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: uiSuccessPath }));
  }
}
