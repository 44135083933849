import { compose } from 'redux';
import { Map } from 'immutable';
import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

import { removeNull, removeEmptyString } from 'helpers';

import { createGroupTypesData, createQueryFromApi } from '../helpers/createQuery';

const excludeFields = ['initialFiltersVisible'];

export const processedQuery = query => {
  const params = Map(query).filter((...[, key]) => !excludeFields.includes(key)).toObject();

  const {
    groupTypes,
    ...rest
  } = params;

  return {
    filters: compose(removeNull, removeEmptyString)(createQueryFromApi(rest)),
    resultTypes: ['sales', 'tourists'],
    groupingTypes: createGroupTypesData(groupTypes),
  };
};

export const getFlexibleReport = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'statistics',
    method: 'POST',
    ...params,
  });

  instance.convertBodyToServerSchema = ({ resultTypes, groupTypes, filters }) => {
    return {
      filters: R.dissoc('resultTypes', filters),
      resultTypes,
      groupingTypes: createGroupTypesData(groupTypes),
    };
  };

  return instance._load({ token });
};
