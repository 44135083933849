import { call, select, put, race, take } from 'redux-saga/effects';

// instruments
import { clientClaimActions } from 'bus/clientClaim/actions';
import { mergeClients } from 'api/methods/client/mergeClients';

export function* mergeConflictClientsWorker({ payload }) {
  const { claim, formik, offer } = payload;
  const { client, id } = yield select(({ clientClaim }) => ({
    client: clientClaim.getIn(['conflictClients', 'currentClient']),
    id: clientClaim.getIn(['conflictClients', 'oldClient', 'id']),
  }));
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(clientClaimActions.resetConflictClients());

  try {
    const newClient = yield call(mergeClients, token, { pathParams: { id }, bodyParams: client });

    yield put(clientClaimActions.createClientSuccess(newClient));

    if (claim) {
      yield put(clientClaimActions.createClaim({ client: id, ...claim }, formik, offer));

      const [claimSuccess, claimFail] = yield race([
        take(clientClaimActions.createClaimSuccess),
        take(clientClaimActions.createClaimFail)
      ]);

      if (claimSuccess) {
        const meta = {
          client: newClient,
          claim: claimSuccess.payload,
        };

        formik && (yield call(formik.setStatus, { success: true, meta }));
      }

      if (claimFail) {
        // fail logic in claim worker
      }
    }

    !claim && formik && (yield call(
      formik.setStatus, { success: true, meta: { client: newClient } })
    );
  } catch (error) {
    yield put(clientClaimActions.createClientFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
