import React, { memo, useMemo, useState } from 'react';
import { Modal, Image, Checkbox, Button, Message } from 'semantic-ui-react';
import moment from 'moment';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import book from 'routes/book';

import styles from './styles.scss';

const TOURSCANER_NEWS = 3;

const NewsPopup = ({
  id,
  title,
  preview_image_full_url: img,
  preview,
  publish_date: date,
  importance,
  ui,
  onConfirmViewdNews,
}) => {
  const { t } = useTranslation(['NEWS_MODAl', 'GLOBAL']);

  const [confirm, setConfirm] = useState(importance < 2 || importance === TOURSCANER_NEWS);

  const publishData = useMemo(() => moment(date).format('DD MMMM YYYY HH:mm'), [date]);

  return (
    <Modal open className={styles.root}>
      <Modal.Header content={title} />
      { img && (
        <Modal.Content>
          <Image fluid src={img} />
        </Modal.Content>
      ) }
      <Modal.Content>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: preview }} />
        <small className={styles.publishData}>{ publishData }</small>
      </Modal.Content>
      { importance === 2 && (
        <Modal.Content>
          <Checkbox
            toggle
            checked={confirm}
            disabled={ui.loading}
            label={t('READ_THIS_NEWS')}
            onChange={() => !ui.loading && setConfirm(!confirm)}
          />
          { ui.error && (
            <Message error visible header={ui.message} />
          ) }
        </Modal.Content>
      ) }
      <Modal.Actions>
        <Button
          as={Link}
          color="blue"
          content={t('READ_MORE')}
          to={generatePath(book.news.children.single, { id })}
        />
        <Button
          content={t('CLOSE')}
          disabled={!confirm || ui.loading}
          loading={ui.loading}
          type="button"
          onClick={!ui.loading ? onConfirmViewdNews : () => undefined}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default memo(NewsPopup);
