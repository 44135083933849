import { put, call, select } from 'redux-saga/effects';

import { uiActions } from 'bus/ui/actions';
import { accountingActions } from 'bus/accounting/actions';
import { getToken } from 'bus/auth/selectors';

import { deleteAkt } from 'api/methods/accounting';
import { getErrorMessage } from 'api/fn/parseErrors.js';

export const AKT_DELETED_PATH = ['akt', 'deleted'];

export function* deleteAktWorker({ payload: id }) {
  const token = yield select(getToken);

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...AKT_DELETED_PATH, id],
  }));

  try {
    yield call(deleteAkt, token, { pathParams: { id } });

    yield put(accountingActions.deleteAktSuccess([id]));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Контрагент не найден',
      }
    );

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...AKT_DELETED_PATH, id, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...AKT_DELETED_PATH, id, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...AKT_DELETED_PATH, id, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...AKT_DELETED_PATH, id, 'completed'] }));
  }
}
