import BaseApiClass from 'api/BaseApiClass';

export const getCommission = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/group',
  method: 'GET',
  ...params,
})._load({ token });
export const getCommissionForCounterparties = (token, params = {}) => new BaseApiClass({
  url: 'counterparty/{:id}/group',
  method: 'GET',
  ...params,
})._load({ token });

export const getCounterpartyGroups = token => new BaseApiClass({
  url: 'tf/groups',
  method: 'GET',
})._load({ token });

export const getCounterpartyCommissionGroups = token => new BaseApiClass({
  url: 'tf/commision-groups',
  method: 'GET',
})._load({ token });
