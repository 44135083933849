import RejectedIcon from 'assets/icons/close.svg';
import ConfirmIcon from 'assets/icons/check-icon.svg';
import { newInstance } from '../../localization';

export const BOOKING_DATE_FORMAT = 'DD.MM.YYYY HH:mm';

const NEW_STATUS = 'new';
const PREBOOKING_STATUS = 'prebooking';
const AVAILABLE_FOR_PAYMENT_STATUS = 'availableForPayment';
const CONFIRMED_STATUS = 'confirmed';
const EXPIRED = 'expired';
const CANCELED_STATUS = 'canceled';
const ON_BOOKING_STATUS = 'onBooking';
const NOT_CONFIRMED = 'notConfirmed';
const WITHOUT_STATUS = 'withoutStatus';

export const STATUSES_LIST_BY_CLAIM_STATUS = [
  NEW_STATUS,
  PREBOOKING_STATUS,
  AVAILABLE_FOR_PAYMENT_STATUS,
  CONFIRMED_STATUS,
  EXPIRED,
  CANCELED_STATUS,
  NOT_CONFIRMED,
  ON_BOOKING_STATUS,
  WITHOUT_STATUS
];

export const BOOKING_STATUS_MAP = {
  [NEW_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:RESERVATION');
    }
  },
  [PREBOOKING_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:PRELIMINARY');
    }
  },
  [AVAILABLE_FOR_PAYMENT_STATUS]: {
    icon: ConfirmIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:AVAILABLE_FOR_PAYMENT');
    }
  },
  [CONFIRMED_STATUS]: {
    icon: ConfirmIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:CONFIRMED');
    }
  },
  [EXPIRED]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:EXPIRED');
    }
  },
  [CANCELED_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:CANCELLED');
    }
  },
  [NOT_CONFIRMED]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:NOT_CONFIRMED');
    }
  },
  [ON_BOOKING_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:ON_BOOKING');
    }
  },
  [WITHOUT_STATUS]: {
    icon: RejectedIcon,
    get title() {
      return newInstance.t('BOOKING_STATUSES:NO_STATUS');
    }
  },
};

export const CALCULATION_TYPES = {
  THROUGH_CO: 0,
  PERCENT_CO: 1,
  OWN_BOOKING: 2,
  PAYBACK_FROM_CO: 3,
  VIA_CO_GROSS: 4,
  GROSS_FROM_AGENT: 5,
};
export const BOOKING_PAYMENT_SCHEDULE_OPTIONS = [
  {
    get text() { return newInstance.t('GLOBAL:ALL'); },
    value: null
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:NO_PAYMENT_SCHEDULE'); },
    value: CALCULATION_TYPES.PERCENT_CO
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:EXPIRED'); },
    value: CALCULATION_TYPES.OWN_BOOKING
  },
  {
    get text() { return newInstance.t('STAT:TODAY'); },
    value: CALCULATION_TYPES.PAYBACK_FROM_CO
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:TOMORROW'); },
    value: CALCULATION_TYPES.VIA_CO_GROSS
  },
  {
    get text() { return newInstance.t('BOOKING_FILTERS_PAGE:DAY_AFTER_TOMORROW'); },
    value: CALCULATION_TYPES.GROSS_FROM_AGENT
  },
];
export const BOOKING_CALCULATION_TYPES_OPTIONS = [
  {
    get text() { return newInstance.t('BOOKING_MONEY:THROUGH_CO'); },
    value: CALCULATION_TYPES.THROUGH_CO
  },
  {
    get text() { return newInstance.t('BOOKING_MONEY:PERCENT_CO'); },
    value: CALCULATION_TYPES.PERCENT_CO
  },
  {
    get text() { return newInstance.t('BOOKING_MONEY:OWN_BOOKING'); },
    value: CALCULATION_TYPES.OWN_BOOKING
  },
  {
    get text() { return newInstance.t('BOOKING_MONEY:COMMISSION_TO_BOOKING'); },
    value: CALCULATION_TYPES.PAYBACK_FROM_CO
  },
  {
    get text() { return newInstance.t('BOOKING_MONEY:VIA_CO_GROSS'); },
    value: CALCULATION_TYPES.VIA_CO_GROSS
  },
  {
    get text() { return newInstance.t('BOOKING_MONEY:GROSS_FROM_AGENT'); },
    value: CALCULATION_TYPES.GROSS_FROM_AGENT
  },
];
export const BOOKING_SERVICES_TYPES_OPTIONS = [
  {
    value: 0,
    get text() {
      return newInstance.t('SERVICES_TYPES:HOTEL');
    }
  },
  {
    value: 1,
    get text() {
      return newInstance.t('SERVICES_TYPES:FLIGHT');
    }
  },
  {
    value: 2,
    get text() {
      return newInstance.t('SERVICES_TYPES:TRANSFER');
    }
  },
  {
    value: 3,
    get text() {
      return newInstance.t('SERVICES_TYPES:INSURANCE');
    }
  },
  {
    value: 4,
    get text() {
      return newInstance.t('SERVICES_TYPES:SURCHARGE');
    }
  },
  {
    value: 5,
    get text() {
      return newInstance.t('SERVICES_TYPES:EXCURSION');
    }
  },
  {
    value: 6,
    get text() {
      return newInstance.t('SERVICES_TYPES:LUGGAGE');
    }
  },
];
export const BOOKING_TRANSPORT_DIRECTION_MAP = {
  OUTBOUND: {
    get text() {
      return newInstance.t('CLAIM:OUTBOUND');
    },
    value: 0
  },
  INBOUND: {
    get text() {
      return newInstance.t('CLAIM:INBOUND');
    },
    value: 1
  },
  TRANSITIONAL: {
    get text() {
      return newInstance.t('CLAIM:TRANSITIONAL');
    },
    value: 2
  },
};

export const FLIGHT = 1;
export const BUS = 2;
export const SIGHTSEEING = 3;
export const HOTEL_ONLY = 4;
export const FLIGHT_TICKETS = 5;
export const BUS_TICKETS = 6;
export const TRANSFER = 7;
export const INSURANCE = 8;
export const PROMOTIONAL = 9;
export const CRUISE = 10;
export const OTHER_TYPE = 11;

export const BOOKING_TYPES = {
  [FLIGHT]: {
    value: FLIGHT,
    get text() {
      return newInstance.t('BOOKING_TYPES:FLIGHT');
    }
  },
  [BUS]: {
    value: BUS,
    get text() {
      return newInstance.t('BOOKING_TYPES:BUS');
    }
  },
  [SIGHTSEEING]: {
    value: SIGHTSEEING,
    get text() {
      return newInstance.t('BOOKING_TYPES:SIGHTSEEING');
    }
  },
  [HOTEL_ONLY]: {
    value: HOTEL_ONLY,
    get text() {
      return newInstance.t('BOOKING_TYPES:HOTEL_ONLY');
    }
  },
  [FLIGHT_TICKETS]: {
    value: FLIGHT_TICKETS,
    get text() {
      return newInstance.t('BOOKING_TYPES:FLIGHT_TICKETS');
    }
  },
  [BUS_TICKETS]: {
    value: BUS_TICKETS,
    get text() {
      return newInstance.t('BOOKING_TYPES:BUS_TICKETS');
    }
  },
  [TRANSFER]: {
    value: TRANSFER,
    get text() {
      return newInstance.t('BOOKING_TYPES:TRANSFER');
    }
  },
  [INSURANCE]: {
    value: INSURANCE,
    get text() {
      return newInstance.t('BOOKING_TYPES:INSURANCE');
    }
  },
  [PROMOTIONAL]: {
    value: PROMOTIONAL,
    get text() {
      return newInstance.t('BOOKING_TYPES:PROMOTIONAL');
    }
  },
  [CRUISE]: {
    value: CRUISE,
    get text() {
      return newInstance.t('BOOKING_TYPES:CRUISE');
    }
  },
  [OTHER_TYPE]: {
    value: OTHER_TYPE,
    get text() {
      return newInstance.t('BOOKING_TYPES:OTHER');
    }
  }
};

export const BOOKING_TYPES_OPTIONS = Object.values(BOOKING_TYPES);

export const BOOKING_STATUSES_WITH_TRANSLATE = [
  {
    id: 0,
    value: 0,
    get text() {
      return newInstance.t('BOOKING_STATUSES:RESERVATION');
    }
  },
  {
    id: 7,
    value: 7,
    get text() {
      return newInstance.t('BOOKING_STATUSES:ON_BOOKING');
    }
  },
  {
    id: 1,
    value: 1,
    get text() {
      return newInstance.t('BOOKING_STATUSES:PRELIMINARY');
    }
  },
  {
    id: 2,
    value: 2,
    get text() {
      return newInstance.t('BOOKING_STATUSES:AVAILABLE_FOR_PAYMENT');
    }
  },
  {
    id: 3,
    value: 3,
    get text() {
      return newInstance.t('BOOKING_STATUSES:CONFIRMED');
    }
  },
  {
    id: 4,
    value: 4,
    get text() {
      return newInstance.t('BOOKING_STATUSES:EXPIRED');
    }
  },
  {
    id: 5,
    value: 5,
    get text() {
      return newInstance.t('BOOKING_STATUSES:CANCELLED');
    }
  },
  {
    id: 6,
    value: 6,
    get text() {
      return newInstance.t('BOOKING_STATUSES:NOT_CONFIRMED');
    }
  },
  {
    id: 8,
    value: 8,
    get text() {
      return newInstance.t('BOOKING_STATUSES:NO_STATUS');
    }
  }
];

export const BOOKING_SOURCES = {
  TOURSCANER: 2,
  PARSET: 1,
  FROM_CO: 0,
};
export const BOOKING_PROCESSING_STATUSES = {
  NEW: 0,
  PROCESSING: 1,
  PROCESSED: 2,
  DELAYED: 3,
};
