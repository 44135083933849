import { put, call, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { get, getIn } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { accountingActions as actions } from 'bus/accounting/actions';

import { searchAccountingInvoices } from 'api/methods/accounting';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { compile } from 'helpers/hash';

const BASE_UI_PATH = ['accounting', 'search'];

export function* searchInvoicesWorker({ payload = {} }) {
  const { formik } = payload;

  const {
    token,
    pathname,
    query,
    limit,
    page,
    search,
    sort,
    order
  } = yield select(({ auth, router, accounting }) => ({
    token: auth.get('token'),
    pathname: router.location.pathname,
    search: router.location.search,
    query: accounting.getIn(['search', 'query']),
    page: accounting.getIn(['search', 'page']),
    order: accounting.getIn(['search', 'order']),
    sort: accounting.getIn(['search', 'sort']),
    limit: accounting.getIn(['search', 'data', 'limit']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  const searchParams = new URLSearchParams(search);

  searchParams.set('form', compile(query));
  searchParams.set('limit', limit);
  searchParams.set('page', page);
  searchParams.set('sort', sort);
  searchParams.set('order', order);

  yield put(replace(`${pathname}?${searchParams}`));

  try {
    const data = yield call(
      searchAccountingInvoices,
      token,
      { queryParams: { ...query, limit, page, sort, order } }
    );

    yield put(actions.searchSuccess(data));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(query, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));

    yield put(actions.searchFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));

    formik && (yield call(formik.setSubmitting, false));
  }
}
