import { select, put, call } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { getScenarios } from 'api/methods/tf/scenarios';
import { tfActions } from '../../actions';

export function* fetchScenariosListWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['loadingTfScenariosList'],
  }));

  try {
    const response = yield call(getScenarios, token);

    yield put(tfActions.fetchScenariosListSuccess(response));
  } catch (error) {
    yield put(tfActions.fetchScenariosListFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['loadingTfScenariosList'],
    }));
  }
}
