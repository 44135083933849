import { call, put, select } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors.js';

import { getOperatorsDocs } from 'api/methods/common/operators';

import { commonActions } from 'bus/common/actions';
import { withUIWorker } from 'bus/ui/helpers';
import { DOCS_UI_KEY } from 'bus/common/constants.js';

const FIELD_NAME = 'claim.document';

function* getOperatorsDocsWorkerWithoutUI({ payload: { id, onSuccess } }) {
  const token = yield select(getToken);

  try {
    const docs = yield call(getOperatorsDocs, token, { pathParams: { id } });

    yield put(commonActions.getOperatorsMtDocsSuccess(docs));

    onSuccess && onSuccess(FIELD_NAME, docs.length ? docs[0].id : null);
  } catch (error) {
    yield put(commonActions.getOperatorsMtDocsFail(error));
  }
}

export const getOperatorsDocsWorker = withUIWorker(
  [commonActions.getOperatorsMtDocsSuccess, commonActions.getOperatorsMtDocsFail],
  DOCS_UI_KEY,
)(getOperatorsDocsWorkerWithoutUI);
