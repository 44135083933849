import { apiKeysActions as actions } from './actions';

export const apiKeysState = {
  apiKeys: {
    keys: [],
  },
};

export const apiKeysReducer = Object.freeze({
  [actions.getApiKeysSuccess]: (state, { payload: keys }) => state.setIn(['apiKeys', 'keys'], keys),
  [actions.createApiKeySuccess]: (state, { payload: key }) => state.updateIn(['apiKeys', 'keys'], keys => [...keys, key]),
  [actions.deleteApiKeySuccess]: (state, { payload: name }) => state.updateIn(['apiKeys', 'keys'], keys => keys.filter(key => key.name !== name)),

});
