import { call, select, put } from 'redux-saga/effects';

// instruments
import { tfActions } from 'bus/tf/actions';
import { uiActions } from 'bus/ui/actions';
import { getRoles } from 'api/methods/common/roles';

export function* fetchRolesListWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['tf', 'loadingRolesList'],
  }));

  try {
    const response = yield call(getRoles, token);

    yield put(tfActions.fetchRolesListSuccess(response));
  } catch (error) {
    yield put(tfActions.fetchRolesListFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['tf', 'loadingRolesList'],
    }));
  }
}
