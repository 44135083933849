import BaseApiClass from 'api/BaseApiClass';

export const getNotifyTypes = (token, params = {}) => new BaseApiClass({
  url: 'tfs/notifies/types',
  method: 'GET',
  ...params,
})._load({ token });

export const getNotifyInfo = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:id}/notifies',
  method: 'GET',
  ...params,
})._load({ token });

export const updateNotify = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tfID}/notifies/{:notifyID}',
  method: 'PUT',
  ...params,
})._load({ token });

export const getValueNotifyByType = (token, params = {}) => new BaseApiClass({
  url: 'v2/tfs/{:tfID}/notifies/{:type}',
  method: 'GET',
  ...params,
})._load({ token });

export const getDefaultValueNotifyByType = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tfID}/notifies/{:type}/default',
  method: 'GET',
  ...params,
})._load({ token });

export const createNotify = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:id}/notifies',
  method: 'POST',
  ...params,
})._load({ token });
