import { race, takeEvery, put, take } from 'redux-saga/effects';

import { registerUI, updateUI } from 'bus/ui/actions';
import { financeActions } from 'bus/finance/actions';
import { createUi } from 'bus/ui/helpers';

import { initialize } from './actions';
import { UI_KEY } from './constants';

function* initializePageSaga() {
  yield put(registerUI(createUi({ loading: true }), UI_KEY));

  yield race([
    take(financeActions.getPaymentsSuccess),
    take(financeActions.getPaymentsFail)
  ]);

  yield put(updateUI({ completed: true, loading: false }, UI_KEY));
}

export default function* watcher() {
  yield takeEvery(initialize, initializePageSaga);
}
