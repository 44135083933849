import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

// insturments
import { deleteClaimSource } from 'api/methods/common/claimSource';
import { commonActions as actions } from 'bus/common/actions';

export function* deleteClaimSourceWorker({ payload }) {
  const { id, replace, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    yield call(deleteClaimSource, token, { pathParams: { id }, bodyParams: { replace } });

    yield put(actions.deleteClaimSourceSuccess(id));
    yield call(formik.setStatus, { success: true });
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
