import React, { memo } from 'react';
import { Button } from 'semantic-ui-react';

// assets
import PlusIcon from 'assets/icons/plus.svg';

// styles
import cx from 'classnames';
import Styles from './styles.scss';

const C = ({ content, handleClick, className, small, icon, loading, ...props }) => {
  return (
    <div className={cx(Styles.labeledButton, { [Styles.small]: small }, className)}>
      <Button
        content={content}
        icon={loading ? undefined : <div className={Styles.icon} dangerouslySetInnerHTML={{ __html: icon }} />}
        loading={loading}
        {...props}
        onClick={handleClick}
      />
    </div>
  );
};

C.defaultProps = {
  content: '',
  handleClick: () => {},
  className: null,
  small: false,
  icon: PlusIcon,
  loading: false,
};

C.displayName = 'LabeledButton';
export const LabeledButton = memo(C);
