import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';
import { getBookingsStat } from 'api/methods/booking';

import { prepareAdminBookingValues } from './searchBooking/helpers';

const BASE_UI_PATH = ['booking', 'getBookingsStat'];

export function* getBookingsStatWorker() {
  const { token, query } = yield select(({ auth, booking }) => ({
    token: auth.get('token'),
    query: booking.getIn(['search', 'query']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  const queryParams = prepareAdminBookingValues(query);

  try {
    const statistic = yield call(getBookingsStat, token, { queryParams });

    yield put(actions.getBookingsStatSuccess(statistic));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));

    yield put(actions.getBookingsStatFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
