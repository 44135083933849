import { newInstance } from 'localization';

// eslint-disable-next-line require-yield
export function* getBasketTemplatesWorker({ payload: callbacks }) {
  const { onSuccess, onFail } = callbacks;

  try {
    onSuccess([
      { content: newInstance.t('TS:BASKET_TEMPLATES:DEFAULT_TEMPLATE_OPTION'), value: 'default' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:STRICT_TEMPLATE_OPTION'), value: 'strict' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:COMPACT_TEMPLATE_OPTION'), value: 'compact' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:MINIMALISM_TEMPLATE_OPTION'), value: 'minimalism' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:MINIMALISM_DARK_TEMPLATE_OPTION'), value: 'minimalism-dark' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:CITRUS_TEMPLATE_OPTION'), value: 'citrus' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:SERENITY_TEMPLATE_OPTION'), value: 'serenity' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:CHILD_TEMPLATE_OPTION'), value: 'child' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:NIGHT_TEMPLATE_OPTION'), value: 'night' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:BEACH_TEMPLATE_OPTION'), value: 'beach' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:TROPIC_TEMPLATE_OPTION'), value: 'tropic' },
      { content: newInstance.t('TS:BASKET_TEMPLATES:DAWN_TEMPLATE_OPTION'), value: 'dawn' },
    ]);
  } catch (error) {
    onFail && onFail(error);
  }
}
