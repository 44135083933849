import { call, put, race, take } from 'redux-saga/effects';

// insturments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';

export function* deleteRegionWorker({ payload }) {
  const { id, formik } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: '' },
    path: ['tf', 'deleteRegions', id],
  }));

  try {
    yield put(commonActions.deleteRegion(id));

    const [success, fail] = yield race([
      take(commonActions.deleteRegionSuccess),
      take(commonActions.deleteRegionFail)
    ]);

    if (fail) {
      const error = fail.payload;

      throw error;
    }

    if (success) {
      yield call(formik.setFieldValue, 'data', formik.values.data.filter(region => region.id !== id));
    }
  } catch (error) {
    const { status } = error.msg || {};

    let message = '';

    switch (status) {
      case 403: {
        message = 'Пользователь не имеет права удалять этот тег';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    if (status !== 404) {
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['tf', 'deleteRegions', id, 'error'],
      }));
      yield put(uiActions.changeUiLoaderFlag({
        status: message,
        path: ['tf', 'deleteRegions', id, 'message'],
      }));
    } else {
      yield call(formik.setFieldValue, 'data', formik.values.data.filter(region => region.id !== id));
    }
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['tf', 'deleteRegions', id, 'loading'],
    }));
  }
}
