import BaseApiClass from 'api/BaseApiClass';

export const getClaimSource = (token, params = {}) => new BaseApiClass({
  url: 'claims/sources',
  method: 'GET',
  ...params,
})._load({ token });

export const getAllClaimSource = (token, params = {}) => new BaseApiClass({
  url: 'claims/sources/all',
  method: 'GET',
  ...params,
})._load({ token });

export const deleteClaimSource = (token, params = {}) => new BaseApiClass({
  url: 'claims/sources/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const updateClaimSource = (token, params = {}) => new BaseApiClass({
  url: 'claims/sources/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const getClaimClosingReasons = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:id}/closing-reasons',
  method: 'GET',
  successCode: 200,
  ...params,
})._load({ token });

export const createClosingReasons = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:userId}/closing-reasons',
  method: 'POST',
  ...params,
})._load({ token });

export const updateClosingReasons = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:userId}/closing-reasons/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteClosingReasons = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:userId}/closing-reasons/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
