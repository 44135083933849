import { createActions } from 'redux-actions';

export const detailsFieldsActions = createActions({
  FETCH_FIELDS: () => undefined,
  FETCH_FIELDS_SUCCESS: fields => ({ fields }),
  FETCH_FIELD_FAIL: error => error,

  CREATE_FIELD: (oldId, field, workerOptions) => ({ oldId, field, workerOptions }),
  CREATE_FIELD_SUCCESS: (oldId, field) => ({ oldId, field }),
  CREATE_FIELD_FAIL: [error => error, (...[, processedError]) => processedError],

  CREATE_FIELD_BY_FORMIK: (id, field, formik) => ({ id, field, formik }),

  DELETE_FIELD: (object, id) => ({ object, id }),
  DELETE_FIELD_FAIL: error => error,

  UPDATE_FIELD: (field, workerOptions) => ({ field, workerOptions }),
  UPDATE_FIELD_SUCCESS: field => ({ field }),
  UPDATE_FIELD_FAIL: [error => error, (...[, processedError]) => processedError],

  UPDATE_FIELD_BY_FORMIK: (field, formik) => ({ field, formik }),

  ADD_EMPTY_FIELD: (configId, fieldId) => ({ configId, fieldId }),
  REMOVE_FIELD: (configId, fieldId) => ({ configId, fieldId }),

  SORT_FIELD: (objectId, fieldId, from, to) => ({ objectId, fieldId, from, to }),
  MOVE_FIELD: (objectId, from, to) => ({ objectId, from, to }),
});
