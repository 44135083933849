import React, { memo } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import * as R from 'ramda';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import deleteIcon from 'assets/icons/materialIcons/delete_black_24dp.svg';

import { IconButtonNew } from 'components/buttons/IconButtonNew';

import { handleWith, prevent } from 'helpers/handlers';

import book from 'routes/book';

import ClaimIcon from './img/claim.svg';

import styles from './styles.scss';

const createCountryDesc = country => (country ? ` ${country.name} ` : ' ');

const generateClaimDesc = ({
  age_childs: children,
  date_start: dateStart,
  location_label: location,
  client,
  country,
  adults
}) => R.call(
  R.pipe(
    R.filter(Boolean),
    R.join(', ')
  ),
  [
    `${client.name} ›${createCountryDesc(country)}${adults}+${children.length}`,
    location,
    `${dateStart ? moment(dateStart).format('DD MMM YYYY') : ''}`,
  ]
);

const ClaimLinkPure = ({
  claim,
  showIcon = true,
  disabledLink,
  onDelete,
  onDeleteUi
}) => {
  const { t } = useTranslation('BOOKING_PAGE');

  const { pathname } = useLocation();

  return (
    <div className={styles.root}>
      <Link
        className={styles.link}
        to={{
          pathname: generatePath(book.clientClaim.children.claim, {
            clientId: claim.client.id,
            claimId: claim.id
          }),
          state: { prevPathName: pathname }
        }}
        onClick={disabledLink ? handleWith(prevent) : undefined}
      >
        {showIcon && (
          <IconButtonNew icon={ClaimIcon} />
        )}
        {generateClaimDesc(claim)}
      </Link>
      {onDelete && (
        <IconButtonNew
          title={t('REMOVE_FROM_ORDER')}
          icon={deleteIcon}
          loading={onDeleteUi.loading}
          onClick={onDelete}
        />
      )}
    </div>
  );
};

export const ClaimDescription = memo(ClaimLinkPure);
