import * as R from 'ramda';

import { PAYMENT_TYPES } from './constants';

import arrowDown from './img/arrowDown.svg';
import arrowUp from './img/arrowUp.svg';
import arrowLeft from './img/arrowLeft.svg';
import arrowRight from './img/arrowRight.svg';

export const getIsProfitPayment = type => type === PAYMENT_TYPES.PAY_US_FROM_TA || type === PAYMENT_TYPES.PAY_US_FROM_TO;

export const isSeverancePayment = type => [
  PAYMENT_TYPES.PAY_FROM_US_TO,
  PAYMENT_TYPES.PAY_FROM_US_TA
].includes(type);

export const getIconByPaymentType = R.cond([
  [R.equals(PAYMENT_TYPES.PAY_US_FROM_TA), R.always(arrowDown)],
  [R.equals(PAYMENT_TYPES.PAY_FROM_US_TO), R.always(arrowUp)],
  [R.equals(PAYMENT_TYPES.PAY_US_FROM_TO), R.always(arrowLeft)],
  [R.equals(PAYMENT_TYPES.PAY_FROM_US_TA), R.always(arrowRight)],
  [R.T, R.always('')],
]);
