import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { accountingActions as actions } from 'bus/accounting/actions';
import { getAccountingInvoiceBase } from 'api/methods/accounting';

const BASE_UI_PATH = ['accounting', 'invoiceBase'];

export function* getInvoiceBaseWorker({ payload: id }) {
  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const invoices = yield call(getAccountingInvoiceBase, token, { pathParams: { id } });

    yield put(actions.getInvoiceBaseSuccess(invoices));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Счёт не существует или уже удален');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
