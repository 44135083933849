import { createActions } from 'redux-actions';

export const claimActions = createActions({
  START_AUTO_FETCH_COUNT_CLAIMS: () => undefined,
  STOP_AUTO_FETCH_COUNT_CLAIMS: () => undefined,
  FETCH_COUNT_CLAIMS_SUCCESS: count => ({ count }),
  FETCH_COUNT_CLAIMS_FAIL: error => error,

  COMBINE_CLAIM_WITH_OFFER: (hotelId, offerId, currency) => ({ hotelId, offerId, currency }),
  COMBINE_CLAIM_WITH_OFFERS: (hotelsIds, offersIds) => ({ offersIds, hotelsIds }),
  COMBINE_CLAIM_WITH_SEARCH: search => ({ search }),
});
