import { put, race, take, delay, select } from 'redux-saga/effects';
import moment from 'moment';
import { matchPath } from 'react-router-dom';

import { newsActions } from 'bus/news';
import { authActions } from 'bus/auth/actions';
import { getLocation } from 'bus/router/selectors';
import book from 'routes/book';

const DELAY_TIME_MS = 15 * 60 * 1000; // 15 minutes

export function* getNewsByTimeoutWorker() {
  const { pathname } = yield select(getLocation);

  while (true) {
    try {
      const queryParams = {
        expired_date_from: moment().utcOffset(0).format(),
        viewed: false,
        importance: matchPath(pathname, { path: book.tourscanner.path })
          ? '1,2,3'
          : '1,2',
        order_by: 'importance',
        order: 'DESC',
      };

      yield put(newsActions.getNews({ UI: false }, queryParams));

      yield race([
        take(newsActions.getNewsSuccess),
        take(newsActions.getNewsFail)
      ]);
    } catch (error) {
      const { status } = error.msg || {};

      if (status === 403) {
        return yield put(authActions.logOut(true));
      }
    }

    yield delay(DELAY_TIME_MS);
  }
}
