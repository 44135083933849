import { call, select, put } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors';
import { bookingActions } from 'bus/booking/actions.js';
import { getBookingId } from 'bus/booking/selectors';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { deleteDocument } from 'api/methods/booking';

import { DELETE_BOOKING_DOCUMENT_UI } from './constants';

export function* deleteDocumentSaga({ payload: id }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  const uiKey = [DELETE_BOOKING_DOCUMENT_UI, id];

  try {
    yield put(registerUI(createUi({ loading: true }), uiKey));

    yield call(deleteDocument, token, { pathParams: { documentId: id, bookingId } });

    yield put(bookingActions.deleteDocumentSuccess(id));
  } catch (error) {
    const { message } = error.msg.body;

    error.message = message ?? 'Ошибка сервера';

    yield put(bookingActions.deleteDocumentFail(error));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}
