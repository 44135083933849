import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { marketingActions } from 'bus/marketing/actions';
import { updateMailing } from 'api/methods/marketing';

export function* updateMailingWorker({ payload }) {
  const { id, values, formik } = payload;

  const { token, tf, search } = yield select(({ auth, marketing }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
    search: marketing.getIn(['mailing', 'search'], {}),
  }));

  try {
    const mailing = yield call(
      updateMailing,
      token,
      { pathParams: { tf, id }, bodyParams: { ...values, search } }
    );

    yield put(marketingActions.updateMailingSuccess(mailing));
    yield call(formik.setStatus, { success: true });
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Ошибка валидации');
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default:
        message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
