import { takeEvery } from 'redux-saga/effects';

import { servicesActions as actions } from '../actions';

import { loginFbWorker } from './workers/loginFb';
import { logoutFbWorker } from './workers/logoutFb';
import { authFbWorker } from './workers/authFb';

export const servicesWatchers = Object.freeze({
  * loginFbWatcher() {
    yield takeEvery(actions.loginFb, loginFbWorker);
  },
  * authFbWorker() {
    yield takeEvery(actions.onAuthFb, authFbWorker);
  },
  * logoutFbWorker() {
    yield takeEvery(actions.logoutFb, logoutFbWorker);
  },
});
