import { createActions } from 'redux-actions';

export const authActions = createActions({
  AUTHENTICATE: (values, remember, locale, formik) => ({ values, remember, locale, formik }),
  AUTHENTICATE_SUCCESS: token => token,

  FETCH_MYSELF: () => undefined,
  FETCH_MYSELF_SUCCESS: profile => profile,
  UPDATE_MYSELF: user => user,

  INCREMENT_ACTIVE_USERS: () => undefined,
  DICREMENT_ACTIVE_USERS: () => undefined,

  AUTO_AUTHENTICATE: () => undefined,

  LOG_OUT: (generateRef, workerOptions) => ({ generateRef, workerOptions }),
  showLimitUserModal: () => undefined,
  logOutPure: () => undefined,
  LOG_OUT_SUCCESS: () => undefined,

  SET_LOCALE: locale => locale,
  SET_OTPUSK_TOKEN: token => token,
  setChartersToken: token => token,

  REGISTER: user => user,
  REGISTER_SUCCESS: token => token,
  REGISTER_FAIL: error => error,

  VALIDATE_EMAIL: email => email,
  VALIDATE_EMAIL_SUCCESS: () => undefined,
  VALIDATE_EMAIL_FAILED: error => error,

  CREATE_REGISTER_CODE: (phone, isShowRepeatMessage) => ({ phone, isShowRepeatMessage }),
  CREATE_REGISTER_CODE_SUCCESS: () => undefined,
  CREATE_REGISTER_CODE_FAIL: () => undefined,

  FORGET: (values, formik) => ({ values, formik }),
  FORGET_SUCCESS: () => undefined,
  FORGET_FAIL: () => undefined,

  removeFacebook: () => undefined,
  removeFacebookSuccess: () => undefined,
  removeFacebookFail: error => error,
}, { prefix: 'MT_LOGIN' });
