import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import mtSvg from 'assets/logo_mt.svg';
import MtUkSvg from 'assets/logo_mt_uk.svg';
import tatSvg from 'assets/logo_tat.svg';
import starsSvg from 'assets/5stars-blue.svg';
import ebSvg from 'assets/EBLogo.svg';
import nkSvg from 'assets/logo_nk.svg';
import multitourSvg from 'assets/logo_multitour.svg';

import { CRM_TYPES } from './constants';

const CRMS_TRANSLATE_MAP = {
  [CRM_TYPES.mt]: 'MT_NAME',
  [CRM_TYPES.tat]: 'TAT_NAME',
  [CRM_TYPES.easybooking]: 'EASYBOOKING_NAME',
  [CRM_TYPES['5stars']]: '5STARS_NAME',
  [CRM_TYPES.nakanikuly]: 'NAKANIKULY_NAME',
  [CRM_TYPES.multitour]: 'MULTITOUR_NAME',
};

export const useCrmNameWithTranslate = () => {
  const { t } = useTranslation('MAIN_PAGE');

  return t(CRMS_TRANSLATE_MAP[__CRM__]);
};

export const useCrmLogo = ({
  tatLogo = tatSvg,
  starsLogo = starsSvg,
  ebLogo = ebSvg,
  nkLogo = nkSvg,
  mtLogo = MtUkSvg,
  multitourLogo = multitourSvg,
} = {}) => {
  return useMemo(() => {
    switch (__CRM__) {
      case CRM_TYPES.mt:
        return mtLogo;
      case CRM_TYPES.tat:
        return tatLogo;
      case CRM_TYPES['5stars']:
        return starsLogo;
      case CRM_TYPES.easybooking:
        return ebLogo;
      case CRM_TYPES.nakanikuly:
        return nkLogo;
      case CRM_TYPES.multitour:
        return multitourLogo;
      default:
        return mtSvg;
    }
  }, [ebLogo, mtLogo, multitourLogo, nkLogo, starsLogo, tatLogo]);
};
