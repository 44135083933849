import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insturments
import { getApiKeys } from 'api/methods/tf/apiKeys';
import { apiKeysActions } from 'bus/tf/apiKeys/actions';
import { uiActions } from 'bus/ui/actions';

const BASE_UI_PATH = ['tf', 'apiKeys', 'get'];

export function* getApiKeysWorker() {
  const { token, user } = yield select(({ auth }) => ({
    token: auth.get('token'),
    user: auth.getIn(['profile', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const keys = yield call(getApiKeys, token, { pathParams: { user } });

    yield put(apiKeysActions.getApiKeysSuccess(keys));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Пользователь не найден');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
