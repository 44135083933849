import { put, call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

// instruments
import { bookingActions as actions } from 'bus/booking/actions';
import { createBookingTourist } from 'api/methods/booking/tourists';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createBookingTouristWorker({ payload }) {
  const { values, id, bookingId, formik, onSuccess } = payload;

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  try {
    const tourist = yield call(
      createBookingTourist,
      token,
      { pathParams: { id: bookingId }, bodyParams: values }
    );

    yield put(actions.createBookingTouristSuccess(tourist, id));
    if (onSuccess) yield call(onSuccess);
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
