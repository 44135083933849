import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Route } from 'react-router-dom';

const C = props => {
  const isCompleteApp = useSelector(({ auth }) => auth.get('isAuthenticate') && auth.get('initialized'), shallowEqual);

  return isCompleteApp && <Route {...props} />;
};

export const PrivateRoute = React.memo(C);
