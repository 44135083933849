import { put, call, select } from 'redux-saga/effects';

import { bookingActions } from 'bus/booking/actions';
import { getToken, getUserId } from 'bus/auth/selectors';
import { withUI } from 'bus/ui/helpers';

import { getBookingMessagesTemplates } from 'api/methods/booking/messages';

import { newInstance } from 'localization';

export function* getMessagesTemplatesSaga() {
  const token = yield select(getToken);
  const userID = yield select(getUserId);

  try {
    const templates = yield call(getBookingMessagesTemplates, token, { pathParams: { userID } });

    yield put(bookingActions.getMessagesTemplatesSuccess(templates));
  } catch (error) {
    error.message = newInstance.t('ERRORS:CONTRACT_ERROR');

    yield put(bookingActions.getMessagesTemplatesFail(error));
  }
}

export const {
  saga: getMessagesTemplatesSagaWithUI,
  uiSelector: getMessagesTemplatesUI
} = withUI(
  'GET_MESSAGES_TEMPLATES',
  [
    bookingActions.getMessagesTemplatesSuccess,
    bookingActions.getMessagesTemplatesFail
  ],
  getMessagesTemplatesSaga
);
