import { takeEvery } from 'redux-saga/effects';

// instruments
import { marketingActions } from 'bus/marketing/actions';
import { getMailingsWorker } from './workers/getMailings';
import { deleteMailingWorker } from './workers/deleteMailing';
import { refreshMailingWorker } from './workers/refreshMailing';
import { getMailingSubscribersWorker } from './workers/getMailingSubscribers';
import { getNewMailingSubscribersWorker } from './workers/getNewMailingSubscribers';
import { searchMailingClientsWorker } from './workers/searchMailingClients';
import { createMailingWorker } from './workers/createMailing';
import { getMailingWorker } from './workers/getMailing';
import { updateMailingWorker } from './workers/updateMailing';
import { sendTestMailingWorker } from './workers/sendTestMailing';
import { sendMailingWorker } from './workers/sendMailing';
import { sendMailingUpdateWorker } from './workers/sendMailingUpdate';
import { getMailsOffersWorker } from './workers/getMailsOffers';

export const marketingWatchers = Object.freeze({
  * getMailings() {
    yield takeEvery(marketingActions.getMailings, getMailingsWorker);
  },
  * deleteMailing() {
    yield takeEvery(marketingActions.deleteMailing, deleteMailingWorker);
  },
  * refreshMailing() {
    yield takeEvery(marketingActions.refreshMailing, refreshMailingWorker);
  },
  * getMailingSubscribers() {
    yield takeEvery(marketingActions.getMailingSubscribers, getMailingSubscribersWorker);
  },
  * getNewMailingSubscribers() {
    yield takeEvery(marketingActions.getNewMailingSubscribers, getNewMailingSubscribersWorker);
  },
  * searchMailingClients() {
    yield takeEvery(marketingActions.searchMailingClients, searchMailingClientsWorker);
  },
  * createMailing() {
    yield takeEvery(marketingActions.createMailing, createMailingWorker);
  },
  * getMailing() {
    yield takeEvery(marketingActions.getMailing, getMailingWorker);
  },
  * updateMailing() {
    yield takeEvery(marketingActions.updateMailing, updateMailingWorker);
  },
  * sendTestMailing() {
    yield takeEvery(marketingActions.sendTestMailing, sendTestMailingWorker);
  },
  * sendMailing() {
    yield takeEvery(marketingActions.sendMailing, sendMailingWorker);
  },
  * sendMailingUpdate() {
    yield takeEvery(marketingActions.sendMailingUpdate, sendMailingUpdateWorker);
  },
  * getMailsOffers() {
    yield takeEvery(marketingActions.getMailsOffers, getMailsOffersWorker);
  },
});
