import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// actions
import { filesActions } from 'bus/files/actions';
import { uiActions } from 'bus/ui/actions';

// instruments
import { createFiles } from 'api/methods/files/clients';
import { parseErrorsByFormik } from 'api/fn/parseErrors';
import { MyError } from 'api/fn/Error';

const BASE_UI_PATH = ['files', 'clients', 'create'];

export function* createFilesBaseWorker({ payload }) {
  const { clientID, files, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: BASE_UI_PATH,
  })));

  try {
    const bodyParams = files.reduce((data, { file, caption }) => {
      data.append('files[]', file);
      data.append('captions[]', caption);

      return data;
    }, new FormData());

    const response = yield call(
      createFiles,
      token,
      { bodyParams, pathParams: { clientId: clientID } }
    );

    const error = response.find(({ validation }) => validation.error);

    if (error) {
      throw new MyError({ status: 400, body: error.validation });
    }

    yield put(filesActions.createFilesSuccess(clientID, response.map(({ entity }) => entity)));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    let errorsByValues = null;

    let otherErrors = null;

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);

        if (violations.length) {
          const computedError = parseErrorsByFormik(files, violations);

          errorsByValues = computedError.errorsByValues;
          otherErrors = computedError.otherErrors;
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу \'Клиенты\' или к клиенту или активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = 'Клиент не найден';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(filesActions.createFilesFail(error, { errorsByValues, otherErrors, message }));

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, 'completed'],
    })));
  }
}
