import { call, put, select } from 'redux-saga/effects';

// instruments
import { getTemplate } from 'api/methods/templates/getTemplate';
import { uiActions } from 'bus/ui/actions';
import { templatesActions } from 'bus/templates/actions';

export function* fetchTemplateWorker({ payload }) {
  const { id } = payload;
  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['templates', 'fetchTemplate'],
  }));

  try {
    const response = yield call(getTemplate, token, { pathParams: { tf, id } });

    yield put(templatesActions.fetchTemplateSuccess(response));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Отсутствует разрешение доступа к шаблонам счетов или активна подписка только на Турсканер';

        break;
      }
      case 404: {
        message = 'Счет не найден';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(templatesActions.fetchTemplateFail(error));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['templates', 'fetchTemplate', 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['templates', 'fetchTemplate', 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['templates', 'fetchTemplate', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['templates', 'fetchTemplate', 'completed'] }));
  }
}
