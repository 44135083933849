import BaseApiClass from 'api/BaseApiClass';

export const createDownloadFileId = (token, params = {}) => new BaseApiClass({
  url: 'clients/search/job/xlsx',
  method: 'POST',
  ...params,
})._load({ token });

export const createBookingJob = (token, params = {}) => new BaseApiClass({
  url: 'bookings/export-jobs',
  method: 'POST',
  ...params,
})._load({ token });

export const createFinanceJob = (token, params = {}) => new BaseApiClass({
  url: 'claims/payments/jobs',
  method: 'POST',
  ...params,
})._load({ token });

export const getStatusJobs = (token, params = {}) => new BaseApiClass({
  url: 'jobs/{:id}',
  method: 'GET',
  ...params,
})._load({ token });
