import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { getIsAuth } from 'bus/auth/selectors';

import { getStorage, GROUPS } from 'helpers/storage';

export const useAuth = () => {
  const auth = useSelector(getIsAuth);

  return useMemo(
    () => {
      if (auth) {
        return true;
      }

      return Boolean(getStorage().getIn([GROUPS.auth, 'token']));
    },
    [auth]);
};
