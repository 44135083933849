import { put, race, take } from 'redux-saga/effects';

import { uiActions } from 'bus/ui/actions';
import { authActions } from 'bus/auth/actions';

import { newInstance } from 'localization';

const BASE_UI_PATH = ['services', 'facebook', 'auth'];

export function* logoutFbWorker() {
  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, success: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    yield put(authActions.removeFacebook());

    const [, fail] = yield race([
      take(authActions.removeFacebookSuccess),
      take(authActions.removeFacebookFail)
    ]);

    if (fail) {
      throw new Error('');
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'success'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: newInstance.t('DETAILS:INTEGRATION_STOPPED'), path: [...BASE_UI_PATH, 'message'] }));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: 'Ошибка сервера', path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
