import { takeEvery } from 'redux-saga/effects';

// actions
import { digitalContractActions as actions } from 'bus/clientClaim/digitalContract';

// workers
import { createDigitalContractWorker } from './workers/createDigitalContract';
import { getDigitalContractsWorker } from './workers/getDigitalContracts';
import { getDigitalContractWorker } from './workers/getDigitalContract';
import { updateDigitalContractWorker } from './workers/updateDigitalContract';
import { sendDigitalContractToEmailWorker } from './workers/sendDigitalContractToEmail';
import { deleteDigitalContractWorker } from './workers/deleteDigitalContract';
import { generateDigitalContractLinkWorker } from './workers/generateDigitalContractLink';
import { fetchDigitalContractTextWorker } from './workers/fetchDigitalContractText';
import { withUiUpdateContractAndFetchContractTextWorker } from './workers/updateContractAndFetchContractText';

export const digitalContractWatchers = Object.freeze({
  * createDigitalContractWatcher() {
    yield takeEvery(actions.createDigitalContract, createDigitalContractWorker);
  },
  * getDigitalContractsWatcher() {
    yield takeEvery(actions.getDigitalContracts, getDigitalContractsWorker);
  },
  * getDigitalContractWatcher() {
    yield takeEvery(actions.getDigitalContract, getDigitalContractWorker);
  },
  * fetchDigitalContractTextWatcher() {
    yield takeEvery(actions.fetchDigitalContractText, fetchDigitalContractTextWorker);
  },
  * updateDigitalContractWatcher() {
    yield takeEvery(actions.updateDigitalContract, updateDigitalContractWorker);
  },
  * sendDigitalContractToEmailWatcher() {
    yield takeEvery(actions.sendDigitalContractToEmail, sendDigitalContractToEmailWorker);
  },
  * deleteDigitalContractWatcher() {
    yield takeEvery(actions.deleteDigitalContract, deleteDigitalContractWorker);
  },
  * generateDigitalContractLinkWatcher() {
    yield takeEvery(actions.generateDigitalContractLink, generateDigitalContractLinkWorker);
  },
  * updateContractAndFetchContractTextWorker() {
    yield takeEvery(
      actions.updateContractAndFetchContractText, withUiUpdateContractAndFetchContractTextWorker
    );
  },
});
