import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getLocale, getUserLocale } from '../auth/selectors';

export const domain = ({ statistic }) => statistic;
export const getSalesFunnel = createSelector(domain, statistic => statistic.get('salesFunnel'));
const getSalesFunnelData = createSelector(getSalesFunnel, R.prop('data'));

export const getFlexibleReportQuery = createSelector(domain, statistic => statistic.getIn(['flexibleReport', 'query']));

export const getSalesFunnelTotal = createSelector(getSalesFunnelData, R.prop('total'));
export const getSalesFunnelTotalWithOpened = createSelector(getSalesFunnelData, R.prop('totalWithOpened'));
export const getSalesFunnelResults = createSelector(getSalesFunnelData, R.prop('results'));

export const getSalesFunnelResultWithTranslate = createSelector(
  [getSalesFunnelResults, getUserLocale, getLocale],
  (salesFunnels, userLocale, tfLocale) => R.map(({ translations, ...rest }) => ({
    ...rest,
    description: R.call(
      R.pipe(
        R.find(({ locale }) => locale === userLocale),
        R.prop('description'),
        translation => translation
          || R.find(({ locale }) => locale === tfLocale, translations).description), translations),
  }), salesFunnels)
);

export const getSalesFunnelResultWithSort = createSelector(getSalesFunnelResultWithTranslate,
  R.sort(({ count: countA }, { count: countB }) => countB - countA)
);
