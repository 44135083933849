import { put, call, select } from 'redux-saga/effects';
import { getToken } from 'bus/auth/selectors';

import { registerUI, updateUI } from 'bus/ui/actions.js';
import { createUi } from 'bus/ui/helpers.js';

import { bookingEnd2EndBooking } from 'api/methods/booking';

import { showToastError, showToastSuccess } from 'services/toaster';

import { getBookingId } from '../../selectors';

import { BOOKING_END2END_BOOKING_UI } from '../../constants';
import { bookingActions } from '../../actions';

export function* bookingEnd2EndBookingSaga({ payload: bodyParams }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  yield put(registerUI(createUi({ loading: true, success: false }), BOOKING_END2END_BOOKING_UI));

  try {
    const { message, ...booking } = yield call(
      bookingEnd2EndBooking,
      token,
      {
        pathParams: { bookingId },
        bodyParams
      }
    );

    yield put(bookingActions.mergeBookingData({
      status: booking.booking_status,
      op_claim_id: booking.op_claim_id,
      id_number: booking.id_number,
    }));

    showToastSuccess(message);
    yield put(updateUI({ success: true }, BOOKING_END2END_BOOKING_UI));
  } catch (error) {
    const { body } = error.msg || {};

    showToastError(body?.message);
    yield put(updateUI({ error: true, message: body?.message }, BOOKING_END2END_BOOKING_UI));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, BOOKING_END2END_BOOKING_UI));
  }
}
