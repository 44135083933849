import { createActions } from 'redux-actions';

export const newsActions = createActions({
  GET_NEWS: (options, queryParams) => ({ options, queryParams }),
  getNewsWithSavingQuery: (options, queryParams) => ({ options, queryParams }),
  GET_NEWS_SUCCESS: news => news,
  GET_NEWS_FAIL: error => error,

  getNewsSelections: () => undefined,
  getNewsSelectionsSuccess: newsSelections => newsSelections,
  getNewsSelectionsFail: error => error,

  GET_SINGLE_NEWS: (id, options) => ({ id, options }),
  GET_SINGLE_NEWS_SUCCESS: news => news,
  GET_SINGLE_NEWS_FAIL: error => error,

  CLEAR_NEWS: () => undefined,
  CLEAR_SINGLE_NEWS: () => undefined,

  START_AUTO_FETCH_NEWS: () => undefined,
  END_AUTO_FETCH_NEWS: () => undefined,

  VIEWED_NEWS: (id, options) => ({ id, options }),
  VIEWED_NEWS_SUCCESS: id => id,
  VIEWED_NEWS_FAIL: error => error,

}, { prefix: 'MT_NEWS' });
