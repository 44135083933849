import { select, call, put } from 'redux-saga/effects';

import { getClaimBonus } from 'api/methods/claim/bonus';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { uiActions } from 'bus/ui/actions';

import { updateClaim } from 'api/methods/claim/updateClaim';

import { newInstance } from 'localization';

export function* fetchClaimBonusWorker({ payload: id }) {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'fetchClaimBonus', 'loading'] }));

  try {
    const response = yield call(getClaimBonus, token, { pathParams: { id } });
    const results = response.results.find(item => item.type === 'Bonuses');
    const getArtistGroup = response.groupings.find(item => item.type === 'Artist');
    const managersList = getArtistGroup
      ? Object.keys(getArtistGroup.groups).map(managerId => {
        const { model_grouped_by: { username }, statistics } = getArtistGroup.groups[managerId];
        const data = statistics.results.find(item => item.type === 'Bonuses') || {};

        return {
          username,
          ...data,
        };
      })
      : null;

    yield put(clientClaimActions.fetchClaimBonusSuccess({ results, managersList }));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Пользователь не имеет прав к разделу ‘Заказы’ или к конкретному заказу';
        break;
      }
      case 404: {
        message = 'Заказ не существует';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['clientClaim', 'fetchClaimBonus', 'message'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'fetchClaimBonus', 'error'] }));
    yield put(clientClaimActions.fetchClaimBonusFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['clientClaim', 'fetchClaimBonus', 'loading'] }));
  }
}

export function* updateClaimBonusWorker({ payload }) {
  const { claimID, dateBonus } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { error: false, loading: true, success: false, message: null },
    path: ['clientClaim', 'updateClaimBonus'],
  }));

  try {
    const response = yield call(
      updateClaim,
      token,
      { pathParams: { id: claimID }, bodyParams: { date_bonus: dateBonus } }
    );

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'updateClaimBonus', 'success'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: newInstance.t('GLOBAL:SAVE_SUCCESS'), path: ['clientClaim', 'updateClaimBonus', 'message'] }));
    yield put(clientClaimActions.updateClaimDateBonusSuccess(response.date_bonus));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Пользователь не имеет прав к разделу ‘Заказы’ или к конкретному заказу';
        break;
      }
      case 404: {
        message = 'Заказ не существует';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['clientClaim', 'updateClaimBonus', 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['clientClaim', 'updateClaimBonus', 'message'] }));
    yield put(clientClaimActions.updateClaimDateBonusFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['clientClaim', 'updateClaimBonus', 'loading'] }));
  }
}
