import BaseApiClass from 'api/BaseApiClass';
import * as R from 'ramda';

export const getSubscription = (token, options) => {
  const instance = new BaseApiClass({
    url: 'tf/billing/subscription',
    method: 'GET',
    ...options,
  });

  instance.parseBodyToClientSchema = ({
    plans,
    active_plan: active,
    last_plan: last
  }) => ({
    active: R.call(
      R.when(
        Boolean,
        R.pipe(R.assoc('isAutoSubscribe', R.prop('is_recurring', active)), R.dissoc('is_recurring'))
      ),
      active),
    last,
    plans
  });

  return instance._load({ token });
};

export const getBalances = (token, options = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/balances/groups',
  method: 'GET',
  ...options,
})._load({ token });

export const getBalanceInvoices = (token, options = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/balances/history',
  method: 'GET',
  ...options,
})._load({ token });

export const getBalanceAmount = (token, options = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/balances/amount',
    method: 'GET',
    ...options,
  });

  instance.parseBodyToClientSchema = data => data.map(({
    used_currency: usedCurrency,
    ...rest
  }) => ({ usedCurrency, ...rest }));

  return instance._load({ token });
};

export const createSubscription = (token, options = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/balance/subscription',
    method: 'POST',
    ...options,
  });

  instance.parseBodyToClientSchema = ({ b_id: balanceId, ...rest }) => ({
    ...rest,
    balanceId,
  });

  return instance._load({ token });
};

export const unsubscription = (token, options = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/balances/unsubscribe',
  method: 'POST',
  successCode: 204,
  ...options,
})._load({ token });

export const createBalanceCharge = (token, options = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/balance/charge',
  method: 'POST',
  ...options,
})._load({ token });

export const getBalanceInfo = (token, options = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/balances/{:balanceId}',
    method: 'GET',
    ...options,
  });

  instance.parseBodyToClientSchema = ({ is_paid: isPaid, ...rest }) => ({
    ...rest,
    isPaid,
  });

  return instance._load({ token });
};
