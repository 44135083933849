import { useEffect } from 'react';

import { getClients as getApiDate } from 'api/methods/common/getClients';
import { useBaseAutoComplete } from '../useBaseAutoComplete';

export const useClients = isNeedToFetch => {
  const {
    loading,
    error,
    handleChange,
    data,
    completed,
    fetchData,
    clearData,
  } = useBaseAutoComplete({ getApiDate, apiParams: {}, searchStr: 'text' });

  useEffect(() => {
    isNeedToFetch && fetchData('');
  }, []);

  return {
    loading,
    error,
    completed,
    handleChange,
    clearData,
    data: data || [],
  };
};
