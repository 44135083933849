import { put, call, select } from 'redux-saga/effects';
import { get, getIn, Map } from 'immutable';

// instruments
import { updateBookingOperatorInvoice } from 'api/methods/booking/invoices';
import { parseErrorsByFormik } from 'api/fn/parseErrors';
import { bookingActions as actions } from 'bus/booking/actions';

export function* updateOperatorInvoiceWorker({ payload }) {
  const { values, id, bookingId, formik } = payload;

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  try {
    const bodyParams = new FormData();

    Map(values).forEach((v, k) => {
      bodyParams.append(k, v);
    });

    const invoice = yield call(
      updateBookingOperatorInvoice,
      token,
      { pathParams: { id, bookingId }, bodyParams }
    );

    yield put(actions.updateOperatorInvoiceSuccess(invoice));

    yield call(formik.setStatus, { success: true });
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default:
        message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
