import { call, put, select, take, race } from 'redux-saga/effects';
import { get } from 'immutable';

import {
  getFbPage,
  getMe,
  subscribeApp,
  getSubscriptionsApp
} from 'api/methods/services/facebook';
import { subscribeUserFacebook } from 'api/methods/tf/user';

import { uiActions } from 'bus/ui/actions';

import { newInstance } from 'localization';
import { authActions as actions } from '../../../auth/actions';

const BASE_UI_PATH = ['services', 'facebook', 'auth'];

export function* authFbWorker({ payload }) {
  const { authResponse } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, success: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const me = yield call(getMe);
    const fbPage = yield call(getFbPage, authResponse);

    if (fbPage) {
      const { token, userId } = yield select(({ auth }) => ({
        token: auth.get('token'),
        userId: auth.getIn(['profile', 'id']),
      }));

      const subscriptions = yield call(getSubscriptionsApp, fbPage.id, fbPage.access_token);

      if (!subscriptions.data.length) {
        yield call(subscribeApp, fbPage.id, fbPage.access_token);
      }

      const bodyParams = {
        facebook_id: authResponse.userID,
        facebook_user_name: me.name,
        facebook_page_name: fbPage.name,
        facebook_token: authResponse.accessToken,
        facebook_page_id: fbPage.id,
      };

      yield call(subscribeUserFacebook, token, { pathParams: { id: userId }, bodyParams });

      yield put(actions.fetchMyself());

      const [, error] = yield race([
        take(actions.fetchMyselfSuccess),
        take(actions.logOut)
      ]);

      if (error) {
        throw new Error('Ошибка сервера');
      }

      yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'success'] }));
      yield put(uiActions.changeUiLoaderFlag({
        status: newInstance.t('DETAILS:SUCCESS_INTEGRATION'),
        path: [...BASE_UI_PATH, 'message'],
      }));
    }
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Неверные параметры');
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      default:
        message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
