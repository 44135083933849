import BaseApiClass from 'api/BaseApiClass';

export const getOffices = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'tf/offices',
    method: 'GET',
    ...params,
  });

  return instance._load({ token });
};

export const createOffice = (token, params = {}) => new BaseApiClass({
  url: 'tf/offices',
  method: 'POST',
  ...params,
})._load({ token });

export const updateOffice = (token, params = {}) => new BaseApiClass({
  url: 'tf/offices/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteOffice = (token, params = {}) => new BaseApiClass({
  url: 'tf/offices/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
