import { select, call } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors';

import { addBasketToClaim } from 'api/methods/tourscaner/baskets';

export function* addBasketToClaimSaga(basketId, claimId, clientId) {
  const token = yield select(getToken);

  return yield call(
    addBasketToClaim,
    token,
    { pathParams: { clientId, basketId, claimId } }
  );
}

export function* addBasketToClaimWorker({ payload }) {
  const { callbacks, basketId, claimId, clientId } = payload;
  const { onSuccess, onFail } = callbacks;

  try {
    const claim = yield call(
      addBasketToClaimSaga,
      basketId,
      claimId,
      clientId
    );

    onSuccess && onSuccess(claim);
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
