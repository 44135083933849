import { createActions } from 'redux-actions';

export const filesActions = createActions({
  FETCH_FILES: clientID => clientID,
  FETCH_FILES_SUCCESS: (clientID, files) => ({ clientID, files }),
  FETCH_FILES_FAIL: error => error,
  CLEAR_FILES: clientID => clientID,

  CREATE_FILES: (clientID, files, workerOptions) => ({ clientID, files, workerOptions }),
  CREATE_FILES_SUCCESS: (clientID, files) => ({ clientID, files }),
  CREATE_FILES_FAIL: [error => error, (...[, processedErrors]) => processedErrors],

  CREATE_FILES_BY_FORMIK: (clientID, files, workerOptions, formik) => (
    { clientID, files, workerOptions, formik }
  ),
  UPDATE_FILE_BY_FORMIK: (clientID, fileID, file, workerOptions, formik) => (
    { clientID, fileID, file, workerOptions, formik }
  ),

  UPDATE_FILE: (clientID, fileID, file, workerOptions) => (
    { clientID, fileID, file, workerOptions }
  ),
  UPDATE_FILE_SUCCESS: (clientID, fileID, file) => ({ clientID, fileID, file }),
  UPDATE_FILE_FAIL: error => error,

  DELETE_FILE: (clientID, id) => ({ clientID, id }),
  DELETE_FILE_SUCCESS: (clientID, id) => ({ clientID, id }),
  DELETE_FILE_FAIL: error => error,

  SET_MANIPULATED_FILE: id => id,
  RESET_MANIPULATED_FILE: () => null,

  ADD_FILES: data => data,
  ADD_FILES_SUCCESS: fileList => fileList,
  ADD_FILES_FAIL: error => error,

  GET_UPLOADS_FILES: options => ({ options }),
  GET_UPLOADS_FILES_SUCCESS: files => files,
  GET_UPLOADS_FILES_FAIL: error => error,

  DELETE_UPLOADS_FILE: (filename, type, options) => ({ filename, type, options }),
  DELETE_UPLOADS_FILE_SUCCESS: filename => filename,
  DELETE_UPLOADS_FILE_FAIL: error => error,

  CREATE_DOWNLOAD_FILE_ID: params => ({ params }),
  CREATE_DOWNLOAD_FILE_ID_SUCCESS: jobId => jobId,
  CREATE_DOWNLOAD_FILE_ID_FAIL: () => undefined,

  CLEAR_DOWNLOAD_FILE_ID: () => null,

  CREATE_FINANCE_JOB: params => ({ params }),
  CREATE_FINANCE_JOB_SUCCESS: jobId => jobId,
  CREATE_FINANCE_JOB_FAIL: () => undefined,

  CLEAR_FINANCE_JOB: () => null,

  CREATE_BOOKING_JOB: params => ({ params }),
  CREATE_BOOKING_JOB_SUCCESS: jobId => jobId,
  CREATE_BOOKING_JOB_FAIL: () => undefined,

  CLEAR_BOOKING_JOB: () => null,

  GET_STATUS_JOBS: id => id,
  GET_STATUS_JOBS_SUCCESS: status => status,
  GET_STATUS_JOBS_FAIL: error => error,
}, { prefix: 'MT_FILES' });
