import { select, call } from 'redux-saga/effects';
import * as R from 'ramda';

import { getToken } from 'bus/auth/selectors';

import { getClients } from 'api/methods/common/getClients';

const validateClient = ({ client }) => client;

const mapClient = client => R.call(
  R.applySpec({
    adults: R.prop('adults'),
    child: R.prop('cnt_child'),
    client: R.prop('client'),
    dateStart: R.prop('date_start'),
    id: R.ifElse(
      R.prop('id'),
      ({ id }) => id.toString(),
      R.converge(
        R.concat,
        [
          R.always('client_'),
          R.pipe(
            R.path(['client', 'id']),
            R.toString
          )
        ]
      )
    ),
    hotel: R.applySpec({
      location: R.prop('location'),
    }),
    nights: R.prop('length'),
    performer: R.path(['performer', 'username']),
    title: R.prop('location_label'),
    baskets: R.prop('selections'),
  }),
  client
);

const handleDefaultSearchString = searchString => (searchString === 'tourscanner'
  ? ''
  : searchString);

export function* getClientsWorker({ payload }) {
  const { callbacks, searchString } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);

  try {
    const response = yield call(
      getClients,
      token,
      { queryParams: { text: handleDefaultSearchString(searchString) } }
    );

    const claims = R.call(
      R.pipe(
        R.filter(validateClient),
        R.map(mapClient)
      ),
      response
    );

    onSuccess(claims);
  } catch (error) {
    console.error(error);
    onFail(error);
  }
}
