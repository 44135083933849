import { put, race, take } from 'redux-saga/effects';

import { accountingActions } from 'bus/accounting/actions';
import { bookingActions } from 'bus/booking/actions';

export function* reExposeInvoiceWorker({ payload }) {
  const { values, formik, invoiceId, callbacks } = payload;

  yield put(accountingActions.createInvoice(values, formik, callbacks));

  const [success] = yield race([
    take(bookingActions.getBookingInvoices),
    take(accountingActions.createInvoiceFail),
  ]);

  if (success) {
    yield put(accountingActions.deleteInvoice(invoiceId, { isBooking: true }));
  }
}
