import { call, put, select, takeEvery, all } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';

import { getErrorMessage } from 'api/fn/parseErrors';
import { getCounterpartyCommissionGroups, getCounterpartyGroups } from 'api/methods/tf/counterparty';

import { tfActions } from '../../actions';

export const GET_GROUPS_UI = 'GET_GROUPS';
export const GET_COMMISSION_GROUPS_UI = 'GET_COMMISSION_GROUPS';

function* getGroupsWorker() {
  const token = yield select(getToken);

  try {
    const result = yield call(getCounterpartyGroups, token);

    yield put(tfActions.getGroupsSuccess(result));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(tfActions.getGroupsFail(error));
  }
}

function* getCommissionGroups() {
  const token = yield select(getToken);

  try {
    const result = yield call(getCounterpartyCommissionGroups, token);

    yield put(tfActions.getCommissionGroupsSuccess(result));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(tfActions.getCommissionGroupsFail(error));
  }
}

export default function* () {
  yield all([
    takeEvery(tfActions.getGroups, withUIWorker([tfActions.getGroupsSuccess, tfActions.getGroupsFail], GET_GROUPS_UI)(getGroupsWorker)),
    takeEvery(tfActions.getCommissionGroups, withUIWorker([tfActions.getCommissionGroupsSuccess, tfActions.getCommissionGroupsFail], GET_COMMISSION_GROUPS_UI)(getCommissionGroups))
  ]);
}
