import { createActions } from 'redux-actions';

export const apiKeysActions = createActions({
  GET_API_KEYS: () => undefined,
  GET_API_KEYS_SUCCESS: keys => keys,
  GET_API_KEYS_FAIL: error => error,

  CREATE_API_KEY: () => undefined,
  CREATE_API_KEY_SUCCESS: key => key,
  CREATE_API_KEY_FAIL: error => error,

  DELETE_API_KEY: name => name,
  DELETE_API_KEY_SUCCESS: name => name,
  DELETE_API_KEY_FAIL: error => error,

  SEND_API_KEY: (name, workerOptions) => ({ name, workerOptions }),
  SEND_API_KEY_SUCCESS: name => name,
  SEND_API_KEY_FAIL: error => error,

}, { prefix: 'MT_TF_API_KEYS' });
