import { select, call } from 'redux-saga/effects';
import * as R from 'ramda';
import moment from 'moment';

import { getToken, getUserId } from 'bus/auth/selectors';

import { removeEmptyString, removeNull } from 'helpers';

import { getBaskets } from 'api/methods/tourscaner/baskets';

const convertDateForApi = date => moment(date, 'DD.MM.YYYY', true).format('YYYY-MM-DD');
const createDatesForApi = (dates = {}) => {
  const { to, from } = dates;

  return {
    createFrom: from ? convertDateForApi(from) : null,
    createTo: to ? convertDateForApi(to) : null,
  };
};

const prepareParams = R.pipe(
  ({ dates, withID, ...rest }) => R.mergeAll([
    rest,
    {
      ...createDatesForApi(dates),
      ...withID ? { id: withID } : {},
    }
  ]),
  removeNull,
  removeEmptyString
);

export function* getBasketsWorker({ payload: { callbacks = {}, params = {} } }) {
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userId = yield select(getUserId);

  try {
    const { baskets, perPage, total, page } = yield call(
      getBaskets,
      token,
      { pathParams: { userId }, queryParams: prepareParams(params) }
    );

    onSuccess({ baskets, perPage, total, page });
  } catch (error) {
    __DEV__ && console.error(error);
    onFail(error);
  }
}
