import { takeEvery, put, select, call, take, cancel, fork } from 'redux-saga/effects';

import { registerUI, resetUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { bookingActions } from 'bus/booking/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { getBookingClaimId } from 'bus/booking/selectors';

import { getInitialDetailsForBookingSaga } from 'components/booking/components/BookingDetail/store/saga';

import { initialize, unMount } from './actions';
import { UI_KEY } from './constants';
import { getBookingId } from './selectors';

function* initBookingPageSaga() {
  const id = yield select(getBookingId);

  yield put(registerUI(createUi({ loading: true }), UI_KEY));

  const [, errorMsg] = yield call(getInitialDetailsForBookingSaga, id);

  if (errorMsg) {
    yield put(updateUI({ message: errorMsg, error: true }, UI_KEY));
  } else {
    const claimId = yield select(getBookingClaimId);

    if (claimId) {
      yield put(clientClaimActions.getClaimSimplified(claimId));
    }
  }

  yield put(updateUI({ completed: true, loading: false }, UI_KEY));
}

function* unMountSaga() {
  yield put(resetUI(UI_KEY));
  yield put(bookingActions.clearBooking());
  yield put(clientClaimActions.clearSimplifiedClaim());
}

export default function* watcher() {
  yield takeEvery(initialize, function* () {
    const runningSaga = yield fork(initBookingPageSaga);

    yield take(unMount);

    yield cancel(runningSaga);
  });
  yield takeEvery(unMount, unMountSaga);
}
