import { put, race, take } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { bookingActions as actions } from 'bus/booking/actions';
import { uiActions } from 'bus/ui/actions';

const BASE_UI_PATH = ['booking', 'saveBooking'];

export function* saveBookingWorker({ payload }) {
  const { bookingId } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  yield put(actions.markBookingViewed(bookingId, { UI: false }));

  const [success, fail] = yield race([
    take(actions.markBookingViewedSuccess),
    take(actions.markBookingViewedFail)
  ]);

  if (success) {
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'success'] }));
  }

  if (fail) {
    const { status, body } = fail.payload.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  }

  yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
  yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
}
