import { call, select, put } from 'redux-saga/effects';

import { registerUI, updateUI } from 'bus/ui/actions';
import { getToken } from 'bus/auth/selectors';
import { createUi } from 'bus/ui/helpers';

import { dismissComment } from 'api/methods/claim/comment';

import { createRemindItemUiKey } from './helpers';

export function* dismissRemindSaga({ payload: { claimID, remindID, onSuccess } }) {
  const token = yield select(getToken);
  const uiKey = createRemindItemUiKey(remindID);

  yield put(registerUI(createUi({ loading: true }), uiKey));

  try {
    yield call(
      dismissComment,
      token,
      { pathParams: { claimID, eventID: remindID } }
    );

    onSuccess && onSuccess();
  } catch (error) {
    const { body } = error.msg;

    yield put(updateUI({ error: true, message: body.message }, uiKey));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, uiKey));
  }
}
