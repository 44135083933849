import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { Map, get } from 'immutable';

const MS = 300;

export const useBaseAutoComplete = ({ getApiDate, apiParams, searchStr = 'term' }) => {
  const [loading, setLoading] = useState(Map());
  const [error, setError] = useState(Map());
  const [completed, setCompleted] = useState(Map());
  const [data, setData] = useState(Map());
  const [value, setValue] = useState('');
  const token = useSelector(({ auth }) => auth.get('token'));

  const fetchData = async val => {
    setValue(val);
    setLoading(loading.set(val, true));
    setCompleted(completed.set(val, false));
    error.get(val, false) && setError(error.set(val, false));

    try {
      const mergeApiParams = Map(apiParams).mergeDeep({ queryParams: { [searchStr]: val } }).toObject();

      const response = await getApiDate(token, mergeApiParams);

      setData(data.set(val, response));
    } catch (err) {
      const { status, body } = err.msg || {};

      let message = '';

      switch (status) {
        case 403: {
          message = get(body, 'message', 'Отсутствует доступ к разделу "Клиенты" или активна подписка только на Турсканер');

          break;
        }
        default: message = 'Ошибка сервера';
      }

      setError(error.set(val, message));
    } finally {
      setLoading(loading.set(val, false));
      setCompleted(completed.set(val, true));
    }
  };

  const { current: handleChange } = useRef(debounce(val => fetchData(val), MS));

  return {
    value,
    loading: loading.get(value, false),
    error: error.get(value, false),
    completed: completed.get(value, false),
    data: data.get(value, null),
    clearData: () => setData(Map()),
    handleChange,
    fetchData,
  };
};
