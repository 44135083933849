import { put, call, select } from 'redux-saga/effects';
import { accountingActions } from 'bus/accounting/actions';
import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { getAkt } from 'api/methods/accounting';
import { getErrorMessage } from 'api/fn/parseErrors';

export const GET_AKT_UI = 'GET_AKT_UI';

function* getAktWorker({ payload: id }) {
  const token = yield select(getToken);

  try {
    const { html } = yield call(getAkt, token, { pathParams: { id } });

    yield put(accountingActions.getAktSuccess(html));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Заявка или счет не найден',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(accountingActions.getAktFail(error));
  }
}

export default withUIWorker(
  [accountingActions.getAktSuccess, accountingActions.getAktFail],
  GET_AKT_UI,
)(getAktWorker);
