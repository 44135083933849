import { createActions } from 'redux-actions';

const PREFIX = 'BOOKING_RESERVATION_INITIALIZING';

export const { initialize, unMount, deleteBooking, onFillClaim } = createActions({
  initialize: bookingId => bookingId,
  unMount: () => undefined,
  deleteBooking: () => undefined,
  onFillClaim: (bookingId, claimId) => ({ bookingId, claimId }),
}, { prefix: PREFIX });
