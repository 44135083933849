import { createActions } from 'redux-actions';

export const clientClaimActions = createActions({
  FETCH_CLIENT: id => ({ id }),
  FETCH_CLIENT_SUCCESS: client => ({ client }),
  FETCH_CLIENT_FAIL: error => error,
  CLEAR_CLIENT: () => undefined,

  UPDATE_CLIENT: (clientID, values, formik) => ({ clientID, values, formik }),
  UPDATE_CLIENT_SUCCESS: client => client,
  UPDATE_CLIENT_FAIL: error => error,

  FETCH_TF_FIELDS_FOR_CLIENT: id => ({ id }),
  FETCH_TF_FIELDS_FOR_CLIENT_SUCCESS: fields => ({ fields }),
  FETCH_TF_FIELDS_FOR_CLIENT_FAIL: error => error,

  FETCH_CLIENT_PASSPORTS: id => ({ id }),
  FETCH_CLIENT_PASSPORTS_SUCCESS: passports => ({ passports }),
  FETCH_CLIENT_PASSPORTS_FAIL: error => error,
  CLEAR_CLIENT_PASSPORTS: () => undefined,

  CREATE_CLIENT: (client, formik) => ({ client, formik }),
  CREATE_CLIENT_SUCCESS: client => client,
  CREATE_CLIENT_FAIL: error => error,

  SEND_MESSAGE_TO_CLIENT: (id, values, formik) => ({ id, values, formik }),
  SEND_MESSAGE_TO_CLIENT_SUCCESS: client => client,
  SEND_MESSAGE_TO_CLIENT_FAIL: error => error,

  DELETE_CLIENT: id => id,

  CREATE_CLAIM_BY_FRESH_CLIENT: (client, claim, formik, offer) => (
    { client, claim, formik, offer }
  ),
  CREATE_CLAIM_WITH_OFFER: (offer, hotel, claim, formik) => ({ offer, hotel, claim, formik }),
  CREATE_CLAIM: (claim, formik, offer, search) => ({ claim, formik, offer, search }),
  CREATE_CLAIM_SUCCESS: claim => claim,
  CREATE_CLAIM_FAIL: error => error,

  SET_CONFLICT_CLIENTS: (oldClient, currentClient) => ({ oldClient, currentClient }),
  RESET_CONFLICT_CLIENTS: () => undefined,

  MERGE_CONFLICT_CLIENTS: (claim, formik, offer, search) => ({ claim, formik, offer, search }),

  CREATE_CLIENT_AND_CLAIM: (client, claim, formik, offer, search) => (
    { client, claim, formik, offer, search }
  ),
  CREATE_CLIENT_AND_CLAIM_FAIL: error => error,
  CREATE_CLIENT_AND_CLAIM_CANCEL: () => undefined,

  GET_CLAIM: id => id,
  GET_CLAIM_SUCCESS: claim => claim,
  GET_CLAIM_FAIL: error => error,
  CLEAR_CLAIM: () => undefined,
  deleteBookingFromClaim: () => undefined,

  getClaimSimplified: id => id,
  getClaimSimplifiedSuccess: simplifiedClaim => simplifiedClaim,
  getClaimSimplifiedFail: error => error,
  clearSimplifiedClaim: () => undefined,

  SET_TOUCHED_CLAIM: (callbacks, offer, hotel) => ({ callbacks, offer, hotel }),
  setTouchedClaimFromBasket: (offer, hotel, callbacks) => ({ callbacks, offer, hotel }),
  SET_TOUCHED_CLAIM_SUCCESS: claim => claim,
  setTouchedClaimFail: error => error,
  RESET_TOUCHED_CLAIM: () => undefined,

  UPDATE_CLAIM_PERFORMER: performer => performer,

  DELETE_SEARCH: searchId => searchId,
  DELETE_SEARCH_SUCCESS: searchId => searchId,
  DELETE_SEARCH_FAIL: error => error,

  GET_CLAIM_OFFER_TEMPLATE: (claimId, type) => ({ claimId, type }),
  GET_CLAIM_OFFER_TEMPLATE_SUCCESS: (type, template) => ({ type, template }),
  GET_CLAIM_OFFER_TEMPLATE_FAIL: error => error,

  GET_DECLARATIONS: countryId => countryId,
  GET_DECLARATIONS_SUCCESS: declarations => declarations,
  GET_DECLARATIONS_FAIL: error => error,

  GET_STATIC_LISTS: () => undefined,
  GET_STATIC_LISTS_SUCCESS: lists => lists,
  GET_STATIC_LISTS_FAIL: error => error,

  DELETE_LOCATION: (claimId, locationId) => ({ claimId, locationId }),

  UPDATE_CONTRACT_CLAIM:
    (claimId, values, formik, fieldsDataForError, showSuccess, isNeedResetTouchedClaim) => (
      { claimId, values, formik, fieldsDataForError, showSuccess, isNeedResetTouchedClaim }
    ),
  UPDATE_CONTRACT_CLAIM_SUCCESS: data => data,
  UPDATE_CONTRACT_CLAIM_FAIL: error => error,
  PREPARE_CONTRACT_CLAIM_TO_ACTION: (claimId, values, formik, action, workerOptions) => (
    { claimId, values, formik, action, workerOptions }
  ),

  CREATE_ROOM_TYPE: name => name,
  CREATE_ROOM_TYPE_SUCCESS: type => type,
  CREATE_ROOM_TYPE_FAIL: error => error,

  CREATE_CLAIM_PAYMENT_INCOME: (claimID, payment, letter, formik) => (
    { claimID, payment, letter, formik }
  ),
  CREATE_CLAIM_PAYMENT_INCOME_SUCCESS: payment => payment,
  CREATE_CLAIM_PAYMENT_INCOME_FAIL: error => error,

  CREATE_CLAIM_PAYMENT_INCOME_AND_UPDATE: (claimID, payment, formik) => (
    { claimID, payment, formik }
  ),
  CREATE_CLAIM_PAYMENT_INCOME_AND_UPDATE_SUCCESS: payment => payment,
  CREATE_CLAIM_PAYMENT_INCOME_AND_UPDATE_FAIL: error => error,

  CREATE_CLAIM_PAYMENT_EXPENSE_AND_UPDATE: (claimID, payment, formik) => (
    { claimID, payment, formik }
  ),
  CREATE_CLAIM_PAYMENT_EXPENSE_AND_UPDATE_SUCCESS: payment => payment,
  CREATE_CLAIM_PAYMENT_EXPENSE_AND_UPDATE_FAIL: error => error,

  CREATE_CLAIM_PAYMENT_EXPENSE: (claimID, payment, formik) => ({ claimID, payment, formik }),
  CREATE_CLAIM_PAYMENT_EXPENSE_SUCCESS: payment => payment,
  CREATE_CLAIM_PAYMENT_EXPENSE_FAIL: error => error,

  CREATE_CLAIM_BOOKING: (claimID, booking, formik, workerOptions) => (
    { claimID, booking, formik, workerOptions }
  ),
  CREATE_CLAIM_BOOKING_SUCCESS: booking => booking,
  CREATE_CLAIM_BOOKING_FAIL: error => error,

  DELETE_CLAIM_BOOKING: (bookingId, formik, workerOptions) => (
    { bookingId, formik, workerOptions }
  ),
  DELETE_CLAIM_BOOKING_SUCCESS: () => undefined,
  DELETE_CLAIM_BOOKING_FAIL: error => error,

  UPDATE_CLAIM_BOOKING: (booking, formik) => ({ booking, formik }),
  UPDATE_CLAIM_BOOKING_SUCCESS: booking => booking,
  UPDATE_CLAIM_BOOKING_FAIL: error => error,

  FETCH_CLAIM_BONUS: claimID => claimID,
  FETCH_CLAIM_BONUS_SUCCESS: bonus => bonus,
  FETCH_CLAIM_BONUS_FAIL: error => error,

  CLEAR_CLAIM_BONUS: () => undefined,

  UPDATE_CLAIM_DATE_BONUS: (claimID, dateBonus) => ({ claimID, dateBonus }),
  UPDATE_CLAIM_DATE_BONUS_SUCCESS: dateBonus => dateBonus,
  UPDATE_CLAIM_DATE_BONUS_FAIL: error => error,

  OPEN_ADD_COMMENT_COMPONENT: (mode, initialData) => ({ mode, initialData }),
  CLOSE_ADD_COMMENT_COMPONENT: () => undefined,

  CREATE_CLAIM_COMMENT: (claimID, values, formik) => ({ claimID, values, formik }),
  CREATE_CLAIM_COMMENT_SUCCESS: comment => comment,
  CREATE_CLAIM_COMMENT_FAIL: error => error,

  FETCH_CLAIM_COMMENTS_SUCCESS: comments => comments,
  FETCH_CLAIM_COMMENTS_FAIL: error => error,

  CLOSE_CLAIM: id => id,
  CLOSE_CLAIM_SUCCESS: () => undefined,
  CLOSE_CLAIM_FAIL: error => error,

  OPEN_CLAIM: message => message,
  OPEN_CLAIM_SUCCESS: () => undefined,
  OPEN_CLAIM_FAIL: () => undefined,

  DELETE_CLAIM: () => undefined,
  DELETE_CLAIM_FAIL: error => error,

  SET_IS_COMMENT_SHOW: isShow => isShow,

  deleteBasket: basketId => basketId,
  removeOfferFromBasket: (basketId, offerID) => ({ basketId, offerID }),
  setBasket: (basketId, basket) => ({ basketId, basket }),

  bootstrapClaim: claimId => claimId,
}, { prefix: 'MT_CLIENT_CLAIM' });
