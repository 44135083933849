import { select, call, put } from 'redux-saga/effects';

import { commonActions } from 'bus/common/actions';
import { getToken } from '../../../../auth/selectors.js';
import { deleteOperator } from '../../../../../api/methods/common/operators';

export function* deleteOperatorWorker({ payload: id }) {
  const token = yield select(getToken);

  try {
    yield call(deleteOperator, token, { pathParams: { id } });
    yield put(commonActions.deleteOperatorSuccess(id));
  } catch (error) {
    yield put(commonActions.deleteOperatorFail(error));
  }
}
