import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insturments
import { getNewMailingSubscribers } from 'api/methods/marketing';
import { marketingActions } from 'bus/marketing/actions';
import { uiActions } from 'bus/ui/actions';

const BASE_UI_PATH = ['marketing', 'newSubscribers'];

export function* getNewMailingSubscribersWorker({ payload }) {
  const { id, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...BASE_UI_PATH, id],
  })));

  try {
    const { count_new: countNew } = yield call(
      getNewMailingSubscribers,
      token,
      { pathParams: { tf, id } }
    );

    yield put(marketingActions.getNewMailingSubscribersSuccess(countNew, id));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Ошибка валидации');
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, id, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, id, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, id, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, id, 'completed'] })));
  }
}
