import { put, takeEvery, select, race, take, call } from 'redux-saga/effects';
import * as R from 'ramda';

import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getCounterpartyInfo } from 'bus/accounting/selector';
import { getBookingData } from 'bus/booking/selectors';
import { accountingActions } from 'bus/accounting/actions';
import { createBookingMessageWorker } from 'bus/booking/saga/workers/bookingMessages';
import { bookingActions } from 'bus/booking/actions';

import { newInstance } from 'localization';
import { prepareStringWithHtmlSymbols } from 'localization/helpers';

import { showToastSuccess } from 'services/toaster';

import { showCounterpartyDetailsModalLoader } from '../components/CounterpartyDetailsModal';

import { confirmReturnCommission, showCounterpartyDetailsModal } from './actions';
import { RETURN_COMMISSION_BUTTON_UI } from './constants';

const createDescription = (counterpartyInfo, bank) => R.join('<br>', [
  `${counterpartyInfo.legalName}${counterpartyInfo.code ? newInstance.t('BOOKING_PAGE:COUNTERPARTY_TIN', { tin: counterpartyInfo.code }) : ''}`,
  bank?.iban,
].filter(Boolean));

function* showCounterpartyDetailsModalSaga() {
  yield put(registerUI(createUi({ loading: true }), RETURN_COMMISSION_BUTTON_UI));

  const booking = yield select(getBookingData);

  const counterpartyId = R.path(['counterparty_tf', 'id'], booking);

  if (counterpartyId) {
    yield put(accountingActions.geCounterpartyInfo(counterpartyId));

    const [success] = yield race([
      take(accountingActions.geCounterpartyInfoSuccess),
      take(accountingActions.geCounterpartyInfoFail)
    ]);

    if (success) {
      const counterpartyInfo = yield select(getCounterpartyInfo);

      const activeBanks = R.filter(R.prop('active'), counterpartyInfo.banks);

      const items = R.map(
        ({ id, iban }) => {
          return ({
            id,
            description: prepareStringWithHtmlSymbols(
              createDescription(counterpartyInfo, { iban })
            )
          });
        },
        activeBanks
      );

      yield put(showCounterpartyDetailsModalLoader({
        props: {
          items,
        },
        mapActions2Store: {
          onSuccess: (dispatch, _, data) => {
            dispatch(confirmReturnCommission(data));
          },
        }
      }));
    }
  }

  yield put(updateUI({ completed: true, loading: false }, RETURN_COMMISSION_BUTTON_UI));
}

function* confirmDetailSaga({ payload: commission }) {
  yield put(registerUI(createUi({ loading: true }), RETURN_COMMISSION_BUTTON_UI));

  yield call(createBookingMessageWorker, bookingActions.createBookingMessage(
    { text: R.join('\n', [
      newInstance.t('BOOKING_PAGE:COMMISSION_REQUEST_TITLE'),
      commission.description
    ]) }
  ));

  showToastSuccess();
  yield put(updateUI({ completed: true, loading: false }, RETURN_COMMISSION_BUTTON_UI));
}

export default function* () {
  yield takeEvery(showCounterpartyDetailsModal, showCounterpartyDetailsModalSaga);
  yield takeEvery(confirmReturnCommission, confirmDetailSaga);
}
