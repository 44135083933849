import React, { memo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

// components
import { NotifyEndingSubscription } from 'components/subscription/NotifyEndingSubscription';

// containers
import { SUBSCRIPTION_IS_OVER } from 'containers/molecules/subscription';

// instruments
import { setGroupValue, getStorage, GROUPS } from 'helpers/storage';

const STORAGE_KEY = 'showNotifyEnding';

export const NotifyEndingSubscriptionContainer = memo(() => {
  const [isShowNotify, setShowNotify] = useState(getStorage().getIn([GROUPS.subscription, STORAGE_KEY], false));
  const isDisableSubscription = useSelector(({ auth }) => auth.getIn(['profile', 'tf', 'subscription']) === SUBSCRIPTION_IS_OVER);

  // computed props
  const isRenderNotify = !isShowNotify && isDisableSubscription;

  // methods
  const handleCloseNotify = useCallback(() => {
    setGroupValue(GROUPS.subscription, { [STORAGE_KEY]: true });
    setShowNotify(true);
  }, []);

  return isRenderNotify && (
    <NotifyEndingSubscription onClose={handleCloseNotify} />
  );
});
