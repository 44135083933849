import { select, put, call } from 'redux-saga/effects';

import { getBalanceInfo } from 'api/methods/subscription';

import { subscriptionActions } from 'bus/subscription';

import { getErrorMessage } from 'api/fn/parseErrors';

// TODO_ERROR
export function* getBalanceInfoWorker({ payload: balanceId }) {
  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);

  try {
    const balance = yield call(getBalanceInfo, token, { pathParams: { tf, balanceId } });

    yield put(subscriptionActions.getBalanceInfoSuccess(balance));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'ТФ или запись balance не найдена',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(subscriptionActions.getBalanceInfoFail(error));
  }
}
