import { select, put, call } from 'redux-saga/effects';
import { Map } from 'immutable';

// instruments
import { getNotifyInfo } from 'api/methods/tf/notify';
import { uiActions } from 'bus/ui/actions';
import { notifyActions } from 'bus/notify';

export function* getNotifiesWorker() {
  const token = yield select(({ auth }) => auth.get('token'));
  const id = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['notify', 'notifies'],
  }));

  try {
    const notifies = yield call(getNotifyInfo, token, { pathParams: { id } });
    const processedNotifies = Map({ ...notifies })
      .groupBy(({ type }) => type)
      .map(notifyList => notifyList.toList().toArray())
      .toObject();

    yield put(notifyActions.getNotifiesSuccess(processedNotifies));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['notify', 'notifies', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['notify', 'notifies', 'message'],
    }));
    yield put(notifyActions.getNotifiesFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['notify', 'notifies', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['notify', 'notifies', 'completed'],
    }));
  }
}
