import { Map } from 'immutable';

import { LOCALES } from 'localization/constants';

import { CURRENCIES } from './currency';

export const getCurrencyByCode = code => CURRENCIES.find(item => item.code === code) || {
  code: null,
  label: null,
  symbol: null,
};

export const ACTIVE_STEPS = Map({
  ORDER: { marker: 'order' },
  TOUR: { marker: 'tour' },
  PASSPORTS: { marker: 'passport' },
  CONTRACT: { marker: 'dogovor' },
  PAY: { marker: 'pay' },
  CONFIRM: { marker: 'bron' },
  OK: { marker: 'ok' },
  DOCS: { marker: 'docs' },
});

export const ACTIVE_STEPS_NAMES = {
  ORDER: 'order',
  TOUR: 'tour',
  PASSPORTS: 'passport',
  CONTRACT: 'dogovor',
  PAY: 'pay',
  CONFIRM: 'bron',

  FLY: 'fly',
  HOTEL: 'hotel',
  OK: 'ok',
  DOCS: 'docs',
};

export const ATC_PROVIDERS = [
  { provider: 'binotel', label: 'BINOTEL_MAIN' },
  { provider: 'phonet', label: 'PHONET_MAIN' }
];

export const FILTER_USER_VALUES = {
  ACTIVE: true,
  NO_ACTIVE: false,
  ALL: 'all',
};

export const FILTER_DOCS_VALUES = {
  ACTIVE: false,
  NO_ACTIVE: true,
  ALL: 'all',
};

export const MT_MAIN_LINKS = {
  [LOCALES.UA]: 'https://www.moituristy.ua/ua/',
  [LOCALES.RU]: 'https://www.moituristy.ua/',
};

export const REFUND_POLICY_LINKS = {
  [LOCALES.UA]: 'https://www.moituristy.ua/ua/public/main/price#money-back',
  [LOCALES.RU]: 'https://www.moituristy.ua/public/main/price#money-back',
  default: 'https://www.moituristy.com/public/main/price#money-back',
};

export const MERCHANTS = {
  liqpay: {
    name: 'liqpay',
    value: 'liqpay',
  },
  '2checkout': {
    name: '2checkout',
    value: '2checkout',
  },
  invoice: {
    name: 'Счёт',
    value: 'invoice',
    tax: {
      single: 'single',
      general: 'general',
    },
  },

};

export const XPAY_LINKS = {
  main: {
    [LOCALES.UA]: 'https://www.xpay.com.ua/',
    [LOCALES.RU]: 'https://www.xpay.com.ua/',
  },
  details: 'https://www.xpay.com.ua/about-xpay',
};

export const SMS_PRICE_LINKS = {
  default: '/api/tfs/sms/prices.html',
};

export const PAYMENT_TYPES = {
  expense: 'expense',
  income: 'income',
};

export const MAILING_STATUS = {
  all: 'all',
  sent: 'sent',
  draft: 'draft',
  deleted: 'deleted',
};

export const BOOKING_OPERATORS = [
  { id: 759, title: 'Alf' },
  { id: 594, title: 'TUI' },
  { id: 9148, title: 'Kompas' },
  { id: 2700, title: 'TPG' },
  { id: 2, title: 'Teztour' },
  { id: 717, title: 'Anextour' },
  { id: 2835, title: 'JoinUp' }
];

export const CALENDAR_TYPES = {
  item: 'item',
  claim: 'claim',
  claimEvent: 'claim_event',
  claimFlightIn: 'claim_flight_in',
  claimFlightOut: 'claim_flight_out',
  clientBirthday: 'client_birthday',
  epassportBirthday: 'epassport_birthday',
};

export const COLOR_PRESET = [
  { backgroundColor: '#FAE5E5', textColor: '#000', borderColor: '#FAE5E5' },
  { backgroundColor: '#F9ECE0', textColor: '#000', borderColor: '#F9ECE0' },
  { backgroundColor: '#FAF4E2', textColor: '#000', borderColor: '#FAF4E2' },
  { backgroundColor: '#F9E8F5', textColor: '#000', borderColor: '#F9E8F5' },
  { backgroundColor: '#E6D7F4', textColor: '#000', borderColor: '#E6D7F4' },
  { backgroundColor: '#D1E1F7', textColor: '#000', borderColor: '#D1E1F7' },
  { backgroundColor: '#C9F4EC', textColor: '#000', borderColor: '#C9F4EC' },
  { backgroundColor: '#CCF7CC', textColor: '#000', borderColor: '#CCF7CC' },
  { backgroundColor: '#EBE7DC', textColor: '#000', borderColor: '#EBE7DC' },
  { backgroundColor: '#EAECEE', textColor: '#000', borderColor: '#EAECEE' },
  { backgroundColor: '#E95673', textColor: '#FFF', borderColor: '#E95673' },
  { backgroundColor: '#F2994A', textColor: '#FFF', borderColor: '#F2994A' },
  { backgroundColor: '#F2C94C', textColor: '#FFF', borderColor: '#F2C94C' },
  { backgroundColor: '#FB9FA5', textColor: '#FFF', borderColor: '#FB9FA5' },
  { backgroundColor: '#8C4A91', textColor: '#FFF', borderColor: '#8C4A91' },
  { backgroundColor: '#807FB3', textColor: '#FFF', borderColor: '#807FB3' },
  { backgroundColor: '#2D9CDB', textColor: '#FFF', borderColor: '#2D9CDB' },
  { backgroundColor: '#27AE60', textColor: '#FFF', borderColor: '#27AE60' },
  { backgroundColor: '#00B5AD', textColor: '#FFF', borderColor: '#00B5AD' },
  { backgroundColor: '#828282', textColor: '#FFF', borderColor: '#828282' }
].map(({ backgroundColor, ...rest }) => ({ backgroundColor: backgroundColor.toLowerCase(), ...rest }));

export const LOCALES_OPTIONS = {
  [LOCALES.UA]: { text: 'Українська', value: LOCALES.UA },
  [LOCALES.RU]: { text: 'Русский', value: LOCALES.RU },
  [LOCALES.EN]: { text: 'English', value: LOCALES.EN },
  [LOCALES.PL]: { text: 'Polski', value: LOCALES.PL },
};

export const DEFAULT_API_KEY_NAME = 'otpusk';

export const FIELDS_TYPES = [
  { value: 'checkbox', text: 'CHECK_MARK' },
  { value: 'string', text: 'LINE' },
  { value: 'text', text: 'TEXT' },
  { value: 'date', text: 'DATE' }
];

export const EDITING_NOTIFIES = {
  digital_contract: 'digital_contract',
  tours: 'tours',
  tours_new_year: 'tours_new_year',
  tours1: 'tours1',
  tours2: 'tours2',
  tours3: 'tours3',
  tours4: 'tours4',
  create: 'create',
  payment: 'payment',
  beforefly: 'beforefly',
  afterback: 'afterback',
  birthday: 'birthday',
  client_message: 'client_message',
  payment_invoice: 'payment_invoice',
};
