import { takeEvery } from 'redux-saga/effects';

// instruments
import { messagesActions as actions } from 'bus/messages';
import { createMessageWorker } from './workers/createMessage';

export const messagesWatchers = Object.freeze({
  * createMessage() {
    yield takeEvery(actions.createMessage, createMessageWorker);
  },
});
