import { select, call, put } from 'redux-saga/effects';

// instruments
import { mainActions } from 'bus/main/actions';
import { uiActions } from 'bus/ui/actions';
import { getStatistic } from 'api/methods/tf/statistic';

export function* fetchFastStatisticWorker({ payload: date }) {
  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['main', 'fastStatistic'],
  }));

  try {
    const statistic = yield call(getStatistic, token, { pathParams: { date, tf } });

    yield put(mainActions.fetchFastStatisticSuccess(statistic));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['main', 'fastStatistic', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['main', 'fastStatistic', 'message'],
    }));
    yield put(mainActions.fetchFastStatisticFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['main', 'fastStatistic', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['main', 'fastStatistic', 'completed'],
    }));
  }
}
