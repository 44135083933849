import { select, call, put } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { getComments } from 'api/methods/claim/comment';

export function* fetchClaimCommentsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));
  const id = yield select(({ clientClaim }) => clientClaim.getIn(['claim', 'id']));

  if (!id) {
    return;
  }

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['clientClaim', 'fetchClaimComments', 'loading'],
  }));
  yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: ['clientClaim', 'fetchClaimComments', 'error'],
  }));

  try {
    const comments = yield call(getComments, token, { pathParams: { id } });

    yield put(clientClaimActions.fetchClaimCommentsSuccess(comments));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['clientClaim', 'fetchClaimComments', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка загрузки комментариев',
      path: ['clientClaim', 'fetchClaimComments', 'message'],
    }));
    yield put(clientClaimActions.fetchClaimCommentsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['clientClaim', 'fetchClaimComments', 'loading'],
    }));
  }
}
