import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { dumpActions } from 'bus/dump/actions';
import { uploadImportFile } from 'api/methods/dump';
import { setGroupValue, GROUPS } from 'helpers/storage';

const STORAGE_KEY = 'importJob';

export function* uploadImportFileWorker({ payload }) {
  const { values, formik } = payload;
  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  const bodyParams = new FormData();

  bodyParams.append('file', values.file);

  try {
    const job = yield call(uploadImportFile, token, { bodyParams });

    const jobWithType = { type: 'analyze', ...job };

    yield call(setGroupValue, GROUPS.dump, { [STORAGE_KEY]: jobWithType });
    yield put(dumpActions.uploadImportFileSuccess(jobWithType));
    yield put(dumpActions.startImportProgress());
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = get(body, 'message', 'Ошибка валидации файла');

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу или к ТФ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'ТФ не найдена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    yield put(dumpActions.uploadImportFileFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
