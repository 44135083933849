import { handleActions, combineActions } from 'redux-actions';
import { Map, List } from 'immutable';

// Instruments
import { filesActions as actions } from './actions';

const initialState = Map({
  fileList: List(),
  editFile: null,

  uploads: {
    files: null,
  },
  clients: {},
  manipulatedFile: null,
  downloadFileId: null,
  financeJobId: null,
  bookingJobId: null,
});

export default handleActions({
  [actions.fetchFilesSuccess]: (state, { payload }) => {
    const { files, clientID } = payload;

    return state.setIn(['clients', clientID], files);
  },
  [actions.clearFiles]: (state, { payload: clientID }) => {
    return state.removeIn(['clients', clientID]);
  },
  [combineActions(
    actions.setManipulatedFile,
    actions.resetManipulatedFile
  )]: (state, { payload: value }) => {
    return state.set('manipulatedFile', value);
  },
  [actions.createFilesSuccess]: (state, { payload }) => {
    const { clientID, files } = payload;

    return state.mergeIn(['clients', clientID], files);
  },
  [actions.updateFileSuccess]: (state, { payload }) => {
    const { clientID, fileID, file } = payload;
    const updateFileIndex = state.getIn(['clients', clientID], []).findIndex(({ id }) => id === fileID);

    return state.setIn(['clients', clientID, updateFileIndex], file);
  },
  [actions.deleteFileSuccess]: (state, { payload }) => {
    const { clientID, id } = payload;

    return state.updateIn(['clients', clientID], files => files.filter(file => file.id !== id));
  },
  [actions.getUploadsFilesSuccess]: (state, { payload: files }) => state.setIn(['uploads', 'files'], files),
  [actions.deleteUploadsFileSuccess]: (state, { payload: filename }) => {
    return state.updateIn(['uploads', 'files'], files => {
      const results = files.filter(file => file.filename !== filename);

      return results.length ? results : null;
    });
  },
  [combineActions(
    actions.createDownloadFileIdSuccess,
    actions.clearDownloadFileId
  )]: (state, { payload: id }) => {
    return state.set('downloadFileId', id);
  },
  [combineActions(
    actions.createFinanceJobSuccess,
    actions.clearFinanceJob
  )]: (state, { payload: id }) => {
    return state.set('financeJobId', id);
  },
  [combineActions(
    actions.createBookingJobSuccess,
    actions.clearBookingJob
  )]: (state, { payload: id }) => {
    return state.set('bookingJobId', id);
  },
}, initialState);
