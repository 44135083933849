import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import * as R from 'ramda';

import book from 'routes/book';

import { addOfferToBasketSaga } from '../baskets/addOfferToBasket';
import { addBasketWithOfferSaga } from '../baskets/addBasket';
import { addBasketToClaimSaga } from '../baskets/addBasketToClaim';

const getLastBasketId = claim => R.pipe(
  R.prop('baskets'),
  R.last
)(claim);

export function* saveOfferToClaimWorker({ payload }) {
  const { callbacks, claimID, clientID, hotel, offer, claim, onCloseModal, query } = payload;

  if (!claimID) {
    const extraParams = JSON.stringify({
      currency: offer.currency,
    });

    if (!clientID) {
      yield put(push(
        `${book.addClient}?offerId=${offer.id}&hotelId=${hotel.id}&extra=${extraParams}`
      ));
    } else {
      const path = generatePath(book.clientClaim.children.createClaim, { clientId: clientID });

      yield put(push(
        `${path}?offerId=${offer.id}&hotelId=${hotel.id}&extra=${extraParams}`
      ));
    }

    onCloseModal();
    callbacks?.onSuccess();

    return;
  }

  try {
    const lastBasket = getLastBasketId(claim);

    if (lastBasket) {
      yield call(addOfferToBasketSaga, offer.id, query.currency, lastBasket.id);
    } else {
      const basket = yield call(addBasketWithOfferSaga, offer.id, query.currency);

      yield call(addBasketToClaimSaga, basket.id, claimID, claim.client.id);
    }

    callbacks?.onSuccess();
  } catch (error) {
    callbacks?.onFail(error);
  }
}
