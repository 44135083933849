import { call, select, put } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

// instruments
import { createDoneMarkerStep, deleteDoneMarkerStep } from 'api/methods/claim/steps';
import { uiActions } from 'bus/ui/actions';
import { stepersActions } from 'bus/clientClaim/stepers';

const BASE_UI_PATH = ['clientClaim', 'stepers', 'toggle'];

export function* toggleDoneStepWorker({ payload }) {
  const { claimID, stepID, status, workerOptions } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, claimID, stepID],
  })));

  try {
    const step = yield call(
      status
        ? createDoneMarkerStep
        : deleteDoneMarkerStep, token, { pathParams: { claimID, stepID } }
    );

    yield put(stepersActions.toggleDoneStepSuccess(claimID, stepID, status, step));
  } catch (error) {
    const { status: statusCode, body } = error.msg || {};

    let message = null;

    let otherErrors = [];

    switch (statusCode) {
      case 400: {
        const isSetViolations = getIn(body, ['violations', 'length']);

        if (isSetViolations) {
          const { violations } = body;

          otherErrors = violations.map(item => ({ ...item, message: item.message.replace('.', '') }));
        } else {
          message = `Ошибка сервера - ${statusCode}`;
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Необходимо иметь разрешение на доступ к разделу ‘Клиенты’ или пользователь не имеет доступа к заказу/шагу или запрещено если отсутстует подписка на CRM');

        break;
      }
      case 404: {
        message = 'Заказ или шаг не найден';

        break;
      }
      default: message = `Ошибка сервера - ${statusCode}`;
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, claimID, stepID, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: [{ property_path: 'message', message }, ...otherErrors].filter(item => item.message),
      path: [...BASE_UI_PATH, claimID, stepID, 'message'],
    })));

    yield put(stepersActions.toggleDoneStepFail(error, { message, otherErrors }));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, claimID, stepID, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, claimID, stepID, 'completed'],
    })));
  }
}
