import { put, call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

import { accountingActions as actions } from 'bus/accounting/actions';
import { getToken } from 'bus/auth/selectors';

import { createAccountingInvoice } from 'api/methods/accounting';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { newInstance } from 'localization';

export function* createInvoiceWorker({ payload }) {
  const { values, formik, callbacks } = payload;

  const { onSuccess } = callbacks;

  const token = yield select(getToken);

  try {
    const invoice = yield call(
      createAccountingInvoice,
      token,
      { bodyParams: values }
    );

    if (onSuccess) {
      onSuccess(invoice);
    }

    yield call(formik.setStatus, { success: true, message: 'Счет успешно сохранен' });

    yield put(actions.setInvoiceTemplateModalValues(invoice));
    yield put(actions.switchInvoiceTemplateModal(true));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Невозможно создать счет на/от удалённого контрагента');
        } else {
          message = 'Невозможно создать счет на/от удалённого контрагента';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      case 409: {
        message = newInstance.t('ERRORS:CONTRACT_ERROR');
        break;
      }
      default:
        message = 'Ошибка сервера';
    }
    yield put(actions.createInvoiceFail(error));
    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
