import { call, select, put } from 'redux-saga/effects';

import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';
import { bookingActions } from 'bus/booking/actions.js';

import { getDocuments } from 'api/methods/booking';

import { GET_BOOKING_DOCUMENTS_UI } from './constants';

function* getDocumentsSaga({ payload: bookingId }) {
  const token = yield select(getToken);

  try {
    const documents = yield call(getDocuments, token, { pathParams: { bookingId } });

    yield put(bookingActions.getDocumentsSuccess(documents));
  } catch (error) {
    const { message } = error.msg.body;

    error.message = message ?? 'Ошибка сервера';

    yield put(bookingActions.getDocumentsFail(error));
  }
}

export const getDocumentsWithUI = withUIWorker(
  [
    bookingActions.getDocumentsSuccess,
    bookingActions.getDocumentsFail
  ],
  GET_BOOKING_DOCUMENTS_UI,
)(getDocumentsSaga);
