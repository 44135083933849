import { takeEvery } from 'redux-saga/effects';

import { claimActions as actions } from 'bus/clientClaim/claim';
import { updateClaimBaseWorker } from './workers/update/base';
import { updateDateCreatedBaseWorker } from './workers/update/dateCreatedBase';
import { orderStepFormWorker } from './workers/update/forms/orderStepForm';

export const claimWatchers = Object.freeze({
  * updateClaimBaseWatcher() {
    yield takeEvery(actions.updateClaim, updateClaimBaseWorker);
  },
  * updateDateCreatedBaseWatcher() {
    yield takeEvery(actions.updateDateCreated, updateDateCreatedBaseWorker);
  },
  * orderStepFormWatcher() {
    yield takeEvery(actions.submitOrderStepForm, orderStepFormWorker);
  }
});
