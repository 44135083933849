import { call, put, race, take } from 'redux-saga/effects';

import { authActions } from 'bus/auth/actions';
import { uiActions } from 'bus/ui/actions';

import { SECOND_STEP } from 'components/forms/auth/Register/constatns';

export function* validateEmailMediator({ payload }) {
  const { formik, user } = payload;
  const { email, phone } = user;

  yield put(authActions.validateEmail(email));
  const [success, failed] = yield race([
    take(authActions.validateEmailSuccess),
    take(authActions.validateEmailFailed)
  ]);

  if (success) {
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, success: false, message: null, completed: false },
      path: ['auth', 'registerCode'],
    }));

    yield put(authActions.createRegisterCode(phone));

    const [successCreateCode] = yield race([
      take(authActions.createRegisterCodeSuccess),
      take(authActions.createRegisterCodeFail)
    ]);

    if (successCreateCode) {
      formik.setFieldValue('step', SECOND_STEP);
    }
  } else {
    const { payload: error } = failed;
    const errorText = error.message ? error.message : 'Ошибка сервера';

    formik.setErrors({ email: errorText });
  }

  yield call(formik.setSubmitting, false);
}
