import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { createPortal } from 'react-dom';

import { getOpenModals } from './store/selectors';

import { getComponent } from './componentsCache';

const rootNode = document.querySelector('body');

const ModalDispatcher = () => {
  const modals = useSelector(getOpenModals);

  const modalsJSX = useMemo(
    () => R.map(({ key, mapActions2Store, props, onClose }) => {
      const Component = getComponent(key);

      return Component && (
        <Component
          key={key}
          mapActions2Store={mapActions2Store}
          modalKey={key}
          props={props}
          onClose={onClose}
        />
      );
    }, modals),
    [modals]
  );

  return !R.isEmpty(modalsJSX) && createPortal(
    modalsJSX,
    rootNode
  );
};

export default ModalDispatcher;
