import { all, spawn } from 'redux-saga/effects';
import { operatorsWatchers } from '@otpusk/apisearch-toolbox/dist/operators';
import { geoWatchers } from '@otpusk/apisearch-toolbox/dist/geo';
import { hotelsWatchers } from '@otpusk/apisearch-toolbox/dist/hotels';
import { servicesWatchers as servicesOtpuskWatchers } from '@otpusk/apisearch-toolbox/dist/services';
import { offersRootSaga } from '@otpusk/apisearch-toolbox/dist/offers/saga';

import { authWatchers as auth } from 'bus/auth/saga/watchers';
import { uiWatchers as ui } from 'bus/ui/saga/watchers';
import { tfUserWatchers as tfUsers } from 'bus/tfUsers/saga/watchers';
import { tfWathcers as tf } from 'bus/tf/saga/watchers';
import { detailDocsWatchers as detailDocs } from 'bus/detailDocs/saga/watchers';
import { searchClientAndClaimWatcher as searchClientAndClaim } from 'bus/searchClientAndClaim/saga/watchers';
import { searchClientLeadWatcher as searchClientLead } from 'bus/searchClientLead/saga/watchers';
import { detailsFieldsWatchers as detailsFields } from 'bus/detailsFields/saga/watchers';
import { documentFieldsWatchers as documentFields } from 'bus/documentFields';
import { claimWatchers as claim } from 'bus/claim/saga/watchers';
import { clientClaimWatchers as clientClaim } from 'bus/clientClaim/saga/watchers';
import { mainWatchers as main } from 'bus/main/saga/watchers';
import { filesWatchers as files } from 'bus/files/saga/watchers';
import { financeWatchers as finance } from 'bus/finance/saga/watchers';
import { commonWatchers as common } from 'bus/common/saga/watchers';
import { statisticWatchers as statistic } from 'bus/statistic/saga/watchers';
import { messagesWatchers as messages } from 'bus/messages';
import { notifyWatchers as notify } from 'bus/notify';
import { newsWatchers as news } from 'bus/news';
import { tourscanerWatchers as tourscaner } from 'bus/tourscaner';
import { subscriptionWatchers as subscription } from 'bus/subscription';
import { templatesWatchers as templates } from 'bus/templates/saga/watchers';
import { marketingWatchers as marketing } from 'bus/marketing/saga/watchers';
import { calendarWatchers as calendar } from 'bus/calendar/saga/watchers';
import { dumpWatchers as dump } from 'bus/dump/saga/watchers';
import { bookingWatchers as booking } from 'bus/booking/saga/watchers';
import { accountingWatchers as accounting } from 'bus/accounting/saga/watchers';
import { servicesWatchers } from 'bus/services/saga/watchers';
import { registersWatcher } from 'containers/form/auth/Register/store/saga/watchers';

import aktsListSaga from 'components/tables/accounting/SearchAktTable/store/saga';
import tourOperatorSaga from 'pages/Details/children/TourOperators/store/saga';
import modalsDispatcherSaga from 'containers/modalsDispatcher/store/saga';
import clientClaimSaga from 'pages/ClientClaim/store/saga';
import InvoiceRequestButtonSaga from 'components/booking/buttons/InvoiceRequestButton/store/saga';
import returnCommissionButtonSaga from 'components/booking/buttons/ReturnCommissionButton/store/saga';
import generateActsButtonSaga from 'components/booking/buttons/GenerateActButton/store/saga';
import rootPublicOfferPageSaga from 'pages/PublicOffer/store/saga';
import clientPaymentsPage from 'pages/Client/children/Payments/store/saga';
import commissionSaga from 'containers/table/Commission/store/saga';
import commissionAccessTable from 'components/commission/CommissionAccessTable/store/saga';
import loginSaga from 'pages/Login/store/saga';
import savedSearches from 'bus/savedSearches/saga';
import initBookingPageWatcher from 'pages/Client/children/BookingPage/store/saga';
import bookingReservationWatcher from 'components/clientClaim/ClaimStepersForms/Reservation/components/BookingReservationFull/store/saga';
import { tourFormRootSaga } from 'components/clientClaim/ClaimStepersForms/TourForm/store/saga';

import bookingAdminPageRootSaga from 'pages/Booking/children/store/saga';
import reExposePaymentsWatcher from '../components/tables/accounting/SearchInvoices/store/saga';
import { balanceWithDelayWorker } from '../containers/subscription/NotifyBalanceInfo/store/saga';
import { initializeDataForPageWatcher } from '../pages/Details/children/Collections/store/saga/watchers';
import { updateTfProfileMediatorWatcher } from '../containers/editProfileTf/detailsProfile/store/saga/watchers';
import otpuskWatchers from '../bus/otpusk/saga/watchers';
import { bookingCommonRootSaga } from '../containers/form/booking/BookingCommon/store/saga';
import { paymentModalSaga } from '../components/modals/PaymentModal';
import { bookingPayRootSaga } from '../components/tables/booking/BookingPay/store/saga';
import { busTicketsSaga } from '../pages/BusTickets/store';

const mapper = wathcers => Object.values(wathcers).map(wathcer => wathcer());

export function* rootSaga() {
  yield all([
    ...mapper(auth),
    ...mapper(ui),
    ...mapper(tfUsers),
    ...mapper(tf),
    ...mapper(detailDocs),
    ...mapper(searchClientAndClaim),
    ...mapper(searchClientLead),
    ...mapper(detailsFields),
    ...mapper(documentFields),
    ...mapper(claim),
    ...mapper(clientClaim),
    ...mapper(main),
    ...mapper(files),
    ...mapper(finance),
    ...mapper(common),
    ...mapper(statistic),
    ...mapper(messages),
    ...mapper(notify),
    ...mapper(tourscaner),
    ...mapper(news),
    ...mapper(subscription),
    ...mapper(templates),
    ...mapper(marketing),
    ...mapper(calendar),
    ...mapper(dump),
    ...mapper(booking),
    ...mapper(accounting),
    ...mapper(servicesWatchers),
    ...mapper(initializeDataForPageWatcher),

    // ts
    ...mapper(operatorsWatchers),
    ...mapper(geoWatchers),
    ...mapper(hotelsWatchers),
    ...mapper(servicesOtpuskWatchers),
    ...mapper(registersWatcher),
    ...mapper(updateTfProfileMediatorWatcher),
    ...mapper(otpuskWatchers),
    spawn(offersRootSaga),

    balanceWithDelayWorker(),
    reExposePaymentsWatcher(),
    aktsListSaga(),
    tourOperatorSaga(),
    modalsDispatcherSaga(),
    clientClaimSaga(),
    InvoiceRequestButtonSaga(),
    returnCommissionButtonSaga(),
    generateActsButtonSaga(),
    rootPublicOfferPageSaga(),
    clientPaymentsPage(),
    commissionSaga(),
    commissionAccessTable(),
    loginSaga(),
    savedSearches(),
    initBookingPageWatcher(),
    bookingReservationWatcher(),
    bookingCommonRootSaga(),
    paymentModalSaga(),
    tourFormRootSaga(),
    bookingPayRootSaga(),
    bookingAdminPageRootSaga(),
    busTicketsSaga()
  ]);
}
