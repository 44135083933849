import { call } from 'redux-saga/effects';
import { get } from 'immutable';

import { parseErrorsByFormik } from 'api/fn/parseErrors';

export const findEntityInArray = (id, array, key) => array && array.find(element => element[key] === id);

export function* getErrorMessage(error, values, formik) {
  const { status, body } = error.payload.msg || {};

  let message = null;

  switch (status) {
    case 400: {
      const { violations } = body;

      if (violations) {
        const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

        errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
        otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

        !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
      } else {
        message = 'Ошибка сервера';
      }

      break;
    }
    case 403: {
      message = get(body, 'message', 'Активна подписка только на Турсканер');
      break;
    }
    default: message = 'Ошибка сервера';
  }

  return message;
}
