import { createActions } from 'redux-actions';

export const dumpActions = createActions({
  UPLOAD_IMPORT_FILE: (values, formik) => ({ values, formik }),
  UPLOAD_IMPORT_FILE_SUCCESS: job => job,
  UPLOAD_IMPORT_FILE_FAIL: error => error,

  IMPORT_PROGRESS: (id, type) => ({ id, type }),
  IMPORT_PROGRESS_SUCCESS: job => job,
  IMPORT_PROGRESS_FAIL: error => error,

  START_IMPORT_PROGRESS: () => undefined,
  STOP_IMPORT_PROGRESS: () => undefined,

  CLEAR_JOB: () => null,

  IMPORT_ANALYZE: (id, page, workerOptions) => ({ id, page, workerOptions }),
  IMPORT_ANALYZE_SUCCESS: response => response,
  IMPORT_ANALYZE_FAIL: error => error,

  CLEAR_ANALYZE: () => null,

  IMPORT_RUN: (id, workerOptions) => ({ id, workerOptions }),
  IMPORT_RUN_SUCCESS: job => job,
  IMPORT_RUN_FAIL: error => error,

  IMPORT_RUN_RESULT: (id, workerOptions) => ({ id, workerOptions }),
  IMPORT_RUN_RESULT_SUCCESS: job => job,
  IMPORT_RUN_RESULT_FAIL: error => error,

  CLEAR_RUN_RESULT: () => null,
}, { prefix: 'MT_DUMP' });
