import { takeEvery } from 'redux-saga/effects';

// actions
import { epassportsActions as actions } from 'bus/clientClaim/epassports';

// workers
import { createEpassportBaseWorker } from 'bus/clientClaim/epassports/saga/workers/create/base';
import { createEpassportFormikWorker } from 'bus/clientClaim/epassports/saga/workers/create/formik';
import { updateEpassportBaseWorker } from 'bus/clientClaim/epassports/saga/workers/update/base';
import { updateEpassportFormikWorker } from 'bus/clientClaim/epassports/saga/workers/update/formik';
import { deleteEpassportBaseWorker } from 'bus/clientClaim/epassports/saga/workers/delete/base';
import { recognizeEpassportWorker } from 'bus/clientClaim/epassports/saga/workers/recognize';

export const epassportsWatchers = Object.freeze({
  * createEpassportBaseWatcher() {
    yield takeEvery(actions.createEpassport, createEpassportBaseWorker);
  },
  * createEpassportFormikWatcher() {
    yield takeEvery(actions.createEpassportByFormik, createEpassportFormikWorker);
  },
  * updateEpassportBaseWatcher() {
    yield takeEvery(actions.updateEpassport, updateEpassportBaseWorker);
  },
  * updateEpassportFormikWatcher() {
    yield takeEvery(actions.updateEpassportByFormik, updateEpassportFormikWorker);
  },
  * deleteEpassportBaseWatcher() {
    yield takeEvery(actions.deleteEpassport, deleteEpassportBaseWorker);
  },
  * recognizeEpassportWatcher() {
    yield takeEvery(actions.recognizeEpassport, recognizeEpassportWorker);
  },
});
