import { takeEvery } from 'redux-saga/effects';

// intruments
import {
  fetchFieldWorker,
  createFieldWorker,
  deleteFieldWorker,
  updateFieldWorker,
  sortFieldWorker,
  createFieldFormikWorker,
  updateFieldFormikWorker
} from './workers';
import { detailsFieldsActions as actions } from '../actions';

export const detailsFieldsWatchers = Object.freeze({
  * fetchField() {
    yield takeEvery(actions.fetchFields, fetchFieldWorker);
  },
  * createField() {
    yield takeEvery(actions.createField, createFieldWorker);
  },
  * deleteField() {
    yield takeEvery(actions.deleteField, deleteFieldWorker);
  },
  * updateField() {
    yield takeEvery(actions.updateField, updateFieldWorker);
  },
  * sortField() {
    yield takeEvery(actions.sortField, sortFieldWorker);
  },
  * createFieldFormik() {
    yield takeEvery(actions.createFieldByFormik, createFieldFormikWorker);
  },
  * updateFieldFormik() {
    yield takeEvery(actions.updateFieldByFormik, updateFieldFormikWorker);
  },
});
