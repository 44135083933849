import modalLoader from 'containers/modalsDispatcher/loader';

const CLOSE_CLAIM_MODAL = 'CLOSE_CLAIM_MODAL';
const OPEN_CLAIM_MODAL = 'OPEN_CLAIM_MODAL';

export const showCloseClaimModalLoader = modalLoader({
  key: CLOSE_CLAIM_MODAL,
  getModal: () => import(/* confirm-modal */'./CloseClaimModal'),
});
export const showOpenClaimModalLoader = modalLoader({
  key: OPEN_CLAIM_MODAL,
  getModal: () => import(/* confirm-modal */'./OpenClaimModal'),
});
