import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { mainActions as actions } from './actions';

const initialState = Map({
  birthdays: {
    byProfiles: [],
    byPassports: [],
  },
  stoppedEpassportsList: [],
  fastStatistic: null,
  calls: Map({
    count: null,
    limit: null,
    data: [],
    statistics: [],
  }),
  reminds: {
    query: { date: null, user: null, limit: 0, page: 1 },
    store: {
      data: [],
      total: null,
    },
  },
});

export default handleActions({
  [actions.fetchBirthdaysSuccess]: (state, { payload }) => {
    const { byProfiles, byPassports } = payload;

    return state.set('birthdays', { byProfiles, byPassports });
  },
  [actions.fetchStoppedEpassportsSuccess]: (state, { payload }) => state.set('stoppedEpassportsList', payload),
  [actions.fetchCallsSuccess]: (state, { payload }) => {
    return state.setIn(['calls', 'count'], payload.count)
      .setIn(['calls', 'limit'], payload.limit)
      .setIn(['calls', 'data'], payload.calls)
      .setIn(['calls', 'statistics'], payload.statistics);
  },
  [actions.fetchRemindsSuccess]: (state, { payload }) => {
    const { reminds: data, total } = payload;

    return state.setIn(['reminds', 'store'], { data, total });
  },
  [actions.setRemindsQuery]: (state, { payload: { name, value } }) => state.setIn(['reminds', 'query', name], value),
  [actions.fetchFastStatisticSuccess]: (state, { payload }) => state.set('fastStatistic', payload),
  [actions.resetPage]: state => {
    return state
      .set('birthdays', { byProfiles: [], byPassports: [] })
      .set('stoppedEpassportsList', [])
      .set('fastStatistic', null)
      .set('calls', Map({
        count: null,
        limit: null,
        data: [],
        statistics: [],
      }))
      .set('reminds', {
        query: { date: null, user: null, limit: 0, page: 1 },
        store: {
          data: [],
          total: null,
        },
      });
  },
}, initialState);
