import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// insturuments
import { uiActions } from 'bus/ui/actions';
import { financeActions } from 'bus/finance/actions';
import { getPayment } from 'api/methods/finance/getPayment';

export function* getPaymentWorker({ payload }) {
  const { id, options } = payload;
  const UI = get(options, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { completed: false, errors: false, loading: true, message: null },
    path: ['finance', 'payment', id],
  })));

  try {
    const response = yield call(getPayment, token, { pathParams: { id } });

    yield put(financeActions.getPaymentSuccess(response));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Отсутствует доступ к разделу';

        break;
      }
      case 404: {
        message = 'Оплата не найдена';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['finance', 'payment', id, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['finance', 'payment', id, 'message'] })));

    yield put(financeActions.getPaymentFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['finance', 'payment', id, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['finance', 'payment', id, 'completed'] })));
  }
}
