import { select, put, call } from 'redux-saga/effects';
import { get, getIn, List, Map } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { claimActions } from 'bus/clientClaim/claim';
import { updateDateCreated } from 'api/methods/claim/updateClaim';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['clientClaim', 'claim', 'dateCreated'];

export function* updateDateCreatedBaseWorker({ payload }) {
  const { claimID, date, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, claimID],
  })));

  try {
    yield call(
      updateDateCreated,
      token,
      { pathParams: { id: claimID }, bodyParams: { created: date } }
    );
    yield put(claimActions.updateDateCreatedSuccess(claimID, date));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    let errorsByValues = Map();

    let otherErrors = List();

    switch (status) {
      case 400: {
        const claimErrors = getIn(body, ['claim', 'violations']);
        const eventErrors = getIn(body, ['event', 'violations']);

        const combineErrors = [claimErrors, eventErrors].filter(item => item).map(violations => {
          return parseErrorsByFormik({ created: date }, violations);
        });

        combineErrors.forEach(item => {
          errorsByValues = errorsByValues.merge(item.errorsByValues);
          otherErrors = otherErrors.merge(item.otherErrors);
        });

        if (errorsByValues.isEmpty() && otherErrors.isEmpty()) {
          message = 'Ошибка сервера.';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу ‘Заказы’ или к заказу для закрытия.');
        break;
      }
      case 404: {
        message = 'Заказ отсутствует.';
        break;
      }
      default: {
        message = 'Ошибка сервера.';
      }
    }

    yield put(claimActions.updateDateCreatedFail(error, { message, errorsByValues, otherErrors }));

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, claimID, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, claimID, 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, claimID, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, claimID, 'completed'],
    })));
  }
}
