import { createActions } from 'redux-actions';

export const templatesActions = createActions({
  GET_TEMPLATES: values => ({ values }),
  GET_TEMPLATES_SUCCESS: data => data,
  GET_TEMPLATES_FAIL: () => undefined,

  CLEAR_TEMPLATES: () => undefined,
  SET_TEMPLATES_PAGE: page => page,

  CREATE_TEMPLATE: (values, formik) => ({ values, formik }),
  CREATE_TEMPLATE_SUCCESS: template => template,
  CREATE_TEMPLATE_FAIL: () => undefined,

  UPDATE_TEMPLATE: (id, values, formik) => ({ id, values, formik }),
  UPDATE_TEMPLATE_SUCCESS: template => template,
  UPDATE_TEMPLATE_FAIL: () => undefined,

  DELETE_TEMPLATE: id => ({ id }),
  DELETE_TEMPLATE_SUCCESS: id => ({ id }),
  DELETE_TEMPLATE_FAIL: error => error,

  FETCH_TEMPLATE: id => ({ id }),
  FETCH_TEMPLATE_SUCCESS: template => template,
  FETCH_TEMPLATE_FAIL: error => error,
  CLEAR_TEMPLATE: () => null,

  GET_DEFAULT_TEMPLATE: type => ({ type }),
  GET_DEFAULT_TEMPLATE_SUCCESS: template => template,
  GET_DEFAULT_TEMPLATE_FAIL: () => undefined,
  CLEAR_DEFAULT_TEMPLATE: () => null,

  GET_TEMPLATE_VARIABLES: () => undefined,
  GET_TEMPLATE_VARIABLES_SUCCESS: variables => variables,
  GET_TEMPLATE_VARIABLES_FAIL: error => error,
  CLEAR_TEMPLATE_VARIABLES: () => [],
});
