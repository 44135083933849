import { select, put, call } from 'redux-saga/effects';
import { get, Map } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { detailsFieldsActions } from 'bus/detailsFields/actions';
import { updateDetailFieldTf } from 'api/methods/tf/fields';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['detailFields', 'fields', 'update'];

export function* updateFieldWorker({ payload }) {
  const { field, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: [...BASE_UI_PATH, field.id],
  })));

  const bodyParams = Map(field).deleteAll(['id', 'object']);

  try {
    yield call(updateDetailFieldTf, token, { pathParams: { id: field.id }, bodyParams });

    yield put(detailsFieldsActions.updateFieldSuccess(field));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    const processedErrors = {};

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);
        const { errorsByValues, otherErrors } = parseErrorsByFormik(field, violations);

        processedErrors.errorsByValues = errorsByValues;
        processedErrors.otherErrors = otherErrors;
        processedErrors.message = 'Ошибка сервера';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    processedErrors.message = message;

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, field.id, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, field.id, 'message'] })));
    yield put(detailsFieldsActions.updateFieldFail(error, processedErrors));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, field.id, 'loading'],
    })));
  }
}
