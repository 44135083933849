import React, { memo, useMemo } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

import { withNotifySubscription } from 'containers/molecules/subscription';

import { getCrmTypes } from 'crms/utils';

export const NotifyEndingSubscriptionMini = compose(withNotifySubscription(), memo)(({ subscriptionNotify }) => {
  const { showNotify, ui, closeNotify } = subscriptionNotify;
  const isRenderNotify = useMemo(() => showNotify && ui.visible, [showNotify, ui.visible]);
  const { t } = useTranslation('SUBSCRIPTION');

  return isRenderNotify && (
    <Modal open size="tiny" onClose={closeNotify}>
      <Modal.Header content={t('SUN_IS_END')} />
      <Modal.Content>
        <Modal.Description content={t('FOR_THI_OPERATION')} />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Ок" type="button" onClick={closeNotify} />
      </Modal.Actions>
    </Modal>
  );
});

NotifyEndingSubscriptionMini.displayName = 'NotifyEndingSubscriptionMini';

export const NotifyEndingSubscription = memo(({ onClose }) => {
  const { t } = useTranslation('SUBSCRIPTION');

  const { isMt, isTat, isNakanikuly } = getCrmTypes();

  return (
    <Modal open onClose={onClose}>
      <Modal.Header content={t('SUBSCRIPTION')} />
      <Modal.Content>
        <Modal.Description>
          { t(isNakanikuly ? 'ACC_WILL_ACTUAL_NK' : 'ACC_WILL_ACTUAL') }
          {' '}
          { isMt && (
            <>
              { t('FOR_CONTINUE_SUB') }
              {' '}
              { t('DEMO_TEST') }
              {' '}
              <nobr>+38 (044) 364-61-71</nobr>
              {' '}
              <a href="mailto:vk@moituristy.com" rel="noreferrer" target="_blank">vk@moituristy.com</a>
            </>
          )}
          { isTat && (
            <>
              { t('FOR_CONTINUE_SUB_TAT') }
              {' '}
              <a href="https://t.me/+380673260303" rel="noreferrer" target="_blank">{ t('TELEGRAM') }</a>
              {' '}
              <a href="mailto:agent@tat.ua" rel="noreferrer" target="_blank">agent@tat.ua</a>
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Ок" type="button" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
});

NotifyEndingSubscription.displayName = 'NotifyEndingSubscription';
