import { call, put, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { createOperator } from '../../../../../api/methods/common/operators';

export function* createOperatorWorker({ payload }) {
  const { bodyParams, options } = payload;
  const UI = get(options, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, completed: false, message: '' },
      path: ['common', 'createOperator'],
    }))
  );

  try {
    const operator = yield call(createOperator, token, { bodyParams });

    yield put(commonActions.createOperatorSuccess(operator));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Активна подписка только на Турсканер';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(commonActions.createOperatorFail(error));
    UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['common', 'createOperator', 'error'],
      }))
    );
    UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: message,
        path: ['common', 'createOperator', 'message'],
      }))
    );
  } finally {
    UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: false,
        path: ['common', 'createOperator', 'loading'],
      }))
    );
    UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['common', 'createOperator', 'completed'],
      }))
    );
  }
}
