import BaseApiClass from 'api/BaseApiClass';

export const getClientAndClaim = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'clients/search',
    method: 'POST',
    ...params,
  });

  return instance._load({ token });
};

export const getClientAndClaimSummary = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'clients/search_summary',
    method: 'POST',
    ...params,
  });

  return instance._load({ token });
};
