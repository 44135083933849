import { race, put, take, takeEvery, all } from 'redux-saga/effects';
import * as R from 'ramda';

import { tfActions } from 'bus/tf/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { bootstrapPage } from './actions';

import { BOOTSTRAP_COMMISSION_UI } from '../constants';

function* bootstrapSaga() {
  yield put(registerUI(createUi({ loading: true }), BOOTSTRAP_COMMISSION_UI));

  yield all([
    put(tfActions.getOperatorCredentials()),
    put(tfActions.getCommission())
  ]);

  const [[, operatorCredentialsError], [, commissionError]] = yield all([
    race([
      take(tfActions.getOperatorCredentialsSuccess),
      take(tfActions.getOperatorCredentialsFail)
    ]),
    race([
      take(tfActions.getCommissionSuccess),
      take(tfActions.getCommissionFail)
    ])
  ]);

  if (operatorCredentialsError || commissionError) {
    const credentialsErrorMessage = R.path(['payload', 'msg', 'body', 'message'], operatorCredentialsError);
    const commissionErrorMessage = R.path(['payload', 'msg', 'body', 'message'], commissionError);
    yield put(
      updateUI({
        error: true,
        message: credentialsErrorMessage || commissionErrorMessage
      }, BOOTSTRAP_COMMISSION_UI)
    );
  }

  yield put(updateUI({ loading: false, completed: true }, BOOTSTRAP_COMMISSION_UI));
}

export default function* () {
  yield takeEvery(bootstrapPage, bootstrapSaga);
}
