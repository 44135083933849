import { select, call, put } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { deleteDoc } from 'api/methods/tf/docs';
import { detailDocsActions } from '../../actions';

export function* deleteDocWorker({ payload }) {
  const { id } = payload;

  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['detailDocs', 'deleteDocs', id],
  }));

  try {
    yield call(deleteDoc, token, { pathParams: { id } });

    yield put(detailDocsActions.deleteDocSuccess(id));
  } catch (error) {
    yield put(detailDocsActions.deleteDocFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['detailDocs', 'deleteDocs', id],
    }));
  }
}
