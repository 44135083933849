import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import { getSearchesFromStorage } from './helpers';
import { removeSearch, saveSearch } from './actions';

const initialState = {
  searches: getSearchesFromStorage()
};

export default handleActions({
  [saveSearch]: (state, { payload: searchEntity }) => R.over(
    R.lensProp('searches'),
    R.append(searchEntity),
    state
  ),
  [removeSearch]: (state, { payload: searchId }) => R.over(
    R.lensProp('searches'),
    R.filter(({ id }) => searchId !== id),
    state
  )
}, initialState);
