import { put, call, select } from 'redux-saga/effects';

import { bookingActions } from 'bus/booking/actions';
import { getToken } from 'bus/auth/selectors';
import { getBookingId } from 'bus/booking/selectors';

import { createBookingSchedules } from 'api/methods/booking/schedules';

import { showToastError, showToastSuccess } from 'services/toaster';

export function* createBookingSchedulesWorker({ payload: schedules }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  try {
    const schedule = yield call(
      createBookingSchedules,
      token,
      { pathParams: { bookingId }, bodyParams: schedules }
    );

    yield put(bookingActions.createBookingSchedulesSuccess(schedule));

    showToastSuccess();
  } catch (error) {
    yield put(bookingActions.createBookingSchedulesFail(error));

    showToastError();
  }
}
