import { call, put } from 'redux-saga/effects';

import { loginFb } from 'api/methods/services/facebook';

import { servicesActions as actions } from 'bus/services/actions';

export function* loginFbWorker() {
  const response = yield call(loginFb);

  if (response.authResponse) {
    yield put(actions.onAuthFb(response));
  }
}
