import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { digitalContractActions } from 'bus/clientClaim/digitalContract';
import { getDigitalContracts } from 'api/methods/claim/digitalContract';

const BASE_UI_PATH = ['clientClaim', 'digitalContract', 'list'];

export function* getDigitalContractsWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { claimId, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH],
  })));

  try {
    const response = yield call(getDigitalContracts, token, { pathParams: { id: claimId } });

    yield put(digitalContractActions.getDigitalContractsSuccess(response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права на добавление. Запрещено если активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = get(body, 'message', 'Заказ не существует');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
