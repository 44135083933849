import BaseApiClass from 'api/BaseApiClass';

export const getManagers = token => {
  const instance = new BaseApiClass({
    url: 'tfs/colleagues',
    method: 'GET',
  });

  instance.parseBodyToClientSchema = data => data.map(
    ({
      username: name,
      bookings_processing: bookingProcessing,
      ...rest
    }) => ({ name, bookingProcessing, ...rest })
  );

  return instance._load({ token });
};
