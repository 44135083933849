import moment from 'moment';
import * as R from 'ramda';

import { newInstance } from 'localization';

import { BOOKING_TRANSPORT_DIRECTION_MAP } from 'modules/booking/constants';

import { isUahCurrency, replaceUAhCurrencyToHrn } from 'helpers/currency/helpers';

import { CLAIM_SERVICE_TYPES } from './constants';

const createBaseTourService = (name, type, { date, peopleCount, nights }) => ({
  date_start: moment(date).format(),
  date_finish: moment(date)
    .add(nights, 'days')
    .format(),
  count: peopleCount,
  type,
  name
});

const createTransportDetails = (transportsDetails, direction) => R.map(({ code, line, portFr, portTo, begin, end }) => {
  const beginMoment = moment(begin);
  const endMoment = moment(end);

  return {
    type: CLAIM_SERVICE_TYPES.TRANSPORT,
    name: '',
    transport_details: {
      type: BOOKING_TRANSPORT_DIRECTION_MAP.INBOUND.value,
      direction,
      code,
      line,
      portFr,
      portTo,
      begin: beginMoment.clone().format('YYYY-MM-DD'),
      begin_time: beginMoment.clone().format('HH:mm:ss'),
      end: endMoment.clone().format('YYYY-MM-DD'),
      end_time: endMoment.clone().format('HH:mm:ss'),
    }
  };
}, transportsDetails);

const createServices = ({ extra, date, people, nights, flights }) => R.call(
  R.pipe(
    R.when(
      () => R.includes(CLAIM_SERVICE_TYPES.INSURANCE, extra),
      R.append(createBaseTourService(
        newInstance.t('CLAIM:INSURANCE_INCLUDED'),
        CLAIM_SERVICE_TYPES.INSURANCE,
        { date, peopleCount: people, nights }
      ))
    ),
    R.when(
      () => R.includes(CLAIM_SERVICE_TYPES.TRANSFER, extra),
      R.append(createBaseTourService(
        newInstance.t('CLAIM:TRANSFER_INCLUDED'),
        CLAIM_SERVICE_TYPES.TRANSFER,
        { date, peopleCount: people, nights }
      ))
    ),
    R.when(
      () => !R.isEmpty(flights.outbound),
      processServices => R.concat(processServices, createTransportDetails(flights.outbound, BOOKING_TRANSPORT_DIRECTION_MAP.OUTBOUND.value))
    ),
    R.when(
      () => !R.isEmpty(flights.inbound),
      processServices => R.concat(processServices, createTransportDetails(flights.inbound, BOOKING_TRANSPORT_DIRECTION_MAP.INBOUND.value))
    ),
  ),
  []
);

export const convertOfferToClaimOffer = offer => R.applySpec({
  operator: R.prop('operator_id'),
  room: R.prop('room'),
  date: R.prop('date'),
  nights: R.pipe(
    R.prop('duration'),
    R.subtract(R.__, 1)
  ),
  priceCurrency: R.prop('price'),
  spo: R.ifElse(
    () => isUahCurrency(offer.currency),
    R.prop('price_uah'),
    R.prop('price')
  ),
  currency: R.pipe(
    R.prop('currency'),
    replaceUAhCurrencyToHrn
  ),
  food: R.pipe(
    R.prop('food'),
    R.toUpper
  ),
  extra: R.prop('extra'),
  flights: R.applySpec({
    outbound: R.path(['to', 'from']),
    inbound: R.path(['to', 'to'])
  }),
  people: R.prop('people'),
  transport: R.prop('transport'),
})(offer);

export const convertBasketOfferToClaimOffer = offer => R.applySpec({
  operator: R.prop('operator'),
  room: R.path(['room', 'name']),
  date: R.prop('date'),
  nights: R.prop('nights'),
  priceCurrency: () => offer.price[offer.currencyLocal],
  spo: () => offer.price[offer.currencyLocal],
  currency: R.pipe(
    R.prop('currencyLocal'),
    replaceUAhCurrencyToHrn
  ),
  food: R.pipe(
    R.prop('food'),
    R.toUpper
  ),
  extra: R.prop('includes'),
  flights: R.prop('flights'),
  people: R.pipe(
    R.prop('people'),
    ({ adults, children }) => {
      return adults + children.length;
    }
  ),
  transport: R.prop('transport'),
})(offer);

export const createTouchedClaim = (offer, hotel, operator, room) => R.mergeAll([
  {
    date_start: offer.date,
    length: offer.nights,
    operator: { id: operator?.id ?? null },
    document: null,
    price_currency: offer.priceCurrency,
    spo: offer.spo,
    currency: offer.currency,
    food: offer.food,
    room_type: room?.id ? room : { new: true, text: offer.room },
    services: createServices(offer)
  },
  {
    location_label: `${hotel.name} ${hotel.stars}*(${hotel.city.name})`,
    hotel: { id: Number(hotel.id) },
    city: { id: Number(hotel.city.id) },
    country: { id: Number(hotel.country.id) },
  }
]);
