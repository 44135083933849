import { takeEvery } from 'redux-saga/effects';

// instruments
import { stepersActions as actions } from 'bus/clientClaim/stepers';
import { toggleDoneStepWorker } from './workers/toggleDoneStep';

export const stepersWatchers = Object.freeze({
  * toggleDoneStepWatcher() {
    yield takeEvery(actions.toggleDoneStep, toggleDoneStepWorker);
  },
});
