import { put, race, take, call } from 'redux-saga/effects';
import { get } from 'immutable';

// actions
import { detailsFieldsActions as actions } from 'bus/detailsFields/actions';

export function* updateFieldFormikWorker({ payload }) {
  const { field, formik } = payload;

  yield put(actions.updateField(field, { UI: false }));

  const [success, fail] = yield race([
    take(actions.updateFieldSuccess),
    take(actions.updateFieldFail)
  ]);

  if (success) {
    yield call(formik.setSubmitting, false);
  }

  if (fail) {
    const { meta } = fail;
    const { errorsByValues, otherErrors, message } = meta;

    errorsByValues && (yield call(formik.setErrors, errorsByValues));

    yield call(formik.setStatus, {
      error: true,
      message: get(otherErrors, 'length') ? otherErrors.join('. ') : message,
    });

    yield call(formik.setSubmitting, false);
  }
}
