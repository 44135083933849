import BaseApiClass from 'api/BaseApiClass';

export const getBonuses = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'claims/bonuses',
    method: 'POST',
    ...params,
  });

  return instance._load({ token });
};
