import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLocalesList } from './helpers';

export const newInstance = i18n.createInstance();

export const localeBootstrap = locale => getLocalesList(locale)()
  .then(({ default: data }) => newInstance
    .use(initReactI18next)
    .init({
      resources: {
        [locale]: data,
      },
      debug: false,
      keySeparator: false,
      lng: locale,
      react: {
        nsMode: 'fallback',
      },
    })
  ).catch(() => {
    throw new Error(`can't download ${locale}`);
  });
