import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { tfActions } from 'bus/tf/actions';
import { getOperatorCredentials } from 'api/methods/tf/operatorCredentials';

const BASE_UI_PATH = ['tf', 'operatorCredentials', 'getCredentials'];

export function* getOperatorCredentialsWorker({ payload }) {
  const { page } = payload;

  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const { total, limit, operatorCredentials: results } = yield call(getOperatorCredentials, token, {
      pathParams: { tf },
      ...page ? { queryParams: { page } } : {},
    });

    yield put(tfActions.getOperatorCredentialsSuccess({ results, total, limit, page }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'ТФ или данные аутентификации не найдены');
        break;
      }
      default:
        message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));

    yield put(tfActions.getOperatorCredentialsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
