import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

export const getCountries = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'countries',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = R.map(
    R.over(
      R.lensProp('translations'),
      R.when(
        Boolean,
        R.pipe(
          R.indexBy(R.prop('locale')),
          R.map(R.prop('name'))
        )
      )
    )
  );

  return instance._load({ token });
};
