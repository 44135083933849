import { put, call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

// instruments
import { tfActions as actions } from 'bus/tf/actions';
import { createMerchant } from 'api/methods/tf/merchants';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createMerchantWorker({ payload }) {
  const { values, type, formik } = payload;

  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  try {
    const merchant = yield call(createMerchant, token, { pathParams: { tf }, bodyParams: values });

    yield put(actions.createMerchantSuccess(merchant, type));

    yield call(formik.setStatus, { success: true });
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      case 406: {
        message = get(body, 'message', 'Невозможно включить мерчанта');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
