import { call, put, select, cancelled, delay } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { getCountClaims } from 'api/methods/claim/getCountClaims';
import { claimActions } from '../../actions';

const DELAY = 30 * 60 * 1000; /* 30 minutes */

export function* fetchCountClaimsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  while (true) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['claims', 'countLoading'],
    }));
    try {
      const response = yield call(getCountClaims, token);

      yield put(claimActions.fetchCountClaimsSuccess(response));
    } catch (error) {
      claimActions.fetchCountClaimsFail(error);
    } finally {
      yield put(uiActions.changeUiLoaderFlag({
        status: false,
        path: ['claims', 'countLoading'],
      }));

      if (yield cancelled()) {
        yield put(claimActions.stopAutoFetchCountClaims());
      }
    }

    yield delay(DELAY);
  }
}
