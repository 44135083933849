import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { financeActions } from 'bus/finance/actions';
import { confirmHold } from 'api/methods/finance/confirmHold';
import { convertToClient } from 'api/methods/finance/convert/payment';

const BASE_UI_PATH = ['finance', 'hold'];

export function* confirmHoldWorker({ payload }) {
  const { claimID, paymentID, values, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { completed: false, errors: false, loading: true, message: null },
    path: BASE_UI_PATH,
  })));

  try {
    const response = yield call(
      confirmHold,
      token,
      { pathParams: { claimID, paymentID }, bodyParams: values }
    );

    yield put(financeActions.confirmHoldSuccess(convertToClient(response)));
  } catch (error) {
    const { body, status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Необходимо иметь разрешение на доступ к платежам');
        break;
      }
      case 404: {
        message = 'Заказ или платеж отсутствует';
        break;
      }
      case 421: {
        message = get(body, 'message', 'Cервер онлайн оплат не отвечает');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] })));

    yield put(financeActions.confirmHoldFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] })));
  }
}
