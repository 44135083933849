import { select, call, put } from 'redux-saga/effects';

import { searchClientAndClaimActions } from 'bus/searchClientAndClaim/actions';

import { getClientAndClaimSummary } from 'api/methods/search/getClientAndClaim';

import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { getPage, getSearchClientAndClaimQuery } from '../../selectors';

import { UI_KEY_SUMMARY } from '../../constants';

import { prepareSearchSummary } from '../../helpers';

function* searchSummaryWorker() {
  const token = yield select(getToken);
  const query = yield select(getSearchClientAndClaimQuery);
  const page = yield select(getPage);

  try {
    const data = yield call(getClientAndClaimSummary, token, {
      bodyParams: prepareSearchSummary(query, page),
    });

    yield put(searchClientAndClaimActions.searchSummarySuccess(data));
  } catch (error) {
    yield put(searchClientAndClaimActions.searchSummaryFail(error));
  }
}

export default withUIWorker(
  [searchClientAndClaimActions.searchSummarySuccess, searchClientAndClaimActions.searchSummaryFail],
  UI_KEY_SUMMARY
)(searchSummaryWorker);
