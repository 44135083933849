import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import uuid from 'uuid/v4';

import { routerUiActions as actions } from 'bus/routerUi/actions';

import { getCrmsPermission } from 'crms/utils';

export const CustomNavLink = ({ children, onClick, to, allowedCRMS, ...props }) => {
  const { pathname } = useSelector(({ router }) => router.location, shallowEqual);

  const isAllowedLink = getCrmsPermission(allowedCRMS);

  const dispatch = useDispatch();

  const handleClick = useCallback(e => {
    onClick(e);

    dispatch(actions.setRouterKey(uuid()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, to]);

  return isAllowedLink && (
    <NavLink {...props} to={to} onClick={handleClick}>
      { children }
    </NavLink>
  );
};

CustomNavLink.displayName = 'CustomNavLink';
CustomNavLink.defaultProps = {
  onClick: () => undefined,
};
