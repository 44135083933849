import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import T from 'prop-types';

import { ErrorWrapper } from 'components/wrappers/ErrorWrapper';

const KNOWN_ERRORS = ['Loading chunk'];
const PUSH_ACTION = 'PUSH';

let sharedResetError = null;

const ErrorBoundary = ({ children, withResetError = false }) => {
  const { t } = useTranslation('ERRORS');
  const history = useHistory();

  useEffect(() => {
    let listener = null;

    if (withResetError) {
      listener = history.listen((_, action) => {
        const isSetResetError = typeof sharedResetError === 'function';

        if (action === PUSH_ACTION && isSetResetError) {
          sharedResetError();
        }
      });
    }

    return () => {
      if (listener) {
        listener();
      }
    };
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => {
        sharedResetError = resetError;

        return (
          <ErrorWrapper>
            <h1>{t('UPS')}</h1>
            <p>
              {t('START_FROM')}
              {' '}
              <a href="/tf">{t('HOME_PAGE')}</a>
              .
              <br />
              {t('INFORM_US')}
              .
            </p>
          </ErrorWrapper>
        );
      }}
      onError={error => {
        const { message } = error;

        const isKnowError = KNOWN_ERRORS.some(errorText => message.includes(errorText));

        if (isKnowError) {
          global.location.reload();
        }
      }}
      onUnmount={() => {
        sharedResetError = null;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: T.any,
  withResetError: T.bool
};

export { ErrorBoundary };
