import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { tfUsersActions } from 'bus/tfUsers/actions';
import { getDefaultSignTemplate } from 'api/methods/tf/user';

export function* getDefaultTemplateWorker({ payload: options }) {
  const token = yield select(({ auth }) => auth.get('token'));

  // options
  const UI = get(options, 'UI', false);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['tf', 'defaultSingTemplate'],
  })));

  try {
    const { text: response } = yield call(getDefaultSignTemplate, token);

    yield put(tfUsersActions.getDefaultTemplateSuccess(response));
  } catch (error) {
    yield put(tfUsersActions.getDefaultTemplateFail(error));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'defaultSingTemplate', 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['tf', 'defaultSingTemplate', 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['tf', 'defaultSingTemplate', 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'defaultSingTemplate', 'completed'],
    })));
  }
}
