import { delay, put, race, take } from 'redux-saga/effects';

import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { subscriptionActions } from 'bus/subscription';

import { getBalanceWithDelay } from './actions';

export const SUBSCRIPTION_UI = 'SUBSCRIPTION';
const LIMIT_COUNT_OF_REQUESTS = 2;

export function* balanceWithDelayWorker() {
  while (true) {
    const { payload: balanceId } = yield take(getBalanceWithDelay);

    yield put(registerUI(createUi({ loading: true }), SUBSCRIPTION_UI));

    let i = 0;

    while (i < LIMIT_COUNT_OF_REQUESTS) {
      yield delay(2500);
      yield put(subscriptionActions.getBalanceInfo(balanceId));

      const [successAction, failedAction] = yield race([
        take(subscriptionActions.getBalanceInfoSuccess),
        take(subscriptionActions.getBalanceInfoFail)
      ]);

      if (successAction && successAction.payload.isPaid) {
        yield put(updateUI({ error: false }, SUBSCRIPTION_UI));
        break;
      }

      failedAction && (yield put(updateUI({ error: failedAction.payload, message: failedAction.payload.message }, SUBSCRIPTION_UI)));
      i += 1;
    }

    yield put(subscriptionActions.getSubscription());
    yield put(updateUI({ loading: false, completed: true }, SUBSCRIPTION_UI));
  }
}
