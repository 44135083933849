import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

import { epassportsActions as actions } from 'bus/clientClaim/epassports';

import { recognizePassport } from 'api/methods/claim/passports';

import { CRM_TYPES } from 'crms/constants';

const prepareValues = values => {
  return {
    birthday: values.birthday || '',
    last_date: values.last_date || '',
    name: values.name || '',
    passport: values.passport || '',
    sex: values.sex || 'm',
    start_date: values.start_date || '',
    surname: values.surname || '',
    type: 'international',
    country: values.country,
    ...CRM_TYPES['5stars'] ? {
      id_number: values.id_number || '',
    } : {},
  };
};

export function* recognizeEpassportWorker({ payload }) {
  const { image, claimId, values, formik, passportFormik } = payload;

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  const bodyParams = new FormData();

  bodyParams.append('image', image);

  try {
    const passport = yield call(recognizePassport, token, { pathParams: { id: claimId }, bodyParams });

    const { status, ...passportValues } = passport;
    const preparedValues = prepareValues(passportValues);

    yield put(actions.updateEpassportSuccess({ issued: '', ...values, ...preparedValues }, values.id));
    yield call(formik.setStatus, { success: true });
    yield call(passportFormik.resetForm);
    yield call(passportFormik.submitForm);
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 402: {
        message = get(body, 'message', 'Сервис недоступен');
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
