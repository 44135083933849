import { takeEvery } from 'redux-saga/effects';

// insturments
import { clientLeadActions as actions } from '../actions';
import { searchWorker } from './workers/search';

export const searchClientLeadWatcher = Object.freeze({
  * searchWatcher() {
    yield takeEvery(actions.search, searchWorker);
  },
});
