import { put, call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';
import * as R from 'ramda';

import { bookingActions } from 'bus/booking/actions';
import { createUi } from 'bus/ui/helpers';
import { registerUI, updateUI } from 'bus/ui/actions';
import {
  getBookingId,
} from 'bus/booking/selectors';
import { getToken } from 'bus/auth/selectors';

import { updateBooking } from 'api/methods/booking';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { createUpdateBookingUIKey } from './helpers';

export function* updateBookingWorker({ payload }) {
  const { values, formik } = payload;

  const bookingId = yield select(getBookingId);
  const token = yield select(getToken);

  const uiKey = createUpdateBookingUIKey(bookingId);

  try {
    yield put(registerUI(createUi({ loading: true }), uiKey));

    const { money, ...bodyParams } = values;

    const booking = yield call(updateBooking, token, { pathParams: { id: bookingId }, bodyParams });

    yield put(bookingActions.updateBookingSuccess(booking));

    formik && (yield call(formik.setStatus, { success: true }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

        errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
        otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

        if (!errorsByValues && !otherErrors.length) {
          message = R.propOr('Контрагент удалён, операция невозможна', 'message')(R.head(body));
        }
        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      case 500: {
        message = get(body, 'message', 'Ошибка при обновление стоимости');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(updateUI({ message, error: true }, uiKey));
    yield put(bookingActions.updateBookingFail(error));
  } finally {
    yield put(updateUI({ loading: false }, uiKey));
  }
}
