import { call, select, put } from 'redux-saga/effects';

// instruments
import { getReminds } from 'api/methods/main/getReminds';
import { uiActions } from 'bus/ui/actions';
import { mainActions } from 'bus/main/actions';

export function* fetchRemindsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));
  const queryParams = yield select(({ main }) => main.getIn(['reminds', 'query']));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['main', 'reminds', 'loading'],
  }));
  yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: ['main', 'reminds', 'error'],
  }));
  yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: ['main', 'reminds', 'success'],
  }));

  try {
    const { events, total } = yield call(getReminds, token, { queryParams });

    yield put(mainActions.fetchRemindsSuccess(events, total));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['main', 'reminds', 'success'],
    }));
  } catch (error) {
    yield put(mainActions.fetchRemindsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['main', 'reminds', 'loading'],
    }));
  }
}
