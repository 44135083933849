import { createActions } from 'redux-actions';

const PREFIX = 'BOOKING_ADMIN_PAGE';

export const {
  initializeBookingPage,
  initializeBookingsPage,
  getBookingDocuments,
  updateBooking,
  getConnectedBooking,
  setConnectedBookings,
  mergeConnectedBookings,
  getTACommission,
  setTaCommission,
  getCOCommission,
  setCOCommission,
  startEnd2EndBooking,
} = createActions({
  initializeBookingPage: () => undefined,
  initializeBookingsPage: () => undefined,
  getBookingDocuments: bookingId => bookingId,
  getConnectedBooking: () => undefined,
  setConnectedBookings: bookings => bookings,
  mergeConnectedBookings: bookings => bookings,
  getTACommission: (counterpartyID, operatorID) => ({ counterpartyID, operatorID }),
  setTaCommission: commissions => commissions,
  getCOCommission: operatorID => operatorID,
  setCOCommission: commissions => commissions,
  startEnd2EndBooking: entity => entity,
}, { prefix: PREFIX });
