import { call, put, select } from 'redux-saga/effects';

// instruments
import { tfUsersActions } from 'bus/tfUsers/actions';
import { uiActions } from 'bus/ui/actions';
import { getUser } from 'api/methods/tf/user';

export function* fetchUserWorker({ payload: id }) {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['tf', 'user'],
  }));

  try {
    const user = yield call(getUser, token, { pathParams: { id } });

    yield put(tfUsersActions.fetchUserSuccess(user));
  } catch (error) {
    const { status } = error.msg || {};

    let message = '';

    switch (status) {
      case 403: {
        message = 'Нету доступа к пользователю';
        break;
      }
      case 404: {
        message = 'Пользователь не найден';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'user', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['tf', 'user', 'message'],
    }));
    yield put(tfUsersActions.fetchUserFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['tf', 'user', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'user', 'completed'],
    }));
  }
}
