import { put, call, select } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getTags } from 'api/methods/common/tags';

export function* getTagsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));
  const id = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: '' },
    path: ['common', 'tags'],
  }));

  try {
    const tags = yield call(getTags, token, { pathParams: { id } });

    yield put(commonActions.getTagsSuccess(tags));
  } catch (error) {
    const { status } = error.msg || {};

    let message = '';

    switch (status) {
      case 403: {
        message = 'Отсутствует доступ к турфирме';
        break;
      }
      case 404: {
        message = 'Турфирма не найдена';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'tags', 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['common', 'tags', 'message'] }));
    yield put(commonActions.getTagsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'tags', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'tags', 'completed'] }));
  }
}
