import { put, race, take, call } from 'redux-saga/effects';
import { List } from 'immutable';

// actions
import { filesActions } from 'bus/files/actions';

export function* createFilesFormikWorker({ payload }) {
  const { clientID, files, workerOptions, formik } = payload;

  yield put(filesActions.createFiles(clientID, files, workerOptions));

  const [success, fail] = yield race([
    take(filesActions.createFilesSuccess),
    take(filesActions.createFilesFail)
  ]);

  if (success) {
    yield call(formik.setStatus, { success: true });
  }

  if (fail) {
    const { meta } = fail;
    const { errorsByValues, otherErrors, message } = meta;

    if (errorsByValues) {
      yield call(formik.setErrors, errorsByValues);
    }

    const cobmineErrorMessages = List().update(list => {
      if (otherErrors) {
        return list.merge(otherErrors.map(item => item.message));
      }

      return list;
    }).update(list => (message ? list.push(message) : list)).toArray();

    if (cobmineErrorMessages.length) {
      yield call(formik.setStatus, { error: true, message: cobmineErrorMessages.join('. ') });
    }

    yield call(formik.setSubmitting, false);
  }
}
