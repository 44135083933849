import { put, takeEvery, take, race } from 'redux-saga/effects';

import { bookingActions } from 'bus/booking/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { withDefaultNotifications } from 'services/toaster';

import { updateBooking, updateBookingWithReload } from './actions';

import { UPDATE_BOOKING_COMMON_UI } from './constants';

function* updateBookingSaga({ payload, notifications }) {
  const { values, formik, onSuccess } = payload;

  const { onShowSuccessMsg, onShowFailMsg } = notifications;

  yield put(registerUI(createUi({ loading: true }), UPDATE_BOOKING_COMMON_UI));

  const { money, ...booking } = values;

  yield put(bookingActions.updateCash(money));

  const [isUpdateCashSuccess] = yield race([
    take(bookingActions.updateCashSuccess),
    take(bookingActions.updateCashFail),
  ]);

  if (isUpdateCashSuccess) {
    yield put(bookingActions.updateBooking(booking, formik));

    const [updatedSuccess] = yield race([
      take(bookingActions.updateBookingSuccess),
      take(bookingActions.updateBookingFail)
    ]);

    if (updatedSuccess) {
      onShowSuccessMsg();
      onSuccess && onSuccess();
    } else onShowFailMsg();
  }

  yield put(updateUI({ loading: false }, UPDATE_BOOKING_COMMON_UI));
}
function* updateBookingWithReloadSaga({ payload: values }) {
  yield put(registerUI(createUi({ loading: true }), UPDATE_BOOKING_COMMON_UI));

  yield put(bookingActions.updateBooking(values, null));

  const [updatedSuccess] = yield race([
    take(bookingActions.updateBookingSuccess),
    take(bookingActions.updateBookingFail)
  ]);

  if (updatedSuccess) {
    global.window.location.reload();
  }

  yield put(updateUI({ loading: false }, UPDATE_BOOKING_COMMON_UI));
}

export function* bookingCommonRootSaga() {
  yield takeEvery(updateBooking, withDefaultNotifications(updateBookingSaga));
  yield takeEvery(updateBookingWithReload, updateBookingWithReloadSaga);
}
