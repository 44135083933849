import BaseApiClass from 'api/BaseApiClass';

export const createClaimBooking = (token, params = {}) => new BaseApiClass({
  url: 'claims/{:id}/bookings',
  method: 'POST',
  ...params,
})._load({ token });

export const updateClaimBooking = (token, params = {}) => new BaseApiClass({
  url: 'claims/bookings/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });
