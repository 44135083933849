import { select, call, put } from 'redux-saga/effects';
import { getIn, get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { tfActions } from 'bus/tf/actions';
import { updateOperatorCredential } from 'api/methods/tf/operatorCredentials';

const BASE_UI_PATH = ['tf', 'operatorCredentials', 'updateCredential'];

export function* updateOperatorCredentialWorker({ payload }) {
  const { values, credentialId } = payload;
  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...BASE_UI_PATH, credentialId],
  }));

  try {
    const credential = yield call(updateOperatorCredential, token, { pathParams: { tf, credentialId }, bodyParams: values });

    yield put(tfActions.updateOperatorCredentialSuccess(credential));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          message = getIn(body.violations, [0, 'message'], 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу, к ТФ или к переданному ТО');
        break;
      }
      case 404: {
        message = 'ТФ или переданный ТО не найдены';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, credentialId, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, credentialId, 'message'] }));
    yield put(tfActions.updateOperatorCredentialFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, credentialId, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, credentialId, 'completed'] }));
  }
}
