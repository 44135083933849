import { call, put, race, take } from 'redux-saga/effects';
import { get, getIn, Map } from 'immutable';

import { authActions } from 'bus/auth/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';
import { uiActions } from 'bus/ui/actions';

export function* registerWorkerMediator({ payload }) {
  const { user, formik } = payload;
  const mappeduser = Map(user).set('country_code', getIn(user, ['countryCode'])).delete('countryCode').toObject();

  yield put(authActions.register(mappeduser));

  const [, failed] = yield race([
    take(authActions.registerSuccess),
    take(authActions.registerFail)
  ]);

  if (failed) {
    const { status, body } = failed.payload.msg;

    let message = null;

    switch (status) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(user, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Код введен неверно');
        yield put(uiActions.changeUiLoaderFlag({
          status: { loading: false, error: true, success: false, message, completed: true },
          path: ['auth', 'registerCode'],
        }));
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  }

  yield call(formik.setSubmitting, false);
}
