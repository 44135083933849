import { createSelector } from 'reselect';

export const domain = ({ ui }) => ui;
const getKey = (_, { key }) => ({ key });
const getPath = (_, { path }) => ({ path });

const defaultUI = {};

export const getUI = createSelector([domain, getKey, getPath], (ui, { key }, { path }) => (path
  ? ui.getIn(path, defaultUI)
  : ui.get(key, defaultUI)));
