// instruments
import { stepersActions as actions } from 'bus/clientClaim/stepers';

export const stepersReducer = {
  [actions.setActiveStep]: (state, { payload: marker }) => state.set('activeClaimStep', marker),
  [actions.clearActiveStep]: state => state.set('activeClaimStep', null),
  [actions.toggleDoneStepSuccess]: (state, { payload }) => {
    const { stepID, status, step } = payload;

    if (status) {
      return state.updateIn(['claim', 'done_steps'], doneSteps => [...doneSteps, step]);
    }

    return state.updateIn(['claim', 'done_steps'], doneSteps => doneSteps.filter(item => item.step.id !== stepID));
  },
};
