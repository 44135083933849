import { put, call, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { get } from 'immutable';
import * as R from 'ramda';

import { uiActions } from 'bus/ui/actions';
import { clientLeadActions as actions } from 'bus/searchClientLead/actions';
import { getPathName } from 'bus/router/selectors';

import { getClientsPure } from 'api/methods/search/getClients';

import { deleteKeyFromCache, setQueryToCache } from 'helpers/cookies';

import { SEARCH_CLIENT_LEAD_COOKIE_KEY } from '../../constants';

import { prepareBodyParams, prepareQuerySearchString } from '../../helpers';

const BASE_UI_PATH = ['searchClientLead'];

function* setQueryParams(query, page, order) {
  const pathname = yield select(getPathName);

  const querySearchString = prepareQuerySearchString(query, page, order).toString();

  if (R.isEmpty(querySearchString)) {
    yield call(deleteKeyFromCache, SEARCH_CLIENT_LEAD_COOKIE_KEY);
  } else {
    yield call(
      setQueryToCache,
      SEARCH_CLIENT_LEAD_COOKIE_KEY,
      querySearchString, { expires: 0.20 }
    );
  }

  yield put(replace(`${pathname}?${querySearchString}`));
}

export function* searchWorker() {
  const { token, query, page, order } = yield select(({ auth, searchClientLead }) => ({
    token: auth.get('token'),
    query: searchClientLead.get('query'),
    page: searchClientLead.get('page'),
    order: searchClientLead.get('order'),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  yield call(setQueryParams, query, page, order);

  try {
    const { result, total } = yield call(
      getClientsPure,
      token,
      { bodyParams: prepareBodyParams(query, page, order) }
    );

    yield put(actions.searchSuccess(result, total));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));

    yield put(actions.searchFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
