import { getIn } from 'immutable';

export const convertToClient = payment => ({
  id: payment.id,
  number: payment.in_id,
  type: payment.paymentType,
  manager: {
    name: payment.owner.username,
    email: payment.owner.email,
  },
  dateCreated: payment.created,
  client: {
    name: payment.claim.client.name,
    id: payment.claim.client.id,
    email: payment.claim.client.mail,
  },
  claim: {
    id: payment.claim.id,
    location: payment.claim.location_label,
    adults: payment.claim.adults,
    child: payment.claim.cnt_child,
    operator: payment.claim.operator,
    hash: payment.claim.hash,
    currency: payment.claim.currency,
  },
  assignment: payment.assignment,
  status: payment.status,
  payStatus: payment.payStatus,
  incomeSum: payment.income,
  expenseSum: payment.expense,
  denied: payment.denied,
  payDateTo: payment.date_to,
  comment: payment.comment || '',
  payed: payment.payed,
  payed_date: payment.payed_date,
  payed_user: payment.payed_user,
  buh: payment.buh,
  buh_date: payment.buh_date,
  buh_user: payment.buh_user,
  payed2: payment.payed2,
  payed2_date: payment.payed2_date,
  payed2_user: payment.payed2_user,
  is_expired: payment.is_expired,
  method: payment.method,
  rate: payment.rate,
  owner: payment.owner,
  pay_uri: payment.pay_uri,
  uuid_xpay: payment.uuid_xpay,
  hold: payment.hold,
  clientPaysCommission: payment.client_pays_commission,
  commission: payment.commission,
  template: getIn(payment, ['template', 'id'], 0),
});
