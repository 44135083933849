import { call, select, put } from 'redux-saga/effects';
import { parseErrorPath } from 'helpers';
import { getIn, hasIn } from 'immutable';

// instruments
import { addClient } from 'api/methods/client/addClient';
import { clientClaimActions } from 'bus/clientClaim/actions';

export function* createClientWorker({ payload }) {
  const { client, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const response = yield call(addClient, token, { bodyParams: client });

    yield put(clientClaimActions.createClientSuccess(response));
    formik && (yield call(formik.setStatus, { success: true, meta: { client: response } }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let errorMsg = null;

    switch (status) {
      case 400: {
        const isSetViolations = getIn(body, ['violations', 'length']);
        const violations = getIn(body, ['violations']);

        if (isSetViolations) {
          const formikErrors = Map({ ...violations })
            .filter(value => {
              const pathToArray = parseErrorPath(value.property_path).split('.');

              return hasIn(client, pathToArray, false);
            }).reduce((results, value) => {
              const pathToArray = parseErrorPath(value.property_path).split('.');

              return results.setIn(pathToArray, value.message);
            }, Map()).toJS();

          formik && (yield call(formik.setErrors, formikErrors));

          const otherErrorList = Map({ ...violations })
            .filter(value => !hasIn(client, parseErrorPath(value.property_path).split('.'), false))
            .map(value => value.message.replace('.', ''))
            .join('. ');

          if (otherErrorList) {
            errorMsg = otherErrorList;
          }
        } else {
          errorMsg = 'Ошибка сервера';
        }

        break;
      }
      case 409: {
        yield put(clientClaimActions.setConflictClients(error.msg.body, client));

        break;
      }
      default: errorMsg = 'Ошибка сервера';
    }

    formik && errorMsg && (yield call(formik.setStatus, { error: true, message: errorMsg }));
    yield put(clientClaimActions.createClientFail(error));
  } finally {
    formik && (yield call(formik.setFieldValue, 'skip_duplicate_check', false, false));
    formik && (yield call(formik.setSubmitting, false));
  }
}
