import { put, call, select, race, take } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';

import { updateBookingCash } from 'api/methods/booking/money';

import { UPDATE_BOOKING_WITH_CASH } from '../../../constants';
import { getBookingId } from '../../../selectors';
import { bookingActions, bookingActions as actions } from '../../../actions';

export function* updateCashWorker({ payload: cash }) {
  const bookingId = yield select(getBookingId);
  const token = yield select(getToken);

  try {
    const response = yield call(
      updateBookingCash,
      token,
      { pathParams: { id: bookingId }, bodyParams: cash }
    );

    yield put(actions.updateCashSuccess(response));
  } catch (error) {
    yield put(actions.updateCashFail(error));
  }
}

export function* updateBookingWithCash({ payload: { cash, booking, callbacks } }) {
  const { onSuccess, onFail } = callbacks;
  yield put(registerUI(createUi({ loading: true }), UPDATE_BOOKING_WITH_CASH));

  yield put(bookingActions.updateCash(cash));

  const [isUpdateCashSuccess] = yield race([
    take(actions.updateCashSuccess),
    take(actions.updateCashFail),
  ]);

  yield put(bookingActions.updateBooking(booking));

  const [isUpdateBookingSuccess] = yield race([
    take(actions.updateBookingSuccess),
    take(actions.updateBookingFail),
  ]);

  if (isUpdateBookingSuccess && isUpdateCashSuccess) {
    if (onSuccess) {
      onSuccess();
    }
  } else {
    onFail();
  }

  yield put(updateUI({ completed: true, loading: false }, UPDATE_BOOKING_WITH_CASH));
}
