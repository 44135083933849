import React, { memo } from 'react';

// providers
import { useAutoAuth } from 'providers/autoAuth';

// services
import { ErrorBoundary } from 'services/errorBoundary';

export const AdminLayout = memo(({ children }) => {
  useAutoAuth();

  return (
    <ErrorBoundary>
      { children }
    </ErrorBoundary>
  );
});
