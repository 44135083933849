import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { routerUiActions as actions } from './actions';

const initialState = Map({
  key: null,
});

export default handleActions({
  [actions.setRouterKey]: (state, { payload: key }) => state.set('key', key),
}, initialState);
