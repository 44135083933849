import { handleActions } from 'redux-actions';
import { Map } from 'immutable';

// instruments
import { claimActions as actions } from './actions';

const initialState = Map({
  countClaims: null,
});

export default handleActions({
  [actions.fetchCountClaimsSuccess]: (state, { payload }) => state.set('countClaims', payload.count),
}, initialState);
