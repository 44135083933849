import { call, select, put } from 'redux-saga/effects';
import { get } from 'immutable';

// instrumetns
import { uiActions } from 'bus/ui/actions';
import { createAccessCookie } from 'api/methods/auth/createAccessCookie';

const BASE_UI_PATH = ['oneTimeAccess'];

export function* getOneTimeAccessWorker({ payload }) {
  const { toDo, onSuccess, onFail, id } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, id],
  }));

  try {
    yield call(createAccessCookie, token);
    yield call(toDo);
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, message, 'Отказано в доступе');

        break;
      }
      default: message = 'Ошибка при переходе';
    }

    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, id, 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, id, 'message'],
    }));
    onSuccess && (yield call(onSuccess));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, id, 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, id, 'completed'],
    }));
    onFail && (yield call(onFail));
  }
}
