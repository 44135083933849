import * as R from 'ramda';
import moment from 'moment';

import BaseApiClass from 'api/BaseApiClass';

import { ALLOWED_TYPES_FOR_OUTER_BOOKING } from './constants';
import { getRateOfConfirmation, getDisabledFields, getExternalFieldsByType } from './helpers';

const prepareDataToServer = ({ title, tf_settings: tfSettings }) => ({
  title,
  tf_settings:
        R.call(
          R.pipe(
            R.map(({
              contract_date: contractDate,
              id,
              ...rest
            }) => ({
              contract_date: contractDate ? moment(contractDate).format() : null,
              tf_detail_id: id,
              ...rest,
            })),
            R.filter(el => Object.keys(el).length)
          ),
          tfSettings
        ),
});

const getIsEnabledBooking = R.ifElse(
  R.isEmpty,
  R.always(true),
  R.all(({ is_deleted: isDeleted }) => isDeleted)
);

export const getOperators = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:id}/operators',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = R.over(
    R.lensProp('operators'),
    R.map(({
      booking_via_co: bookingViaCo,
      tf_credentials: tfCredentials,
      available_calculation_types: availableCalculationTypes,
      booking_detailed_conditions: bookingConditions,
      booking_type: bookingType,
      passport_format: passportFormat,
      confirmation_rate: rateOfConfirmation,
      ...rest
    }) => ({
      ...rest,
      bookingViaCo,
      tfCredentials,
      bookingConditions,
      isEnabledBooking: bookingViaCo && getIsEnabledBooking(tfCredentials),
      availableCalculationTypes: availableCalculationTypes ?? [],
      hasOwnBookingServices: ALLOWED_TYPES_FOR_OUTER_BOOKING.includes(bookingType),
      externalPassportFields: getExternalFieldsByType(passportFormat),
      disabledPassportFields: getDisabledFields(passportFormat),
      rateOfConfirmation: getRateOfConfirmation(rateOfConfirmation),
    }))
  );

  return instance._load({ token });
};

export const getOperatorsDocs = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'settings/operators/{:id}/docs',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = docs => docs.map(
    ({ filename: title, ...rest }) => ({ title, ...rest })
  );

  return instance._load({ token });
};

export const getOperatorRequestLogin = (token, params = {}) => new BaseApiClass({
  url: 'settings/operators/{:operatorId}/request-login',
  method: 'GET',
  ...params,
})._load({ token });

export const createOperator = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'settings/operators',
    method: 'POST',
    ...params,
  });

  instance.convertBodyToServerSchema = prepareDataToServer;

  return instance._load({ token });
};

export const updateOperator = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'settings/operators/{:id}',
    method: 'PUT',
    ...params,
  });

  instance.convertBodyToServerSchema = prepareDataToServer;

  return instance._load({ token });
};

export const deleteOperator = (token, params = {}) => new BaseApiClass({
  url: 'settings/operators/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
