import { select, call } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { getBasketsSimplified } from 'api/methods/tourscaner/baskets';

export function* getBasketsSimplifiedWorker({ payload: { callbacks = {}, queryParams } }) {
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userId = yield select(getUserId);

  try {
    const { data: baskets, limit: perPage, total } = yield call(
      getBasketsSimplified,
      token,
      { pathParams: { userId }, queryParams }
    );

    onSuccess({
      baskets,
      total,
      perPage,
    });
  } catch (error) {
    __DEV__ && console.error(error);
    onFail(error);
  }
}
