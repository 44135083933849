import BaseApiClass from 'api/BaseApiClass';

export const getExtraByDoc = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs/{:id}/extra',
  method: 'GET',
  ...params,
})._load({ token });

export const getExtraByOperator = (token, params = {}) => new BaseApiClass({
  url: 'settings/operators/{:id}/extra',
  method: 'GET',
  ...params,
})._load({ token });

export const getGuarantees = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs/guarantees',
  method: 'GET',
  ...params,
})._load({ token });

export const getDocs = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs',
  method: 'GET',
  ...params,
})._load({ token });

export const getDoc = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const createDoc = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs',
  method: 'POST',
  ...params,
})._load({ token });

export const updateDoc = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const toggleDoc = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs/{:id}/toggle',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteDoc = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getDocVariables = (token, params = {}) => new BaseApiClass({
  url: 'settings/docs/patterns',
  method: 'GET',
  ...params,
})._load({ token });
