import { takeEvery, take, select, cancel, fork } from 'redux-saga/effects';

// actions
import { dumpActions } from '../actions';

// workers
import { uploadImportFileWorker } from './workers/uploadImportFile';
import { importProgressWorker } from './workers/importProgress';
import { importAnalyzeWorker } from './workers/importAnalyze';
import { importRunWorker } from './workers/importRun';
import { importRunResultWorker } from './workers/importRunResult';

export const dumpWatchers = Object.freeze({
  * uploadImportFileWatcher() {
    yield takeEvery(dumpActions.uploadImportFile, uploadImportFileWorker);
  },
  * forkImportProgress() {
    while (yield take(dumpActions.startImportProgress)) {
      const job = yield select(({ dump }) => dump.getIn(['import', 'job']));

      const bgImportProgress = yield fork(
        importProgressWorker,
        { payload: { id: job.id, type: job.type } }
      );

      yield take(dumpActions.stopImportProgress);

      yield cancel(bgImportProgress);
    }
  },
  * importProgressWatcher() {
    yield takeEvery(dumpActions.importProgress, importProgressWorker);
  },
  * importAnalyzeWatcher() {
    yield takeEvery(dumpActions.importAnalyze, importAnalyzeWorker);
  },
  * importRunWatcher() {
    yield takeEvery(dumpActions.importRun, importRunWorker);
  },
  * importRunResult() {
    yield takeEvery(dumpActions.importRunResult, importRunResultWorker);
  },
});
