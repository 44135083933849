import { put, race, take } from 'redux-saga/effects';
import { generatePath, Link } from 'react-router-dom';
import React from 'react';

import { uiActions } from 'bus/ui/actions';
import { bookingActions as actions } from 'bus/booking/actions';

import { DURATIONS, showToastSuccess } from 'services/toaster';
import { newInstance } from 'localization';
import book from 'routes/book';

const BASE_UI_PATH = ['booking', 'managingBooking'];

export function* createAndFillClaimWorker({ payload }) {
  const { bookingId, params, onSuccess } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  yield put(actions.createClaimFromBooking(bookingId, params, { UI: true }));

  const [successCreate, fail] = yield race([
    take(actions.createClaimFromBookingSuccess),
    take(actions.createClaimFromBookingFail)
  ]);

  if (successCreate) {
    const { id: claimId } = successCreate.payload;

    yield put(actions.fillClaimFromBooking(bookingId, claimId));

    yield race([
      take(actions.fillClaimFromBookingSuccess),
      take(actions.fillClaimFromBookingFail)
    ]);

    if (onSuccess) {
      onSuccess(successCreate.payload);
    }

    // TODO NT
    showToastSuccess(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <b>{newInstance.t('BOOKING_CONNECT_MODAL:CONNECT_WITH_CLAIM')}</b>
        <div>
          <Link
            to={`${generatePath(book.clientClaim.children.claim, {
              clientId: successCreate.payload.client.id,
              claimId: successCreate.payload.id
            })}?active_step=bron`}
          >
            {newInstance.t('BOOKING_CONNECT_MODAL:TO_CLAIM')}
          </Link>
        </div>
      </div>,
      { duration: DURATIONS.LONG }
    );
  }

  if (fail) {
    const message = fail.payload;

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  }

  yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
  yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
}
