import { takeEvery, put, select, call } from 'redux-saga/effects';
import * as R from 'ramda';
import uuid from 'uuid/v4';

import { bookingActions } from 'bus/booking/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getBookingId, getBookingSchedules } from 'bus/booking/selectors';
import {
  getBookingSchedulesWorker,
  createBookingSchedulesWorker,
  createSchedulesFromTemplate,
  updateBookingSchedulesWorker
} from 'bus/booking/saga/workers/bookingSchedules';

import { getSchedules, saveSchedules } from './actions';
import { SAVE_BOOKING_SCHEDULES, GET_BOOKING_SCHEDULES } from './costants';
import { getIsInitialSchedules, prepareSchedules2Create, prepareSchedules2Update } from './helpers';

const createSchedule = () => ({
  id: uuid(),
  date: null,
  amount: '',
  IS_INITIAL: true,
});

function* getSchedulesSaga() {
  yield put(registerUI(createUi({ loading: true }), GET_BOOKING_SCHEDULES));

  const bookingId = yield select(getBookingId);

  yield call(
    getBookingSchedulesWorker,
    bookingActions.getBookingSchedules(bookingId),
  );

  const schedules = yield select(getBookingSchedules);

  // if schedules is empty, create default schedules
  if (R.isEmpty(schedules)) {
    yield put(bookingActions.createBookingSchedulesSuccess([
      createSchedule(),
      createSchedule(),
    ]));
  }

  yield put(updateUI({ completed: true, loading: false }, GET_BOOKING_SCHEDULES));
}

function* saveSchedulesSaga({ payload: values }) {
  yield put(registerUI(createUi({ loading: true }), SAVE_BOOKING_SCHEDULES));

  const { templateId, schedules } = values;

  switch (true) {
    case Boolean(templateId): {
      yield call(createSchedulesFromTemplate, bookingActions.createSchedulesFromTemplate(templateId));

      break;
    }
    case getIsInitialSchedules(schedules): {
      yield call(createBookingSchedulesWorker, bookingActions.createBookingSchedules(
        prepareSchedules2Create(schedules),
      ));

      break;
    }
    default: {
      yield call(updateBookingSchedulesWorker, bookingActions.updateBookingSchedules(
        prepareSchedules2Update(schedules),
      ));
    }
  }

  yield put(registerUI(createUi({ loading: false, completed: true }), SAVE_BOOKING_SCHEDULES));
}

export function* schedulesBookingRootSaga() {
  yield takeEvery(getSchedules, getSchedulesSaga);
  yield takeEvery(saveSchedules, saveSchedulesSaga);
}
