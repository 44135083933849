import { createActions } from 'redux-actions';

export const commentsActions = createActions({
  CREATE_COMMENT: (claimID, comment, workerOptions) => ({ claimID, comment, workerOptions }),
  CREATE_COMMENT_SUCCESS: (claimID, comment) => ({ claimID, comment }),
  CREATE_COMMENT_FAIL: [error => error, (...[, processedErrors]) => processedErrors],

  UPDATE_COMMENT: (claimID, commentID, comment, workerOptions) => (
    { claimID, commentID, comment, workerOptions }
  ),
  UPDATE_COMMENT_SUCCESS: (claimID, comment) => ({ claimID, comment }),
  UPDATE_COMMENT_FAIL: [error => error, (...[, processedErrors]) => processedErrors],

  DISMISS_COMMENT: (claimID, commentID, workerOptions) => ({ claimID, commentID, workerOptions }),
  DISMISS_COMMENT_SUCCESS: (claimID, comment) => ({ claimID, comment }),

  DELETE_COMMENT: (claimID, eventID, workerOptions) => ({ claimID, eventID, workerOptions }),
  DELETE_COMMENT_SUCCESS: (claimID, eventID) => ({ claimID, eventID }),
  DELETE_COMMENT_FAIL: [error => error, (...[, processedErrors]) => processedErrors],

  SUBMIT_COMMENT_FORM: (claimID, commentID, comment, formik) => (
    { claimID, commentID, comment, formik }
  ),
}, { prefix: 'MT_CLIENT_CLAIM/COMMENTS' });
