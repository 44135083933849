import { race, take, call, put } from 'redux-saga/effects';
import { getIn } from 'immutable';

// instruments
import { commonActions } from 'bus/common/actions';
import { uiActions } from 'bus/ui/actions';

export function* updateRegionWorker({ payload }) {
  const { id, name, formik } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: '' },
    path: ['tf', 'updateRegions', id],
  }));
  yield put(commonActions.updateRegion(id, name));

  const [success, fail] = yield race([
    take(commonActions.updateRegionSuccess),
    take(commonActions.updateRegionFail)
  ]);

  if (fail) {
    const { payload: error } = fail;
    const { status, body } = error.msg || {};

    let message = '';

    switch (status) {
      case 400: {
        const isHaveErrors = getIn(body, ['violations', 'length']);

        if (isHaveErrors) {
          const violations = getIn(body, ['violations']);
          const errorByName = violations.find(item => item.property_path === 'name');
          const otherErrors = violations.filter(item => item.property_path !== 'name');

          if (errorByName) {
            const indexErrorField = formik.values.data.findIndex(item => item.id === id);

            yield call(formik.setFieldError, `data.${indexErrorField}.name`, errorByName.message);
            yield call(formik.setFieldTouched, `data.${indexErrorField}.name`, true, false);
          }

          if (otherErrors.length) {
            message = otherErrors.map(item => item.message.replace('.', '')).join('. ');
          }
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'updateRegions', id, 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['tf', 'updateRegions', id, 'message'],
    }));
  }

  if (success) {
    // success to do
  }

  yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: ['tf', 'updateRegions', id, 'loading'],
  }));
}
