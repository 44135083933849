import * as R from 'ramda';
import { prepareBookingServices } from '@otpusk/json-api/dist/methods/toursBookServices';

import BaseApiClass from 'api/BaseApiClass';

import { BOOKING_SERVICES_BASE } from './constants';

export const getOfferTemplate = (token, params = {}) => new BaseApiClass({
  url: 'offers/{:type}/render',
  method: 'POST',
  ...params,
})._load({ token });

export const logSearchStatistics = (token, params = {}) => new BaseApiClass({
  url: 'tourscanner/stat',
  method: 'POST',
  successCode: 204,
  ...params,
})._load({ token });

export const recognizePassports = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'users/{:userId}/epassports/recognize',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = R.dissoc('Content-Type');

  return instance._load({ token });
};

export const getBookingServices = (params = {}) => {
  const instance = new BaseApiClass({
    method: 'GET',
    outerResource: `${BOOKING_SERVICES_BASE}book/{:offerID}/services`,
    customHeaders: {
      Authorization: 'Basic b2RldjpvZGV2T0RFVjEyMw=='
    },
    ...params,
  });

  instance.parseBodyToClientSchema = R.over(
    R.lensProp('services'),
    R.pipe(
      prepareBookingServices,
      R.map(
        ({ number, perBooking, obligatory, excludedCodes, ...rest }) => ({
          enabledTourists: number,
          isGlobalService: Boolean(perBooking),
          disabled: obligatory && R.isEmpty(excludedCodes),
          excludedServices: excludedCodes,
          ...rest,
        })
      )
    ),
  );

  return instance._load({ token: null });
};

export const recalculateBookingServices = (params = {}) => {
  const instance = new BaseApiClass({
    method: 'POST',
    outerResource: `${BOOKING_SERVICES_BASE}book/{:offerID}/calculate`,
    customHeaders: {
      Authorization: 'Basic b2RldjpvZGV2T0RFVjEyMw=='
    },
    ...params,
  });

  return instance._load({ token: null });
};
