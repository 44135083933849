import { call, select, put, race, take, all } from 'redux-saga/effects';
import { hasIn, getIn, Map } from 'immutable';
import { getOffer } from '@otpusk/apisearch-toolbox/dist/offers/selectors';
import { getHotel } from '@otpusk/apisearch-toolbox/dist/hotels/selectors';
import { captureException, withScope } from '@sentry/browser';
import { getSearch, replace } from 'connected-react-router';
import * as R from 'ramda';

import { addClaim } from 'api/methods/claim/addClaim';
import { createOffer } from 'api/methods/claim/offers';
import { createSearch } from 'api/methods/claim/searches';

import { addBasketWithOfferSaga } from 'bus/tourscaner/saga/workers/baskets/addBasket';
import { addBasketToClaimSaga } from 'bus/tourscaner/saga/workers/baskets/addBasketToClaim';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { commonActions } from 'bus/common/actions';

import { parseErrorPath } from 'helpers';
import { replaceHrnToUah } from 'helpers/currency/helpers';
import { convertOfferAndHotelToInternalOffer } from 'helpers/offer';

export function* createClaimWorker({ payload }) {
  const { claim, formik, offer, search } = payload;

  const token = yield select(({ auth }) => auth.get('token'));
  const searchQuery = yield select(getSearch);

  if (claim.source) {
    const { id, title } = claim.source;

    if (!id) {
      yield put(commonActions.createSource(title));
      const { newSource } = yield race({
        newSource: take(commonActions.createSourceSuccess),
      });

      claim.source = newSource.payload.id;
    } else {
      claim.source = id;
    }
  }

  if (searchQuery) {
    // eslint-disable-next-line camelcase
    claim.is_from_otpusk = true;
  }

  try {
    const response = yield call(addClaim, token, { bodyParams: claim });

    if (offer) {
      const { values } = offer;

      if (values.offerId && values.hotelId) {
        const infoByOffer = yield select(state => getOffer()(state, { offerID: values.offerId }));
        const infoByHotel = yield select(state => getHotel()(state, values.hotelId));
        const currency = replaceHrnToUah(claim.currency);

        const isPriceAndCurrency = infoByOffer.price[currency || infoByOffer.currency];

        if (isPriceAndCurrency) {
          const bodyParams = {
            id: response.id,
            ...convertOfferAndHotelToInternalOffer(
              infoByHotel,
              R.set(R.lensProp('currency'), currency, infoByOffer)
            ),
          };

          delete bodyParams.op;

          const room = yield select(
            ({ clientClaim }) => clientClaim
              .getIn(['staticLists', 'rooms'])
              .find(({ name }) => name === infoByOffer.room.name)
          );

          const contractValues = {
            claim: {
              food_type: infoByOffer.food.toUpperCase(),
              operator: infoByOffer.operator,
              room_type: room ? room.id : { new: true, text: infoByOffer.room.name },
            },
          };

          yield put(clientClaimActions.updateContractClaim(response.id, contractValues, null));

          const [basket] = yield all([
            call(
              addBasketWithOfferSaga,
              values.offerId,
              currency,
            ),
            race([
              take(clientClaimActions.updateContractClaimSuccess),
              take(clientClaimActions.updateContractClaimFail)
            ])
          ]);

          yield call(
            addBasketToClaimSaga,
            basket.id,
            response.id,
            claim.client,
          );
        } else {
          withScope(scope => {
            scope.setExtra('infoByOffer', infoByOffer);

            captureException('CreateClaim');
          });
        }
      } else if (values.offersIds && values.hotelsIds) {
        yield all(new Array(values.offersIds.length)
          .fill(0).map((...args) => call(function* (...[, index]) {
            const offerId = values.offersIds[index];
            const hotelId = values.hotelsIds[index];

            const infoByOffer = yield select(state => getOffer()(state, { offerID: offerId }));
            const infoByHotel = yield select(({ hotels }) => hotels.getIn(['store', hotelId]));

            const isPriceAndCurrency = infoByOffer.price[infoByOffer.currency];

            if (isPriceAndCurrency) {
              const bodyParams = {
                id: response.id,
                ...convertOfferAndHotelToInternalOffer(infoByHotel, infoByOffer),
              };

              yield call(createOffer, token, { bodyParams });
            } else {
              captureException(infoByOffer);
            }
          }, ...args)));
      }
    } else if (search) {
      const { value: searchEntity, contractDataBySearch } = search;

      if (contractDataBySearch) {
        const processedContractData = {
          claim: {
            food_type: contractDataBySearch.food_type.toUpperCase(),
          },
        };

        yield put(clientClaimActions.updateContractClaim(response.id, processedContractData, null));
      }

      yield all([
        call(createSearch, token, { bodyParams: { id: response.id, ...searchEntity } }),
        contractDataBySearch && race([
          take(clientClaimActions.updateContractClaimSuccess),
          take(clientClaimActions.updateContractClaimFail)
        ])
      ]);
    }

    if (search || offer) {
      const pathname = yield select(({ router }) => router.location.pathname);

      yield put(replace(pathname)); /* reset query params */
    }

    yield put(clientClaimActions.clearClaim());

    formik && (yield call(formik.setStatus, { createClaimSuccess: true, claim: response }));
    yield put(clientClaimActions.createClaimSuccess(response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const isSetViolations = getIn(body, ['claim', 'validation', 'violations', 'length']);

        if (isSetViolations) {
          const violations = getIn(body, ['claim', 'validation', 'violations']);
          const formikError = Map({ ...violations })
            .filter(value => {
              const pathToArray = parseErrorPath(value.property_path).split('.');

              return hasIn(claim, pathToArray, false);
            })
            .mapEntries(([, value]) => [parseErrorPath(value.property_path), value.message.replace('.', '')])
            .reduce((result, msg, path) => {
              const pathToArray = parseErrorPath(path).split('.');

              return result.setIn(pathToArray, msg);
            }, Map())
            .toJS();
          const otherErrors = Map({ ...violations })
            .filter(value => {
              const pathToArray = parseErrorPath(value.property_path).split('.');

              return !hasIn(claim, pathToArray, false);
            })
            .map(value => value.message.replace('.', ''))
            .join('. ');

          formik && (yield call(formik.setErrors, formikError));

          otherErrors && (message = otherErrors);
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      default: {
        message = 'Ошибка сервера';
      }
    }

    formik && message && (yield call(formik.setStatus, { error: true, message }));

    yield put(clientClaimActions.createClaimFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
