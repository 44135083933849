import { CALCULATION_TYPES } from 'modules/booking/constants';

import { newInstance } from 'localization';

import money from './img/money.svg';
import card from './img/card.svg';

export const BOOKING_CALCULATION_TYPES = {
  [CALCULATION_TYPES.THROUGH_CO]: {
    value: CALCULATION_TYPES.THROUGH_CO,
    get content() {
      return newInstance.t('TS:PAYMENT_TYPES:THROUGH_CO_CONTENT');
    },
    icon: money
  },
  [CALCULATION_TYPES.VIA_CO_GROSS]: {
    value: CALCULATION_TYPES.VIA_CO_GROSS,
    get content() {
      return newInstance.t('TS:PAYMENT_TYPES:THROUGH_CO_CONTENT');
    },
    icon: money
  },
  [CALCULATION_TYPES.GROSS_FROM_AGENT]: {
    value: CALCULATION_TYPES.GROSS_FROM_AGENT,
    get content() {
      return newInstance.t('TS:PAYMENT_TYPES:VIA_CO_GROSS_CONTENT');
    },
    get description() {
      return newInstance.t('TS:PAYMENT_TYPES:COMMISSION_DESCRIPTION');
    },
    icon: money
  },
  [CALCULATION_TYPES.PAYBACK_FROM_CO]: {
    value: CALCULATION_TYPES.PAYBACK_FROM_CO,
    get content() {
      return newInstance.t('TS:PAYMENT_TYPES:PAYBACK_FROM_CO_CONTENT');
    },
    get description() {
      return newInstance.t('TS:PAYMENT_TYPES:COMMISSION_DESCRIPTION');
    },
    icon: card
  },
};

export const OPTIONS_CONTROLS_TYPES = {
  CHECKBOX: 'checkbox',
  PHONE: 'phone',
  BASE_PHONE: 'tel',
  BASE_DEFAULT: null,
};

export const TF_GROUPS = {
  JOIN_UP: 20,
};
export const OPERATORS_BY_TF_GROUP = {
  JOIN_UP: [2835, 3357]
};

export const BOOKING_OPTIONS_BY_GROUPS = {
  [TF_GROUPS.JOIN_UP]: {
    get bookingTitle() {
      return newInstance.t('TS:BOOKING_OPERATORS_SETTINGS:JOIN_UP_BOOKING_TITLE');
    },
    get bookingDescription() {
      return newInstance.t('TS:BOOKING_OPERATORS_SETTINGS:JOIN_UP_BOOKING_DESCRIPTION');
    },
  }
};
