import { takeEvery } from 'redux-saga/effects';

// instruments
import { fetchDocsWorker, toggleDocWorker, deleteDocWorker, createDocWorker, updateDocWorker, fetchDocVariablesWorker, fetchExtraDocWorker, fetchGuaranteesWorker, fetchDocWorker } from './workers';
import { detailDocsActions as actions } from '../actions';

export const detailDocsWatchers = Object.freeze({
  * fetchDocsWatcher() {
    yield takeEvery(actions.fetchDocs, fetchDocsWorker);
  },
  * toggleDocsWatcher() {
    yield takeEvery(actions.toggleDoc, toggleDocWorker);
  },
  * deleteDocsWatcher() {
    yield takeEvery(actions.deleteDoc, deleteDocWorker);
  },
  * createDocsWatcher() {
    yield takeEvery(actions.createDoc, createDocWorker);
  },
  * updateDocsWatcher() {
    yield takeEvery(actions.updateDoc, updateDocWorker);
  },
  * fetchDocWatcher() {
    yield takeEvery(actions.fetchDoc, fetchDocWorker);
  },
  * fetchDocVariablesWathcer() {
    yield takeEvery(actions.fetchDocVariables, fetchDocVariablesWorker);
  },
  * fetchExtraDocWathcer() {
    yield takeEvery(actions.fetchExtraDoc, fetchExtraDocWorker);
  },
  * fetchGuaranteesWathcer() {
    yield takeEvery(actions.fetchGuarantees, fetchGuaranteesWorker);
  },
});
