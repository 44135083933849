import { select, call, put } from 'redux-saga/effects';
import { get } from 'immutable';

import { uploadProfileImage } from 'api/methods/tf/profile';
import { tfActions } from 'bus/tf/actions';
import { newInstance } from '../../../../../localization';

export function* uploadTfImageWorker({ payload }) {
  const { file, type, imagePath, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const tf = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  const bodyParams = new FormData();

  bodyParams.append('file', file);
  bodyParams.append('type', type);

  try {
    const { image_patch: url } = yield call(uploadProfileImage, token, { pathParams: { tf }, bodyParams });

    yield put(tfActions.uploadTfImageSuccess(url, imagePath));
    formik && (yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = 'Ошибка при загрузке изображения';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права обновлять турфирму');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(tfActions.uploadTfImageFail(error));
    formik && message && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
