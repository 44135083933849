import moment from 'moment';
import { select, call } from 'redux-saga/effects';
import * as R from 'ramda';

import { getToken } from 'bus/auth/selectors';
import { getOperators } from 'bus/common/selectors';

import { getBookings } from 'api/methods/booking';

const DATE_FORMAT = 'YYYY-MM-DD';

const getTouristsTitle = R.pipe(
  R.head,
  ({ name, surname }) => `- ${name} ${surname}`,
);
const bookingMapper = bookings => R.map(
  R.applySpec({
    value: R.prop('id'),
    content: ({ op_claim_id: opClaimId, id, created, tourists }) => R.join(' ', [
      opClaimId || `T-${id}`,
      moment(created).format('DD.MM.YYYY'),
      !R.isEmpty(tourists) ? getTouristsTitle(tourists) : ''
    ])
  }),
  bookings
);

export function* getConnectedBookingsSaga({ payload }) {
  const { callbacks, operatorID } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const operators = yield select(getOperators);

  const operator = R.find(
    ({
      otpusk_id: otpuskId,
      alternative_otpusk_ids: alternativeOtpuskIds = []
    }) => otpuskId === operatorID || alternativeOtpuskIds.includes(String(operatorID)),
    operators
  );

  try {
    const { bookings } = yield call(getBookings, token, { queryParams: {
      operators: operator.id,
      created_from: moment().subtract(3, 'days').format(DATE_FORMAT),
      created_to: moment().format(DATE_FORMAT),
      sort: 'date',
      page: 1
    } });

    onSuccess && onSuccess(
      bookingMapper(bookings)
    );
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
