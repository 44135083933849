import { put, race, take, all } from 'redux-saga/effects';
import * as R from 'ramda';

import { tfActions } from 'bus/tf/actions';
import { commonActions } from 'bus/common/actions';
import { notifyActions } from 'bus/notify';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getErrorMessage } from 'api/fn/parseErrors';
import { COLLECTION_UI_KEY } from '../../../constans';

// TODO_ERROR
export function* initializeDataForPageWorker() {
  yield put(registerUI(createUi({ loading: true }), COLLECTION_UI_KEY));

  yield put(tfActions.fetchTfProfile());
  yield put(notifyActions.getNotifies());
  yield put(notifyActions.getTypes());
  yield put(commonActions.getPatterns());

  const effects = [
    race(
      [take(tfActions.fetchTfProfileSuccess),
        take(tfActions.fetchTfProfileFail)]
    ),
    race(
      [take(notifyActions.getNotifiesSuccess),
        take(notifyActions.getNotifiesFail)]
    ),
    race(
      [take(notifyActions.getTypesSuccess),
        take(notifyActions.getTypesFail)]
    ),
    race(
      [take(commonActions.getPatternsSuccess),
        take(commonActions.getPatternsFail)]
    )];

  const [
    [, fetchTfProfileFail],
    [, getNotifiesFail],
    [, getTypesFail],
    [, getPatternsFail]
  ] = yield all(effects);

  const errors = [];

  fetchTfProfileFail && errors.push(
    getErrorMessage(
      fetchTfProfileFail.payload,
      {
        403: 'Пользователь не имеет права просматривать турфирму',
      }
    )
  );
  getNotifiesFail && errors.push('Ошибка при загрузке уведомлений');
  getTypesFail && errors.push('Ошибка при загрузке данных');
  getPatternsFail && errors.push('Ошибка при загрузке шаблонов');

  const completedErrors = R.filter(Boolean, errors);

  yield put(updateUI({ completed: true, loading: false }, COLLECTION_UI_KEY));
  if (!R.isEmpty(completedErrors)) {
    yield put(updateUI({ message: R.join(' ', completedErrors), error: true }, COLLECTION_UI_KEY));
  }
}
