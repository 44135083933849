import { call, select, all, delay } from 'redux-saga/effects';
import moment from 'moment';
import * as R from 'ramda';
import { generatePath } from 'react-router-dom';
import { indexBy, pipe, prop } from 'ramda';

import { getTfGroups, getToken, getUserId } from 'bus/auth/selectors';
import { getOperatorOptions, getOperators } from 'bus/common/selectors';

import { createBooking, updateBooking } from 'api/methods/booking';
import { createBookingTourist } from 'api/methods/booking/tourists';
import { updateBookingCash } from 'api/methods/booking/money';
import { createServices } from 'api/methods/booking/services';
import { createBookingMessage } from 'api/methods/booking/messages';

import { NEW_MONEY_TYPES } from 'containers/form/booking/constants';

import book from 'routes/book';

import {
  createFullDesc,
  isSetViolations,
  mapValuesToApi,
  computeFullBookingServicesPrice,
  getOperatorSettingsByTfGroup,
  getBookingType,
  generateBookingMassage,
  convertFlights2Array,
  getIsCreateServicesFromFlights,
  mapBookingServices,
  mapFlightsServices,
} from './heplers';

const ON_BOOKING_STATUS = 7;

export function* bookingOfferByOperatorWorker({ payload }) {
  const {
    callbacks = {},
    bookingEntity,
    offer,
    hotel,
    query,
    activeFlights,
    activeBookingServices,
    recalculatedPrice
  } = payload;
  const { onSuccess, onFail } = callbacks;

  try {
    const token = yield select(getToken);
    const userId = yield select(getUserId);
    const operators = yield select(getOperators);

    const operator = R.pipe(
      R.find(({
        otpusk_id: otpuskId,
        alternative_otpusk_ids: alternativeOtpuskIds = []
      }) => otpuskId === offer.operator || alternativeOtpuskIds.includes(String(offer.operator)))
    )(operators);

    const operatorOptions = yield select(state => getOperatorOptions(state, { id: operator.id }));
    const tfGroups = yield select(pipe(
      getTfGroups,
      indexBy(prop('id')),
    ));
    const mappedBooking = mapValuesToApi(bookingEntity, operatorOptions);
    const computedServices = R.concat(
      mapBookingServices(activeBookingServices, operator.hasOwnBookingServices),
      getIsCreateServicesFromFlights(offer)
        ? mapFlightsServices(
          convertFlights2Array(activeFlights),
          offer.transport,
          offer.adults + offer.children
        )
        : []
    );

    const booking = yield call(createBooking, token, {
      bodyParams: {
        operator: operator.id.toString(),
        status: ON_BOOKING_STATUS,
        calculation_type: bookingEntity.paymentType,
      },
    });

    yield delay(500);

    yield all([
      call(updateBooking, token, {
        pathParams: { id: booking.id },
        bodyParams: {
          comment: createFullDesc({
            offer,
            hotel,
            query,
            booking: mappedBooking,
            activeFlights,
            activeBookingServices,
            recalculatedPrice,
            operatorSettings: getOperatorSettingsByTfGroup(operator.id, tfGroups)
          }),
          country: hotel.country.id,
          counterparty_tf: bookingEntity.company,
          check_in: offer.date,
          check_out: moment(offer.date).startOf('day').add(offer.nights, 'days').format('YYYY-MM-DD'),
          sub_operator: offer.subOperator.code,
          performer: userId,
          booking_type: getBookingType(offer),
          ...!R.isEmpty(mappedBooking.previousBookings)
            ? { connected_bookings: mappedBooking.previousBookings }
            : {},
          offer_id: offer.id,
          tourist_phone: bookingEntity.phone,
          ...offer.isTransportGDS
            ? { gds: true }
            : {},
          hotel_name: hotel.name,
          hotel_otpusk_id: hotel.id,
          stars: hotel.stars,
          city: hotel.city.name,
          food_type: offer.food,
          room_type: offer.room.name,
        },
      }),
      call(updateBookingCash, token, { pathParams: { id: booking.id }, bodyParams: {
        [NEW_MONEY_TYPES.CURRENCY]: offer.currency,
        [NEW_MONEY_TYPES.SOURCE_PRICE]: recalculatedPrice
          ? recalculatedPrice.price[offer.currency]
          : (
            offer.priceByOperator[offer.currency] || offer.price[offer.currency]
          ) + computeFullBookingServicesPrice(offer.currency, activeBookingServices),
        [NEW_MONEY_TYPES.RATE]: recalculatedPrice?.rate ?? (offer.currencyOperatorRate || offer.currencyRate)
      } }),
      call(createBookingTourist, token, {
        pathParams: { id: booking.id },
        bodyParams: { tourists: mappedBooking.passports },
      }),
      mappedBooking.comment && call(
        createBookingMessage,
        token,
        {
          pathParams: { id: booking.id },
          bodyParams: generateBookingMassage({
            text: mappedBooking.comment,
          })
        }
      ),
      !R.isEmpty(computedServices) && call(
        createServices,
        token,
        {
          pathParams: { id: booking.id },
          bodyParams: {
            services: computedServices
          }
        }
      )
    ]);

    onSuccess && onSuccess({
      id: `T-${booking.id}`,
      viewLink: generatePath(book.client.children.bookingPage, { id: booking.id }),
      bookingsLink: book.client.children.booking,
    });
  } catch (e) {
    const { body } = e.msg || {};

    const errorMessage = R.join(
      ', ',
      R.concat(
        isSetViolations(body) ? R.reduce(
          (acc, { message }) => acc.concat(message),
          [],
          R.path(['validation', 'violations'], body)
        ) : [],
        R.prop('message', body) ? [R.prop('message', body)] : []
      )
    );

    onFail && onFail(new Error(errorMessage));
  }
}
