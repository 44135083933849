import * as R from 'ramda';
import { matchPath } from 'react-router-dom';

import book from '../../routes/book';
import modalLoader from '../../containers/modalsDispatcher/loader';

const DONT_REF_PAGES = ['/', book.login];
const DECODE_LINKS = [
  book.tourscanner.path,
  book.charters.path,
  book.client.path,
  book.tourscanner.children.offer
];

const decodeQuery = str => R.call(
  R.pipe(
    R.split('&'),
    R.map(param => {
      const [key, value] = R.split('=', param);

      return R.join('=', [key, decodeURIComponent(value)]);
    }),
    R.join('&')
  ),
  str
);

export const createRefLink = (pathname, search = '') => {
  const isTSLink = DECODE_LINKS.some(path => matchPath(pathname, { path, exact: true }));
  const query = new URLSearchParams();
  const ref = DONT_REF_PAGES.every(path => !matchPath(pathname, { path, exact: true })) ? `${pathname + search}` : '';

  query.append('ref', ref);

  return isTSLink ? query.toString() : decodeQuery(query.toString());
};

const KEY = 'utm';
const REFERER = 'referer';

export const prepareUtmSearchParam = (iterator = []) => R.call(
  R.pipe(
    R.filter(([key]) => R.or(key.includes(KEY), key.includes(REFERER))),
    R.fromPairs
  ), [...iterator]);

export const loadLimitedUserModal = modalLoader({
  key: 'LIMITED_USERS_KEY',
  getModal: () => import(/* confirm-modal */'components/modals/LimitedUserModal'),
});
