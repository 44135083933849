import { call, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { copyToBufferDynamicContent } from 'helpers';
import { convertOfferAndHotelToInternalOffer } from 'helpers/offer';

import { getOfferTemplate } from 'api/methods/tourscaner';

import { getToken } from 'bus/auth/selectors';

import { prepareStringToCopy } from '../../helpers';

const TEMPLATE_NAME = 'tours4';

const createBaseError = error => {
  return new Error(error.msg.body.message);
};

export function* copyOfferToBufferSaga({ payload: { callbacks, offer, hotel } }) {
  const token = yield select(getToken);

  const internalOffer = convertOfferAndHotelToInternalOffer(hotel, offer);

  try {
    yield call(
      copyToBufferDynamicContent,
      () => getOfferTemplate(
        token,
        {
          pathParams: { type: TEMPLATE_NAME },
          bodyParams: { offer: internalOffer },
        }
      )
        .then(prepareStringToCopy)
        .then(R.tap(callbacks.onSuccess))
    );
  } catch (error) {
    callbacks && callbacks.onFail(
      createBaseError(error)
    );
  }
}
