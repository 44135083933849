import { select, call, put } from 'redux-saga/effects';
import { get, getIn } from 'immutable';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import { addUser } from 'api/methods/tf/user';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

import { tfUsersActions } from 'bus/tfUsers/actions';

import book from 'routes/book';

import { replaceValuesForPasswordError } from '../helpers';

export function* addUserWorker({ payload }) {
  const { values, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const user = yield call(addUser, token, { bodyParams: values });

    yield put(tfUsersActions.addUserSuccess(user));
    yield put(push(generatePath(book.details.children.settingUser, { id: user.id })));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = '';

    switch (status) {
      case 400: {
        const isHaveErrors = getIn(body, ['violations', 'length']);

        if (isHaveErrors) {
          const { password, ...prepareValues } = values;
          const { errorsByValues, otherErrors } = parseErrorsByFormik({ ...prepareValues, plain_password: password }, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, replaceValuesForPasswordError(errorsByValues)));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }
        break;
      }
      case 403: {
        message = body.message || 'Отказ в доступе';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    get(formik, 'setStatus') && (yield call(formik.setStatus, { error: true, message }));
    yield put(tfUsersActions.addUserFail(error));
  } finally {
    get(formik, 'setSubmitting') && (yield call(formik.setSubmitting, false));
  }
}
