import { select, call, put } from 'redux-saga/effects';
import moment from 'moment';
import { get } from 'immutable';

// instruments
import { messagesActions } from 'bus/messages';
import { createMessage } from 'api/methods/messages';
import { parseErrorsByFormik } from 'api/fn/parseErrors';
import { newInstance } from '../../../../localization';

export function* createMessageWorker({ payload }) {
  const { message: { start_time: startTime, ...message }, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const response = yield call(
      createMessage,
      token,
      { bodyParams: { start_time: startTime.clone().utcOffset(0), ...message } }
    );

    yield put(messagesActions.createMessageSuccess(response));
    formik && (yield call(formik.setStatus, { success: true, message: startTime.diff(moment(), 'seconds') <= 0
      ? newInstance.t('MALLING:YOUR_MESSAGE_WAS_SENT')
      : startTime.format('DD.MM.YYYY HH:mm') }));
  } catch (error) {
    const { status, body } = error.msg;

    let errorMsg = null;

    switch (status) {
      case 400: {
        if (body.violations) {
          const {
            errorsByValues,
            otherErrors
          } = parseErrorsByFormik(payload.message, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (errorMsg = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (errorMsg = 'Ошибка сервера');
        } else {
          errorMsg = 'Ошибка сервера';
        }
        break;
      }
      case 402: {
        errorMsg = get(body, 'message', 'Недостаточно средств на счету');
        break;
      }
      case 403: {
        errorMsg = get(body, 'message', 'Пользователь не имеет права на отправку сообщения или к переданному заказу или активна подписка только на Турсканер');
        break;
      }
      default: errorMsg = 'Ошибка сервера';
    }

    formik && errorMsg && (yield call(formik.setStatus, { error: true, message: errorMsg }));
    yield put(messagesActions.createMessageFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
