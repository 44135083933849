import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import { push } from 'connected-react-router';

import { getCrmTypes } from 'crms/utils';

import { getMessagesCountUnread } from 'bus/booking/selectors.js';
import { getUI } from 'bus/ui/selectors.js';
import { getCountClaims } from 'bus/claim/selectors.js';

import Styles from '../../Client/styles.scss';

const UI_KEY = ['booking', 'messagesCountUnread'];
const CLAIM_UI_KEY = ['claims', 'countLoading'];

const LabelCreator = ({ loading, value, link }) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(e => {
    e.preventDefault();
    dispatch(push(link));
  }, [link]);

  return (
    <div className={Styles.label} onClick={link ? handleClick : null}>
      { loading
        ? <Icon loading name="spinner" /> : value}
    </div>
  );
};

export const useGetMessagesCount = labelLink => {
  const messagesCountUnread = useSelector(getMessagesCountUnread);
  const { loading } = useSelector(state => getUI(state, { path: UI_KEY }));

  const { isMt } = getCrmTypes();

  return !isMt && Boolean(messagesCountUnread) && <LabelCreator link={labelLink} loading={loading} value={messagesCountUnread} />;
};

export const useCountClaims = labelLink => {
  const messagesCountUnread = useSelector(getCountClaims);
  const loading = useSelector(state => getUI(state, { path: CLAIM_UI_KEY }));

  return Boolean(messagesCountUnread) && <LabelCreator link={labelLink} loading={loading} value={messagesCountUnread} />;
};
