import React, { memo } from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';

// styles
import cx from 'classnames';
import Styles from './styles.scss';

const B = ({ text, className, handleClick, loading, ...props }) => {
  return (
    <div className={cx(Styles.root, className)}>
      <SemanticButton
        content={text}
        disabled={loading}
        loading={loading}
        type="button"
        onClick={handleClick}
        {...props}
      />
    </div>
  );
};

B.displayName = 'Button';
B.defaultProps = {
  className: null,
  handleClick: () => {},
};
export const Button = memo(B);
