import React, { memo } from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

import defaultImg from './img/Default.png';

export const SIZES = {
  SMALL: 'small',
  DEFAULT: 'default'
};

const createUrl = path => `${window.location.origin}/${path}`;

const AvatarPure = ({
  src,
  size = SIZES.DEFAULT
}) => (
  <img
    className={classnames(
      styles.root,
      styles[`size--${size}`]
    )}
    alt="preview"
    src={src ? createUrl(src) : defaultImg}
  />
);

export const Avatar = memo(AvatarPure);
