import { takeEvery } from 'redux-saga/effects';

import { statisticActions } from 'bus/statistic/actions';
import { searchBonusesReports, searchReports } from './workers/searchFlexibleReport';
import { getCallsWorker } from './workers/getCalls';
import { getLogsWorker } from './workers/getLogs';
import { getBonusesStat } from './workers/getBonuses';
import getSalesFunnelWorker from './workers/getSalesFunnel';

export const statisticWatchers = Object.freeze({
  * searchFlexibleReportWathcer() {
    yield takeEvery(statisticActions.searchFlexibleReport, searchReports);
  },
  * searchBonusesReportsWathcer() {
    yield takeEvery(statisticActions.searchBonusesReports, searchBonusesReports);
  },
  * getCalls() {
    yield takeEvery(statisticActions.getCalls, getCallsWorker);
  },
  * getLogs() {
    yield takeEvery(statisticActions.getLogs, getLogsWorker);
  },
  * getBonuses() {
    yield takeEvery(statisticActions.getBonuses, getBonusesStat);
  },
  * getSalesFunnel() {
    yield takeEvery(statisticActions.getSalesFunnel, getSalesFunnelWorker);
  },
});
