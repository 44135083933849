import { createActions } from 'redux-actions';

export const commonActions = createActions({
  GET_OPERATORS_MT: queryParams => ({ queryParams }),
  GET_OPERATORS_MT_SUCCESS: operators => operators,
  GET_OPERATORS_MT_FAIL: error => error,
  setOperatorsPagination: (total, limit) => ({ total, limit }),

  CLEAR_OPERATORS: () => [],

  getClosingReasons: () => undefined,
  closingReasonsSuccess: closingReasons => closingReasons,
  closingReasonsFail: error => error,

  createClosingReason: (closingReasons, formik, onSuccess) => (
    { closingReasons, onSuccess, formik }
  ),
  updateClosingReason: (id, description) => ({ id, description }),
  removeClosingReason: (id, onSuccess) => ({ id, onSuccess }),

  GET_OPERATORS_MT_DOCS: (id, onSuccess) => ({ id, onSuccess }),
  GET_OPERATORS_MT_DOCS_SUCCESS: docs => docs,
  GET_OPERATORS_MT_DOCS_FAIL: error => error,

  getDocumentsTypes: id => id,
  getDocumentsTypesSuccess: documents => documents,
  getDocumentsTypesFail: error => error,

  CREATE_OPERATOR: (bodyParams, options) => ({ bodyParams, options }),
  CREATE_OPERATOR_SUCCESS: operator => operator,
  CREATE_OPERATOR_FAIL: error => error,

  DELETE_OPERATOR: id => id,
  DELETE_OPERATOR_SUCCESS: id => id,
  DELETE_OPERATOR_FAIL: error => error,

  UPDATE_OPERATOR: (id, bodyParams) => ({ id, bodyParams }),
  UPDATE_OPERATOR_SUCCESS: operator => operator,
  UPDATE_OPERATOR_FAIL: error => error,

  getOperatorRequestLogin: operatorId => operatorId,
  getOperatorRequestLoginSuccess: () => undefined,
  getOperatorRequestLoginError: error => error,

  GET_MANAGERS: () => undefined,
  GET_MANAGERS_SUCCESS: managers => managers,
  GET_MANAGERS_FAIL: error => error,

  GET_TAGS: () => undefined,
  GET_TAGS_SUCCESS: tags => tags,
  GET_TAGS_FAIL: error => error,
  CREATE_TAG: name => name,
  CREATE_TAG_SUCCESS: tag => tag,
  CREATE_TAG_FAIL: error => error,
  DELETE_TAG: id => id,
  DELETE_TAG_SUCCESS: id => id,
  DELETE_TAG_FAIL: error => error,
  UPDATE_TAG: (id, name) => ({ id, name }),
  UPDATE_TAG_SUCCESS: (id, name) => ({ id, name }),
  UPDATE_TAG_FAIL: error => error,

  GET_OFFICES: () => undefined,
  GET_OFFICES_SUCCESS: offices => offices,
  GET_OFFICES_FAIL: error => error,
  CREATE_OFFICE: name => name,
  CREATE_OFFICE_SUCCESS: offices => offices,
  CREATE_OFFICE_FAIL: error => error,
  DELETE_OFFICE: id => id,
  DELETE_OFFICE_SUCCESS: id => id,
  DELETE_OFFICE_FAIL: error => error,
  UPDATE_OFFICE: (id, name) => ({ id, name }),
  UPDATE_OFFICE_SUCCESS: (id, name) => ({ id, name }),
  UPDATE_OFFICE_FAIL: error => error,

  GET_REGIONS: () => undefined,
  GET_REGIONS_SUCCESS: regions => regions,
  GET_REGIONS_FAIL: error => error,
  CREATE_REGION: name => name,
  CREATE_REGION_SUCCESS: region => region,
  CREATE_REGION_FAIL: error => error,
  DELETE_REGION: id => id,
  DELETE_REGION_SUCCESS: id => id,
  DELETE_REGION_FAIL: error => error,
  UPDATE_REGION: (id, name) => ({ id, name }),
  UPDATE_REGION_SUCCESS: (id, name) => ({ id, name }),
  UPDATE_REGION_FAIL: error => error,

  CREATE_SOURCE: description => description,
  CREATE_SOURCE_SUCCESS: source => source,
  CREATE_SOURCE_FAIL: error => error,

  GET_DETAILS: () => undefined,
  GET_DETAILS_SUCCESS: (details, fieldset) => ({ details, fieldset }),
  GET_DETAILS_FAIL: error => error,

  CREATE_DETAIL: (tempID, detail, options) => ({ tempID, detail, options }),
  CREATE_DETAIL_SUCCESS: (tempID, detail) => ({ detail, tempID }),
  CREATE_DETAIL_FAIL: error => error,
  ADD_DETAIL: detail => detail,

  DELETE_DETAIL: (id, options) => ({ id, options }),
  DELETE_DETAIL_SUCCESS: id => id,
  DELETE_DETAIL_FAIL: error => error,

  UPDATE_DETAIL: (id, detail, options) => ({ id, detail, options }),
  UPDATE_DETAIL_SUCCESS: (id, detail) => ({ id, detail }),
  UPDATE_DETAIL_FAIL: error => error,

  UPLOAD_DETAIL_STAMP: (id, file) => ({ id, file }),
  UPLOAD_DETAIL_STAMP_SUCCESS: (id, url) => ({ id, url }),
  UPLOAD_DETAIL_STAMP_FAIL: error => error,

  DELETE_DETAIL_STAMP: (id, clearFormik, workerOptions) => ({ id, clearFormik, workerOptions }),
  DELETE_DETAIL_STAMP_SUCCESS: id => ({ id, url: null }),
  DELETE_DETAIL_STAMP_FAIL: error => error,

  GET_DOCS_BY_OPERATORS: () => undefined,
  GET_DOCS_BY_OPERATORS_SUCCESS: list => list,
  GET_DOCS_BY_OPERATORS_FAIL: error => error,

  FETCH_IPASSPORT_FIELD_SET: () => undefined,
  FETCH_IPASSPORT_FIELD_SET_SUCCESS: fieldSet => fieldSet,
  FETCH_IPASSPORT_FIELD_SET_FAIL: error => error,

  GET_PATTERNS: () => undefined,
  GET_PATTERNS_SUCCESS: patterns => patterns,
  GET_PATTERNS_FAIL: error => error,

  GET_ROLES: () => undefined,
  GET_ROLES_SUCCESS: roles => roles,
  GET_ROLES_FAIL: error => error,

  GET_NOTIFICATION_TYPES: () => undefined,
  GET_NOTIFICATION_TYPES_SUCCESS: types => types,
  GET_NOTIFICATION_TYPES_FAIL: error => error,

  GET_COUNTRIES: queryParams => ({ queryParams }),
  GET_COUNTRIES_SUCCESS: countries => countries,
  GET_COUNTRIES_FAIL: error => error,

  GET_UNIQUE_ACTIVE_EPASSPORTS: (clientID, workerOptions) => ({ clientID, workerOptions }),
  GET_UNIQUE_ACTIVE_EPASSPORTS_SUCCESS: (clientID, epassports) => ({ clientID, epassports }),
  GET_UNIQUE_ACTIVE_EPASSPORTS_FAIL: error => error,

  GET_SMS_PRICES: workerOptions => ({ workerOptions }),
  GET_SMS_PRICES_SUCCESS: prices => prices,
  GET_SMS_PRICES_FAIL: error => error,

  GET_ONLINE_PAYMENT: () => undefined,
  GET_ONLINE_PAYMENT_SUCCESS: response => response,
  GET_ONLINE_PAYMENT_FAIL: fail => fail,

  GET_DEPARTMENTS: () => undefined,
  GET_DEPARTMENTS_SUCCESS: departments => departments,
  GET_DEPARTMENTS_FAIL: error => error,

  GET_CLAIM_SOURCE: () => undefined,
  GET_CLAIM_SOURCE_SUCCESS: sources => sources,
  GET_CLAIM_SOURCE_FAIL: error => error,

  DELETE_CLAIM_SOURCE: (id, replace, formik) => ({ id, replace, formik }),
  DELETE_CLAIM_SOURCE_SUCCESS: sourceId => sourceId,
  DELETE_CLAIM_SOURCE_FAIL: error => error,

  UPDATE_CLAIM_SOURCE: (source, formik) => ({ source, formik }),
  UPDATE_CLAIM_SOURCE_SUCCESS: source => source,
  UPDATE_CLAIM_SOURCE_FAIL: error => error,

  CREATE_CLAIM_SOURCE: (description, formik) => ({ description, formik }),
  CREATE_CLAIM_SOURCE_SUCCESS: source => source,
  CREATE_CLAIM_SOURCE_FAIL: error => error,

}, { prefix: 'MT_COMMON' });
