import { getSearch } from 'connected-react-router';

import { convertBrowserLangToLocale } from 'localization/helpers';

import { getUI } from 'bus/ui/selectors';

import { UI_KEY } from './constants';

export const getIsLoading = state => {
  return Boolean(getUI(state, { key: UI_KEY }).loading);
};

export const getIsCompleted = state => {
  return Boolean(getUI(state, { key: UI_KEY }).completed);
};

export const getLocale = state => {
  return new URLSearchParams(getSearch(state)).get('locale') || convertBrowserLangToLocale();
};

export const getCurrency = state => {
  return new URLSearchParams(getSearch(state)).get('currency');
};
