import { call, select, put } from 'redux-saga/effects';

import { createUi } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';
import { bookingActions } from 'bus/booking/actions.js';
import { getBookingId } from 'bus/booking/selectors';
import { registerUI } from 'bus/ui/actions';

import { updateDocument } from 'api/methods/booking';

import { getUpdateDocumentUIKey } from './helpers';

export function* updateDocumentSaga({ payload: { id, document } }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  const UIKey = getUpdateDocumentUIKey(id);

  try {
    yield put(registerUI(createUi({ loading: true }), UIKey));

    const updatedDocument = yield call(
      updateDocument,
      token,
      { pathParams: { documentId: id, bookingId }, bodyParams: document }
    );

    yield put(bookingActions.updateDocumentSuccess(id, updatedDocument));
  } catch (error) {
    const { message } = error.msg.body;

    error.message = message ?? 'Ошибка сервера';

    yield put(registerUI({ error: true, message: error.message }, UIKey));

    yield put(bookingActions.updateDocumentFail(error));
  } finally {
    yield put(registerUI({ loading: false, completed: true }, UIKey));
  }
}
