import { put, all, select, race, take, call } from 'redux-saga/effects';
import { offersActions } from '@otpusk/apisearch-toolbox/dist/offers';
import { getOffer } from '@otpusk/apisearch-toolbox/dist/offers/selectors';
import { hotelsActions } from '@otpusk/apisearch-toolbox/dist/hotels';
import { operatorsActions } from '@otpusk/apisearch-toolbox/dist/operators';
import { List, get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { getSearchLocation } from 'api/methods/common/getSearchLocation';

export function* combineClaimWithOffersWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const { offersIds, hotelsIds } = payload;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, meta: {} },
    path: ['claims', 'combineClaimWithOffers'],
  }));

  try {
    yield all([
      ...offersIds.map((...args) => call(function* saga(offerId) {
        const isSetOffer = yield select(state => getOffer()(state, { offerID: offerId }));

        if (isSetOffer) {
          return null;
        }

        yield put(offersActions.getOffer(offerId));

        const [, fail] = yield race([
          take(offersActions.getOfferSuccess),
          take(offersActions.getOfferFail)
        ]);

        if (fail) {
          throw new Error('Ошибка при загрузке оффера');
        }

        return true;
      }, ...args)),
      ...hotelsIds.map((...args) => call(function* hotelSaga(hotelId) {
        const isSetHotel = yield select(({ hotels }) => hotels.hasIn(['store', String(hotelId)]));

        if (isSetHotel) {
          return null;
        }

        yield put(hotelsActions.getHotel(hotelId));

        const [, fail] = yield race([
          take(hotelsActions.getHotelSuccess),
          take(hotelsActions.getHotelFail)
        ]);

        if (fail) {
          throw new Error('Ошибка при загрузке отеля');
        }

        return true;
      }, ...args))
    ]);

    const hotelsStore = yield select(({ hotels }) => hotels.get('store'));

    const uniqalsCountriesIdsByHotels = List(hotelsIds.map(hotelId => hotelsStore.getIn([String(hotelId), 'country', 'id']))).toSet();
    const isSetStaticLists = yield select(({ clientClaim }) => clientClaim.getIn(['staticLists', 'size'], null));

    !isSetStaticLists && (yield put(clientClaimActions.getStaticLists()));

    const combineFetchingData = yield all([
      ...uniqalsCountriesIdsByHotels.map((...args) => call(function* (countryId) {
        const isSetOperators = yield select(({ operators }) => operators.hasIn(['store', String(countryId)]));

        if (!isSetOperators) {
          yield put(operatorsActions.getOperators(countryId));

          const [, fail] = yield race([
            take(operatorsActions.getOperatorsSuccess),
            take(operatorsActions.getOperatorsFail)
          ]);

          if (fail) {
            throw new Error('Ошибка при загрузке оператора');
          }
        }
      }, ...args)),
      !isSetStaticLists && race([
        take(clientClaimActions.getStaticListsSuccess),
        take(clientClaimActions.getStaticListsFail)
      ]),
      ...hotelsIds.map((...args) => call(function* (hotelId) {
        try {
          const hotelName = hotelsStore.getIn([String(hotelId), 'name']);
          const response = yield call(
            getSearchLocation,
            token,
            { queryParams: { term: hotelName } }
          );
          const isSetOtpuskHotelInMt = get(response, 'hotels', [])
            .find(hotel => Number(hotel.id) === Number(hotelId));

          yield put(uiActions.changeUiLoaderFlag({
            status: Boolean(isSetOtpuskHotelInMt),
            path: [
              'claims', 'combineClaimWithOffers', 'meta', 'isSetOtpuskHotelsInMt', String(hotelId)
            ],
          }));
        } catch (error) {
          if (error) {
            throw new Error('Ошибка при загрузке отеля');
          }
        }
      }, ...args)),
      ...offersIds.map((...args) => call(function* offerSaga(offerId) {
        const offerCurrency = yield select(state => {
          return getOffer()(state, { offerID: offerId }).currency;
        });
        const currencyList = yield select(
          ({ clientClaim }) => clientClaim.getIn(['staticLists', 'currencies'], [])
        );

        yield put(uiActions.changeUiLoaderFlag({
          status: Boolean(
            currencyList.find(currency => currency.value === offerCurrency.replace('uah', 'hrn'))
          ),
          path: [
            'claims', 'combineClaimWithOffers', 'meta', 'isSetCurrencyOtpuskInMt', String(offerId)
          ],
        }));
      }, ...args))
    ]);

    const [, resultsStaticListDownload] = combineFetchingData;

    if (resultsStaticListDownload) {
      const [, fail] = resultsStaticListDownload;

      if (fail) {
        throw new Error('Ошибка при загрузке списка валют');
      }
    }
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['claims', 'combineClaimWithOffers', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: error.message,
      path: ['claims', 'combineClaimWithOffers', 'message'],
    }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['claims', 'combineClaimWithOffers', 'completed'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['claims', 'combineClaimWithOffers', 'loading'],
    }));
  }
}
