import { select, call, put } from 'redux-saga/effects';

// instruments
import { getStoppedEpassports } from 'api/methods/main/stoppedEpassports';
import { uiActions } from 'bus/ui/actions';
import { mainActions } from 'bus/main/actions';

export function* fetchStoppedEpassportsWorker({ payload: date }) {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['main', 'stoppedEpassports'],
  }));

  try {
    const response = yield call(getStoppedEpassports, token, { pathParams: { date } });

    yield put(mainActions.fetchStoppedEpassportsSuccess(response));
  } catch (error) {
    yield put(mainActions.fetchStoppedEpassportsFail(error));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['main', 'stoppedEpassports', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['main', 'stoppedEpassports', 'message'],
    }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['main', 'stoppedEpassports', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['main', 'stoppedEpassports', 'completed'],
    }));
  }
}
