import { call, select, put } from 'redux-saga/effects';
import { getIn, get, Map, Set } from 'immutable';

// instruments
import { updateComment } from 'api/methods/claim/comment';
import { commentsActions as actions } from 'bus/clientClaim/comments';
import { uiActions } from 'bus/ui/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['clientClaim', 'comments', 'update'];

export function* updateCommentBaseWorker({ payload }) {
  const { claimID, commentID, comment, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, commentID],
  })));

  try {
    const response = yield call(
      updateComment,
      token,
      { pathParams: { claimID, eventID: commentID }, bodyParams: comment }
    );

    yield put(actions.updateCommentSuccess(claimID, response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    let errorsByValues = Map();

    let otherErrors = Set();

    switch (status) {
      case 400: {
        const errorsInstance = [getIn(body, ['claim', 'validation', 'violations']), getIn(body, ['event', 'validation', 'violations'])].filter(o => o);

        if (errorsInstance.length) {
          errorsInstance.forEach(violations => {
            const compiledErrors = parseErrorsByFormik(comment, violations);

            errorsByValues = errorsByValues.merge(compiledErrors.errorsByValues);
            otherErrors = otherErrors.merge(compiledErrors.otherErrors);
          });
        } else {
          message = 'Ошибка сервера';
        }
        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права просматривать эту заявку или доступа к разделу ‘Клиенты’');
        break;
      }
      case 404: {
        message = 'Заказ не найден';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    errorsByValues = errorsByValues.toObject();
    otherErrors = otherErrors.toArray();

    yield put(actions.updateCommentFail(error, { message, errorsByValues, otherErrors }));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, commentID, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message || otherErrors.map(o => o.message).join('. '),
      path: [...BASE_UI_PATH, commentID, 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, commentID, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, commentID, 'completed'],
    })));
  }
}
