import { select, takeEvery, call, put } from 'redux-saga/effects';

import { getToken } from 'bus/auth/selectors';
import { accountingActions } from 'bus/accounting/actions';
import { getBookingId } from 'bus/booking/selectors';

import { getDefaultAccountingPayment } from 'api/methods/accounting';

import { changePaymentType } from './actions';

function* changePaymentTypeSaga({ payload: type }) {
  const token = yield select(getToken);
  const booking = yield select(getBookingId);

  const payment = yield call(getDefaultAccountingPayment, token, { queryParams: {
    type,
    booking
  } });

  yield put(accountingActions.mergePayment({
    account_calculation: payment.account_calculation,
    type
  }));
}

export function* bookingPayRootSaga() {
  yield takeEvery(changePaymentType, changePaymentTypeSaga);
}
