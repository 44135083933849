import { createActions } from 'redux-actions';

export const stepersActions = createActions({
  SET_ACTIVE_STEP: marker => marker,
  CLEAR_ACTIVE_STEP: () => undefined,

  TOGGLE_DONE_STEP: (claimID, stepID, status, workerOptions) => (
    { claimID, stepID, status, workerOptions }
  ),
  TOGGLE_DONE_STEP_SUCCESS: (claimID, stepID, status, step) => ({ claimID, stepID, status, step }),
  TOGGLE_DONE_STEP_FAIL: [error => error, (...[, processedError]) => processedError],
});
