import { call, put, race, take } from 'redux-saga/effects';
import { get } from 'immutable';

import { tfActions } from 'bus/tf/actions';

export function* updateTfProfileAndImageWorker({ payload }) {
  const { values, file, fileType, imagePath, formik } = payload;

  yield put(tfActions.uploadTfImage(file, fileType, imagePath, null));
  const [success, fail] = yield race([
    take(tfActions.uploadTfImageSuccess),
    take(tfActions.uploadTfImageFail)
  ]);

  if (success) {
    yield put(tfActions.updateTfProfile(values, formik));
  } else if (fail) {
    const { status: statusCode, body } = fail.payload.msg || {};

    let message = null;

    switch (statusCode) {
      case 400: {
        message = 'Ошибка при загрузке изображения';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Пользователь не имеет права обновлять турфирму');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    formik && (yield call(formik.setSubmitting, false));
  }
}
