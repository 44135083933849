import { select, put, call } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getRegions } from 'api/methods/common/regions';

export function* getRegionsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: '' },
    path: ['common', 'regions'],
  }));

  try {
    const response = yield call(getRegions, token, {});

    yield put(commonActions.getRegionsSuccess(response));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({ status: 'Ошибка сервера', path: ['common', 'regions', 'message'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'regions', 'error'] }));
    yield put(commonActions.getRegionsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'regions', 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'regions', 'completed'] }));
  }
}
