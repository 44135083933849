import { createActions } from 'redux-actions';

export const tfUsersActions = createActions({
  FETCH_USERS: () => undefined,
  FETCH_USERS_SUCCESS: users => users,
  FETCH_USERS_FAIL: error => error,

  SET_USERS_FILTER_VALUE: value => value,

  TOGGLE_TF_USER_STATUS: id => ({ user: id }),
  TOGGLE_TF_USER_STATUS_SUCCESS: user => user,
  TOGGLE_TF_USER_STATUS_FAIL: error => error,

  FETCH_USER: userId => userId,
  FETCH_USER_SUCCESS: user => user,
  FETCH_USER_FAIL: error => error,
  RESET_USER: () => undefined,

  UPDATE_USER_INFO: (userId, values, formik, uiKey) => ({ userId, values, uiKey, formik }),
  UPDATE_USER_INFO_SUCCESS: user => user,
  UPDATE_USER_INFO_FAIL: error => error,

  updateUserImage: (userId, image) => ({ userId, image }),
  updateUserImageSuccess: image => image,
  updateUserImageFail: error => error,

  removeUserImage: userId => userId,
  removeUserImageSuccess: () => undefined,
  removeUserImageFail: error => error,

  ADD_USER: (values, formik) => ({ values, formik }),
  ADD_USER_SUCCESS: user => user,
  ADD_USER_FAIL: error => error,

  DELETE_USER_TELEGRAM: () => undefined,

  GET_DEFAULT_TEMPLATE: options => options,
  GET_DEFAULT_TEMPLATE_SUCCESS: template => template,
  GET_DEFAULT_TEMPLATE_FAIL: error => error,
  CLEAR_DEFAULT_TEMPLATE: () => undefined,
}, { prefix: 'MT_TF_USERS' });
