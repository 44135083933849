import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';

// styles
import cx from 'classnames';

// assets
import AddIcon from 'assets/icons/plus.svg';
import EditIcon from 'assets/icons/pencil.svg';
import DeleteIcon from 'assets/icons/trash.svg';
import CloneIcon from 'assets/icons/clone.svg';
import TurnOnIcon from 'assets/icons/turn-on.svg';
import CopyIcon from 'assets/icons/copy.svg';
import OptionsIcon from 'assets/icons/options.svg';
import EmailIcon from 'assets/icons/email-action.svg';
import PrintIcon from 'assets/icons/printer.svg';
import DownloadIcon from 'assets/icons/download.svg';
import UploadIcon from 'assets/icons/upload.svg';
import ResetIcon from 'assets/icons/eraser.svg';
import SaveIcon from 'assets/icons/save.svg';
import CommissionIcon from 'assets/icons/commission.svg';
import Styles from './styles.scss';

const icons = {
  copy: CopyIcon,
  clone: CloneIcon,
  add: AddIcon,
  edit: EditIcon,
  delete: DeleteIcon,
  turnOn: TurnOnIcon,
  options: OptionsIcon,
  send: EmailIcon,
  print: PrintIcon,
  download: DownloadIcon,
  upload: UploadIcon,
  reset: ResetIcon,
  save: SaveIcon,
  commission: CommissionIcon,
};

const C = ({ handleClick, className, svg, code, disabled, loading, label, type, position = 'top center', ...props }) => {
  return (
    <div className={cx(Styles.root, className)}>
      <Popup
        content={label}
        disabled={!label}
        position={position}
        trigger={
          svg
            ? <div className={Styles.svg} dangerouslySetInnerHTML={{ __html: svg }} onClick={handleClick} />
            : (
              <Button
                {...props}
                className={Styles[code]}
                disabled={disabled}
                loading={loading}
                type={type}
                onClick={handleClick}
              >
                <div className={Styles.icon} dangerouslySetInnerHTML={{ __html: icons[code] }} />
              </Button>
            )
        }
      />
    </div>
  );
};

C.defaultProps = {
  handleClick: () => {},
  className: null,
  code: 'add',
  disabled: false,
  loading: false,
  label: false,
  type: 'button',
};

C.propTypes = {
  code: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
};

C.displayName = 'IconButton';
export const IconButton = memo(C);
