import BaseApiClass from 'api/BaseApiClass';

export const getFields = (token, params = {}) => new BaseApiClass({
  url: 'tf/fields',
  method: 'GET',
  ...params,
})._load({ token });

export const createDetailFieldTf = (token, params = {}) => new BaseApiClass({
  url: 'tf/fields',
  method: 'POST',
  ...params,
})._load({ token });

export const updateDetailFieldTf = (token, params = {}) => new BaseApiClass({
  url: 'tf/fields/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteDetailFieldTf = (token, params = {}) => new BaseApiClass({
  url: 'tf/fields/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getIpassportFieldSet = (token, params = {}) => new BaseApiClass({
  url: 'clients/ipassport/fieldset',
  method: 'GET',
  ...params,
})._load({ token });
