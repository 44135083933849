import * as R from 'ramda';
import { normalizeOffer } from '@otpusk/json-api/dist/normalize/normalizers';
import moment from 'moment/moment';
import { generatePath } from 'react-router-dom';

import book from 'routes/book';

import { LOCALES } from 'localization/constants';

const generateClaimDesc = ({ age_childs: children, date_start: dateStart, location_label: location, client, country, adults }) => R.call(
  R.pipe(
    R.filter(Boolean),
    R.join(', ')
  ),
  [
    `${client.name} ›${country?.name || ''}${adults}+${children.length}`,
    `${dateStart ? moment(dateStart).format('DD MMM YYYY') : ''}`,
    location
  ]
);

export const claimMapper = claim => R.mergeAll([
  claim,
  {
    claimLink: `${generatePath(book.clientClaim.children.claim, { clientId: claim.client.id, claimId: claim.id })}?active_step=tour`,
    claimDesc: generateClaimDesc(claim),
  }
]);

const createOfferEntityFromBasketOffer = ({ data, id, comment, price_edited: editedPrice }) => ({
  id,
  comment,
  offer: normalizeOffer(data),
  editedPrice
});
const PL_LOCALE = 'pl_PL';

const convertLocale = R.cond([
  [R.equals(LOCALES.PL), R.always(PL_LOCALE)],
  [R.T, locale => locale]
]);

export const basketNormalizer = R.pipe(
  R.over(R.lensProp('offers'), R.map(createOfferEntityFromBasketOffer)),
  ({ claims = [], template, url_type: offersReviewsLink, locale, ...rest }) => R.mergeAll([
    rest,
    {
      claim: R.pipe(
        R.head,
        R.when(Boolean, claimMapper),
        R.defaultTo(null)
      )(claims),
      template: template ?? 'default',
      offersReviewsLink,
      locale: convertLocale(locale)
    }
  ])
);

export const basketsMapper = baskets => R.map(basketNormalizer, baskets);

export const prepareOfferForUpdate = ({ editedPrice, ...rest }) => ({
  ...rest,
  ...editedPrice === undefined ? {} : { price_edited: editedPrice }
});
