import { createActions } from 'redux-actions';

export const epassportsActions = createActions({
  CREATE_EPASSPORT: (passport, tempId, claimId, workerOptions) => (
    { passport, tempId, claimId, workerOptions }
  ),
  CREATE_EPASSPORT_SUCCESS: (passport, tempId) => ({ passport, tempId }),
  CREATE_EPASSPORT_FAIL: [error => error, (...[, processedError]) => processedError],

  CREATE_EPASSPORT_BY_FORMIK: (passport, tempId, claimId, formik) => (
    { passport, tempId, claimId, formik }
  ),

  CREATE_EMPTY_EPASSPORT: external => external,

  DELETE_EPASSPORT: (id, workerOptions) => ({ id, workerOptions }),
  DELETE_EPASSPORT_SUCCESS: id => id,
  DELETE_EPASSPORT_FAIL: [error => error, (...[, processedError]) => processedError],

  DELETE_EPASSPORT_BY_FORMIK: (id, formik) => ({ id, formik }),

  DELETE_FRESH_EPASSPORT: tempId => tempId,

  UPDATE_EPASSPORT: (passport, id, claimId, workerOptions) => (
    { passport, id, claimId, workerOptions }
  ),
  UPDATE_EPASSPORT_SUCCESS: (passport, id) => ({ passport, id }),
  UPDATE_EPASSPORT_FAIL: [error => error, (...[, processedError]) => processedError],

  UPDATE_EPASSPORT_BY_FORMIK: (passport, id, claimId, formik) => (
    { passport, id, claimId, formik }
  ),

  RECOGNIZE_EPASSPORT: (image, claimId, values, formik, passportFormik) => ({
    image,
    claimId,
    values,
    formik,
    passportFormik
  }),
  RECOGNIZE_EPASSPORT_SUCCESS: passport => passport,
  RECOGNIZE_EPASSPORT_FAIL: error => error,
}, { prefix: 'MT_CLIENT_CLAIM_EPASSPORTS' });
