import React, { memo } from 'react';
import moment from 'moment';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import book from 'routes/book';

import { getUserTfCurator, getIsAuth } from 'bus/auth/selectors';

import { getCrmTypes } from 'crms/utils';
import { useCrmNameWithTranslate } from 'crms/hooks';

import VibrIcon from 'assets/icons/viber.svg';
import TelegramIcon from 'assets/icons/telegram.svg';
import PhoneIcon from './img/deskphone.svg';
import starIcon from './img/starIcon.svg';

import { Avatar, SIZES } from '../../Avatar/Avatar';
import { showConfirmModal } from '../../ConfirmModal';

import { IconButtonNew } from '../../buttons/IconButtonNew';

import { ToUpButton } from './components/ToUpButton';

import styles from './styles.scss';

const ModalContent = ({ photoUrl, telegram, viber, status, phones, username }) => (
  <div className={styles.curatorModalContent}>
    <div className={styles.header}>
      <Avatar src={photoUrl} />
      <div>
        <b>{username}</b>
        <p style={{ marginTop: '7px' }}>Куратор</p>
      </div>
    </div>
    {status && (
      <div className={styles.row}>
        <IconButtonNew icon={starIcon} />
        <div className={styles.status}>
          {status}
        </div>
      </div>
    )}
    {R.map(number => (
      <div className={styles.row} key={number}>
        <IconButtonNew icon={PhoneIcon} />
        <a href={`tel:${number}`}>
          {`${number}`}
        </a>
      </div>
    ), phones)}
    <div className={styles.row}>
      {telegram && (
        <a href={telegram}>
          <IconButtonNew icon={TelegramIcon} />
        </a>
      )}
      {viber && (
        <a href={`viber://chat?number=${viber.replace('+', '')}`}>
          <IconButtonNew icon={VibrIcon} />
        </a>
      )}
    </div>
  </div>
);

const Footer = () => {
  const isAuth = useSelector(getIsAuth);
  const {
    photo_url: photo,
    username,
    phones = [],
    telegram,
    viber,
    status_message: status,
    id
  } = useSelector(getUserTfCurator);

  const { isTat } = getCrmTypes();
  const crm = useCrmNameWithTranslate();

  const { t } = useTranslation('MAIN_PAGE');

  const dispatch = useDispatch();
  const onShowModal = () => {
    dispatch(showConfirmModal({
      props: {
        content: <ModalContent
          photoUrl={photo}
          telegram={telegram}
          viber={viber}
          status={status}
          phones={phones}
          username={username}
        />,
        cancelButton: false,
        confirmButtonText: 'Ok'
      },
    }));
  };

  return (
    <>
      <div className={styles.footerContainer}>
        {isAuth && (
          <div className={styles.footer}>
            <div className={styles.links}>
              <Link to={generatePath(book.news.children.single, { id: 1 })}>
                {t('CONTACTS_AND_SUPPORT')}
              </Link>
              {isTat && (
                <Link to={`${book.news.path}?news_selection=2`}>
                  {t('BOOKING_CONDITIONS')}
                </Link>
              )}
              <span className={styles.copyright}>
                ©
                {moment().format('YYYY')}
                {' '}
                «
                {crm}
                »
              </span>
            </div>
            {id && (
              <a className={styles.curatorBlock} onClick={onShowModal}>
                <Avatar src={photo} size={SIZES.SMALL} />
                <div dangerouslySetInnerHTML={{ __html: t('CURATOR_RENDER', { name: username }) }} />
                {status && <IconButtonNew icon={starIcon} title={status} />}
              </a>
            )}
          </div>
        )}
      </div>
      <ToUpButton />
    </>
  );
};

export default memo(Footer);
