import { select, call, put, takeEvery } from 'redux-saga/effects';

import { getBookingData } from 'bus/booking/selectors';
import { getToken } from 'bus/auth/selectors';
import { withUI } from 'bus/ui/helpers';

import { autoGenerateInvoice } from 'api/methods/accounting';

import { withDefaultNotifications } from 'services/toaster';

import { bookingActions } from '../../../actions';
import { AUTO_GENERATE_INVOICE_UI } from '../../../constants';

function* autoGenerateInvoiceSaga({ payload: onSuccess, notifications }) {
  const booking = yield select(getBookingData);
  const token = yield select(getToken);

  try {
    const invoice = yield call(
      autoGenerateInvoice,
      token,
      { bodyParams: {
        booking: booking.id,
        is_incoming: false,
      } }
    );

    yield put(bookingActions.autoGenerateInvoiceSuccess(invoice));
    yield put(bookingActions.getBookingInvoices(booking.id));

    if (onSuccess) {
      yield call(onSuccess, invoice);
    }
    notifications?.onShowSuccessMsg();
  } catch (error) {
    const { body } = error.msg;

    error.message = body ? body : 'Ошибка сервера';

    yield put(bookingActions.autoGenerateInvoiceFail(error));
    notifications?.onShowFailMsg(body);
  }
}

export const {
  saga: autoGenerateInvoiceSagaWithUI,
  uiSelector: getAutoGenerateInvoiceUI
} = withUI(
  AUTO_GENERATE_INVOICE_UI,
  [
    bookingActions.autoGenerateInvoiceSuccess,
    bookingActions.autoGenerateInvoiceFail
  ],
  autoGenerateInvoiceSaga
);

export function* autoGenerateRootSaga() {
  yield takeEvery(bookingActions.autoGenerateInvoice, autoGenerateInvoiceSagaWithUI);
  yield takeEvery(bookingActions.autoGenerateInvoiceWithNotify, withDefaultNotifications(autoGenerateInvoiceSagaWithUI));
}
