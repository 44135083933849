import { select, call, put, race, take } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { getIn } from 'immutable';

// instruments
import { detailDocsActions } from 'bus/detailDocs/actions';
import book from 'routes/book';
import { createDoc } from 'api/methods/tf/docs';
import { commonActions } from 'bus/common/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createDocWorker({ payload }) {
  const { values, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    if (values.operator.DONT_SAVE) {
      const { name } = values.operator;

      yield put(commonActions.createOperator({ title: name }, { UI: false }));

      const [success, fail] = yield race([
        take(commonActions.createOperatorSuccess),
        take(commonActions.createOperatorFail)
      ]);

      if (success) {
        values.operator = success.payload.id;
      }

      if (fail) {
        throw fail.payload;
      }
    }

    const doc = yield call(createDoc, token, { bodyParams: values });

    yield put(detailDocsActions.createDocSuccess(doc.id, doc));
    yield put(replace(generatePath(book.details.children.editDoc, { id: doc.id })));
    formik && (yield call(formik.setStatus, { success: true, message: 'Договор успешно создан' }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = 'Активна подписка только на Турсканер';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    yield put(detailDocsActions.createDocFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
