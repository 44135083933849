import { call, select } from 'redux-saga/effects';

import { getOtpuskToken, getUserOrTfLocale } from 'bus/auth/selectors';

import { getBookingServices } from 'api/methods/tourscaner';

import { getLangForOtpuskApi } from './heplers';

export function* getBookingServicesSaga({ payload: { callbacks, currency, offerID } }) {
  try {
    const lang = yield select(getUserOrTfLocale);
    const otpuskToken = yield select(getOtpuskToken);

    const { services } = yield call(getBookingServices, {
      queryParams: {
        access_token: otpuskToken,
        currencyLocal: currency,
        lang: getLangForOtpuskApi(lang)
      },
      pathParams: { offerID }
    });

    callbacks?.onSuccess(services);
  } catch (error) {
    callbacks?.onFail(error);
  }
}
