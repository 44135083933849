import BaseApiClass from 'api/BaseApiClass';

export const createAccessCookie = token => {
  const instance = new BaseApiClass({
    url: 'oneTimeCookieAuthToken',
    method: 'GET',
    successCode: 204,
  });

  return instance._load({ token });
};
