import { call, select, put } from 'redux-saga/effects';

import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';
import { bookingActions } from 'bus/booking/actions.js';
import { getBookingId } from 'bus/booking/selectors';

import { addDocuments } from 'api/methods/booking';

import { ADD_BOOKING_DOCUMENTS_UI } from './constants';

function* addDocumentsSaga({ payload: documents }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  try {
    const bodyParams = new FormData();

    for (const document of documents) {
      bodyParams.append('files[]', document.file);
    }

    const files = yield call(
      addDocuments,
      token,
      { pathParams: { bookingId }, bodyParams }
    );

    yield put(bookingActions.addDocumentsSuccess(files));
  } catch (error) {
    const { message } = error.msg.body;

    error.message = message ?? 'Ошибка сервера';

    yield put(bookingActions.addDocumentsFail(error));
  }
}

export const addDocumentsSagaWithUI = withUIWorker(
  [
    bookingActions.addDocumentsSuccess,
    bookingActions.addDocumentsFail
  ],
  ADD_BOOKING_DOCUMENTS_UI
)(addDocumentsSaga);
