import { put, call, select } from 'redux-saga/effects';
import { get, getIn } from 'immutable';
import { generatePath } from 'react-router-dom';
import { push } from 'connected-react-router';

// instruments
import { createBooking } from 'api/methods/booking';
import { parseErrorsByFormik } from 'api/fn/parseErrors';
import book from 'routes/book';

const valuesMapper = ({ op_claim_id: id, ...rest }) => ({
  ...rest,
  op_claim_id: id ? id.trim() : '',
});

export function* createBookingWorker({ payload }) {
  const { values, formik } = payload;

  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  try {
    const booking = yield call(createBooking, token, { bodyParams: valuesMapper(values) });

    yield put(push(generatePath(book.adminBooking.children.booking, { id: booking.id })));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        const violations = getIn(body, ['validation', 'violations'], []);

        if (violations) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Контрагент удалён, операция невозможна');
        } else {
          message = 'Контрагент удалён, операция невозможна';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Турфирмы не существует');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield call(formik.setStatus, { error: true, message });
  } finally {
    yield call(formik.setSubmitting, false);
  }
}
