import { createActions } from 'redux-actions';

export const searchClientAndClaimActions = createActions({
  SEARCH: () => undefined,
  SEARCH_SUCCESS: (result, total) => ({ result, total }),
  SEARCH_FAIL: error => error,

  searchDirectorStat: () => undefined,
  searchSummary: () => undefined,
  searchSummarySuccess: searchSummary => searchSummary,
  searchSummaryFail: error => error,

  SET_QUERY: query => query,

  SET_FRESH_QUERY: query => query,
  RESET_FRESH_QUERY: () => null,

  SET_REMEMBER_QUERY: query => query,
  RESET_REMEMBER_QUERY: () => null,

  SET_REMEMBER_PAGE: page => page,
  RESET_REMEMBER_PAGE: () => null,

  SET_PAGE: page => page,
  SET_ORDER: order => order,
  SET_TEMPLATE: template => template,
}, { prefix: 'MT_SEARCH_CLIENT_AND_CLAIM' });
