import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { authActions } from 'bus/auth/actions';

import { GROUPS, ROOT_KEY } from 'helpers/storage';

const TOKEN_PATH = 'token';

export const useAutoLogOutFromOtherTab = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogOut = ({ key, newValue, oldValue }) => {
      if (key === ROOT_KEY && newValue && oldValue) {
        if (R.path([GROUPS.auth, TOKEN_PATH], JSON.parse(oldValue)) !== R.path([GROUPS.auth, TOKEN_PATH], JSON.parse(newValue))) {
          dispatch(authActions.logOut(false));
        }
      }
    };

    window.addEventListener('storage', handleLogOut);

    return () => {
      window.removeEventListener('storage', handleLogOut);
    };
  }, [dispatch]);
};
