import BaseApiClass from 'api/BaseApiClass';

export const createDoneMarkerStep = (token, params) => new BaseApiClass({
  url: 'claims/{:claimID}/steps/{:stepID}/done',
  method: 'POST',
  ...params,
})._load({ token });

export const deleteDoneMarkerStep = (token, params) => new BaseApiClass({
  url: 'claims/{:claimID}/steps/{:stepID}/done',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
