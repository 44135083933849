import { select, call } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { removeClaimFromBasket } from 'api/methods/tourscaner/baskets';

export function* removeClaimFromBasketWorker({ payload }) {
  const { callbacks, claimID, basketID } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const userID = yield select(getUserId);

  try {
    yield call(
      removeClaimFromBasket,
      token,
      { pathParams: { userID, basketID, claimID } }
    );

    onSuccess && onSuccess();
  } catch (error) {
    __DEV__ && console.error(error);
    onFail && onFail(error);
  }
}
