import { useEffect } from 'react';
import { Map } from 'immutable';

export const useScript = (src, async, onLoad = () => undefined, options = {}) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = src;
    script.async = async;
    script.onload = onLoad;
    script.onerror = () => console.log('error');

    Map(options).forEach((value, key) => {
      script[key] = value;
    });

    document.head.append(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
