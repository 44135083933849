import { put, race, take, select, all, call } from 'redux-saga/effects';
import { hotelsActions } from '@otpusk/apisearch-toolbox/dist/hotels';
import { geoActions } from '@otpusk/apisearch-toolbox/dist/geo';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { getSearchLocation } from 'api/methods/common/getSearchLocation';

export function* combineClaimWithSearchWorker({ payload }) {
  const { search } = payload;
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, meta: {} },
    path: ['claims', 'combineClaimWithSearch'],
  }));

  const IS_SINGLE_HOTEL = search.th.length === 1;
  const IS_SINGLE_CITY = search.tc.length === 1;
  const countryId = search.i;
  const cityId = search.tc[0];
  const hotelId = search.th[0];

  const isSetHotel = yield select(({ hotels }) => hotels.hasIn(['store', hotelId]));
  const isSetCities = yield select(({ geo }) => geo.hasIn(['cities', countryId]));
  const isSetStaticLists = yield select(({ clientClaim }) => clientClaim.hasIn(['staticLists', 'size']));

  !isSetHotel && IS_SINGLE_HOTEL && (yield put(hotelsActions.getHotel(hotelId)));
  !isSetCities && IS_SINGLE_CITY && (yield put(geoActions.getCities(countryId)));
  !isSetStaticLists && (yield put(clientClaimActions.getStaticLists()));

  try {
    const resultsFetching = yield all([
      !isSetHotel && IS_SINGLE_HOTEL && race([
        take(hotelsActions.getHotelSuccess),
        take(hotelsActions.getHotelFail)
      ]),
      !isSetCities && IS_SINGLE_CITY && race([
        take(geoActions.getCitiesSuccess),
        take(geoActions.getCitiesFail)
      ]),
      !isSetStaticLists && race([
        take(clientClaimActions.getStaticListsSuccess),
        take(clientClaimActions.getStaticListsFail)
      ])
    ]);

    const [
      resultFetchingHotel,
      resultFetchingCities,
      resultFetchingStaticLists
    ] = resultsFetching;

    if (resultFetchingHotel) {
      const [, fail] = resultFetchingHotel;

      if (fail) {
        throw new Error('Ошибка при загрузке отеля');
      }
    }

    if (resultFetchingCities) {
      const [, fail] = resultFetchingCities;

      if (fail) {
        throw new Error('Ошибка при загрузке городов');
      }
    }

    if (resultFetchingStaticLists) {
      const [, fail] = resultFetchingStaticLists;

      if (fail) {
        throw new Error('Ошибка при загрузке валюты');
      }
    }

    try {
      if (IS_SINGLE_HOTEL) {
        const hotelName = yield select(({ hotels }) => hotels.getIn(['store', String(hotelId), 'name']));
        const response = yield call(getSearchLocation, token, { queryParams: { term: hotelName } });
        const isSetOtpuskHotelInMt = get(response, 'hotels', []).find(hotel => Number(hotel.id) === Number(hotelId));

        yield put(uiActions.changeUiLoaderFlag({
          status: Boolean(isSetOtpuskHotelInMt),
          path: ['claims', 'combineClaimWithSearch', 'meta', 'isSetOtpuskHotelInMt'],
        }));
      }
    } catch (error) {
      if (error) {
        throw new Error('Ошибка при загрузке отеля');
      }
    }

    const { pc: offerCurrency } = search;
    const currencyList = yield select(({ clientClaim }) => clientClaim.getIn(['staticLists', 'currencies'], []));

    yield put(uiActions.changeUiLoaderFlag({
      status: Boolean(currencyList.find(currency => currency.value === offerCurrency.replace('uah', 'hrn'))),
      path: ['claims', 'combineClaimWithSearch', 'meta', 'isSetCurrencyOtpuskInMt'],
    }));

    if (IS_SINGLE_CITY) {
      const cities = yield select(({ geo }) => geo.getIn(['cities', countryId], []));

      yield put(uiActions.changeUiLoaderFlag({
        status: Boolean(cities.find(city => Number(city.id) === Number(cityId))),
        path: ['claims', 'combineClaimWithSearch', 'meta', 'isSetCityOtpuskInMt'],
      }));
    }
  } catch (error) {
    const { message } = error;

    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['claims', 'combineClaimWithSearch', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['claims', 'combineClaimWithSearch', 'message'],
    }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['claims', 'combineClaimWithSearch', 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['claims', 'combineClaimWithSearch', 'completed'],
    }));
  }
}
