import { call, put, race, take } from 'redux-saga/effects';
import TagManager from 'react-gtm-module';
import * as R from 'ramda';
import ReactPixel from 'react-facebook-pixel';

import { authActions as actions } from 'bus/auth/actions';

import { register } from 'api/methods/auth/user';

import { setGroupValue, GROUPS } from 'helpers/storage';

import { CRM_TYPES } from 'crms/constants';

import { prepareUtmSearchParam } from '../../helpers';

export function* registerWorker({ payload: user }) {
  try {
    const utmSearchParams = yield call(
      prepareUtmSearchParam,
      new URLSearchParams(global.window.location.search).entries()
    );

    const { token } = yield call(register, { bodyParams: R.mergeAll([user, utmSearchParams]) });

    if (__CRM__ === CRM_TYPES.tat) {
      yield call(ReactPixel.track, 'CompleteRegistration');
    }

    !__DEV__ && (yield call(
      TagManager.dataLayer,
      {
        dataLayer: { event: 'registration' },
        dataLayerName: 'dataLayer',
      }
    ));

    yield call(setGroupValue, GROUPS.auth, { token, remember: false });
    yield put(actions.registerSuccess(token));
    yield put(actions.fetchMyself());
    yield race([
      take(actions.fetchMyselfSuccess),
      take(actions.logOut)
    ]);
  } catch (error) {
    yield put(actions.registerFail(error));
  }
}
