import { call, select, put } from 'redux-saga/effects';

// instruments
import { getDefaultValueNotifyByType } from 'api/methods/tf/notify';
import { uiActions } from 'bus/ui/actions';
import { notifyActions } from 'bus/notify';

export function* getDefaultNotifyTemplateWorker({ payload }) {
  const { type, locale, formik } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const tfID = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  const status = { loading: false, error: false, message: null };

  status.loading = true;
  yield call(formik.setStatus, { defaultTemplate: status });

  try {
    const response = yield call(
      getDefaultValueNotifyByType,
      token,
      { pathParams: { tfID, type }, queryParams: locale && { locale } }
    );

    yield call(formik.setFieldValue, 'text', response.text);
    yield call(formik.setFieldValue, 'title', response.title);
  } catch (error) {
    status.error = true;
    status.message = 'Ошибка сервера';
  } finally {
    status.loading = false;
    yield call(formik.setStatus, { defaultTemplate: status });
  }
}

export function* getDefaultNotifyValueWorker({ payload }) {
  const { type } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const tfID = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['notify', 'defaultNotify'],
  }));

  try {
    const notify = yield call(getDefaultValueNotifyByType, token, { pathParams: { tfID, type } });

    yield put(notifyActions.getDefaultNotifyValueSuccess(notify));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['notify', 'defaultNotify', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['notify', 'defaultNotify', 'message'],
    }));
    yield put(notifyActions.getDefaultNotifyValueFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['notify', 'defaultNotify', 'loading'],
    }));
  }
}
