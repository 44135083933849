import React, { useCallback, useMemo } from 'react';
import { useDispatch, useStore } from 'react-redux';
import T from 'prop-types';
import * as R from 'ramda';

import { close, destroy } from './store/actions';

const withModalApi = Component => {
  const WithModalApi = ({ mapActions2Store, modalKey, props, onClose }) => {
    const dispatch = useDispatch();
    const { getState } = useStore();

    const handleClose = useCallback(
      (...args) => {
        dispatch(close(modalKey));
        dispatch(destroy(modalKey));
        onClose(dispatch, getState, ...args);
      },
      [dispatch, modalKey, onClose, getState]
    );

    const actions = useMemo(
      () => (mapActions2Store
        ? R.call(
          R.pipe(
            R.toPairs,
            R.map(([actionName, action]) => [
              actionName,
              R.partial(action, [dispatch, getState])
            ]
            ),
            R.fromPairs
          ),
          mapActions2Store
        )
        : {}),
      [dispatch, getState, mapActions2Store]
    );

    return (
      <Component
        {...actions}
        {...props}
        onClose={handleClose}
      />
    );
  };

  WithModalApi.propTypes = {
    modalKey: T.string.isRequired,
    mapActions2Store: T.object,
    props: T.object,
    onClose: T.func,
  };

  WithModalApi.defaultProps = {
    props: {},
    onClose: () => {},
  };

  return WithModalApi;
};

export default withModalApi;
