import { select, call, put } from 'redux-saga/effects';

import { updateProfile } from 'api/methods/tf/profile';
import { tfActions } from 'bus/tf/actions';
import { getToken } from 'bus/auth/selectors';

export function* updateTFProfileInfoWorker({ payload: profile }) {
  const token = yield select(getToken);

  try {
    const response = yield call(updateProfile, token, { bodyParams: profile });

    yield put(tfActions.updateTfProfileSuccess(response));
  } catch (error) {
    yield put(tfActions.updateTfProfileFail(error));
  }
}
