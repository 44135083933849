import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { filesActions } from 'bus/files/actions';
import { uiActions } from 'bus/ui/actions';
import { getFiles } from 'api/methods/files/clients';

const BASE_UI_PATH = ['files', 'clients', 'fetch'];

export function* getFilesWorker({ payload: clientID }) {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, clientID],
  }));

  try {
    const files = yield call(getFiles, token, { pathParams: { clientId: clientID } });

    yield put(filesActions.fetchFilesSuccess(clientID, files));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу \'Клиенты\' или к клиенту или активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = 'Клиент не найден';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(filesActions.fetchFilesFail(error));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, clientID, 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, clientID, 'message'],
    }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, clientID, 'loading'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, clientID, 'completed'],
    }));
  }
}
