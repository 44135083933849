import { select, put, call } from 'redux-saga/effects';
import { getIn, get, Map } from 'immutable';

// instruments
import { financeActions } from 'bus/finance/actions';
import { updatePaymentTemplate } from 'api/methods/finance/updatePaymentTemplate';

export function* updatePaymentTemplateWorker({ payload }) {
  const { paymentId, values, formik } = payload;
  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  try {
    yield call(
      updatePaymentTemplate,
      token,
      { pathParams: { paymentId }, bodyParams: { ...values } }
    );

    yield put(financeActions.updatePaymentTemplateSuccess());

    formik && (yield call(formik.setStatus, { success: true, message: 'Платеж изменен' }));
  } catch (error) {
    const { body, status } = error.msg || {};

    let message = null;

    switch (status) {
      case 404: {
        message = 'Заказ или платеж отсутствует';
        break;
      }
      case 403: {
        message = 'Пользователь не имеет доступа к заказу платежа или отсутствует подписка на CRM или отсутствует разрешение на доступ к платежей';
        break;
      }
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const errorsList = Map({ ...get(body, 'violations', []) }).mapEntries(([, value]) => [value.property_path, value.message]);
          const formikError = errorsList.filter((...[, key]) => Boolean(values[key])).toObject();

          message = errorsList.filter((...[, key]) => !values[key]).toList().map(str => str.replace('..', '.')).join('. ');
          formik && (yield call(formik.setErrors, formikError));
        } else {
          message = 'Ошибка сервера';
        }
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(financeActions.updatePaymentTemplateFail(error));
    formik && message && (yield call(formik.setStatus, { error: true, message }));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
