import { put, takeEvery, select, race, take, call } from 'redux-saga/effects';

import { showToastError } from 'services/toaster';

import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getBookingData, getLastBookingInvoice } from 'bus/booking/selectors';
import { accountingActions } from 'bus/accounting/actions';
import { getToken } from 'bus/auth/selectors';
import { bookingActions } from 'bus/booking/actions';

import { createAccessCookie } from 'api/methods/auth/createAccessCookie';
import { downloadAkt } from 'api/methods/accounting';

import { newInstance } from 'localization';

import { generateAkt } from './actions';
import { GENERATE_ACT_UI } from './constants';

function* generateAktSaga() {
  yield put(registerUI(createUi({ loading: true }), GENERATE_ACT_UI));

  const invoice = yield select(getLastBookingInvoice);

  if (invoice) {
    const booking = yield select(getBookingData);

    yield put(accountingActions.sendAkts([invoice.id], false, booking.tour.check_out));

    const [sendAktsSuccess] = yield race([
      take(accountingActions.sendAktsSuccess),
      take(accountingActions.sendAktsFail),
    ]);

    if (sendAktsSuccess) {
      const akt = sendAktsSuccess.payload[0];

      if (akt) {
        yield put(bookingActions.setAktToInvoice(invoice.id, akt));

        const downloadAktEntity = downloadAkt({ pathParams: { id: akt.id } });

        const token = yield select(getToken);
        yield call(createAccessCookie, token);

        console.log(downloadAktEntity.getURL());
        window.open(downloadAktEntity.getURL(), '_blank');
      }
    }
  } else {
    showToastError(newInstance.t('BOOKING_PAGE:GENERATE_ACT_WITHOUT_INVOICE'));
  }

  yield put(updateUI({ completed: true, loading: false }, GENERATE_ACT_UI));
}

export default function* () {
  yield takeEvery(generateAkt, generateAktSaga);
}
