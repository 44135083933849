import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

import { userMapper } from './helpers';

export const updateUser = (token, params = {}) => new BaseApiClass({
  url: 'settings/users/{:id}',
  method: 'PUT',
  ...params,
}).setParseBodyToClientSchema(userMapper)._load({ token });

export const updateUserImage = (token, params = {}) => new BaseApiClass({
  url: 'settings/users/{:userId}/image',
  method: 'POST',
  ...params,
}).setProccessHeaders(R.dissoc('Content-Type'))._load({ token });

export const deleteUserImage = (token, params = {}) => new BaseApiClass({
  url: 'settings/users/{:userId}/image',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const addUser = (token, params = {}) => new BaseApiClass({
  url: 'settings/users',
  method: 'POST',
  ...params,
})._load({ token });

export const getUser = (token, params) => {
  const instance = new BaseApiClass({
    url: 'settings/users/{:id}',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = userMapper;

  return instance._load({ token });
};

export const getUsers = (token, params = {}) => new BaseApiClass({
  url: 'settings/users',
  method: 'GET',
  ...params,
})._load({ token });

export const getDefaultSignTemplate = (token, params = {}) => new BaseApiClass({
  url: 'settings/users/sign/default',
  method: 'GET',
  ...params,
})._load({ token });

export const toggleUserStatus = (token, params = {}) => new BaseApiClass({
  url: 'settings/users/{:id}/toggle',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteUserTelegram = (token, params = {}) => new BaseApiClass({
  url: 'users/{:id}/telegram_data',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const subscribeUserFacebook = (token, params = {}) => new BaseApiClass({
  url: 'settings/users/{:id}/facebook',
  method: 'PUT',
  successCode: 204,
  ...params,
})._load({ token });
