import { put, race, take, spawn } from 'redux-saga/effects';
import * as R from 'ramda';

import { registerUI, updateUI } from './actions';
import { getUI } from './selectors';

export const createUi = (nextUI = {}) => ({
  loading: false,
  completed: false,
  error: false,
  message: null,
  ...nextUI,
});

export const withUIWorker = ([successAction, failAction], uiKey) => worker => function* (action) {
  yield put(registerUI(createUi({ loading: true }), uiKey));

  yield spawn(worker, action);

  const [, failed] = yield race([
    take(successAction),
    take(failAction)
  ]);

  failed && (yield put(updateUI({ error: failed.payload, message: failed.payload.message }, uiKey)));

  yield put(updateUI({
    loading: false,
    completed: true,
  }, uiKey));
};

export const createUiSelector = uiKey => R.partialRight(getUI, [{ key: uiKey }]);

export const withUI = (uiKey, [successAction, failAction], saga) => {
  return {
    saga: withUIWorker([successAction, failAction], uiKey)(saga),
    uiSelector: createUiSelector(uiKey)
  };
};
