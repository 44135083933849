import { takeEvery } from 'redux-saga/effects';

import { mainActions } from '../actions';
import { fetchBirthdaysWorker, fetchStoppedEpassportsWorker, fetchCallsWorker, fetchRemindsWorker, fetchFastStatisticWorker } from './workers';

import { dismissRemindSaga } from './workers/dismissRemind';

export const mainWatchers = Object.freeze({
  * fetchBirthdaysWathcer() {
    yield takeEvery(mainActions.fetchBirthdays, fetchBirthdaysWorker);
  },
  * fetchEpassportsWathcer() {
    yield takeEvery(mainActions.fetchStoppedEpassports, fetchStoppedEpassportsWorker);
  },
  * fetchCallsWathcer() {
    yield takeEvery(mainActions.fetchCalls, fetchCallsWorker);
  },
  * fetchRemindsWathcer() {
    yield takeEvery(mainActions.fetchReminds, fetchRemindsWorker);
  },
  * fetchFastStatistic() {
    yield takeEvery(mainActions.fetchFastStatistic, fetchFastStatisticWorker);
  },
  * dismissRemind() {
    yield takeEvery(mainActions.dismissRemind, dismissRemindSaga);
  },
});
