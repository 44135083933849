import { select, put, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

// instruments
import { uiActions } from 'bus/ui/actions';
import book from 'routes/book';
import { deleteClient } from 'api/methods/client/deleteClient';

export function* deleteClientWorker({ payload: id }) {
  const uiErrorPath = ['clientClaim', 'deleteClient', 'error'];
  const uiLoadingPath = ['clientClaim', 'deleteClient', 'loading'];
  const token = yield select(({ auth }) => auth.get('token'));
  const isSetError = yield select(({ ui }) => ui.getIn(uiErrorPath));

  isSetError && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: uiErrorPath })));
  yield put(uiActions.changeUiLoaderFlag({ status: true, path: uiLoadingPath }));

  try {
    yield call(deleteClient, token, { pathParams: { id } });
    yield put(replace(book.client.path));
  } catch (error) {
    const { status } = error.msg;
    const errorMeta = { msg: null };

    switch (status) {
      case 403: {
        errorMeta.msg = 'Отсутвует доступ к клиенту или к удалению клиента';
        break;
      }
      case 404: {
        errorMeta.msg = 'Клиент не найден';
        break;
      }
      default: errorMeta.msg = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: errorMeta, path: uiErrorPath }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: uiLoadingPath }));
  }
}
