import { call, put, select } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getDepartments } from 'api/methods/common/getDepartments';

export function* getDepartmentsWorker() {
  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'fetchDepartments'] }));

  try {
    const departments = yield call(getDepartments, token, { pathParams: { tf } });

    yield put(commonActions.getDepartmentsSuccess(departments));
  } catch (error) {
    yield put(commonActions.getDepartmentsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'fetchDepartments'] }));
  }
}
