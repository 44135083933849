import { put, call, select } from 'redux-saga/effects';

// actions
import { filesActions } from 'bus/files/actions';

// instruments
import { getStatusJobs } from 'api/methods/files';

export function* getStatusJobsWorker({ payload: id }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const status = yield call(getStatusJobs, token, { pathParams: { id } });

    yield put(filesActions.getStatusJobsSuccess(status));
  } catch (error) {
    yield put(filesActions.getStatusJobsFail(error));
  }
}
