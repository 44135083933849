import { Map } from 'immutable';
import { handleActions } from 'redux-actions';

import { uiActions as actions, registerUI, updateUI, resetUI } from './actions';

const initialState = Map({
  // ts
  saveOfferToClaim: {},
  auth: {
    logout: { loading: false },
    registerCode: { loading: false, error: false, success: false, completed: false, message: '' },
  },
  common: Map({
    fetchOperators: false,
    createOperators: { loading: false, error: false, completed: false, message: '' },
    fetchManagers: false,
    fetchDepartments: false,
    tags: { loading: false, error: false, completed: false, message: '' },
    offices: { loading: false, error: false, completed: false, message: '' },
    regions: { loading: false, error: false, completed: false, message: '' },
    details: { loading: false, error: false, completed: false, message: '' },
    createDetail: {},
    deleteDetail: {},
    updateDetail: {},
    deleteDetailStamp: {},
    fetchDocsByOperators: false,
    ipassportFieldSet: { loading: false },
    patterns: { loading: false, error: false, message: null },
    roles: { loading: false, error: false, message: null },
    notificationTypes: { loading: false, error: false, completed: false, message: '' },
    countries: { loading: false, error: false, completed: false, message: '' },
    rooms: {
      create: { loading: false, error: false, completed: false, message: null },
    },
    uniqueActiveEpassports: {
      fetch: {},
    },
    smsPrices: { loading: false, error: false, completed: false, message: null },
    creditCoeffs: { loading: false, error: false, message: null, completed: false },
    claimSource: {
      get: { loading: false, error: false, message: null, completed: false },
      update: {},
    },
  }),
  main: Map({
    birthdays: { loading: false, error: false, success: false, message: null },
    stoppedEpassports: { loading: false, error: false, success: false, message: null },
    calls: { loading: false, error: false, completed: false, message: null },
    reminds: { loading: false, error: false, success: false, message: null },
    fastStatistic: { loading: false, error: false, completed: false, message: null },
  }),
  tf: Map({
    user: { loading: false, error: false, completed: false, message: null },
    users: { loading: false, error: false, completed: false, message: null },
    defaultSingTemplate: { loading: false, error: false, completed: false, message: null },
    loadingToggleStatus: Map(),
    loadingRolesList: false,
    fetchProfile: { loading: false, error: false, completed: false, message: null },
    fetchFields: false,
    deleteTags: Map(),
    createTags: Map(),
    updateTags: Map(),
    deleteOffices: Map(),
    createOffices: Map(),
    updateOffices: Map(),
    deleteRegions: Map(),
    createRegions: Map(),
    updateRegions: Map(),
    deleteTfImage: { loading: false, error: false, completed: false, message: null },
    operatorCredentials: {
      getCredentials: { loading: false, error: false, completed: false, message: null },
      updateCredential: {},
      deleteCredential: {},
    },
    apiKeys: {
      get: { loading: false, error: false, completed: false, message: null },
      create: { loading: false, error: false, completed: false, message: null },
      delete: {},
      send: {},
    },
    distribution: { loading: false, error: false, completed: false, message: null },
    commission: { loading: false, error: false, completed: false, message: null },
    merchants: { loading: false, error: false, completed: false, message: null },
  }),
  notifyEditWindow: false,
  loadingNotificationList: false,
  loadingTfScenariosList: false,
  loadingCountries: false,
  detailDocs: Map({
    fetchDocs: { loading: false, error: false, completed: false, message: null },
    fetchDoc: {},
    toggleDocs: Map(),
    deleteDocs: Map(),
    fetchDocVariables: false,
    fetchExtraDoc: false,
    fetchGuarantees: { error: false, success: false },
  }),
  searchClientAndClaim: Map({ loading: false, error: false, completed: false, message: null }),
  searchClientLead: { loading: false, error: false, completed: false, message: null },
  detailFields: Map({
    fetchFields: false,
    fields: {
      create: {},
      delete: {},
      update: {},
    },
    errorAfterUpdate: false,
  }),
  documentFields: {
    fetchFields: { loading: false, error: false, message: null },
  },
  claims: Map({
    countLoading: false,
    statusList: { loading: false, error: false, message: null },
    combineClaimWithOffer: { loading: false, error: false, message: null, meta: null },
    combineClaimWithOffers: { loading: false, error: false, message: null, meta: null },
    combineClaimWithSearch: { loading: false, error: false, message: null, meta: null },
  }),
  clientClaim: Map({
    claim: {
      update: {},
      dateCreated: {},
    },
    fetchClient: false,
    deleteClient: { loading: false, error: false },
    fetchClaim: { error: false, loading: false },
    fetchClientError: false,
    fetchClientField: true,
    fetchClientPassports: false,
    fetchClaimBonus: { loading: false, error: false, message: null },
    updateClaimBonus: { loading: false, error: false, success: false, message: null },
    updateClient: false,
    generateLink: false,
    epassports: {
      create: {},
      update: {},
      delete: {},
    },
    searchList: {
      delete: {},
    },
    offerList: {
      delete: {},
    },
    setTouchedClaim: {},
    deletePassport: Map({}),
    updatePassport: Map({}),
    staticLists: { loading: false, error: false, success: false, message: null },
    prepareContractClaimToAction: {},
    fetchClaimComments: { loading: false, error: false, message: null },
    comments: {
      create: { loading: false, error: false, completed: false, message: null },
      update: { loading: false, error: false, completed: false, message: null },
      dismiss: {},
      delete: {},
    },
    toggleActiveClaim: { loading: false, error: false, message: null },
    deleteClaim: { loading: false, error: false, message: null },
    deleteClaimBooking: { loading: false, error: false, message: null, completed: false },
    stepers: {
      toggle: {},
    },
    digitalContract: {
      list: { loading: false, error: false, completed: false, message: null },
      item: { loading: false, error: false, completed: false, message: null },
      link: { loading: false, error: false, completed: false, message: null },
      fetchText: { loading: false, error: false, completed: false, message: null },
      delete: {},
    },
  }),
  files: {
    clients: {
      fetch: {},
      create: {},
      update: {},
      delete: {},
    },
    showUploadModal: false,
    updateFile: false,
    downloadFileId: { loading: false, error: false, message: null },
    financeJob: { loading: false, error: false, message: null },
    bookingJob: { loading: false, error: false, message: null },
  },
  finance: Map({
    payment: {},
    fetchPaymentsFail: false,
    updatePayment: false,
    hold: { loading: false, error: false, message: null, completed: false },
    paymentTemplate: { loading: false, error: false, message: null, completed: false },
  }),
  statistic: Map({
    searchFlexibleReport: { loading: false, error: false, message: null, completed: false },
    calls: { loading: false, error: false, message: null, completed: false },
    logs: { loading: false, error: false, message: null, completed: false },
    bonuses: { loading: false, error: false, message: null, completed: false },
  }),
  templates: Map({
    getTemplates: { loading: true, error: false, message: null, completed: false },
    fetchTemplate: { loading: false, error: false, message: null, completed: false },
    getDefaultTemplate: { loading: false, error: false, message: null, completed: false },
    deleteTemplate: Map(),
    templateVariables: { loading: false, error: false, message: null, completed: false },
  }),
  notify: Map({
    types: { loading: false, error: false, message: null, completed: false },
    notifies: { loading: false, error: false, message: null, completed: false },
    updateNotify: {},
    defaultNotify: { loading: false, error: false, message: null },
    prepareNotify: {},
  }),
  tourscaner: Map({
    history: { loading: false, error: false, message: null, completed: false },
    publicUrls: {},
    tourInfo: { loading: false, error: false, message: null, completed: false },
  }),
  news: Map({
    many: { loading: false, error: false, message: null, completed: false },
    single: { loading: false, error: false, message: null, completed: false },
    viewed: {},
  }),
  subscription: {
    notifyByEnding: { visible: false },
    notifyByLimitUsers: { visible: false },
    notifyAddLimitUsers: { visible: false },
    fetch: { loading: false, error: false, message: null, completed: false },
    balances: { fetch: { loading: false, error: false, message: null, completed: false } },
    amount: { fetch: { loading: false, error: false, message: null, completed: false } },
    invoices: { fetch: { loading: false, error: false, message: null, completed: false } },
    balanceInfo: { loading: false, error: false, message: null, completed: false },
  },
  oneTimeAccess: {},
  editor: {
    filebrowser: { loading: false, error: false, message: null, completed: false },
    deleteFileBrowser: {},
  },
  services: {
    facebook: {
      initialized: false,
      auth: { loading: false, error: false, success: false, message: null, completed: false },
    },
  },
  marketing: {
    mailings: { loading: false, error: false, message: null, completed: false },
    getMailing: { loading: false, error: false, message: null, completed: false },
    delete: {},
    refresh: {},
    subscribers: {},
    sendTest: { loading: false, error: false, message: null, completed: false },
    sendMailing: { loading: false, error: false, message: null, completed: false },
    sendMailingUpdate: {},
    newSubscribers: {},
    mailsOffers: { loading: false, error: false, message: null, completed: false },
  },
  calendar: {
    events: { loading: true, error: false, message: null, completed: false },
    event: { loading: false, error: false, message: null, completed: false },
    deleteEvent: { loading: false, error: false, message: null, completed: false },
  },
  dump: {
    import: {
      job: { loading: false, error: false, message: null, completed: false },
      analyze: { loading: false, error: false, message: null, completed: false },
      run: { loading: false, error: false, message: null, completed: false },
      runResult: { loading: false, error: false, message: null, completed: false },
    },
  },
  booking: {
    search: { loading: false, error: false, message: null, completed: false },
    getBookings: { loading: false, error: false, message: null, completed: false },
    getBooking: { loading: false, error: false, message: null, completed: false },
    getBookingsStat: { loading: false, error: false, message: null, completed: false },
    managingBooking: { loading: false, error: false, message: null, completed: false, link: null },
    bookingChanges: { loading: false, error: false, message: null, completed: false },
    bookingMessages: { loading: false, error: false, message: null, completed: false },
    messagesCountUnread: { loading: false, error: false, message: null, completed: false },
    payments: { loading: false, error: false, message: null, completed: false },
    invoices: { loading: false, error: false, message: null, completed: false },
    schedules: {
      get: { loading: false, error: false, message: null, completed: false },
      delete: {},
    },
    refreshBooking: { loading: false, error: false, message: null, completed: false, success: false },
    saveBooking: { loading: false, error: false, message: null, completed: false, success: false },
    deleteMessage: {},
    deleteServices: {},
    deleteTourists: {},
    delete: {},
  },
  accounting: {
    search: { loading: false, error: false, message: null, completed: false },
    payment: {
      get: { loading: false, error: false, message: null, completed: false },
      delete: {},
    },
    invoice: {
      get: { loading: false, error: false, message: null, completed: false },
      getDefault: { loading: false, error: false, message: null, completed: false },
      getFranchise: { loading: false, error: false, message: null, completed: false },
      delete: {},
    },
    accounts: { loading: false, error: false, message: null, completed: false },
    counterparties: { loading: false, error: false, message: null, completed: false },
    cashFlows: { loading: false, error: false, message: null, completed: false },
    organizations: { loading: false, error: false, message: null, completed: false },
    contracts: { loading: false, error: false, message: null, completed: false },
    invoiceBase: { loading: false, error: false, message: null, completed: false },
  },

});

export default handleActions({
  [actions.changeUiLoaderFlag]: (state, { payload }) => {
    const { path, status } = payload;

    return state.setIn(path, status);
  },
  [registerUI]: (state, { payload }) => {
    const { baseUI, path } = payload;

    return state.setIn(path, baseUI);
  },
  [updateUI]: (state, { payload }) => {
    const { nextUI, path } = payload;

    return state.mergeIn(path, nextUI);
  },
  [resetUI]: (state, { payload }) => {
    const { nextUI, path } = payload;

    return state.setIn(path, nextUI);
  },
}, initialState);
