import { put, call, select } from 'redux-saga/effects';
import { getToken } from 'bus/auth/selectors';

import { registerUI, updateUI } from 'bus/ui/actions.js';
import { createUi } from 'bus/ui/helpers.js';

import { addBookingView } from 'api/methods/booking';

import { GET_BOOKING_VIEWS_US } from '../../constants';
import { bookingActions } from '../../actions';

export function* getBookingViewsSaga({ payload: bookingId }) {
  const token = yield select(getToken);

  yield put(registerUI(createUi({ loading: true }), GET_BOOKING_VIEWS_US));

  try {
    const users = yield call(
      addBookingView,
      token,
      { pathParams: { bookingId } }
    );

    yield put(bookingActions.setBookingViews(users));
  } catch (error) {
    yield put(updateUI({ error: true }, GET_BOOKING_VIEWS_US));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, GET_BOOKING_VIEWS_US));
  }
}
