import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { MyError } from 'api/fn/Error';
import { uiActions } from 'bus/ui/actions';
import { updateClaimSource } from 'api/methods/common/claimSource';
import { commonActions as actions } from 'bus/common/actions';

const BASE_UI_PATH = ['common', 'claimSource', 'update'];

export function* updateClaimSourceWorker({ payload }) {
  const { source, formik } = payload;
  const { token, sources } = yield select(({ auth, common }) => ({
    token: auth.get('token'),
    sources: common.get('claimSource'),
  }));

  const { values } = formik;
  const { id, description } = source;

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: [...BASE_UI_PATH, id],
  }));

  try {
    const isDuplicate = sources.find(item => item.description.trim() === description.trim());

    if (isDuplicate && isDuplicate.id !== id) {
      const index = values.options.findIndex(item => item.id === id);

      throw new MyError({ status: 400, body: { path: `options.${index}.description`, message: 'Название не уникальное' } });
    }

    const response = yield call(
      updateClaimSource,
      token,
      { pathParams: { id }, bodyParams: { description } }
    );

    yield put(actions.updateClaimSourceSuccess(response));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        if (body.path) {
          yield call(formik.setFieldError, body.path, body.message);
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, id, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, id, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, id, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, id, 'completed'] }));
  }
}
