import BaseApiClass from 'api/BaseApiClass';
import { compose } from 'redux';

// instruments
import { removeNull, removeEmptyString } from 'helpers';
import { createQueryFromApi } from '../helpers/createQuery';

export const getClients = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'clients/clients_search',
    method: 'POST',
    ...params,
  });

  instance.convertBodyToServerSchema = body => {
    const { page, ...rest } = body;

    return {
      page, filters: { ...compose(removeNull, removeEmptyString, createQueryFromApi)(rest) },
    };
  };

  return instance._load({ token });
};

export const getClientsPure = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'clients/clients_search',
    method: 'POST',
    ...params,
  });

  return instance._load({ token });
};
