import { takeEvery } from 'redux-saga/effects';

// instruments
import { financeActions as actions } from '../actions';
import { getPaymentsWorker } from './workers/getPayments';
import { getPaymentWorker } from './workers/getPayment';
import { updatePaymentWorker } from './workers/updatePayment';
import { sendPaymentToEmailWorker } from './workers/sendPaymentToEmail';
import { confirmHoldWorker } from './workers/confirmHold';
import { cancelHoldWorker } from './workers/cancelHold';
import { getPaymentTemplateWorker } from './workers/getPaymentTemplate';
import { updatePaymentTemplateWorker } from './workers/updatePaymentTemplate';

export const financeWatchers = Object.freeze({
  * getPaymentsWatcher() {
    yield takeEvery(actions.getPayments, getPaymentsWorker);
  },
  * getPaymentWatcher() {
    yield takeEvery(actions.getPayment, getPaymentWorker);
  },
  * updatePaymentWatcher() {
    yield takeEvery(actions.updatePayment, updatePaymentWorker);
  },
  * sendPaymentToEamilWatcher() {
    yield takeEvery(actions.sendPaymentToEmail, sendPaymentToEmailWorker);
  },
  * confirmHoldWatcher() {
    yield takeEvery(actions.confirmHold, confirmHoldWorker);
  },
  * cancelHoldWatcher() {
    yield takeEvery(actions.cancelHold, cancelHoldWorker);
  },
  * getPaymentTemplateWatcher() {
    yield takeEvery(actions.getPaymentTemplate, getPaymentTemplateWorker);
  },
  * updatePaymentTemplateWatcher() {
    yield takeEvery(actions.updatePaymentTemplate, updatePaymentTemplateWorker);
  },
});
