import { Map } from 'immutable';

// instruments
import BaseApiClass from 'api/BaseApiClass';

export const createBookingOperatorInvoice = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'bookings/{:id}/operator_invoice',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};

export const updateBookingOperatorInvoice = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'bookings/{:bookingId}/operator_invoice/{:id}',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};
