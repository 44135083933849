import { call, put, select, all, fork, spawn } from 'redux-saga/effects';
import moment from 'moment-timezone';
import { Map, List, getIn } from 'immutable';
import uuidv4 from 'uuid/v4';
import * as R from 'ramda';
import { localeBootstrap } from 'localization';

import { authActions as actions } from 'bus/auth/actions';
import { getInfo } from 'api/methods/auth/user';
import { setGroupValue, getStorage, saveSessions, GROUPS } from 'helpers/storage';

import { deleteClaimsSteps, getLasClaimsUpdate, setLastClaimsUpdate } from 'helpers/claim/storage';

import { updateAppNotifySaga } from './updateAppNotifySaga';

const MAX_CLAIMS_HOLD_DAYS = 14;

const getIsNeedResetClaims = lastClaimsUpdate => {
  const daysDifference = moment().diff(moment(lastClaimsUpdate), 'days');

  return daysDifference >= MAX_CLAIMS_HOLD_DAYS;
};

export function* validateClaimStorageSaga() {
  const lastClaimsUpdate = yield call(getLasClaimsUpdate);

  if (!lastClaimsUpdate) {
    yield call(setLastClaimsUpdate, moment().format());

    return;
  }

  if (getIsNeedResetClaims(lastClaimsUpdate)) {
    yield call(deleteClaimsSteps);
    yield call(setLastClaimsUpdate, moment().format());
  }
}

export function* fetchMyselfWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const response = yield call(getInfo, token);

    const { activeUsersCount, locale, user, subscription, collectionsAPI } = response;

    moment.tz.setDefault(user.tf.city.timezone);
    moment.locale(locale);

    yield call(localeBootstrap, locale);

    const profile = Map(user)
      .updateIn(['tf'], ({ scenarios, ...rest }) => {
        const normalizeScenarios = scenarios.map(({ steps, ...scenario }) => ({
          ...scenario,
          steps: steps.map(({ id }) => id),
        }));
        const steps = scenarios.reduce((results, scenario) => {
          return results.merge(...scenario.steps);
        }, List()).toMap().mapEntries(([, step]) => [step.id, step]);

        return {
          ...rest,
          scenarios: normalizeScenarios,
          steps,
        };
      })
      .updateIn(['commission'], commission => {
        if (!commission) {
          return null;
        }

        const { ticket_percent: commissionByTicketPrice, ...rest } = commission;

        return ({
          ...rest,
          commissionByTicketPrice,
        });
      })
      .set('collectionsAPI', collectionsAPI)
      .setIn(['tf', 'subscription'], subscription)
      .setIn(['tf', 'activeUsersCount'], activeUsersCount)
      .setIn(['tf', 'city', 'otpuskRegionId'], user.tf.city.otpusk_region_id)
      .setIn(['tf', 'city', 'country', 'currencyCode'], user.tf.city.country.currency_code.toLowerCase())
      .setIn(['tf', 'subscriptions'], R.map(
        ({ is_active: isActive, ...rest }) => ({ isActive, ...rest }),
        user.tf.subscriptions))
      .deleteIn(['tf', 'city', 'otpusk_region_id'])
      .deleteIn(['tf', 'city', 'country', 'currency_code'])
      .toJS();

    yield all([
      put(actions.setOtpuskToken(getIn(profile, ['tf', 'apikey']))),
      put(actions.setChartersToken(getIn(profile, ['tf', 'apikeycharters']))),
      put(actions.setLocale(locale)),
      put(actions.fetchMyselfSuccess(profile))
    ]);

    const SESSION_ID = uuidv4();
    const prevStorage = yield call(getStorage);
    const session = { id: SESSION_ID, meta: null };
    const sessions = List(prevStorage.get(GROUPS.sessions, []))
      .filter(({ sessionStorage }) => !sessionStorage)
      .push(session).toArray();

    yield call(setGroupValue, GROUPS.sessions, sessions);
    yield call(saveSessions, { ...session, sessionStorage: true });

    yield fork(validateClaimStorageSaga);

    if (__PROD__) {
      yield spawn(updateAppNotifySaga);
    }

    window.addEventListener('beforeunload', () => {
      const prevSessions = getStorage().get(GROUPS.sessions, []);

      setGroupValue(GROUPS.sessions, prevSessions.filter(({ id }) => id !== SESSION_ID));
    });
  } catch (error) {
    yield put(actions.logOut(true));
  }
}
