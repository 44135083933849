import { put, race, take, call } from 'redux-saga/effects';
import { getIn, Map, hasIn } from 'immutable';
import { parseErrorPath } from 'helpers';

// instruements
import { clientClaimActions } from 'bus/clientClaim/actions';

export function* createClientAndClaimWorker({ payload }) {
  const { client, claim, formik, offer, search } = payload;

  yield put(clientClaimActions.createClient(client));

  const { clientResponseSuccess, clientResponseFail } = yield race({
    clientResponseSuccess: take(clientClaimActions.createClientSuccess),
    clientResponseFail: take(clientClaimActions.createClientFail),
  });

  if (clientResponseSuccess) {
    const clientID = clientResponseSuccess.payload.id;

    yield put(
      clientClaimActions.createClaim({ client: clientID, ...claim }, formik, offer, search)
    );

    const [claimResponseSuccess, claimResponseFail] = yield race([
      take(clientClaimActions.createClaimSuccess),
      take(clientClaimActions.createClaimFail)
    ]);

    if (claimResponseSuccess) {
      const meta = {
        client: clientResponseSuccess.payload,
        claim: claimResponseSuccess.payload,
      };

      yield put(clientClaimActions.clearClient());

      formik && (yield call(formik.setStatus, { success: true, meta }));
    }

    if (claimResponseFail) {
      // set errors in create claim worker
      formik && (yield call(formik.setFieldValue, 'CREATED_CLIENT', clientResponseSuccess.payload, false));
    }
  }

  if (clientResponseFail) {
    const clientError = clientResponseFail.payload.msg;
    const { status, body } = clientError || {};

    let errorMsg = null;

    switch (status) {
      case 400: {
        const isSetViolations = getIn(body, ['violations', 'length']);
        const violations = getIn(body, ['violations']);

        if (isSetViolations) {
          const formikErrors = Map({ ...violations })
            .filter(value => {
              const pathToArray = parseErrorPath(value.property_path).split('.');

              return hasIn(client, pathToArray, false);
            }).reduce((results, value) => {
              const pathToArray = parseErrorPath(value.property_path).split('.');

              return results.setIn(pathToArray, value.message);
            }, Map()).toJS();

          formik && (yield call(formik.setErrors, formikErrors));

          const otherErrorList = Map({ ...violations })
            .filter(value => !hasIn(client, parseErrorPath(value.property_path).split('.'), false))
            .map(value => value.message.replace('.', ''))
            .join('. ');

          if (otherErrorList) {
            errorMsg = otherErrorList;
          }
        } else {
          errorMsg = 'Ошибка сервера';
        }

        break;
      }
      case 409: {
        yield put(
          clientClaimActions.setConflictClients(clientResponseFail.payload.msg.body, client)
        );

        break;
      }
      default: errorMsg = 'Ошибка сервера';
    }

    formik && errorMsg && (yield call(formik.setStatus, { error: true, message: errorMsg }));
  }

  formik && (yield call(formik.setFieldValue, 'skip_duplicate_check', false, false));
  formik && (yield call(formik.setSubmitting, false));
}

export function* createClaimByFreshClientWorker({ payload }) {
  const { client, claim, formik, offer } = payload;

  yield put(clientClaimActions.createClaim({ client: client.id, ...claim }, formik, offer));

  const [claimResponseSuccess, claimResponseFail] = yield race([
    take(clientClaimActions.createClaimSuccess),
    take(clientClaimActions.createClaimFail)
  ]);

  if (claimResponseSuccess) {
    const meta = {
      client,
      claim: claimResponseSuccess.payload,
    };

    yield put(clientClaimActions.clearClient());

    formik && (yield call(formik.setStatus, { success: true, meta }));
    formik && (yield call(formik.setFieldValue, 'CREATED_CLIENT', false, false));
  }

  if (claimResponseFail) {
    // set errors in create claim worker
  }

  formik && (yield call(formik.setSubmitting, false));
}
