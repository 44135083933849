import { select, call, put } from 'redux-saga/effects';

import { getToken, getUserId } from 'bus/auth/selectors';

import { getErrorMessage } from 'api/fn/parseErrors';
import { removeFacebook } from 'api/methods/auth/user';

import { authActions } from '../../actions';

export function* removeFacebookSaga() {
  const token = yield select(getToken);
  const user = yield select(getUserId);

  try {
    yield call(removeFacebook, token, { pathParams: { user } });

    yield put(authActions.removeFacebookSuccess());
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
      }
    );

    error.message = message ? message : 'Ошибка сервера';
    yield put(authActions.removeFacebookFail(error));
  }
}
