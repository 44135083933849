import { takeEvery } from 'redux-saga/effects';

// instruments
import { registerWorkerMediator } from './watchers/registerWorkerMediator';
import { validateEmailMediator } from './watchers/validateEmailMediator';
import { registerAction, validateEmailAction } from '../actions';

export const registersWatcher = Object.freeze({
  * registerWorkerWrapper() {
    yield takeEvery(registerAction, registerWorkerMediator);
  },
  * validateEmailWrapper() {
    yield takeEvery(validateEmailAction, validateEmailMediator);
  },
});
