import { takeEvery } from 'redux-saga/effects';

// actions
import { roomsActions } from 'bus/common/rooms';

// workers
import { createRoomBaseWorker } from './workers/createRoomBase';

export const roomsWatchers = Object.freeze({
  * createRoomBaseWatcher() {
    yield takeEvery(roomsActions.createRoom, createRoomBaseWorker);
  },
});
