import { put, call, select } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { accountingActions as actions } from 'bus/accounting/actions';
import { getAccountingOrganizations } from 'api/methods/accounting';

const BASE_UI_PATH = ['accounting', 'organizations'];

export function* getOrganizationsWorker() {
  const { token } = yield select(({ auth }) => ({
    token: auth.get('token'),
  }));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: BASE_UI_PATH,
  }));

  try {
    const payload = yield call(getAccountingOrganizations, token);

    yield put(actions.getOrganizationsSuccess(payload));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = get(body, 'message', 'Доступ отказано');
        break;
      }
      case 404: {
        message = get(body, 'message', 'Заявка не существует или уже удалена');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(actions.getOrganizationsFail(error));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'error'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: message, path: [...BASE_UI_PATH, 'message'] }));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({ status: false, path: [...BASE_UI_PATH, 'loading'] }));
    yield put(uiActions.changeUiLoaderFlag({ status: true, path: [...BASE_UI_PATH, 'completed'] }));
  }
}
