import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { updateFile } from 'api/methods/files/clients';
import { uiActions } from 'bus/ui/actions';
import { filesActions } from 'bus/files/actions';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

const BASE_UI_PATH = ['files', 'clients', 'update'];

export function* updateFileBaseWorker({ payload }) {
  const { clientID, fileID, file, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, fileID],
  })));

  const bodyParams = new FormData();

  bodyParams.append('caption', file.caption);
  file.file instanceof File && bodyParams.append('file', file.file);

  try {
    const response = yield call(updateFile, token, { bodyParams, pathParams: { fileId: fileID } });

    yield put(filesActions.updateFileSuccess(clientID, fileID, response));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    let errorsByValues = null;

    let otherErrors = null;

    switch (status) {
      case 400: {
        const violations = get(body, 'violations', []);

        if (violations.length) {
          const computedError = parseErrorsByFormik(file, violations);

          errorsByValues = computedError.errorsByValues;
          otherErrors = computedError.otherErrors;
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу \'Клиенты\' или к клиенту или активна подписка только на Турсканер');

        break;
      }
      case 404: {
        message = 'Клиент не найден';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    filesActions.updateFileFail(error, { message, errorsByValues, otherErrors });

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, fileID, 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, fileID, 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, fileID, 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, fileID, 'completed'],
    })));
  }
}
