import React, { memo, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import { NotifyLimitUsers } from 'components/subscription/NotifyLimitUsers';

// instruments
import { uiActions } from 'bus/ui/actions';
import { getStorage, setGroupValue, GROUPS } from 'helpers/storage';

const COUNTS_VISIBLE_NOTIFY_STORAGE_KEY = 'countToVisibleNotifyByLimitUsers';
const IS_USER_SHOW_LIMIT_NOTIFY = 'isUserShowLimitNotify';
const MAX_COUNT_CHANGE_PAGES = 10;

export const useNotifyLimitUsers = () => {
  const [activeUsers, limitUsers, isTrialSubscription, ui] = useSelector(state => [
    state.auth.getIn(['profile', 'tf', 'activeUsersCount']),
    state.auth.getIn(['profile', 'tf', 'subscription', 'cnt_man']),
    state.auth.getIn(['profile', 'tf', 'subscription', 'is_trial']),
    state.ui.getIn(['subscription', 'notifyByLimitUsers'])
  ]);

  // methods
  const dispatch = useDispatch();
  const incrementCountToVisible = () => {
    const newGroupValue = getStorage()
      .updateIn([GROUPS.subscription, COUNTS_VISIBLE_NOTIFY_STORAGE_KEY], count => (count ? count + 1 : 1))
      .get(GROUPS.subscription);

    setGroupValue(GROUPS.subscription, newGroupValue);
  };
  const resetCountToVisible = () => {
    const newGroupValue = getStorage()
      .setIn([GROUPS.subscription, COUNTS_VISIBLE_NOTIFY_STORAGE_KEY], 0)
      .get(GROUPS.subscription);

    setGroupValue(GROUPS.subscription, newGroupValue);
  };
  const setShowNotifyToStorage = useCallback(show => {
    const newGroupValue = getStorage()
      .setIn([GROUPS.subscription, IS_USER_SHOW_LIMIT_NOTIFY], show)
      .get(GROUPS.subscription);

    setGroupValue(GROUPS.subscription, newGroupValue);
  }, []);
  const onVisible = useCallback(() => dispatch(uiActions.changeUiLoaderFlag({ status: true, path: ['subscription', 'notifyByLimitUsers', 'visible'] })), []);
  const onHide = useCallback(() => dispatch(uiActions.changeUiLoaderFlag({ status: false, path: ['subscription', 'notifyByLimitUsers', 'visible'] })), []);

  const isDefaultShow = useMemo(() => {
    const visible = getStorage().getIn([GROUPS.subscription, IS_USER_SHOW_LIMIT_NOTIFY], false);

    return limitUsers < activeUsers && !visible && !isTrialSubscription;
  }, [limitUsers, activeUsers, isTrialSubscription]);

  return {
    activeUsers,
    limitUsers,
    isLimited: activeUsers >= limitUsers && !isTrialSubscription,
    isDefaultShow,
    ui,
    onHide,
    onVisible,
    setShowNotifyToStorage,
    resetCountToVisible,
    incrementCountToVisible,
  };
};

export const NotifyLimitUsersContainer = memo(() => {
  const url = useSelector(({ router }) => `${router.location.pathname}`);
  const {
    activeUsers,
    limitUsers,
    isDefaultShow,
    ui,
    onHide,
    onVisible,
    setShowNotifyToStorage,
    resetCountToVisible,
    incrementCountToVisible,
  } = useNotifyLimitUsers();

  useEffect(() => {
    if (isDefaultShow) {
      onVisible();
      setShowNotifyToStorage(true);
    }

    if (activeUsers <= limitUsers) {
      setShowNotifyToStorage(false);
      resetCountToVisible();
    }
  }, []);

  useEffect(() => {
    const storage = getStorage();
    const isUserShowNotify = storage.getIn([GROUPS.subscription, IS_USER_SHOW_LIMIT_NOTIFY], false);

    if (isUserShowNotify) {
      const countsShow = storage.getIn([GROUPS.subscription, COUNTS_VISIBLE_NOTIFY_STORAGE_KEY], 0);

      if (Number(countsShow) === MAX_COUNT_CHANGE_PAGES) {
        onVisible();
        resetCountToVisible();
      } else {
        incrementCountToVisible();
      }
    }
  }, [url]);

  return ui.visible && (
    <NotifyLimitUsers
      activeUsers={activeUsers}
      limitUsers={limitUsers}
      onUnmount={onHide}
    />
  );
});
