import { put, call, select, all } from 'redux-saga/effects';
import * as R from 'ramda';

import { bookingActions } from 'bus/booking/actions';
import {
  getBookingId,
  getExternalMessageWithoutMyView
} from 'bus/booking/selectors';
import { getToken } from 'bus/auth/selectors';

import { updateMessageViewed } from 'api/methods/booking/messages';

export function* updateMessageViewedWorker({ payload: messageId }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  try {
    const view = yield call(
      updateMessageViewed,
      token,
      { pathParams: { id: bookingId, messageId } }
    );

    yield put(bookingActions.updateMessageViewedSuccess(view, messageId));
  } catch (error) {
    console.log(error);
  }
}

export function* updateAllMessagesViewsWorker({ payload: excludedIds = [] }) {
  const messages = yield select(state => {
    const externalMessages = getExternalMessageWithoutMyView(state);

    return R.reject(({ id }) => R.includes(id, excludedIds), externalMessages);
  });

  yield all(
    R.map(({ id }) => put(bookingActions.updateMessageViewed(id)), messages)
  );
}
