// instruments
import BaseApiClass from 'api/BaseApiClass';

export const getBookingSchedules = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/schedules',
  method: 'GET',
  ...params,
})._load({ token });

export const createBookingSchedules = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/schedules',
  method: 'POST',
  ...params,
})._load({ token });

export const updateBookingSchedules = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:bookingId}/schedules',
  method: 'PUT',
  ...params,
})._load({ token });

export const createBookingSchedulesFromTemplate = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/create-schedules',
  method: 'POST',
  ...params,
})._load({ token });
