import { Map } from 'immutable';
import { handleActions, combineActions } from 'redux-actions';

// instruments
import { templatesActions as actions } from './actions';

const initialState = Map({
  templates: {
    data: {
      results: [],
      limit: null,
      count: null,
    },
    page: null,
  },
  template: null,
  defaultTemplate: null,
  templateVariables: [],
});

export default handleActions({
  [actions.getTemplatesSuccess]: (state, { payload: { results, limit, count } }) => state.setIn(['templates', 'data'], { results, limit, count }),
  [actions.clearTemplates]: state => state.setIn(['templates', 'data'], { results: [], limit: null, count: null }),
  [actions.setTemplatesPage]: (state, { payload }) => state.setIn(['templates', 'page'], payload),

  [combineActions(actions.createTemplateSuccess, actions.updateTemplateSuccess, actions.fetchTemplateSuccess, actions.clearTemplate)]: (state, { payload }) => {
    return state.setIn(['template'], payload);
  },
  [actions.deleteTemplateSuccess]: (state, { payload }) => {
    const { id } = payload;

    return state.updateIn(['templates', 'data', 'results'], templates => templates.filter(template => template.id !== id));
  },
  [combineActions(actions.getDefaultTemplateSuccess, actions.clearDefaultTemplate)]: (state, { payload }) => state.setIn(['defaultTemplate'], payload),
  [combineActions(actions.getTemplateVariablesSuccess, actions.clearTemplateVariables)]: (state, { payload: variables }) => state.set('templateVariables', variables),
}, initialState);
