import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instrumetns
import { uiActions } from 'bus/ui/actions';
import { tfActions } from 'bus/tf/actions';
import { fetchProfile } from 'api/methods/tf/profile';

export function* fetchTfProfileWorker({ payload: options }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const UI = get(options, 'UI');

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: ['tf', 'fetchProfile'],
  })));

  try {
    const { user: { tf } } = yield call(fetchProfile, token);

    yield put(tfActions.fetchTfProfileSuccess(tf));
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 403: {
        message = 'Пользователь не имеет права просматривать турфирму';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(tfActions.fetchTfProfileFail(error));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'fetchProfile', 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['tf', 'fetchProfile', 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['tf', 'fetchProfile', 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['tf', 'fetchProfile', 'completed'],
    })));
  }
}
