import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

import { BASKET_STATUSES_BY_API, DEFAULT_BASKET_TYPE } from './constants';

import { basketNormalizer, basketsMapper, claimMapper, prepareOfferForUpdate } from './helpers';

export const getBaskets = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'users/{:userId}/selections',
    method: 'GET',
    ...params,
  });

  instance.convertQueryToServerSchema = R.over(
    R.lensProp('status'),
    (status = DEFAULT_BASKET_TYPE) => BASKET_STATUSES_BY_API[status]
  );

  instance.parseBodyToClientSchema = ({ data, limit, total, page }) => ({
    baskets: basketsMapper(data),
    perPage: limit,
    total,
    page,
  });

  return instance._load({ token });
};

export const getBasketsSimplified = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'users/{:userId}/selections/simplified',
    method: 'GET',
    ...params,
  });

  instance.convertQueryToServerSchema = R.over(
    R.lensProp('status'),
    (status = DEFAULT_BASKET_TYPE) => BASKET_STATUSES_BY_API[status]
  );

  instance.parseBodyToClientSchema = R.over(
    R.lensProp('data'),
    R.map(({ claims, ...rest }) => ({
      ...rest,
      claim: R.pipe(
        R.head,
        R.when(Boolean, claimMapper)
      )(claims)
    }))
  );

  return instance._load({ token });
};

export const addBasket = (token, params = {}) => new BaseApiClass({
  url: 'users/{:userId}/selections',
  method: 'POST',
  ...params,
})._load({ token });

export const addBasketToClaim = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'users/{:clientId}/selections/{:basketId}/claims/{:claimId}',
    method: 'POST',
    ...params,
  });

  instance.parseBodyToClientSchema = claimMapper;

  return instance._load({ token });
};
export const removeBasket = (token, params = {}) => new BaseApiClass({
  url: 'users/{:userId}/selections/{:basketId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const addOfferToBasket = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'users/{:userId}/selections/{:basketId}/offers',
    method: 'POST',
    ...params,
  });

  instance.parseBodyToClientSchema = basketNormalizer;

  return instance._load({ token });
};

export const updateOfferInBasket = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'users/{:userId}/selections/{:basketId}/offers/{:offerId}',
    method: 'PUT',
    ...params,
  });

  instance.parseBodyToClientSchema = basketNormalizer;

  instance.convertBodyToServerSchema = prepareOfferForUpdate;

  return instance._load({ token });
};
export const sendBasket = (token, params = {}) => new BaseApiClass({
  url: 'users/{:userId}/selections/{:basketId}/send',
  method: 'PUT',
  ...params,
})._load({ token });

export const updateBasket = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'users/{:userId}/selections/{:basketId}',
    method: 'PUT',
    ...params,
  });

  instance.parseBodyToClientSchema = basketNormalizer;

  return instance._load({ token });
};

export const moveOfferInBasket = (token, params = {}) => new BaseApiClass({
  url: 'users/{:userId}/selections/{:basketId}/offers/{:offerId}/move',
  method: 'PUT',
  ...params,
})._load({ token });

export const removeOfferFromBasket = (token, params = {}) => new BaseApiClass({
  url: 'users/{:userId}/selections/{:basketId}/offers/{:offerId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const removeClaimFromBasket = (token, params = {}) => new BaseApiClass({
  url: 'users/{:userID}/selections/{:basketID}/claims/{:claimID}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getBasketTemplate = (token, params = {}) => new BaseApiClass({
  url: 'users/{:userId}/selections/{:basketId}/offers/{:template}/render',
  method: 'GET',
  ...params,
})._load({ token });

export const getOfferFromBasketTemplate = (token, params = {}) => new BaseApiClass({
  url: 'selections/{:basketId}/offers/{:offer}/{:type}/render',
  method: 'GET',
  ...params,
})._load({ token });
