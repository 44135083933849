import { race, select, put, take } from 'redux-saga/effects';
import * as R from 'ramda';

import { getBookingData, getTodayRatesByOperator } from 'bus/booking/selectors';
import { bookingActions } from 'bus/booking/actions';
import { accountingActions } from 'bus/accounting/actions';
import { registerUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { getOrganizations } from 'bus/accounting/selector';

import { showInvoiceModal } from 'components/modals/InvoiceModal';

import { SHOW_CREATE_INVOICE_MODAL_UI } from './constants';

export function* showCreateInvoiceModalSaga({ payload: defaultInvoiceData = {} }) {
  yield put(registerUI(createUi({ loading: true }), SHOW_CREATE_INVOICE_MODAL_UI));

  const booking = yield select(getBookingData);
  const isNeedLoadOrganisations = yield select(state => R.isEmpty(getOrganizations(state)));

  yield put(accountingActions.getInvoiceDefault({ booking: booking.id, ...defaultInvoiceData }));
  isNeedLoadOrganisations && (yield put(accountingActions.getOrganizations()));

  const [invoiceDefaultSuccess] = yield race([
    take(accountingActions.getInvoiceDefaultSuccess),
    take(accountingActions.getInvoiceDefaultFail),
  ]);

  if (invoiceDefaultSuccess) {
    const invoice = invoiceDefaultSuccess.payload;

    const ratesByToday = yield select(getTodayRatesByOperator);

    yield put(accountingActions.getContracts(invoice.counterparty.id));

    yield race([
      take(accountingActions.getContractsSuccess),
      take(accountingActions.getContractsFail),
    ]);

    yield put(showInvoiceModal({
      props: {
        invoice: {
          ...invoice,
          currency_rate: ratesByToday[invoice.currency] ?? 1
        },
        meta: { booking: booking.id }
      },
      mapActions2Store: {
        onSuccess: dispatch => {
          dispatch(bookingActions.getBookingInvoices(booking.id));
        },
        onOrganizationChange: (dispatch, _, organization) => {
          dispatch(bookingActions.showCreateInvoiceModal({ organization }));
        },
      }
    }));
  } else {
    yield put(updateUI({ message: 'Ошибка сервера', error: true }, SHOW_CREATE_INVOICE_MODAL_UI));
  }

  yield put(updateUI({ completed: true, loading: false }, SHOW_CREATE_INVOICE_MODAL_UI));
}
