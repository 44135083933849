import { select, call, put } from 'redux-saga/effects';

import { newsActions } from 'bus/news';
import { getToken } from 'bus/auth/selectors';
import { withUIWorker } from 'bus/ui/helpers';

import { getNewsSelections } from 'api/methods/news';
import { getErrorMessage } from 'api/fn/parseErrors';

import { NEWS_SELECTIONS_UI } from '../../constants';

function* getNewsSelectionsWorker() {
  const token = yield select(getToken);

  try {
    const newsSelections = yield call(getNewsSelections, token);

    yield put(newsActions.getNewsSelectionsSuccess(newsSelections));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Заявка или счет не найден',
      }
    );

    error.message = message ? message : 'Ошибка сервера';
    yield put(newsActions.getNewsSelectionsFail(error));
  }
}

export default withUIWorker(
  [newsActions.getNewsSelectionsSuccess, newsActions.getNewsSelectionsFail],
  NEWS_SELECTIONS_UI,
)(getNewsSelectionsWorker);
