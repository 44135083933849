import { put, call, select } from 'redux-saga/effects';

import { accountingActions } from 'bus/accounting/actions';
import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { sendActs } from 'api/methods/accounting';
import { getErrorMessage } from 'api/fn/parseErrors';

import { SEND_AKTS_UI } from '../../constants';

function* sendActsSaga({ payload: { invoices, demo = true, date } }) {
  const token = yield select(getToken);

  try {
    const results = yield call(sendActs, token, {
      bodyParams: {
        invoices,
        demo,
        ...(date ? { akt_date: date } : {})
      }
    });

    yield put(accountingActions.sendAktsSuccess(results));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Заявка или счет не найден',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(accountingActions.sendAktsFail(error));
  }
}

export const sendActsSagaWithUI = withUIWorker(
  [
    accountingActions.sendAktsSuccess,
    accountingActions.sendAktsFail
  ],
  SEND_AKTS_UI,
)(sendActsSaga);
