import { call, select, put } from 'redux-saga/effects';

import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { getClaimSimplified } from 'api/methods/claim/getClaim';

import { clientClaimActions } from '../../actions';

import { GET_CLAIM_SIMPLIFIED_UI } from '../../constants';

function* getClaimSimplifiedWorker({ payload: id }) {
  const token = yield select(getToken);

  try {
    const claimSimplified = yield call(getClaimSimplified, token, { pathParams: { id } });

    yield put(clientClaimActions.getClaimSimplifiedSuccess(claimSimplified));
  } catch (error) {
    const { message } = error.msg.body;

    error.message = message ? message : 'Ошибка сервера';

    yield put(clientClaimActions.getClaimSimplifiedFail(error));
  }
}

const getClaimSimplifiedWorkerWithUI = withUIWorker(
  [
    clientClaimActions.getClaimSimplifiedSuccess,
    clientClaimActions.getClaimSimplifiedFail
  ],
  GET_CLAIM_SIMPLIFIED_UI,
);

export default getClaimSimplifiedWorkerWithUI(getClaimSimplifiedWorker);
