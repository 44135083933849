import { takeEvery } from 'redux-saga/effects';

// instruments
import { initializeDataForPageWorker } from './watchers/initializeDataForPageWorker';
import { initializeDataForPageAction } from '../actions';

export const initializeDataForPageWatcher = {
  * registerWorkerWrapper() {
    yield takeEvery(initializeDataForPageAction, initializeDataForPageWorker);
  },
};
