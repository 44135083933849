import { select, call, put } from 'redux-saga/effects';

// instruments
import { createSource } from 'api/methods/common/createSource';
import { commonActions } from 'bus/common/actions';

export function* createSourceWorker({ payload: description }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const source = yield call(createSource, token, { bodyParams: { description } });

    yield put(commonActions.createSourceSuccess(source));
  } catch (error) {
    yield put(commonActions.createSourceFail(error));
  }
}
