import { call, put, select } from 'redux-saga/effects';

import { deleteUserImage, } from 'api/methods/tf/user';
import { getErrorMessage } from 'api/fn/parseErrors';

import { getToken } from 'bus/auth/selectors';
import { withUI } from 'bus/ui/helpers';

import { tfUsersActions } from '../../../actions';

function* removeUserImageSaga({ payload: userId }) {
  const token = yield select(getToken);

  try {
    yield call(deleteUserImage, token, { pathParams: { userId } });

    yield put(tfUsersActions.removeUserImageSuccess());
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(tfUsersActions.removeUserImageFail(error));
  }
}

export const {
  saga: removeUserImageSagaWithUI,
  uiSelector: removeUserImageSagaSelector
} = withUI(
  'REMOVE_USER_IMAGE',
  [
    tfUsersActions.removeUserImageSuccess,
    tfUsersActions.removeUserImageFail
  ],
  removeUserImageSaga
);
