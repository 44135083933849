import { put, call, select } from 'redux-saga/effects';

import { createBookingSchedulesFromTemplate } from 'api/methods/booking/schedules';

import { bookingActions } from 'bus/booking/actions';
import { getBookingId } from 'bus/booking/selectors';
import { getToken } from 'bus/auth/selectors';

import { newInstance } from 'localization';

import { showToastError, showToastSuccess } from 'services/toaster';

export function* createSchedulesFromTemplateSaga({ payload: templateId }) {
  const token = yield select(getToken);
  const bookingId = yield select(getBookingId);

  try {
    const schedules = yield call(
      createBookingSchedulesFromTemplate,
      token,
      { pathParams: { id: bookingId }, bodyParams: { template_id: templateId } }
    );

    yield put(bookingActions.createSchedulesFromTemplateSuccess(schedules));

    showToastSuccess();
  } catch (error) {
    error.message = newInstance.t('ERRORS:ERROR_LOAD_DATA');

    yield put(bookingActions.createSchedulesFromTemplateFail(error));

    showToastError();
  }
}
