import { put, call, select } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getPatterns } from 'api/methods/common/patterns';

export function* getPatternsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null },
    path: ['common', 'patterns'],
  }));

  try {
    const patterns = yield call(getPatterns, token);

    yield put(commonActions.getPatternsSuccess(patterns));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['common', 'patterns', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка сервера',
      path: ['common', 'patterns', 'message'],
    }));
    yield put(commonActions.getPatternsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['common', 'patterns', 'loading'],
    }));
  }
}
