import { handleActions, combineActions } from 'redux-actions';
import { Map } from 'immutable';

// actions
import { documentFieldsActions as actions } from 'bus/documentFields';

const initialState = Map({
  fields: [],
});

export const documentFieldsReducer = handleActions({
  [combineActions(
    actions.fetchFieldsSuccess,
    actions.resetFields
  )]: (state, { payload: fields }) => {
    return state.set('fields', fields);
  },
}, initialState);
