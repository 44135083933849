import { createSelector } from 'reselect';
import * as R from 'ramda';

const domain = ({ ownModalsDispatcher }) => ownModalsDispatcher;

const getModalsList = createSelector(
  domain,
  modals => R.map(
    ([key, modal]) => R.mergeAll([modal, { key }]),
    R.toPairs(modals)
  )
);

export const getOpenModals = createSelector(
  getModalsList,
  R.filter(R.prop('open'))
);

export const getModalsKeys = createSelector(
  domain,
  R.keys
);

const getModal = () => createSelector(
  domain,
  (_, { key }) => key,
  (modals, key) => modals[key]
);

export const isOpen = () => createSelector(
  getModal(),
  R.pipe(
    R.prop('open'),
    Boolean
  )
);
