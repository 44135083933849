import { put, select, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { getClient } from 'api/methods/client/getClient';
import { uiActions } from 'bus/ui/actions';
import { clientClaimActions } from '../../actions';

export function* fetchClientWorker({ payload, workerOptions }) {
  const { id } = payload;
  const UI = get(workerOptions, 'UI', true);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['clientClaim', 'fetchClient'],
  })));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: false,
    path: ['clientClaim', 'fetchClientError'],
  })));

  try {
    const response = yield call(getClient, token, { pathParams: { id } });

    yield put(clientClaimActions.fetchClientSuccess(response));
  } catch (error) {
    const { status, body } = error.msg;

    let message = '';

    switch (status) {
      case 404: {
        message = 'Клиент не найден';
        break;
      }
      case 403: {
        message = get(body, 'message', 'В доступе отказано');
        break;
      }
      default: message = 'Ошибка сервера';
    }

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: ['clientClaim', 'fetchClientError'],
    })));
    yield put(clientClaimActions.fetchClientFail(error));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['clientClaim', 'fetchClient'],
    })));
  }
}
