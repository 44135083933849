import { createAction } from 'redux-actions';

const PREFIX = 'MT_MODAL_DISPATCHER';

export const setup = createAction(
  `${PREFIX}/setup`,
  ({ key, onClose, props, mapActions2Store }) => ({
    [key]: { onClose, props, mapActions2Store },
  })
);

export const open = createAction(`${PREFIX}/open`);

export const close = createAction(`${PREFIX}/close`);

export const destroy = createAction(`${PREFIX}/destroy`);

export const load = createAction(`${PREFIX}/load`);

export const updateProps = createAction(`${PREFIX}/updateProps`, (key, props) => ({ key, props }));

export const globalReset = createAction(`${PREFIX}/globalReset`);
