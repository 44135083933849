import { createActions } from 'redux-actions';

export const claimActions = createActions({
  UPDATE_CLAIM: (claimID, claim, workerOptions) => ({ claimID, claim, workerOptions }),
  UPDATE_CLAIM_SUCCESS: claim => claim,
  UPDATE_CLAIM_FAIL: [error => error, (...[, processedError]) => processedError],

  UPDATE_DATE_CREATED: (claimID, date, workerOptions) => ({ claimID, date, workerOptions }),
  UPDATE_DATE_CREATED_SUCCESS: (claimID, date) => ({ claimID, date }),
  UPDATE_DATE_CREATED_FAIL: [error => error, (...[, processedError]) => processedError],

  SUBMIT_ORDER_STEP_FORM: (claimID, claim, formik) => ({ claimID, claim, formik }),
}, { prefix: 'MT_CLIENT_CLAIM/CLAIM' });
