import { call, select, put } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';
import { getUniqueAndActiveEpassports } from 'api/methods/client/epassports';

const BASE_UI_PATH = ['common', 'uniqueActiveEpassports', 'fetch'];

export function* getUniqueActiveEpassportsWorker({ payload }) {
  const { clientID, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);
  const token = yield select(({ auth }) => auth.get('token'));

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: null },
    path: [...BASE_UI_PATH, String(clientID)],
  })));

  try {
    const epassports = yield call(
      getUniqueAndActiveEpassports,
      token,
      { pathParams: { client: clientID } }
    );

    yield put(commonActions.getUniqueActiveEpassportsSuccess(clientID, epassports));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 404: {
        message = 'Клиент не найден';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Необходимо иметь разрешение на доступ к разделу Клиенты. Нет доступа к данному клиенту. Запрещено если активна подписка только на Турсканер');

        break;
      }
      default: message = 'Ошбика при скачивание паспортов';
    }

    yield put(commonActions.getUniqueActiveEpassportsFail(error));

    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, String(clientID), 'error'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: message,
      path: [...BASE_UI_PATH, String(clientID), 'message'],
    })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: [...BASE_UI_PATH, String(clientID), 'loading'],
    })));
    UI && (yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: [...BASE_UI_PATH, String(clientID), 'completed'],
    })));
  }
}
