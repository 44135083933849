import { put, select, call, all } from 'redux-saga/effects';

// instruments
import { uiActions } from 'bus/ui/actions';
import { fetchFieldWorker } from 'bus/detailsFields/saga/workers';
import { getClientField } from 'api/methods/claim/fields';
import { clientClaimActions } from '../../actions';

export function* fetchClientFieldsWorker({ payload }) {
  const { id } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const fields = yield select(({ detailsFields }) => detailsFields.get('fields'));

  yield put(uiActions.changeUiLoaderFlag({
    status: true,
    path: ['clientClaim', 'fetchClientField'],
  }));

  try {
    let dataFields = null;

    if (!fields.size) {
      [dataFields] = yield all([
        call(getClientField, token, { pathParams: { id } }),
        call(fetchFieldWorker)
      ]);
    } else {
      dataFields = yield call(getClientField, token, { pathParams: { id } });
    }

    yield put(clientClaimActions.fetchTfFieldsForClientSuccess(dataFields));
  } catch (error) {
    yield put(clientClaimActions.fetchTfFieldsForClientFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['clientClaim', 'fetchClientField'],
    }));
  }
}
