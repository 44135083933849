import BaseApiClass from 'api/BaseApiClass';
import { Map } from 'immutable';

export const getDetails = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/details',
  method: 'GET',
  ...params,
})._load({ token });

export const createDetail = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/details',
  method: 'POST',
  ...params,
})._load({ token });

export const updateDetail = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/details/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteDetail = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/details/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const uploadDetailStamp = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'tfs/{:tf}/details/{:id}/stamp',
    method: 'POST',
    ...params,
  });

  instance.proccessHeaders = defaultHeaders => Map(defaultHeaders).remove('Content-Type').toObject();

  return instance._load({ token });
};

export const deleteDetailStamp = (token, params = {}) => new BaseApiClass({
  url: 'tfs/{:tf}/details/{:id}/stamp',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
