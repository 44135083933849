import { select, call, put } from 'redux-saga/effects';
import { getIn, get } from 'immutable';

// instruments
import { tfActions } from 'bus/tf/actions';
import { createOperatorCredentials } from 'api/methods/tf/operatorCredentials';
import { parseErrorsByFormik } from 'api/fn/parseErrors';

export function* createOperatorCredentialsWorker({ payload }) {
  const { values, operator, formik } = payload;
  const { token, tf } = yield select(({ auth }) => ({
    token: auth.get('token'),
    tf: auth.getIn(['profile', 'tf', 'id']),
  }));

  try {
    const template = yield call(createOperatorCredentials, token, { pathParams: { tf }, bodyParams: { ...values, operator } });

    yield put(tfActions.createOperatorCredentialsSuccess(template));
    formik && (yield call(formik.setStatus, { success: true, message: 'Данные добавлены' }));
  } catch (error) {
    const { status, body } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        if (getIn(body, ['violations', 'length'])) {
          const { errorsByValues, otherErrors } = parseErrorsByFormik(values, body.violations);

          errorsByValues && formik && (yield call(formik.setErrors, errorsByValues));
          otherErrors.length && (message = otherErrors.map(item => item.message).join('. '));

          !errorsByValues && !otherErrors.length && (message = 'Ошибка сервера');
        } else {
          message = 'Ошибка сервера';
        }

        break;
      }
      case 403: {
        message = get(body, 'message', 'Отсутствует доступ к разделу, к ТФ или к переданному ТО');
        break;
      }
      case 404: {
        message = 'ТФ или переданный ТО не найдены';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && (yield call(formik.setStatus, { error: true, message }));
    yield put(tfActions.createOperatorCredentialsFail(error));
  } finally {
    formik && (yield call(formik.setSubmitting, false));
  }
}
