import { call, select, put } from 'redux-saga/effects';

// instruments
import { createRegion } from 'api/methods/common/regions';
import { commonActions } from 'bus/common/actions';

export function* createRegionWorker({ payload: name }) {
  const token = yield select(({ auth }) => auth.get('token'));

  try {
    const region = yield call(createRegion, token, { bodyParams: { name } });

    yield put(commonActions.createRegionSuccess(region));
  } catch (error) {
    yield put(commonActions.createRegionFail(error));
  }
}
