import { spawn } from 'redux-saga/effects';
import * as R from 'ramda';
import { toast, Toaster } from 'react-hot-toast';

import { newInstance } from 'localization';

export const showToastSuccess = (message = newInstance.t('GLOBAL:SAVE_SUCCESS'), options = {}) => {
  toast.success(message, options);
};

export const showToastError = (message = newInstance.t('ERRORS:ERROR'), options = {}) => {
  toast.error(message, options);
};

export const withDefaultNotifications = saga => function* (action) {
  yield spawn(
    saga,
    R.assoc(
      'notifications',
      {
        onShowSuccessMsg: showToastSuccess,
        onShowFailMsg: showToastError,
      },
      action,
    )
  );
};

export {
  Toaster as NotificationsProvider
};
