import { createAction, createActions } from 'redux-actions';

export const uiActions = createActions({
  CHANGE_UI_LOADER_FLAG: ({ status, path }) => ({ status, path }),
  SWITCH_CRM_PAGE: (ref, options) => ({ ref, options }),
  GET_ONE_TIME_ACCESS: (toDo, onSuccess, onFail, id) => ({ toDo, onSuccess, onFail, id }),
});

const PREFIX = 'UI';

const normalizePath = path => (Array.isArray(path) ? path : [path]);

export const registerUI = createAction(
  `${PREFIX}/registerUI`,
  (baseUI, path) => ({
    baseUI,
    path: normalizePath(path),
  })
);

export const updateUI = createAction(
  `${PREFIX}/updateUI`,
  (nextUI, path) => ({
    nextUI,
    path: normalizePath(path),
  })
);

const EMPTY_UI = {
  loading: false,
  completed: false,
  error: false,
  message: null,
};
export const resetUI = createAction(
  `${PREFIX}/resetUI`,
  path => ({
    nextUI: { ...EMPTY_UI },
    path: normalizePath(path),
  })
);
