import { all, put, race, select, take, takeEvery } from 'redux-saga/effects';

import { commonActions } from 'bus/common/actions';
import { clientClaimActions } from 'bus/clientClaim/actions';
import { getClaimId } from 'bus/clientClaim/selectors';

import { showCloseClaimModalLoader } from '../children/components/CommentsManagerBlock/components/modals/helpers';

import { openClaim, showCloseClaimModal } from './actions';

function* showCloseClaimModalWorker() {
  const claimId = yield select(getClaimId);

  yield put(commonActions.getClosingReasons());

  yield put(showCloseClaimModalLoader({
    mapActions2Store: {
      sendCloseReason: (dispatch, _, closeReason) => {
        dispatch(clientClaimActions.closeClaim(closeReason));
      },
    },
  }));

  const [success] = yield race([
    take(clientClaimActions.closeClaimSuccess),
    take(clientClaimActions.closeClaimFail)
  ]);

  success && (yield put(clientClaimActions.getClaim(claimId)));
}

function* openClaimWorker({ payload: message }) {
  const claimId = yield select(getClaimId);

  yield put(clientClaimActions.openClaim(message));

  const [success] = yield race([
    take(clientClaimActions.openClaimSuccess),
    take(clientClaimActions.openClaimFail)
  ]);

  success && (yield put(clientClaimActions.getClaim(claimId)));
}

export default function* () {
  yield all([
    takeEvery(showCloseClaimModal, showCloseClaimModalWorker),
    takeEvery(openClaim, openClaimWorker)
  ]);
}
